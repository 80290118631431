import {
    DataGridPro,
    DEFAULT_GRID_AUTOSIZE_OPTIONS,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { OrdersWrapper, StyledSceneContent } from "../../components/styledComponents";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router'

import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { deleteViewElastic, getAllOrders, getAllOrdersElastic, getAllSearchFieldsElastic, getAllUserViewsExtendedSearch, searchOrdersElastic, getAllOrdersElasticForExport } from "../../store/actions/orderActions";
import SaveView from '../../components/SaveView/SaveView'
import Filter from '../../components/Filter/Filter'

import filtericon from '../../assets/icons/filter_icon.png'
import DeleteIcon from '@mui/icons-material/Delete'
import './ElasticStyle.css'

import { InputAdornment, Box, Paper, Typography, Button, Popover, List, ListItem, ListItemButton, ListItemText, Divider, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { saveAs } from 'file-saver';
import CustomDropdown from '../../components/CustomDropdown';
import CustomTextField from '../../components/CustomTextField';
import CustomDatePicker from '../../components/CustomDatePicker';
import {
    convertCenturyToMDY,
    convertYYYYMMDDToMMDDYY,
    convertMMDDYYToYYYYMMDD,
    convertMilitaryDateToYMD,
    validateTimeField
} from '../../utils/global-functions';
import { POST } from '../../utils/axios';
import CustomAutoComplete from '../../components/CustomAutoComplete';
import {validateDateField} from "../../utils/validateDateField"
import ResponsiveGrid from '../../components/GridCustomComponent';

import {
    sanitizeStringForDBField,
    convertYMDToMDY,
    convertMDYToYMD,
    formatDateTime,
} from '../../utils/global-functions';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
// import ResponsiveGrid from '../../components/GridCustomComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanded view
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { debounce } from "lodash";

const actionStyles = { display: 'flex', width: '100%', padding: '1em', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }


const defaultView = {
    "status": true,
    "orderType": true,
    "controlNumber": true,
    "shipperAgentCode": false,
    "shipperAgent": true,
    "unitNumber": true,
    "secondaryUnit": false,
    "chassisNumber": true,
    "referenceNumber": true,
    "releaseNumber": true,
    "shipper": true,
    "shipperAddress": false,
    "shipperCity": false,
    "shipperState": false,
    "shipperZip": false,
    "shipperPhone": false,
    "stopStatus": true,
    "stopNumber": true,
    "stops.code": false,
    "stops.name": true,
    "stops.address": false,
    "stops.city": false,
    "stops.state": false,
    "stops.zip": false,
    "stops.phone": false,
    "stops.apptDate": true,
    "stops.apptTime": true,
    "stops.pieces": false,
    "stops.weight": false,
    "stops.commodity": false,
    "stops.doorSeal": false,
    "stops.arriveDate": false,
    "stops.arriveTime": false,
    "stops.spotDate": false,
    "stops.spotTime": false,
    "stops.unloadDate": false,
    "stops.unloadTime": false,
    "stops.departDate": false,
    "stops.departTime": false,
    "stops.availDate": false,
    "stops.availTime": false,
    "stops.arriveTruck": false,
    "stops.arriveDriver": false,
    "stops.departTruck": false,
    "stops.departDriver": false,
    "stops.driverNotes": false,
    "stops.driverNotes2": false,
    "stops.etaDate": false,
    "stops.etaTime": false,
    "stops.shipperBillOfLadding": false,
    "stops.pickupNumber": false,
    "createdDate": true,
    "billingDate": true,
    "billingBooking": true,
    "poruPoint": false,
    "disposition": false,
    "dispositionCChassisPickupPoint": false,
    "dispositionCChassisDisposition": false,
    "etaDate": false,
    "etaTime": false,
    "notifyDate": false,
    "notifyTime": false,
    "cuttOffDate": false,    
    "cutOffTime": false,
    "pickDate": false, // Last/Diem Day
    "hazMat": false,
    "equipSize": false,
    "vessel": false,
    "voyageNumber": false,
    "dispositionCDestinationPort": false,
    "shipperOrConsignee": false,
    "consignee": false,
    "consigneeAddress": false,
    "consigneeCity": false,
    "consigneeState": false,
    "consigneeZip": false,
    "consigneePhone": false,
    "poruDate": false,
    "poruTime": false,
    "returnDate": true,
    "returnTime": false,
}

const ElasticDashBoard = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const apiRef = useGridApiRef()
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [showColumnFilter, setColumnFilter] = useState(false);
    const [selectedView, setSelectedView] = useState('')
    const [showDeleteViewButton, setShowDeleteViewButton] = useState(false)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

    const [searchClause, setSearchClause] = useState([
        { clause: '', field: '', operand: '', value: '' }
    ])

    const [view, setView] = useState(defaultView)

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 500,
        page: 0,
    });

    const [elasticFilter, setElasticFilter] = useState([])

    const [sortModel, setSortModel] = useState({
        sortBy: 'controlNumber',
        sortOrder: 'desc',
    });

    const [rows, setRows] = useState([]);
    const [deletedRows, setDeletedRows] = useState([])
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [isExportInProgress, setIsExportInProgress] = useState(false);

    const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
    const unsavedChangesRef = useRef({
        unsavedRows: {},
        rowsBeforeChange: {},
    });
    const [isSaving, setIsSaving] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
     const [isExpanded, setIsExpanded] = useState(true);

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const userViews = useSelector(
        ({ orderReducer }) => orderReducer.allExtendedSearchViews
    );


    const newOrderClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popOverID = open ? "new-order-popover" : undefined;

    // const allOrders = data //passed static data
    const allOrders = useSelector(({ orderReducer }) => orderReducer.allElasticOrders)
    const searchOptions = useSelector(({ orderReducer }) => orderReducer.searchFieldsRes)
    console.log('All orders', allOrders)


    const [searchText, setSearchText] = useState('')
    const [clearBtn, setClearBtn] = useState(false)
    const [openSaveView, setOpenSave] = useState(false);

    // Column auto resizing properties
    const [includeHeaders, setIncludeHeaders] = useState(
        DEFAULT_GRID_AUTOSIZE_OPTIONS.includeHeaders,
    );
    const [includeOutliers, setExcludeOutliers] = useState(
        DEFAULT_GRID_AUTOSIZE_OPTIONS.includeOutliers,
    );
    const [outliersFactor, setOutliersFactor] = useState(
        String(DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor),
    );
    const [expand, setExpand] = useState(DEFAULT_GRID_AUTOSIZE_OPTIONS.expand);

    useEffect(() => {

        if (ordersLocation?.locationNumber) {
            dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null }))
        }
        dispatch(getAllSearchFieldsElastic({}))
        dispatch(getAllUserViewsExtendedSearch({})).then(res => console.log('RESPONSE FOR VIEWS', res))
        setSearchClause([{
            clause: { label: 'AND' },
            field: { fieldName: 'locationNumber', format: "Number", label: "Location" },
            operand: { label: 'IS' },
            value: (typeof ordersLocation?.locationNumber == "number") ? ordersLocation?.locationNumber.toString() : ordersLocation?.locationNumber || '0'
        }])
        setElasticFilter([{
            clause: 'AND',
            field: 'locationNumber',
            operand: 'IS',
            value: ordersLocation?.locationNumber || 0
        }])
    }, [ordersLocation?.locationNumber])

    useEffect(() => {
        let filterRows = allOrders?.data.filter(item =>
            !deletedRows.includes(`${item.controlNumber}-${item['stops.lineNumber']}`)
        );
        setRows(filterRows);
    }, [allOrders?.data])

    const handleSearch = () => {
        // console.log('Searching For', searchText)
        // dispatch(getAllOrders({ locationNumber: ordersLocation.locationNumber, search: searchText }))
        const formattedSearchStr = searchText.trimEnd();
        if (ordersLocation) {
            let searchClause = [
                {
                    clause: { label: 'AND' },
                    field: { fieldName: 'locationNumber', format: "Number", label: "Location" },
                    operand: { label: 'IS' },
                    value: ordersLocation?.locationNumber || 0
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "controlNumber", "format": "Number", "label": "Control #" },
                    "operand": { "label": "IS" }, "value": (!isNaN(formattedSearchStr)) ? formattedSearchStr.substring(0, 9) : '-1'
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "shipperAgent", "format": "Alphanumeric", "label": "Order By" },
                    "operand": { "label": "CONTAINS"}, "value": `${formattedSearchStr}`
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "unitNumber", "format": "Alphanumeric", "label": "Unit #" },
                    "operand": { "label":  "CONTAINS"}, "value": `${formattedSearchStr}`
                }, {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "billingBooking", "format": "Alphanumeric", "label": "Booking" },
                    "operand": { "label": "CONTAINS"}, "value": `${formattedSearchStr}`
                }, {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "billingReference", "format": "Alphanumeric", "label": "Rerference #" },
                    "operand": { "label": "CONTAINS"}, "value": `${formattedSearchStr}`
                },
                {
                    "clause": { "label": "OR" },
                    "field": { "fieldName": "chassisNumber", "format": "Alphanumeric", "label": "Chassis" },
                    "operand": { "label": "CONTAINS" }, "value": `${formattedSearchStr}`
                }]
            handleApplyFilter(searchClause);
        }
    }

    const clearSearch = () => {
        setElasticFilter([{
            clause: 'AND',
            field: 'locationNumber',
            operand: 'IS',
            value: ordersLocation?.locationNumber || 0
        }])
        dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null, query: { page: paginationModel.page, pageSize: paginationModel.pageSize } }))
    }
    const openOrder = (params) => {
        console.log(params.row)
        history.push(`/orders/${params.row.locationNumber}/${params.row.controlNumber}`)
    }
    const handleClickOpen = () => {
        setOpenSave(true);
    };
    const handleCloseSave = (value) => {
        setOpenSave(false);
    };
    const handleFilter = () => {
        setShowFilter(true);
    };
    
    const hazMatMapping = {
        "HAZMAT": "Y",
        "TANKER": "T",
        "OVERWEIGHT": "O",
        "REEFER": "R",
        "RESIDENIAL": "Z",
        "HIGH VALUE": "H",
        "N": "N"
    };

    const handleApplyFilter = (searchClause) => {
        console.log(`In handleApplyFilter searchClause is `, searchClause)
        if (ordersLocation) {
            // Local cache filter for 7 days validity, unless user changes
            // setItem('AppliedElasticFilter', searchClause, 7)
            // let wildCardFields = [
            //     'shipperAgentCode', 'shipperAgent', 'unitNumber', 'chassisNumber', 'billingBooking',
            //     'billingReference', 'secondaryUnit',
            //     'voyageNumber', 'equipSize', 'shipperOrConsignee', 'poruPoint', 'disposition', 'dispositionChassisPickupPoint',
            //     'dispositionChassisDisposition', 'orderType', 'releaseNumber', 'shipper',
            //     'stopStatus', 'stops.code', 'stops.name', 'stops.address', 'stops.city',
            //     'stops.state', 'stops.phone', 'stops.commodity', 'stops.doorSeal', 'stops.shipperBillOfLadding',
            //     'dispositionCDestinationPort', 'consignee', 'consigneeAddress', 'consigneeCity', 'consigneeState',
            //     'consigneePhone', 'status'
            // ];
            let filter = searchClause.filter(item =>
                item.clause?.label &&
                item.field?.fieldName &&
                item.operand?.label &&
                item.field?.format
            ).map(item => ({
                clause: item.clause.label,
                field: item.field.fieldName,
                operand: item.operand.label,
                // value: wildCardFields.includes(item.field.fieldName) && item.value? Array.isArray(item.value) ? item.value : `${item.value}*` : item.value,
                value: item.field.fieldName === "hazMat"
                ? Array.isArray(item.value)
                    ? item.value.map(val => hazMatMapping[val] || val) // Use mapping and fallback
                    : item.value // Fallback if not an array
                : item.value,
                format: item.field.format
            }))
            // filter.push({
            //     clause: 'AND',
            //     field: 'locationNumber',
            //     operand: 'IS',
            //     value: ordersLocation.locationNumber,
            // })

            // let isNested = filter.filter(item => (item.field.toString().includes('.')))
            // if(isNested.length > 0){
            //     let nestedKeys = isNested.map(item => item.field.split('.')[0])
            //     nestedKeys = nestedKeys.filter((value, index, array) => {
            //         return array.indexOf(value) === index;
            //       })
            //     console.log('Nested Keys', nestedKeys)
            //     let nestedObject = {}
            //     let nestedClauses = nestedKeys.map(key => filter.filter(item => (item.field.toString().startsWith(key))))
            //     nestedClauses.forEach((item, index) => {
            //         nestedObject[nestedKeys[index]] = item
            //     })
            //     console.log('Nested Object', nestedObject)
            //     const notNested = filter.filter(item => (!item.field.toString().includes('.')))
            //     nestedObject[nestedKeys[0]] = [
            //         ...notNested,
            //         ...nestedObject[nestedKeys[0]],
            //     ]
            //     filter = {
            //         "nested": nestedObject
            //     }
            // }
            console.log('FILTERING', filter)
            setElasticFilter(filter);
            dispatch(searchOrdersElastic(filter))
        }
    }

    const loadUserView = (event, value) => {
        console.log('Loading View', value)
        if (!value) {
            // Default
            setView(defaultView)
            // setSearchClause([
            //     { clause: '', field: '', operand: '', value: '' }
            // ])
            setSearchClause([{
                clause: { label: 'AND' },
                field: { fieldName: 'locationNumber', format: "Number", label: "Location" },
                operand: { label: 'IS' },
                value: (typeof ordersLocation?.locationNumber == "number") ? ordersLocation?.locationNumber.toString() : ordersLocation?.locationNumber || '0'
            }])
            setShowDeleteViewButton(false)
            setSelectedView('')
            // deleteItem('AppliedElasticFilter')
            if (ordersLocation) {
                dispatch(getAllOrdersElastic({ locationNumber: ordersLocation.locationNumber, search: null }))
            }
        }
        else {
            setSearchClause(value.searchClause)
            handleApplyFilter(value.searchClause)
            setView(value.view)
            setShowDeleteViewButton(true)
            console.log(value)
            setSelectedView(value)
        }
        // if (ordersLocation)
        //     dispatch(searchOrdersElastic(value.searchClause))

    }

    const handleColumnFilter = () => {
        setColumnFilter(true);
    }

    const formatFilterPayload = (searchClause) => {
        let wildCardFields = [
            'shipperAgentCode', 'shipperAgent', 'unitNumber', 'chassisNumber', 'billingBooking',
            'billingReference', 'secondaryUnit',
            'voyageNumber', 'equipSize', 'shipperOrConsignee', 'poruPoint', 'disposition', 'dispositionChassisPickupPoint',
            'dispositionChassisDisposition', 'orderType', 'releaseNumber', 'shipper',
            'stopStatus', 'stops.code', 'stops.name', 'stops.address', 'stops.city',
            'stops.state', 'stops.phone', 'stops.commodity', 'stops.doorSeal', 'stops.shipperBillOfLadding',
            'dispositionCDestinationPort', 'consignee', 'consigneeAddress', 'consigneeCity', 'consigneeState',
            'consigneePhone'
        ];
        let filter = searchClause.map(item => ({
            clause: item.clause.label,
            field: item.field.fieldName,
            operand: item.operand.label,
            value: wildCardFields.includes(item.field.fieldName) ? `${item.value}*` : item.value,
            format: item.field.format
        }))
        return filter;
    }
    const handlePageChange = (params) => {
        setPaginationModel({ pageSize: params.pageSize, page: params.page })
        dispatch(searchOrdersElastic(elasticFilter, { query: { page: params.page, pageSize: params.pageSize, sortBy: sortModel.sortBy, sortOrder: sortModel.sortOrder } }))
    }
    const sortChange = (event) => {
        if (event.length > 0) {
            setSortModel({ sortBy: event[0].field, sortOrder: event[0].sort });
            //console.log('====1', elasticFilter)
            //console.log('====2', searchClause)
            //let searchCriteria = [...elasticFilter, searchClause];
            dispatch(searchOrdersElastic(elasticFilter, { query: { page: paginationModel.page, pageSize: paginationModel.pageSize, sortBy: event[0].field, sortOrder: event[0].sort } }))
        }
    };

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 50,
            flex: 1,
        },
        {
            field: 'orderType',
            headerName: 'Type',
            minWidth: 100,
            flex: 1,
            // editable: true,
            // renderEditCell: (params) => (
            //     <CustomDropdown
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         options={[
            //             { value: 'INBOUND', label: 'INBOUND' },
            //             { value: 'OUTBOUND', label: 'OUTBOUND' },
            //             { value: 'IMPORT', label: 'IMPORT' },
            //             { value: 'EXPORT', label: 'EXPORT' }
            //         ]}
            //     />
            // ),
        },
        {
            field: 'controlNumber',
            headerName: 'Control #',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`/orders/${params.row.locationNumber}/${params.row.controlNumber}`} target='_blank' >{params.row.controlNumber}</a>
                )
            }
        },
        {
            field: 'shipperAgentCode',
            headerName: 'Order By Code',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'shipperAgent',
            headerName: 'Order By',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'unitNumber',
            headerName: 'Unit #',
            minWidth: 100,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={13}
                />
            ),
        },
        {
            field: 'secondaryUnit',
            headerName: 'Secondary Unit #',
            minWidth: 100,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={13}
                />
            ),
        },
        {
            field: 'chassisNumber',
            headerName: 'Chassis',
            minWidth: 100,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={15}
                />
            ),
        },
        {
            field: 'billingReference',
            headerName: 'Reference #',
            minWidth: 100,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={12}
                />
            ),
        },
        {
            field: 'billingBooking',
            headerName: 'Booking #',
            minWidth: 120,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={25}
                />
            ),
        },
        {
            field: 'releaseNumber',
            headerName: 'Release#',
            minWidth: 100,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={30}
                />
            ),
        },
        {
            field: 'shipper',
            headerName: 'Shipper/Consignee',
            //type: 'number',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (typeof params.row.orderType !== 'undefined' && params.row.orderType === 'EXPORT') ? params.row.shipperOrConsignee : params.row.shipper,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint={`/shippers/${ordersLocation?.locationNumber}`}
                    searchKey='searchVal'
                    //queryParams={{ region: "west" }}
                    valueChange={(newValue) => {
                        if (newValue !== null && typeof newValue === "object") {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue.name })

                            // Update stops.name (non-editable field) directly in the row data
                            const updatedRow = {
                                ...params.row,
                                'shipperOrConsShipper': newValue?.code?.substring(0, 10) || '',
                                'shipperAddress': newValue?.address || "",
                                'shipperCity': newValue?.city || "",
                                'shipperState': newValue?.state || "",
                                'shipperZip': newValue?.zip || "",
                                'shipperPhone': newValue?.phone || "",
                                'shipperFax': newValue?.fax || "",
                            };
                            params.api.updateRows([updatedRow]); // Trigger row update
                        } else if (newValue === null) {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: "" })

                            // Update stops.name (non-editable field) directly in the row data
                            const updatedRow = {
                                ...params.row,
                                'shipperOrConsShipper': "",
                                'shipperAddress': "",
                                'shipperCity': "",
                                'shipperState': "",
                                'shipperZip': "",
                                'shipperPhone': "",
                                'shipperFax': "",
                            };
                            params.api.updateRows([updatedRow]); // Trigger row update
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) =>
                        option && option.code
                            ? `${option.code} - ${option.name || ''} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`
                            : ''
                    }
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                    existingObject={{ code: params.value, name: '' }}
                />
            )
        },
        {
            field: 'shipperAddress',
            headerName: 'Shipper Address',
            //type: 'number',
            minWidth: 150,
            flex: 2
        },
        {
            field: 'shipperCity',
            headerName: 'Shipper City',
            //type: 'number',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'shipperState',
            headerName: 'Shipper State',
            //type: 'number',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'shipperZip',
            headerName: 'Shipper Zip',
            //type: 'number',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'shipperPhone',
            headerName: 'Shipper Phone',
            //type: 'number',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'stopStatus',
            headerName: 'Stop Status',
            type: 'number',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'stopNumber',
            headerName: 'Stop #',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'stops.code',
            headerName: 'Stop Shipper/Consignee Code',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint={`/shippers/${ordersLocation?.locationNumber}`}
                    searchKey='searchVal'
                    valueChange={async (newValue) => {
                        if (newValue !== null && typeof newValue === "object") {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue.code })

                            // Update stops.name (non-editable field) directly in the row data
                            const updatedRow = {
                                ...params.row,
                                'stops.name': newValue?.name || "",
                                'stops.address': newValue?.address || "",
                                'stops.city': newValue?.city || "",
                                'stops.state': newValue?.state || "",
                                'stops.zip': newValue?.zip || "",
                                'stops.fax': newValue?.fax || "",
                            };
                            params.api.updateRows([updatedRow]); // Trigger row update
                        } else if (newValue === null) {
                            if (params.row['stops.lineNumber'] !== 1 && params.row['totalStops'] !== 1) {
                                params.api.setEditCellValue({ id: params.id, field: params.field, value: "" })

                                // Update stops.name (non-editable field) directly in the row data
                                const updatedRow = {
                                    ...params.row,
                                    'stops.name': "",
                                    'stops.address': "",
                                    'stops.city': "",
                                    'stops.state': "",
                                    'stops.zip': "",
                                    'stops.fax': "",
                                };
                                params.api.updateRows([updatedRow]); // Trigger row update
                            } else {
                                alert('Stop 1 cannot be removed!')
                            }
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) =>
                        option && option.code
                            ? `${option.code} - ${option.name || ''} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`
                            : ''
                    }
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                    existingObject={{ code: params.value, name: '' }}
                />
            )
        },
        {
            field: 'stops.name',
            headerName: 'Stop Shipper/Consignee',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.address',
            headerName: 'Stop Address',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.city',
            headerName: 'Stop City',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.state',
            headerName: 'Stop State',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.zip',
            headerName: 'Stop Zip',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.phone',
            headerName: 'Stop Phone',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.apptDate',
            headerName: 'Appt Date ',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                // Always display in MM/DD/YYYY format
                return params.row["stops.apptDate"] ? convertYMDToMDY(params.row["stops.apptDate"]) : '';
            },
            renderEditCell: (params) => (
                <CustomDatePicker
                    size="small"
                    className="width_100"
                    variant="standard"
                    value={params.row["stops.apptDate"] ? convertMDYToYMD(params.row["stops.apptDate"]) : ''}
                    onValueChange={(selectedVal) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal) // Ensure MM/DD/YYYY format
                        });
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 130,
            flex: 1,
            valueGetter: (params) => {
                const datePart = convertMDYToYMD(params.row["stops.apptDate"]);
                return datePart;
            }
        },
        {
            field: 'stops.apptTime',
            headerName: 'Appt Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={12}
                />
            ),
        },
        {
            field: 'stops.pieces',
            headerName: 'Stop Pieces',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={12}
                />
            ),
        },
        {
            field: 'stops.weight',
            headerName: 'Stop Weight',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={10}
                />
            ),
        },
        {
            field: 'stops.commodity',
            headerName: 'Stop Commodity',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={50}
                />
            ),
        },
        {
            field: 'stops.doorSeal',
            headerName: 'Stop Door Seal',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={30}
                />
            ),
        },
        {
            field: 'stops.arriveDate',
            headerName: 'Arrival Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
              const formattedDate = params.row['stops.arriveDate']
                ? formatDateTime(params.row['stops.arriveDate'], params.row['stops.arriveTime'])
                : '';
              return formattedDate;
            },
            renderEditCell: (params) => {
              const isoFormattedDate = (params.row['stops.arriveDate'] && params.row['stops.arriveTime'])
                ? `${convertMDYToYMD(params.row['stops.arriveDate'])}T${params.row['stops.arriveTime']}`
                : '';
          
              return (
                <CustomDateTimePicker
                  variant="standard"
                  size="small"
                  className="width_100"
                  value={isoFormattedDate || ''}
                  onValueChange={(selectedVal) => {
                    const formattedApptDate = convertYMDToMDY(selectedVal.eDate);
                    const apptTime = selectedVal.eTime;
          
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: formattedApptDate, // MM/DD/YYYY format
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: 'stops.arriveTime',
                      value: apptTime, // HH:mm
                    });
                  }}
                  hasFocus={params.hasFocus}
                  isArrowKeyDisabled={true}
                />
              );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              // Access 'stops.arriveDate' and 'stops.arriveTime' using bracket notation
              const datePart = convertMDYToYMD(params.row['stops.arriveDate']);
              const timePart = params.row['stops.arriveTime'] || '00:00:00'; // Default to midnight if no time
              return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
              // Ensure values are valid Date objects before comparing
              const date1 = v1 instanceof Date ? v1 : new Date(v1);
              const date2 = v2 instanceof Date ? v2 : new Date(v2);
              return date1 - date2;
            },
        },
        {
            field: 'stops.arriveTime',
            headerName: 'Arrive Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'stops.spotDate',
            headerName: 'Spot Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
              const formattedDate = params.row['stops.spotDate']
                ? formatDateTime(params.row['stops.spotDate'], params.row['stops.spotTime'])
                : '';
              return formattedDate;
            },
            renderEditCell: (params) => {
              const isoFormattedDate = (params.row['stops.spotDate'] && params.row['stops.spotTime'])
                ? `${convertMDYToYMD(params.row['stops.spotDate'])}T${params.row['stops.spotTime']}`
                : '';
          
              return (
                <CustomDateTimePicker
                  variant="standard"
                  size="small"
                  className="width_100"
                  value={isoFormattedDate || ''}
                  onValueChange={(selectedVal) => {
                    const formattedspotDate = convertYMDToMDY(selectedVal.eDate);
                    const spotTime = selectedVal.eTime;
          
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: formattedspotDate, // MM/DD/YYYY format
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: 'stops.spotTime',
                      value: spotTime, // HH:mm
                    });
                  }}
                  hasFocus={params.hasFocus}
                  isArrowKeyDisabled={true}
                />
              );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              // Access 'stops.spotDate' and 'stops.spotTime' using bracket notation
              const datePart = convertMDYToYMD(params.row['stops.spotDate']);
              const timePart = params.row['stops.spotTime'] || '00:00:00'; // Default to midnight if no time
              return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
              // Ensure values are valid Date objects before comparing
              const date1 = v1 instanceof Date ? v1 : new Date(v1);
              const date2 = v2 instanceof Date ? v2 : new Date(v2);
              return date1 - date2;
            },
        },
        {
            field: 'stops.spotTime',
            headerName: 'Spot Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'stops.unloadDate',
            headerName: 'Unload Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
              const formattedDate = params.row['stops.unloadDate']
                ? formatDateTime(params.row['stops.unloadDate'], params.row['stops.unloadTime'])
                : '';
              return formattedDate;
            },
            renderEditCell: (params) => {
              const isoFormattedDate = (params.row['stops.unloadDate'] && params.row['stops.unloadTime'])
                ? `${convertMDYToYMD(params.row['stops.unloadDate'])}T${params.row['stops.unloadTime']}`
                : '';
          
              return (
                <CustomDateTimePicker
                  variant="standard"
                  size="small"
                  className="width_100"
                  value={isoFormattedDate || ''}
                  onValueChange={(selectedVal) => {
                    const formattedunloadDate = convertYMDToMDY(selectedVal.eDate);
                    const unloadTime = selectedVal.eTime;
          
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: formattedunloadDate, // MM/DD/YYYY format
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: 'stops.unloadTime',
                      value: unloadTime, // HH:mm
                    });
                  }}
                  hasFocus={params.hasFocus}
                  isArrowKeyDisabled={true}
                />
              );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              // Access 'stops.unloadDate' and 'stops.unloadTime' using bracket notation
              const datePart = convertMDYToYMD(params.row['stops.unloadDate']);
              const timePart = params.row['stops.unloadTime'] || '00:00:00'; // Default to midnight if no time
              return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
              // Ensure values are valid Date objects before comparing
              const date1 = v1 instanceof Date ? v1 : new Date(v1);
              const date2 = v2 instanceof Date ? v2 : new Date(v2);
              return date1 - date2;
            },
        },
        {
            field: 'stops.unloadTime',
            headerName: 'Unload Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'stops.departDate',
            headerName: 'Depart Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
              const formattedDate = params.row['stops.departDate']
                ? formatDateTime(params.row['stops.departDate'], params.row['stops.departTime'])
                : '';
              return formattedDate;
            },
            renderEditCell: (params) => {
              const isoFormattedDate = (params.row['stops.departDate'] && params.row['stops.departTime'])
                ? `${convertMDYToYMD(params.row['stops.departDate'])}T${params.row['stops.departTime']}`
                : '';
          
              return (
                <CustomDateTimePicker
                  variant="standard"
                  size="small"
                  className="width_100"
                  value={isoFormattedDate || ''}
                  onValueChange={(selectedVal) => {
                    const formatteddepartDate = convertYMDToMDY(selectedVal.eDate);
                    const departTime = selectedVal.eTime;
          
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: formatteddepartDate, // MM/DD/YYYY format
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: 'stops.departTime',
                      value: departTime, // HH:mm
                    });
                  }}
                  hasFocus={params.hasFocus}
                  isArrowKeyDisabled={true}
                />
              );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              // Access 'stops.departDate' and 'stops.departTime' using bracket notation
              const datePart = convertMDYToYMD(params.row['stops.departDate']);
              const timePart = params.row['stops.departTime'] || '00:00:00'; // Default to midnight if no time
              return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
              // Ensure values are valid Date objects before comparing
              const date1 = v1 instanceof Date ? v1 : new Date(v1);
              const date2 = v2 instanceof Date ? v2 : new Date(v2);
              return date1 - date2;
            },
        },
        {
            field: 'stops.departTime',
            headerName: 'Depart Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'stops.availDate',
            headerName: 'Avail Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
              const formattedDate = params.row['stops.availDate']
                ? formatDateTime(params.row['stops.availDate'], params.row['stops.availTime'])
                : '';
              return formattedDate;
            },
            renderEditCell: (params) => {
              const isoFormattedDate = (params.row['stops.availDate'] && params.row['stops.availTime'])
                ? `${convertMDYToYMD(params.row['stops.availDate'])}T${params.row['stops.availTime']}`
                : '';
          
              return (
                <CustomDateTimePicker
                  variant="standard"
                  size="small"
                  className="width_100"
                  value={isoFormattedDate || ''}
                  onValueChange={(selectedVal) => {
                    const formattedavailDate = convertYMDToMDY(selectedVal.eDate);
                    const availTime = selectedVal.eTime;
          
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: formattedavailDate, // MM/DD/YYYY format
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: 'stops.availTime',
                      value: availTime, // HH:mm
                    });
                  }}
                  hasFocus={params.hasFocus}
                  isArrowKeyDisabled={true}
                />
              );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              // Access 'stops.availDate' and 'stops.availTime' using bracket notation
              const datePart = convertMDYToYMD(params.row['stops.availDate']);
              const timePart = params.row['stops.availTime'] || '00:00:00'; // Default to midnight if no time
              return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
              // Ensure values are valid Date objects before comparing
              const date1 = v1 instanceof Date ? v1 : new Date(v1);
              const date2 = v2 instanceof Date ? v2 : new Date(v2);
              return date1 - date2;
            },
        },
        {
            field: 'stops.availTime',
            headerName: 'Avail Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'stops.arriveTruck',
            headerName: 'Arrive Truck',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.arriveDriver',
            headerName: 'Arrive Driver',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.departTruck',
            headerName: 'Depart Truck',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.departDriver',
            headerName: 'Depart Driver',
            minWidth: 160,
            flex: 1
        },
        {
            field: 'stops.driverNotes',
            headerName: 'Driver Notes 1',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={12}
                />
            ),
        },
        {
            field: 'stops.driverNotes2',
            headerName: 'Driver Notes 2',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={12}
                />
            ),
        },
        {
            field: 'stops.etaDate',
            headerName: 'Stops ETA Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
              const formattedDate = params.row['stops.etaDate']
                ? formatDateTime(params.row['stops.etaDate'], params.row['stops.etaTime'])
                : '';
              return formattedDate;
            },
            renderEditCell: (params) => {
              const isoFormattedDate = (params.row['stops.etaDate'] && params.row['stops.etaTime'])
                ? `${convertMDYToYMD(params.row['stops.etaDate'])}T${params.row['stops.etaTime']}`
                : '';
          
              return (
                <CustomDateTimePicker
                  variant="standard"
                  size="small"
                  className="width_100"
                  value={isoFormattedDate || ''}
                  onValueChange={(selectedVal) => {
                    const formattedetaDate = convertYMDToMDY(selectedVal.eDate);
                    const etaTime = selectedVal.eTime;
          
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: formattedetaDate, // MM/DD/YYYY format
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: 'stops.etaTime',
                      value: etaTime, // HH:mm
                    });
                  }}
                  hasFocus={params.hasFocus}
                  isArrowKeyDisabled={true}
                />
              );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              const datePart = convertMDYToYMD(params.row['stops.etaDate']);
              const timePart = params.row['stops.etaTime'] || '00:00:00'; // Default to midnight if no time
              return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
              const date1 = v1 instanceof Date ? v1 : new Date(v1);
              const date2 = v2 instanceof Date ? v2 : new Date(v2);
              return date1 - date2;
            },
        },
        {
            field: 'stops.etaTime',
            headerName: 'Stops ETA Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'stops.shipperBillOfLadding',
            headerName: 'Stop Bill Of Ladding',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={30}
                />
            ),
        },
        {
            field: 'stops.pickupNumber',
            headerName: 'Stop PO/PU',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={12}
                />
            ),
        },
        {
            field: 'createdDate',
            headerName: 'Created',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{convertCenturyToMDY(params.row.createdDate)}</p>
                )
            }
        },
        {
            field: 'billingDate',
            headerName: 'Billed Date',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => convertCenturyToMDY(params.row["billingDate"]),
            // editable: true,
            // renderCell: (params) => convertCenturyToMDY(params.row["billingDate"]),
            // renderEditCell: (params) => (
            //     <CustomDatePicker
            //         size="small"
            //         className="width_100"
            //         variant="standard"
            //         value={params.row["billingDate"] || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: convertYYYYMMDDToMMDDYY(selectedVal)
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //         isArrowKeyDisabled={true}
            //     />
            // )
        },
        {
            field: 'poruPoint',
            headerName: 'Pickup Location',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint="/rail-road"
                    searchKey='code'
                    //queryParams={{ region: "west" }}
                    valueChange={(newValue) => {
                        if (newValue !== null & typeof newValue === 'object') {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.code || '' })
                        } else if (newValue === null) {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: '' })
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) =>
                        option && option.code
                            ? `${option.code} - ${option.name || ''} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`
                            : ''
                    }
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                    existingObject={{ code: params.value, name: '' }}
                />
            )
        },
        {
            field: 'disposition',
            headerName: 'Disposition',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint="/rail-road"
                    searchKey='code'
                    //queryParams={{ region: "west" }}
                    valueChange={(newValue) => {
                        if (newValue !== null & typeof newValue === 'object') {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.code || '' })
                        } else if (newValue === null) {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: '' })
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) =>
                        option && option.code
                            ? `${option.code} - ${option.name || ''} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`
                            : ''
                    }
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                    existingObject={{ code: params.value, name: '' }}
                />
            )
        },
        {
            field: 'dispositionCChassisPickupPoint',
            headerName: 'Chassis Pickup Location',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint="/rail-road"
                    searchKey='code'
                    //queryParams={{ region: "west" }}
                    valueChange={(newValue) => {
                        if (newValue !== null & typeof newValue === 'object') {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.code || '' })
                        } else if (newValue === null) {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: '' })
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) => `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                />
            )
        },
        {
            field: 'dispositionCChassisDisposition',
            headerName: 'Chassis Del Location',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint="/rail-road"
                    searchKey='code'
                    //queryParams={{ region: "west" }}
                    valueChange={(newValue) => {
                        if (newValue !== null & typeof newValue === 'object') {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.code || '' })
                        } else if (newValue === null) {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: '' })
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) => `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                />
            )
        },
        {
            field: 'etaDate',
            headerName: 'ETA Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.etaDate ? formatDateTime(params.row.etaDate, params.row.etaTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.etaDate)}T${params.row.etaTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedetaDate = convertYMDToMDY(selectedVal.eDate);
                            const etaTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedetaDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'etaTime',
                                value: etaTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine etaDate and etaTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.etaDate);
                const timePart = params.row.etaTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        {
            field: 'etaTime',
            headerName: 'ETA Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            //renderEditCell: (params) => (
                // <CustomTextField
                //     hasFocus={params.hasFocus}
                //     value={params.value}
                //     onChange={(newValue) =>
                //         params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                //     }
                //     maxLength={12}
                // />
            //),
        },
        {
            field: 'notifyDate',
            headerName: 'Notify Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.notifyDate ? formatDateTime((params.row.notifyDate), params.row.notifyTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.notifyDate)}T${params.row.notifyTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedNotifyDate = convertYMDToMDY(selectedVal.eDate);
                            const notifyTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedNotifyDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'notifyTime',
                                value: notifyTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine notifyDate and notifyTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.notifyDate);
                const timePart = params.row.notifyTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        {
            field: 'notifyTime',
            headerName: 'Notify Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },
        {
            field: 'cuttOffDate',
            headerName: 'CutOff Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.cuttOffDate ? formatDateTime(params.row.cuttOffDate, params.row.cutOffTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.cuttOffDate)}T${params.row.cutOffTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedcuttOffDate = convertYMDToMDY(selectedVal.eDate);
                            const cutOffTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedcuttOffDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'cutOffTime',
                                value: cutOffTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine cuttOffDate and cuttOffDateTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.cuttOffDate);
                const timePart = params.row.cutOffTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },,
        {
            field: 'cutOffTime',
            headerName: 'CutOff Time',
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTextField
            //         hasFocus={params.hasFocus}
            //         value={params.value}
            //         onChange={(newValue) =>
            //             params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
            //         }
            //         maxLength={12}
            //     />
            // ),
        },        
        {
            field: 'pickDate',
            headerName: 'Last/Diem Date',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderCell: (params) => {
                // Always display in MM/DD/YYYY format
                return params.row.pickDate ? convertYMDToMDY(params.row.pickDate) : '';
            },
            renderEditCell: (params) => (
                <CustomDatePicker
                    size="small"
                    className="width_100"
                    variant="standard"
                    value={params.row.pickDate ? convertMDYToYMD(params.row.pickDate) : ''}
                    onValueChange={(selectedVal) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal) // Ensure MM/DD/YYYY format
                        });
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 130,
            flex: 1,
            valueGetter: (params) => {
                const datePart = convertMDYToYMD(params.row.pickDate);
                return datePart;
            }
        },
        {
            field: 'hazMat',
            headerName: 'Hazmat',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderCell: (params) => {
                let HazMatLabel = "";
                switch (params.row.hazMat) {
                    case 'T':
                        HazMatLabel = 'Tanker';
                        break;
                    case 'O':
                        HazMatLabel = 'OW';
                        break;
                    case 'R':
                        HazMatLabel = 'Reefer';
                        break;
                    case 'Z':
                        HazMatLabel = 'Rez';
                        break;
                    case 'H':
                        HazMatLabel = 'HV';
                        break;
                    default:
                        HazMatLabel = '';
                        break;
                }
                return HazMatLabel;
            },
            renderEditCell: (params) => (
                <CustomDropdown
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    options={[
                        { value: 'Y', label: 'HazMat' },
                        { value: 'T', label: 'Tanker' },
                        { value: 'O', label: 'OW' },
                        { value: 'R', label: 'Reefer' },
                        { value: 'Z', label: 'Rez' },
                        { value: 'H', label: 'HV' }
                    ]}
                />
            ),
        },
        {
            field: 'equipSize',
            headerName: 'Equipment Size',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={8}
                />
            ),
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={25}
                />
            ),
        },
        {
            field: 'voyageNumber',
            headerName: 'Voyage',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={5}
                />
            ),
        },
        {
            field: 'dispositionCDestinationPort',
            headerName: 'Destination Port',
            minWidth: 160,
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <CustomTextField
                    hasFocus={params.hasFocus}
                    value={params.value}
                    onChange={(newValue) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                    }
                    maxLength={25}
                />
            ),
        },
        {
            field: 'shipperOrConsignee',
            headerName: 'Consignee',
            minWidth: 160,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <CustomAutoComplete
                    hasFocus={params.hasFocus}
                    fetchDataEndpoint={`/shippers/${ordersLocation?.locationNumber}`}
                    searchKey='searchVal'
                    //queryParams={{ region: "west" }}
                    valueChange={(newValue) => {
                        if (newValue !== null && typeof newValue === "object") {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue.code })

                            const updatedRow = {
                                ...params.row,
                                'consignee': newValue?.name || "",
                                'consigneeAddress': newValue?.address || "",
                                'consigneeCity': newValue?.city || "",
                                'consigneeState': newValue?.state || "",
                                'consigneeZip': newValue?.zip || "",
                                'consigneePhone': newValue?.fax || "",
                                'consigneeFax': newValue?.fax || "",
                            };
                            params.api.updateRows([updatedRow]); // Trigger row update

                        } else if (newValue === null) {

                            params.api.setEditCellValue({ id: params.id, field: params.field, value: "" })

                            const updatedRow = {
                                ...params.row,
                                'consignee': "",
                                'consigneeAddress': "",
                                'consigneeCity': "",
                                'consigneeState': "",
                                'consigneeZip': "",
                                'consigneePhone': "",
                                'consigneeFax': "",
                            };
                            params.api.updateRows([updatedRow]); // Trigger row update                          
                        }
                    }
                    }
                    existingValue={params.value}
                    optionLabel={(option) =>
                        option && option.code
                            ? `${option.code} - ${option.name || ''} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`
                            : ''
                    }
                    optionKey="code"
                    placeholder="Search..."
                    debounceTime={300}
                    existingObject={{ code: params.value, name: '' }}
                />
            )
        },
        {
            field: 'consignee',
            headerName: 'Consignee Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeAddress',
            headerName: 'Consignee Address',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeCity',
            headerName: 'Consignee City',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeState',
            headerName: 'Consignee State',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneeZip',
            headerName: 'Consignee Zip',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'consigneePhone',
            headerName: 'Consignee Phone',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'poruDate',
            headerName: 'Pickup Date & Time',   //Pickup DateTime in OrderScreen
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.poruDate ? formatDateTime(params.row.poruDate, params.row.poruTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.poruDate)}T${params.row.poruTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedporuDate = convertYMDToMDY(selectedVal.eDate);
                            const poruTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedporuDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'poruTime',
                                value: poruTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine poruDate and poruTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.poruDate);
                const timePart = params.row.poruTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        {
            field: 'poruTime',
            headerName: 'Pickup Time',    //Pickup DateTime in OrderScreen
            minWidth: 160,
            flex: 1,
            editable: true,
            hideable:false,
            //renderEditCell: (params) => (
                // <CustomTextField
                //     hasFocus={params.hasFocus}
                //     value={params.value}
                //     onChange={(newValue) =>
                //         params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                //     }
                //     maxLength={12}
                // />
            //),
        },
        {
            field: 'returnDate',
            headerName: 'Return Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.returnDate ? formatDateTime(params.row.returnDate, params.row.returnTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.returnDate)}T${params.row.returnTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedreturnDate = convertYMDToMDY(selectedVal.eDate);
                            const returnTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedreturnDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'returnTime',
                                value: returnTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine returnDate and returnTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.returnDate);
                const timePart = params.row.returnTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        {
            field: 'returnTime',
            headerName: 'Return Time',
            minWidth: 160,
            flex: 1,
            editable: true,            
            hideable:false,
            //renderEditCell: (params) => (
                // <CustomTextField
                //     hasFocus={params.hasFocus}
                //     value={params.value}
                //     onChange={(newValue) =>
                //         params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                //     }
                //     maxLength={12}
                // />
            //),
        },
    ];
    
    /**
     * Handle remove selected button click
     * manually select row & remove rows from the view
     */
    const handleRemoveSelected = () => {
        let rowsSelected = rowSelectionModel.map(item => (`${item.split('-')[1]}-${item.split('-')[2]}`))
        let filterRows = rows.filter(item =>
            !rowsSelected.includes(`${item.controlNumber}-${item['stops.lineNumber']}`)
        );
        setDeletedRows([...deletedRows, ...rowsSelected]);
        setRowSelectionModel([]);
        setRows(filterRows);
    }

    /**
     * Custom Export feature
     */
    const dateFields = [
        'stops.apptDate',
        'stops.arriveDate',
        'stops.spotDate',
        'stops.unloadDate',
        'stops.departDate',
        'stops.availDate',
        'stops.etaDate',
        'createdDate',
        'billingDate',
        'etaDate',
        'notifyDate',
        'cuttOffDate',
        'pickDate',
        'returnDate',
    ]

    const timeFields = [
        'lfdTime', // Time related field
        'apptTime',
        'etaTime',
        'notifyTime',
        'cutOffTime',
        'stops.apptTime',
        'stops.arriveTime',
        'stops.spotTime',
        'stops.unloadTime',
        'stops.departTime',
        'stops.availTime',
        'stops.etaTime',
    ];
    
    const exportToCsv = (data, headers, fields, fileName) => {
        if (!data || !data.length) {
            console.error("No data available to export.");
            return;
        }

        // Add headers as the first row in the CSV
        const csvRows = [headers.join(',')];

        // Add data rows
        data.forEach(row => {
            const values = fields.map(field => {
                let value = row[field];

                // Merge date and time if applicable
                // Merge date and time if applicable
                // Merge date and time if applicable
                if (dateFields.includes(field)) {
                    const timeField = field.replace('Date', 'Time'); // Get corresponding time field
                    if (row[timeField]) {
                        value = `${value} ${row[timeField]}`; // Concatenate date and time
                    }
                    // Explicitly format dates for Excel
                    if (value) {
                        value = `="${value}"`; // Prevents Excel from auto-formatting
                    }
                } else if (timeFields.includes(field)) {
                    return null; // Skip time fields since they are merged with dates
                }

                // Handle special cases
                if (value === 0) {
                    value = '';
                } else if (typeof value === 'string') {
                    value = `"${value.replace(/"/g, '""')}"`; // Escape quotes
                }

                return value;
            });
            csvRows.push(values.join(','));
        });

        // Create a Blob and trigger download
        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    const handleExport = async () => {
        setIsExportInProgress(true); // Start loader
        try {
            const fetchAllData = async () => {
                const response = await getAllOrdersElasticForExport(elasticFilter, { query: { page: 1, pageSize: 1000 } });
                return response.data;
            };

            const allData = await fetchAllData();

            // Get visible columns and their field names from DataGrid
            const visibleColumns = apiRef.current.getVisibleColumns();
            const headers = visibleColumns.map(column => column.headerName || column.field); // Use `headerName` or fallback to `field`
            const fields = visibleColumns.map(column => column.field); // Use `field` for accessing data

            // Export to CSV
            exportToCsv(allData, headers, fields, `orders-Loc#${ordersLocation?.locationNumber}.csv`);
        } catch (error) {
            console.error("Failed to fetch all data for export:", error);
        } finally {
            setIsExportInProgress(false); // Stop loader
        }
    };


    /**
     * Update apiRef for edited fields
     * @param {*} newRow 
     * @param {*} oldRow 
     * @returns 
     */
    const processRowUpdate = (newRow, oldRow) => {
        const rowId = newRow.id;

        // **Immediately check the count** before calling debounce
        const unsavedRowKeys = Object.keys(unsavedChangesRef.current.unsavedRows);
        const unsavedCount = unsavedRowKeys.length;

        if (unsavedCount >= 10 && !unsavedRowKeys.includes(rowId)) {
            setSnackOpen(true);
            setSnackContent({
                severity: "error",
                message: "You can edit up to 10 rows at a time. Please save or discard changes before modifying more rows.",
            });

            return oldRow; // Discard the change
        } else {
            setSnackOpen(false);
        }
        // No need to delete anything; just prevent adding beyond the limit
        updateUnsavedRows(rowId, newRow, oldRow);
        setHasUnsavedRows(true);
        return newRow;
    };

    const updateUnsavedRows = debounce((rowId, newRow, oldRow) => {
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    }, 200); // Adjust delay if necessary

    /**
     * Handle save change button click
     */
    const saveChanges = async () => {
        setIsSaving(true);
        try {
            setSnackOpen(true);
            setSnackContent({ severity: "info", message: "Saving multiple orders will take an extended amount of time, roughly 5 seconds per order." })
            let orderData = mapOrdersToServer(unsavedChangesRef.current.unsavedRows);
            let res = await POST(`/orders/bulk-update/${ordersLocation?.locationNumber}`, orderData);
            if (res?.httpCode === 200) {
                setSnackOpen(true);
                setSnackContent({ severity: "success", message: res?.message })
            } else if (res?.httpCode === 500) {
                setSnackOpen(true);
                setSnackContent({ severity: "success", message: res?.message })
            }
        } catch (error) {
            setSnackOpen(true);
            setSnackContent({ severity: "error", message: 'Something went wrong, Please try again!' })
        } finally {
            setHasUnsavedRows(false);
            setIsSaving(false);
            unsavedChangesRef.current = {
                unsavedRows: {},
                rowsBeforeChange: {},
            };
        }
    };

    /**
     * Handle discard changes button click
     */
    const discardChanges = () => {
        setHasUnsavedRows(false);
        apiRef.current.updateRows(
            Object.values(unsavedChangesRef.current.rowsBeforeChange),
        );
        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
        };
    };

    const mapOrdersToServer = (orders) => {
        return Object.entries(orders).map(([key, item]) => {
            const billingControl = {
                orderBy: {
                    shipperAgentCode: item.shipperAgentCode || "",
                    shipperAgent: item.shipperAgent || "",
                    shipperOrAgentLoc: item.shipperOrAgentLoc || "",
                    shipperAgentPhone: item.shipperAgentPhone || "",
                    shipperAgentFax: item.shipperAgentFax || "",
                    email: "", // TODO contact api
                    contact: "", // TODO contact api
                    customerNumber: item.customerNumber || "",
                    billingReference: item.billingReference || "",
                    status: item.status || "",
                    billingDate: validateDateField(item.billingDate) || "",
                    dispositionCOrderByContactSeq: item.dispositionCOrderByContactSeq || 0,
                },
                equipmentDetails: {
                    unitNumber: item.unitNumber || "",
                    chassisNumber: item.chassisNumber || "",
                    equipSize: item.equipSize || "",
                    realeaseNumber: item.releaseNumber || "",
                    ssl: "",
                    billingBooking: item.billingBooking || "",
                    voyageNumber: item.voyageNumber || "",
                    vessel: item.vessel || "",
                    dispositionCDestinationPort: item.dispositionCDestinationPort || "",
                    equipmentType: "",
                    secondaryUnit: item.secondaryUnit || "",
                    secondaryChassis: "",
                    chassisProvider: "",
                    chassisRelease: "",
                    hazMat: item.hazMat || "N",
                    tanker: "N",
                    overWeight: "N",
                    reefer: "N",
                    residential: "N",
                },
                customerNotes: {
                    internalNotes: item.internalNotes || "",
                },
                pickup: {
                    poruPoint: item.poruPoint || "",
                    poruDate: convertMDYToYMD(item.poruDate) || "",
                    poruTime: validateTimeField(item.poruTime) || "",
                    disposition: item.disposition || "",
                    returnDate: convertMDYToYMD(item.returnDate) || "",
                    returnTime: validateTimeField(item.returnTime) || "",
                    dispositionCChassisPickupPoint: item.dispositionCChassisPickupPoint || "",
                    chassisPickupDate: "",
                    chassisPickupTime: "",
                    dispositionCChassisDisposition: item.dispositionCChassisDisposition || "",
                    chassisReturnDate: "",
                    chassisReturnTime: "",
                    //notifyDate: validateDateField(item.notifyDate) || 0,
                    notifyDate: convertMDYToYMD(item.notifyDate),
                    notifyTime: validateTimeField(item.notifyTime) || "",
                    //cuttOffDate: validateDateField(item.cuttOffDate) || 0,
                    cuttOffDate: convertMDYToYMD(item.cuttOffDate) || 0,
                    cutOffTime: validateTimeField(item.cutOffTime) || "",
                    pickDate: validateDateField(item.pickDate) || 0,  //Last/Diem Date                    
                    // //etaDate: validateDateField(item.etaDate) || 0,
                    etaDate: convertMDYToYMD(item.etaDate) || 0,
                    etaTime: validateTimeField(item.etaTime) || "",
                },
                shipper: {
                    shipperOrConsShipper: item.shipperOrConsShipper || "",
                    shipper: item.shipper || "",
                    billingShipperPO: item.shipperPONumber?.substring(0, 10) || "",
                    shipperAddress: item.shipperAddress || "",
                    shipperCity: item.shipperCity || "",
                    shipperState: item.shipperState || "",
                    shipperZip: item.shipperZip || "",
                    shipperPhone: item.shipperPhone || "",
                    shipperFax: item.shipperFax || "",
                },
                consignee: {
                    shipperOrConsShipper: item.shipperOrConsignee || "",
                    consignee: item.consignee || "",
                    billingConsigneePO: item.billingConsigneePO || "",
                    consigneeAddress: item.consigneeAddress || "",
                    consigneeCity: item.consigneeCity || "",
                    consigneeState: item.consigneeState || "",
                    consigneeZip: item.consigneeZip || "",
                    consigneePhone: item.consigneePhone || "",
                    consigneeFax: item.consigneeFax || "",
                },
                driverNotes: {
                    driverNotes: item.driverNotes || "",
                    driverNotes2: item.driverNotes2 || "",
                },
                masterDetails: {
                    billOfLading: item.billOfLading || "",
                    purchaseOrder: item.purchaseOrder || "",
                },
                orderType: item.orderType || "",
                controlNumber: item.controlNumber || "",
            };

            const stops = {
                lineNumber: item['stops.newLineNumber'] || item['stops.lineNumber'] || '',
                newLineNumber: item['stops.newLineNumber'] || item['stops.lineNumber'],
                pickupNumber: item['stops.pickupNumber'] || "",
                code: item['stops.code'] || "",
                name: item['stops.name'] || "",
                address: item['stops.address'] || "",
                city: item['stops.city'] || "",
                state: item['stops.state'] || "",
                zip: item['stops.zip'] || "",
                contact: item['stops.contact'] || "",
                phone: item['stops.phone'] || "",
                fax: item['stops.fax'] || "",
                apptDate: validateDateField(item['stops.apptDate']),
                apptTime: item['stops.apptTime'],
                // apptDate: convertMDYToYMD(item['stops.apptDate']),
                //apptTime: validateTimeField(item['stops.apptTime']),
                pieces: item['stops.pieces'] || "",
                weight: item['stops.weight'] || "",
                commodity: item['stops.commodity'] || "",
                doorSeal: item['stops.doorSeal'] || "",
                //arriveDate: validateDateField(item['stops.arriveDate']) || "",
                arriveDate: convertMDYToYMD(item['stops.arriveDate']) || "",
                arriveTime: validateTimeField(item['stops.arriveTime']) || "",     
                // spotDate: validateDateField(item['stops.spotDate']) || "",
                spotDate: convertMDYToYMD(item['stops.spotDate']) || "",
                spotTime: validateTimeField(item['stops.spotTime']) || "",
                // unloadDate: validateDateField(item['stops.unloadDate']) || "",
                unloadDate: convertMDYToYMD(item['stops.unloadDate']) || "",
                unloadTime: validateTimeField(item['stops.unloadTime']) || "",
                departDate: convertMDYToYMD(item['stops.departDate']) || "",
                departTime: validateTimeField(item['stops.departTime']) || "",
                availDate: convertMDYToYMD(item['stops.availDate']) || "",
                availTime: validateTimeField(item['stops.availTime']) || "",
                etaDate: convertMDYToYMD(item['stops.etaDate']) || "",
                etaTime: validateTimeField(item['stops.etaTime']) || "",
                arriveTruck: item['stops.arriveTruck'] || "",
                arriveDriver: item['stops.arriveDriver'] || "",
                departTruck: item['stops.departTruck'] || "",
                departDriver: item['stops.departDriver'] || "",
                arriveTruckStatus: item['stops.arriveTruckStatus'] || "",
                departTruckStatus: item['stops.departTruckStatus'] || "",
                internalNotes: item['stops.internalNotes'] || "",
                driverNotes: item['stops.driverNotes'] || "",
                driverNotes2: item['stops.driverNotes2'] || "",
                shipperBillOfLadding: item['stops.shipperBillOfLadding'] || "",
            };
            return { ...billingControl, stops: [stops], ratesCharges: [] };
        });
    };

    /**
    * As per the requirement overriding default tab key behaviour for mui datagrid
    */
    const handleKeyDown = (params, event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            moveFocusToNextColumn(params);
        }
    }
    const moveFocusToNextColumn = (params) => {
        let tableColumns = columns;
        console.log(tableColumns);
        let currentColumnIndex = tableColumns.findIndex((col) => col.field === params.field);
        let nextColumnIndex = currentColumnIndex + 1;

        // Find the next visible column
        while (nextColumnIndex < tableColumns.length && tableColumns[nextColumnIndex].hide) {
            nextColumnIndex++;
        }

        // Wrap around to the first visible column if needed
        if (nextColumnIndex >= tableColumns.length) {
            nextColumnIndex = 0;
            while (nextColumnIndex < tableColumns.length && tableColumns[nextColumnIndex].hide) {
                nextColumnIndex++;
            }
        }

        if (nextColumnIndex < tableColumns.length) {
            const nextColumnField = tableColumns[nextColumnIndex].field;
            apiRef.current.setCellFocus(params.id, nextColumnField);
        }
    };
    return (
        <OrdersWrapper>
        {/* <SceneHeader title="Orders"> */}
        <ResponsiveGrid gridValues={[12]}> 
          <Box display="flex" sx={{ pt: 2, px: 2 }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                color: "#0911A7",
                flexGrow: 1,
              }}
            >
              Orders List
            </Typography>
            <Button
        variant="outlined"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          display: { xs: "flex", md: "flex" }, // Hide on medium and larger screens
          // marginBottom: 2,
        }}
      >
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      </Box>
      </ResponsiveGrid>
     {isExpanded?( <Box sx={{ pt: 2, px: 2 }}>
        <ResponsiveGrid gridValues={[3, 1, 2, 2, 2, 1, 1,]}>
  {/* Search Bar and Filter */}
  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
  <TextField
    fullWidth
    label="Search Orders ..."
    size="small"
    value={searchText}
    onChange={(event) => setSearchText(event.target.value)}
    onKeyPress={(event) => {
      if (event.key === 'Enter') {
        handleSearch();
        setClearBtn(true);
      }
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {clearBtn ? (
            <IconButton onClick={() => { setClearBtn(false); setSearchText(''); clearSearch(); }}>
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => { setClearBtn(true); handleSearch(); }} title="Search By Control, Unit, Ref, OrderBy, Booking">
              <SearchIcon />
            </IconButton>
          )}
        </InputAdornment>
      )
    }}
  />
  <div 
    style={{ 
      width: '30px', 
      height: '30px', 
      backgroundColor: '#D9E7F7', 
      borderRadius: '3px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      marginLeft: '8px' 
    }} 
    onClick={handleFilter}
  >
    <img src={filtericon} style={{ width: '18px', height: '18px' }} />
  </div>
    {/* Filter Component */}
    {showFilter?<Filter
    showFilter={showFilter}
    setShowFilter={setShowFilter}
    handleFilter={handleApplyFilter}
    searchFields={searchOptions}
    searchClause={searchClause}
    setSearchClause={setSearchClause}
    loadDefaultView={loadUserView}
  />:null}
</div>



 

  {/* Save and Remove Buttons */}
  <div >
    {/* <Button variant="text" onClick={handleClickOpen} style={{ textDecoration: 'underline', textTransform: 'capitalize', color: '#0B4387' }} fullWidth> */}
    <Button variant="text"  onClick={handleClickOpen} style={{textDecoration: 'underline',  textTransform: 'capitalize',color: '#0B4387' }} fullWidth>
      Save View
    </Button>
    
  {/* Save View Component */}
    {openSaveView?<SaveView open={openSaveView} onClose={handleCloseSave} searchClause={searchClause} view={view} />:null}
    </div>
    <div>
    {/* <Button variant="text" onClick={handleRemoveSelected} style={{ textDecoration: 'underline', textTransform: 'capitalize', color: '#0B4387' }} fullWidth> */}
    <Button variant="text"  onClick={handleRemoveSelected} style={{textDecoration: 'underline',  textTransform: 'capitalize',color: '#0B4387' }} fullWidth>
      Remove Selected
    </Button>
  </div>

  

  {/* Save and Discard Changes Buttons */}

    <Button variant="outlined"  sx={{ textTransform: "capitalize" }} disabled={!hasUnsavedRows || isSaving} onClick={saveChanges} fullWidth>
      {isSaving && <CircularProgress size={16} sx={{ color: "inherit", marginRight: 1 }} />}
      Save Changes
    </Button>


    <Button variant="outlined" sx={{ textTransform: "capitalize" }} disabled={!hasUnsavedRows || isSaving} onClick={discardChanges} fullWidth>
      Discard Changes
    </Button>

    {/* </ResponsiveGrid>
    <ResponsiveGrid gridValues={[2, 2]} center> */}

  {/* Export and New Order Buttons */}
  <div >
    {/* <Button variant="text" onClick={handleExport} disabled={isExportInProgress} style={{ textDecoration: 'underline', textTransform: 'capitalize', color: '#0B4387' }} fullWidth> */}
    <Button variant="text"  onClick={handleExport} disabled={isExportInProgress} style={{textDecoration: 'underline', textTransform: 'capitalize', color: '#0B4387' }} fullWidth>
      {isExportInProgress ? <CircularProgress size={20} style={{ color: "#0B4387" }} /> : "Export"}
    </Button>
    </div>
    <div>
    <Button onClick={newOrderClick} style={{ backgroundColor: '#0B4387' }}  variant="contained" fullWidth>
      NEW ORDER
    </Button>
  </div>

  {/* Popover for Order Types */}
  <Popover
    id={popOverID}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
  >
    <List>
      {["INBOUND", "OUTBOUND", "IMPORT", "EXPORT"].map((type) => (
        <ListItem disablePadding divider key={type}>
          <ListItemButton component="a" href={`/orders/${ordersLocation?.locationNumber}/new/${type}`} dense>
            <ListItemText primary={type} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Popover>
</ResponsiveGrid>
</Box>):null}


            <Paper sx={{ display: 'flex', flexDirection: "column", flex: 1, padding: '1rem', alignItems: 'right', maxHeight: '60%' }}>
                <div style={{ width: '100%', backgroundColor: '#0B4387', height: '55px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', alignItems: 'center', display: 'flex', justifyContent: 'end' }}>
                    <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'end', display: 'flex', paddingRight: '2rem' }}>
                        <label style={{ color: 'white', paddingRight: '1rem' }}>View :</label>
                        <Autocomplete
                            disablePortal
                            className="autocomplestyl"
                            id="combo-box-demo"
                            value={selectedView || ""}
                            options={userViews ? userViews.data : []}
                            getOptionLabel={(option) => option ? `${decodeURIComponent(option.name)}` : ""}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                        <div style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between" }}>
                                            <p>{`${decodeURIComponent(option.name)}`}</p>
                                        </div>
                                    </li>
                                )
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name || option === ""}
                            sx={{ width: 200, backgroundColor: 'white' }}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={loadUserView}
                        />
                        {
                            showDeleteViewButton && (
                                <Button onClick={() => {
                                    setIsConfirmDialogOpen(true)
                                }}>
                                    <DeleteIcon style={{ color: 'white' }} />
                                </Button>

                            )
                        }
                        <ConfirmationDialog
                            isConfirmDialogOpen={isConfirmDialogOpen}
                            title={`Delete View`}
                            description={`Are you sure you want to delete view ${selectedView.name} ?`}
                            acceptTxt="Yes"
                            rejectTxt="No"
                            handleNo={() => setIsConfirmDialogOpen(false)}
                            handleYes={() => {
                                console.log('Delete = Yes')
                                dispatch(deleteViewElastic(selectedView)).then(res => {
                                    console.log('RESPONSE FOR DELETE VIEWS', res)
                                    setIsConfirmDialogOpen(false)
                                    loadUserView('')
                                    dispatch(getAllUserViewsExtendedSearch({}))
                                })
                            }}
                        />

                    </div>
                </div>
                <Box
                    sx={{
                        height: window.innerHeight - Number(isExpanded?345: 265),
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                        '& .tableTH': {
                            fontWeight: 700
                        },
                        '& .non-editable': {
                            backgroundColor: "#69696929 !important"
                        },
                    }}
                >
                    <DataGridPro
                        apiRef={apiRef}
                        autoHeight={false}
                        rows={rows}
                        rowCount={allOrders?.totalRows}
                        columns={columns}
                        columnVisibilityModel={view}
                        loading={allOrders.isLoading}
                        // getRowId={(row) => `${row.locationNumber}-${row.controlNumber}-${row.stopNumber}`}
                        // getRowId={(row) => `${Math.random()}-${row.controlNumber}-${row['stops.lineNumber']}`}
                        // onRowClick={openOrder}                        
                        onColumnVisibilityModelChange={(model, details) => {
                            console.log("model", model);
                            setView(model)
                        }}
                        paginationMode="server"
                        pagination={true}
                        pageSizeOptions={[50, 100, 500]}
                        onPaginationModelChange={(params) => { handlePageChange(params) }}
                        sortingOrder={['desc', 'asc']}
                        sortingMode="server"
                        onSortModelChange={sortChange}
                        initialState={{
                            pagination: { pageSize: paginationModel.pageSize, page: paginationModel.size },
                            sortModel: [
                                {
                                    field: 'controlNumber',
                                    sort: 'desc',
                                },
                            ],
                        }}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}

                        paginationModel={paginationModel}
                        //onPaginationModelChange={setPaginationModel}
                        autosizeOptions={{
                            includeHeaders,
                            includeOutliers,
                            outliersFactor: Number.isNaN(parseFloat(outliersFactor))
                                ? 1
                                : parseFloat(outliersFactor),
                            expand,
                        }}
                        editMode="row"
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(error) => {
                            console.error('Error updating row:', error);
                        }}
                        onCellKeyDown={handleKeyDown}
                    />
                </Box>
                < Snackbar open={snackOpen} style={{ marginTop: "3%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackOpen(false)} >
                    <Alert onClose={() => setSnackOpen(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                        {snackContent.message}
                    </Alert>
                </Snackbar>
            </Paper>
            {/* </StyledSceneContent> */}
        </OrdersWrapper>
    );
};

export default ElasticDashBoard;