import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { getUserFromCookie, logoutInternal } from '../../utils/cookie';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { useGoogleLogout } from 'react-google-login';
import { logout } from '../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const MobileviewNavBar = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subAnchorEl, setSubAnchorEl] = React.useState(null);
  const [openSubMenuIndex, setOpenSubMenuIndex] = React.useState(null); // Track which submenu is open

  const open = Boolean(anchorEl);
  const subOpen = Boolean(subAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubAnchorEl(null);
    setOpenSubMenuIndex(null);
  };

  const handleSubMenuClick = (event, index) => {
    setSubAnchorEl(event.currentTarget);
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index); // Toggle submenu
  };

  const handleSubMenuClose = () => {
    setSubAnchorEl(null);
    setOpenSubMenuIndex(null);
  };

  const { signOut } = useGoogleLogout({
    clientId: '231767053305-h0ps5uct5r4rdi7b6ft62im3thvv1e1s.apps.googleusercontent.com',
    onLogoutSuccess: () => {
     
        logoutInternal();
        dispatch(logout())
    }
});

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <img src={props.profilepic} width={40} height={40} style={{ borderRadius: '50px', marginLeft: '10px', marginBottom: '8px' }} alt="profile" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={(e) => handleSubMenuClick(e, 0)}>
          Orders {openSubMenuIndex === 0 ? <ArrowDropUp /> : <ArrowDropDown />}
        </MenuItem>

        {openSubMenuIndex === 0 && (
          <Menu
            anchorEl={subAnchorEl}
            open={openSubMenuIndex === 0}
            onClose={handleSubMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Link to={'/orders'} style={{ textDecoration: 'none', color: '#0911A7' }}>Orders</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/templates'} style={{ textDecoration: 'none', color: '#0911A7' }}>Templates</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/edi-orders'} style={{ textDecoration: 'none', color: '#0911A7' }}>EDI Orders</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/trace'} style={{ textDecoration: 'none', color: '#0911A7' }}>Trace</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/order/completed-order'} style={{ textDecoration: 'none', color: '#0911A7' }}>Completed Order</Link>
            </MenuItem>
          </Menu>
        )}

        <MenuItem onClick={handleClose}>
          <Link to={'/ratebook'} style={{ textDecoration: 'none', color: '#0911A7' }}>Ratebook</Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <Link to={'/dispatch'} style={{ textDecoration: 'none', color: '#0911A7' }}>Dispatch</Link>
        </MenuItem>

        <MenuItem onClick={(e) => handleSubMenuClick(e, 1)}>
          Maintenance {openSubMenuIndex === 1 ? <ArrowDropUp /> : <ArrowDropDown />}
        </MenuItem>

        {openSubMenuIndex === 1 && (
          <Menu
            anchorEl={subAnchorEl}
            open={openSubMenuIndex === 1}
            onClose={handleSubMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Link to={'/shipperconsignee'} style={{ textDecoration: 'none', color: '#0911A7' }}>Shipper/Consignee</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/truckdriver'} style={{ textDecoration: 'none', color: '#0911A7' }}>Truck/Driver</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/equip/pick-up/dispo'} style={{ textDecoration: 'none', color: '#0911A7' }}>Pickup/Disposition</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/order-by'} style={{ textDecoration: 'none', color: '#0911A7' }}>Order By</Link>
            </MenuItem>
          </Menu>
        )}
         <MenuItem onClick={(e) => handleSubMenuClick(e, 2)}>
          Reports {openSubMenuIndex === 2 ? <ArrowDropUp /> : <ArrowDropDown />}
        </MenuItem>

        {openSubMenuIndex === 2 && (
          <Menu
            anchorEl={subAnchorEl}
            open={openSubMenuIndex === 2}
            onClose={handleSubMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Link to={'/purchase-transportation-report'} style={{ textDecoration: 'none', color: '#0911A7' }}>PT Report</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/billing-report'} style={{ textDecoration: 'none', color: '#0911A7' }}>Billing Report</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/pt-paid-without-revenue-report'} style={{ textDecoration: 'none', color: '#0911A7' }}>PT Paid without Revenue</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to={'/revenue-by-customer-report'} style={{ textDecoration: 'none', color: '#0911A7' }}>Customer Revenue</Link>
            </MenuItem>
          </Menu>
        )}


        <Divider />
        {!subAnchorEl&&(<MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" style={{ color: '#0911A7' }} />
          </ListItemIcon>
          Logout
        </MenuItem>)}
      </Menu>
    </React.Fragment>
  );
};

export default MobileviewNavBar;

