import React, { useEffect } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { useSelector } from "react-redux";

const LocAutoComplete = ({
  options,
  seletedLocationList,
  setSelectedLocationList,
  label = "Location",
  limitTags = 1,
  maxDisplayTags = 6,
}) => {
  const ordersLocation = useSelector(
          ({ orderReducer }) => orderReducer.ordersLocation
      );
  const locationList = useSelector(({ loadsReducer }) => loadsReducer.locationList);
  const stringAllLocationList = locationList?.map(item => String(item.locationNumber)) || [];
  const defaultLocation = locationList.filter(item => item.default === "Y").map(item => String(item.locationNumber));


  useEffect(() => {
    if (locationList.length > 0 && ordersLocation?.locationNumber) {  
      
      console.log("Default Location Selected:", defaultLocation);
      setSelectedLocationList([`${ordersLocation?.locationNumber}`]);
    }

  }, [locationList,ordersLocation?.locationNumber]);

  const handleChangeLocationData = (event, newValue) => {
    setSelectedLocationList(newValue);
  };

  return (
    <Box width="100%">
      <Autocomplete
        limitTags={limitTags}
        id="multi-location-list"
        multiple
        options={stringAllLocationList}
        getOptionLabel={(option) => option}
        value={seletedLocationList}
        // onChange={handleChangeLocationData}
        onChange={(event, newValue, reason) => {
          // Prevent removal of the default option
          if (reason === "removeOption" && newValue.includes(`${ordersLocation.locationNumber}`) === false) {
            return;
          }
          handleChangeLocationData(event, newValue);
        }}
        disableClearable
        renderTags={(value) => (
          <Box
            sx={{
              display: "flex",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {seletedLocationList.length <= maxDisplayTags
              ? value.join(", ")
              : `${seletedLocationList.slice(0, maxDisplayTags).join(", ")}...`}
          </Box>
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              pointerEvents: option === ordersLocation.locationNumber ? "none" : "auto",
              opacity: option === `${ordersLocation.locationNumber}` ? 0.5 : 1,
            }}
          >
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={label} size="small" fullWidth />
        )}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      />
    </Box>
  );
};

export default LocAutoComplete;


// // Usage in another component
// import React, { useState } from "react";
// import CustomAutocomplete from "./CustomAutocomplete";

// const ParentComponent = () => {
//   const [selectedLocationList, setSelectedLocationList] = useState([]);
//   const stringAllLocationList = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix"];

//   const handleChangeLocationData = (event, newValue) => {
//     setSelectedLocationList(newValue);
//   };

//   return (
//     <CustomAutocomplete
//       options={stringAllLocationList}
//       seletedLocationList={selectedLocationList}
//       onChange={handleChangeLocationData}
//       label="Select Location"
//     />
//   );
// };

// export default ParentComponent;