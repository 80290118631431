import { useState, useEffect } from 'react';

import {
    Stack,
    TextField,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import CallSplit from '@mui/icons-material/CallSplit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import CallMerge from '@mui/icons-material/CallMerge';

// import custom date picker
import CustomDatePicker from '../../../../components/CustomDatePicker';

import {
    getPayableGlCode,
    getParentPayableRate,
    findMaxLineNumber,
    calAmount,
    calAmountByPer,
    getSubitemReceivableGL,
    getSubitemPayableGL,
    getSubItemUnit,
    sanitizeAmountField,
    getParentPayableUnit,
    sanitizePercentageField,
    convertStrToUpperCase,
    convertDateToMDY
} from '../CommonFn';

// import components
import RatesAutoComplete from './RatesAutoComplete';
import GLCodesAutoComplete from './GLCodesAutoComplete';
import TrucksAutoComplete from './TrucksAutoComplete';
import '../RatesAndChargesStyles.css'
import { useParams } from "react-router-dom";
import {
    postRatesCharges
} from "../../../../store/actions/orderActions";

export default function AccessorialTableRow(props) {
    const {
        rowIndex,
        row,
        glCodesData,
        orderType,
        tableType,
        handleLineItemDelete,
        handleExistingRow,
        unPayableGLCodes,
        linehaulEditRef,
        accessorialEditRef,
        snackBarHandler,
        isTemplateScreen,
        ordersLocation,
        controlNumber
    } = props;
    let params = useParams();
    params = params?.locationNumber ? params : { locationNumber: ordersLocation, controlNumber: controlNumber }

    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [payableRow, setPayableRow] = useState(row.payable);
    const [receivablesRow, setReceivablesRow] = useState(row.receivable);
    const [disablePayableRowOnSplit, setDisablePayableRowOnSplit] = useState((row.payable?.splitItems?.length > 0) ? true : false)
    // state to disable editable fields
    const [disablePayableRow, setDisablePayableRow] = useState(
        (!row.payable.hasOwnProperty("lineNumber") ||
            (typeof row.payable?.isFreeLockPayable !== 'undefined' && row.payable?.isFreeLockPayable === true) ||
            (typeof row.payable?.isLockPayable !== 'undefined' && row.payable?.isLockPayable === true) || 
            (row.payable?.splitItems?.length > 0 && row.payable?.splitItems.some(item => item.isLockPayable === true))
        ) ? true : false
    );
    const [disableReceivableRow, setDisableReceivableRow] = useState(
        (!row.receivable?.hasOwnProperty("lineNumber") || row.receivable?.isFreeLockReceivable || row.receivable?.isLockReceivable) ? true : false
    );

    const maxLineNumber = findMaxLineNumber([...linehaulEditRef.current, ...accessorialEditRef.current]) + 1;
    const handleSave = () => {
        setIsEditModeOn(false);
        handleExistingRow({
            receivable: receivablesRow,
            payable: payableRow
        }, rowIndex)
    }

    // handle delete of sub items based on lineNumber
    const handleReceivableDelete = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {
            if (receivablesRow?.isServerRecord !== undefined &&receivablesRow?.isServerRecord=== true) {
                const delResponse = await handleLineItemDelete(receivablesRow?.lineNumber);
                console.log('rates charges receivable delete response', delResponse);
                if (delResponse) {
                    setDisableReceivableRow(true);
                    setReceivablesRow({})
                    setPayableRow({ ...payableRow, payRec: '' })
                    handleExistingRow({
                        ...row,
                        receivable: {},
                        payable: { ...payableRow, payRec: '' }
                    }, rowIndex)
                }
            } else {
                setDisableReceivableRow(true);
                setReceivablesRow({})
                if(isTemplateScreen){
                     // Check if payableRow is empty
             const payabledata = Object.keys(payableRow).length === 0
             ? {}
             : { ...payableRow, payRec: '' };
             setPayableRow(payabledata)
             handleExistingRow({
                ...row,
                receivable: {},
                payable:payabledata
            }, rowIndex)
             
                }else{
                setPayableRow({ ...payableRow, payRec: '' })
                handleExistingRow({
                    ...row,
                    receivable: {},
                    payable: { ...payableRow, payRec: '' }
                }, rowIndex)
            }
            }
        }
    }
    const handlePayableDelete = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {
            if (payableRow?.isServerRecord !== undefined &&payableRow?.isServerRecord=== true) {
                const delResponse = await handleLineItemDelete(payableRow?.lineNumber);
                console.log('rates charges payable delete response', delResponse);
                if (delResponse) {
                    setDisablePayableRow(true);
                    setPayableRow({})
                    handleExistingRow({
                        ...row,
                        payable: {}
                    }, rowIndex)

                }
            } else {
                setDisablePayableRow(true);
                setPayableRow({})
                handleExistingRow({
                    ...row,
                    payable: {}
                }, rowIndex);
            }
        }
    }
    const handleSplitItem = async () => {

        let paySplitItems;
        paySplitItems = {
            //...payableRow,
            subItems: accessorialEditRef.current[rowIndex]?.payable?.subItems?.length > 0 ? [{
                splitItems: [
                    {
                        ...payableRow?.subItems?.[0],
                        lineNumber: maxLineNumber + Number(2),
                        parentLineNumber: '',
                        splitLineNumber: accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.lineNumber,
                        recAgainstSplitLine: receivablesRow?.subItems?.[0]?.lineNumber,
                        isServerRecord: accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.isServerRecord ? true : false,
                        payRec: '',
                        description: convertStrToUpperCase(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.description),
                        glCode: accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.glCode,
                        unitRate: (Number(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.unitRate) / Number(2)).toFixed(2),
                        units: (Number(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.units)).toFixed(2),
                        amount: (Number(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.amount) / Number(2)).toFixed(2)
                    },
                    {
                        ...payableRow?.subItems?.[0],
                        lineNumber: maxLineNumber + Number(3),
                        parentLineNumber: '',
                        splitLineNumber: accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.lineNumber,
                        recAgainstSplitLine: receivablesRow?.subItems?.[0]?.lineNumber,
                        isServerRecord: accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.isServerRecord ? true : false,
                        payRec: '',
                        description: convertStrToUpperCase(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.description),
                        glCode: accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.glCode,
                        unitRate: Number(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.unitRate) / Number(2),
                        units: (Number(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.units)).toFixed(2),
                        amount: (Number(accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.amount) / Number(2)).toFixed(2),
                        truckNumber: '',
                        processedDate: '',
                    }
                ]
            }] : [],
            splitItems: [
                {
                    ...payableRow,
                    lineNumber: maxLineNumber,
                    parentLineNumber: '',
                    splitLineNumber: payableRow?.lineNumber,
                    recAgainstSplitLine: receivablesRow?.lineNumber,
                    isServerRecord: accessorialEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                    payRec: '',
                    description: convertStrToUpperCase(payableRow?.description),
                    glCode: payableRow?.glCode,
                    unitRate: Number(payableRow?.unitRate) / Number(2), // modified on 11-10-2023
                    units: (Number(payableRow?.units)).toFixed(2), // modified on 11-10-2023
                    amount: (Number(payableRow?.amount) / Number(2)).toFixed(2),
                },
                {
                    ...payableRow,
                    lineNumber: maxLineNumber + Number(1),
                    parentLineNumber: '',
                    splitLineNumber: payableRow?.lineNumber,
                    recAgainstSplitLine: receivablesRow?.lineNumber,
                    isServerRecord: accessorialEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                    payRec: '',
                    description: convertStrToUpperCase(payableRow?.description),
                    glCode: payableRow?.glCode,
                    unitRate: Number(payableRow?.unitRate) / Number(2), // modified on 11-10-2023
                    units: (Number(payableRow?.units)).toFixed(2), // modified on 11-10-2023
                    amount: (Number(payableRow?.amount) / Number(2)).toFixed(2),
                    truckNumber: '',
                    processedDate: '',
                }
            ]
        }
        /**
         * delete parent of split item from server
         */
        if (accessorialEditRef.current[rowIndex]?.payable?.isServerRecord !== 'undefined' && accessorialEditRef.current[rowIndex]?.payable?.isServerRecord === true) {
            let parentPayable = accessorialEditRef.current[rowIndex]?.payable || [];
            let subItem = accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0] || [];
            let splitItemParentArr = [parentPayable, subItem];
            let delRes = await Promise.all(splitItemParentArr.map(async (item) => {
                if (typeof item?.isServerRecord !== 'undefined' && item?.isServerRecord === true) {
                    return await handleLineItemDelete(item?.lineNumber);
                }
                return false;
            }));
            if (delRes.includes(true)) {
                snackBarHandler({
                    status: true,
                    type: 'info',
                    message: 'Splitting Payables....'
                });
                let res = await saveRatesCharges(paySplitItems);
            }
            console.log('delete parent of split item result', delRes);
        }

        setDisablePayableRow(true);
        setPayableRow(paySplitItems);
        handleExistingRow({ ...accessorialEditRef.current[rowIndex], payable: paySplitItems }, rowIndex);
    }
    /**
     * Delete split items
     */
    const deleteSplitItems = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {

            // handle local state
            let paySplitItems;
            paySplitItems = {
                ...payableRow?.splitItems[0],
                lineNumber: maxLineNumber,
                parentLineNumber: '',
                splitLineNumber: '',
                recAgainstSplitLine: '',
                isServerRecord: accessorialEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                payRec: receivablesRow.lineNumber,
                unitRate: Number(payableRow?.splitItems[0]?.unitRate || 0) * Number(2),
                amount: Number(payableRow?.splitItems[0]?.amount || 0) * Number(2),
                truckNumber: payableRow?.splitItems[0]?.truckNumber,
                processedDate: payableRow?.splitItems[0]?.processedDate,

                subItems: payableRow?.subItems?.length > 0 ? [{
                    ...payableRow?.subItems?.[0]?.splitItems[0],
                    lineNumber: maxLineNumber + Number(1),
                    parentLineNumber: maxLineNumber,
                    splitLineNumber: '',
                    recAgainstSplitLine: '',
                    isServerRecord: accessorialEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                    payRec: '',
                    unitRate: Number(payableRow?.subItems?.[0]?.splitItems[0]?.unitRate || 0) * Number(2),
                    amount: Number(payableRow?.subItems?.[0]?.splitItems[0]?.amount || 0) * Number(2),
                    truckNumber: payableRow?.subItems?.[0]?.splitItems[0]?.truckNumber,
                    processedDate: payableRow?.subItems?.[0]?.splitItems[0]?.processedDate,
                    splitItems: []
                }] : [],
                splitItems: []
            }
            // handle server data
            const delRes = await deleteSplitItemsFromServer()
            console.log('delete result', delRes);
            if (delRes.includes(true)) {
                snackBarHandler({
                    status: true,
                    type: 'info',
                    message: 'Merging Payables...'
                });
                let res = await saveRatesCharges(paySplitItems);
            }
            setDisablePayableRow(false);
            setPayableRow(paySplitItems);
            handleExistingRow({ ...accessorialEditRef.current[rowIndex], payable: paySplitItems }, rowIndex);
        }
    }
    const deleteSplitItemsFromServer = async () => {
        let parentItem = accessorialEditRef.current[rowIndex]?.payable || [];
        let parentSplitItem = accessorialEditRef.current[rowIndex]?.payable?.splitItems || [];
        let subSplitItem = accessorialEditRef.current[rowIndex]?.payable?.subItems?.[0]?.splitItems || [];
        let splitItemArr = [parentItem, ...parentSplitItem, ...subSplitItem];
        if (splitItemArr.length > 0) {
            let res = await Promise.all(splitItemArr.map(async (item) => {
                if (typeof item?.isServerRecord !== 'undefined' && item?.isServerRecord === true) {
                    return await handleLineItemDelete(item?.lineNumber);
                }
                return false;
            }));
            return res;
        }
        return true;
    }
    const saveRatesCharges = async (paySplitItems) => {
        if (typeof params.locationNumber !== 'undefined' && typeof params.controlNumber !== 'undefined') {
            let res = await postRatesCharges(params.locationNumber, params.controlNumber, paySplitItems);
            if (res?.status === 'success') {
                snackBarHandler({
                    status: true,
                    type: 'success',
                    message: 'Payables updated successfully!'
                });
            }
        }
    }
    useEffect(() => {
     
        // Refresh disablePayableRow state on splitItem changes
        if (row.payable?.splitItems?.length > 0) {
            setDisablePayableRowOnSplit(true)
        } else {
            setDisablePayableRowOnSplit(false)
        }
      

    }, [row])
    const allGlCodesToHideSplit=[3505000,3535000,3555000,3323000,6985000]
    return (
        <>
            {isEditModeOn ? (
                <TableRow className={`${receivablesRow.transmitted === 'Y' ? 'tx_r_y' : ''} ${payableRow.transmitted === 'Y' ? 'tx_p_y' : ''}`}>
                    <TableCell>
                        <Stack direction="row">
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => handleSave()}>
                                <DoneIcon titleAccess='Save Changes' fontSize="small" color="success" />
                            </IconButton>
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(false)}>
                                <CloseIcon titleAccess='Cancel Changes' fontSize="small" sx={{ color: 'red' }} />
                            </IconButton>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <RatesAutoComplete
                            valueChange={(val) => {
                                //console.log(val)
                                if (val !== null) {
                                    setReceivablesRow({
                                        ...receivablesRow,
                                        description: val.invoiceDesc,
                                        destinationCity: val.destinationCity,
                                        destinationStatePrefix: val.destinationState,
                                        glCode: val.GLCode,
                                        glDescription: val.GLDesc,
                                        notes: val.notes,
                                        originCity: val.originCity,
                                        originName: val.originCity,
                                        originPrefix: val.originState,
                                        unitRate: val.receivableRates,
                                        amount: val.receivableRates
                                    })
                                    if (unPayableGLCodes.includes(val.GLCode)) {
                                        setPayableRow({})
                                    } else {
                                        setPayableRow({
                                            ...payableRow,
                                            description: val.invoiceDesc,
                                            destinationCity: val.destinationCity,
                                            destinationStatePrefix: val.destinationState,
                                            glCode: getPayableGlCode(orderType, val.GLCode),
                                            glDescription: val.GLDesc,
                                            notes: val.notes,
                                            originCity: val.originCity,
                                            originName: val.originCity,
                                            originPrefix: val.originState,
                                            unitRate: val.receivableRates,
                                            units: getParentPayableUnit(val.GLCode, val.payableRatioBroker, val.payableRatioContract),
                                            amount: getParentPayableRate(val.GLCode, val.payableRateBroker, val.payableRateContract)
                                        })
                                    }
                                }
                            }
                            }
                            existingValue={receivablesRow.notes}
                            lineAccount={tableType}
                            isDisabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <GLCodesAutoComplete
                            valueChange={(val) => {
                                if (val !== '') {
                                    setReceivablesRow({
                                        ...receivablesRow,
                                        glCode: val
                                    });
                                    if (unPayableGLCodes.includes(val)) {
                                        setPayableRow({})
                                    } else {
                                        setPayableRow({
                                            ...payableRow,
                                            glCode: getPayableGlCode(orderType, val),
                                        })
                                    }

                                } else if (val === '') {
                                    setPayableRow({
                                        ...payableRow,
                                        glCode: '',
                                    })
                                }
                            }
                            }
                            existingValue={receivablesRow.glCode}
                            glCodesData={glCodesData}
                            isDisabled={disableReceivableRow}
                            lineAccount={tableType}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={receivablesRow.description}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                description: e.target.value
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                unitRate: e.target.value,
                                amount: calAmount(e.target.value, receivablesRow.units)
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                units: e.target.value,
                                amount: calAmount(receivablesRow.unitRate, e.target.value)
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow.amount}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                amount: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow.description}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                description: e.target.value
                            })}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.glCode}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                glCode: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                unitRate: e.target.value,
                                amount: calAmount(e.target.value, payableRow.units)
                            })}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                units: e.target.value,
                                amount: calAmount(payableRow.unitRate, e.target.value)
                            })}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.amount}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                amount: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        {/* <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow.truckNumber}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                truckNumber: e.target.value
                            })}
                            inputProps={{ maxLength: 5 }}
                            disabled={disablePayableRow}
                        /> */}
                        <TrucksAutoComplete
                            valueChange={(val) => {
                                if (val !== '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: val,
                                    })

                                } else if (val === '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: '',
                                    })
                                }
                            }
                            }
                            existingValue={payableRow.truckNumber}
                            isDisabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <CustomDatePicker
                            variant="standard"
                            value={convertDateToMDY(payableRow.processedDate) || ''}
                            style={{ width: 'fit-content' }}
                            onValueChange={(val) => setPayableRow({
                                ...payableRow,
                                processedDate: val
                            })}
                            disabled={disablePayableRow}
                            showOneMonthAlert={true}
                        />
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
            ) : (
                <>
                    {(Object.keys(payableRow || {}).length === 0 && Object.keys(receivablesRow || {}).length === 0) ? null : (
                        <TableRow className={`${receivablesRow.transmitted === 'Y' ? 'tx_r_y' : 'tx_r_n'} ${payableRow.transmitted === 'Y' ? 'tx_p_y' : 'tx_p_n'}`}>
                            <TableCell>
                                <Stack direction="row">
                                    <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(true)}>
                                        <EditIcon titleAccess='Edit' color="black" />
                                    </IconButton>
                                    <IconButton disabled={(receivablesRow?.length === 0 || disableReceivableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handleReceivableDelete()}>
                                        <DeleteIcon titleAccess='Delete receivable' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                            {/* <TableCell align="center">{convertStrToUpperCase(receivablesRow?.notes)}</TableCell>
                            <TableCell align="center">{receivablesRow?.glCode}</TableCell>
                            <TableCell align="center">{convertStrToUpperCase(receivablesRow?.description)}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.unitRate)}</TableCell>
                            <TableCell align="center">{receivablesRow?.units}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.amount)}</TableCell>
                            <TableCell align="center">{convertStrToUpperCase(payableRow?.description)}</TableCell>
                            <TableCell align="center">{payableRow?.glCode}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(payableRow?.unitRate)}</TableCell>
                            <TableCell align="center">{sanitizePercentageField(payableRow?.units)}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(payableRow?.amount)}</TableCell>
                            <TableCell align="center">{payableRow?.truckNumber}</TableCell>
                            <TableCell align="center">{convertDateToMDY(payableRow?.processedDate)}</TableCell>
                            <TableCell align="center">
                                <Stack direction="row" sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <IconButton disabled={(payableRow?.length === 0 || disablePayableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handlePayableDelete()}>
                                        <DeleteIcon titleAccess='Delete Payable' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell> */}
                              <TableCell align="center">{convertStrToUpperCase(receivablesRow?.notes)}</TableCell>
                            <TableCell align="center">{receivablesRow?.glCode}</TableCell>
                            <TableCell align="center">{convertStrToUpperCase(receivablesRow?.description)}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.unitRate)}</TableCell>
                            <TableCell align="center">{receivablesRow?.units}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.amount)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{convertStrToUpperCase(payableRow?.description)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.glCode}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizeAmountField(payableRow?.unitRate)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizePercentageField(payableRow?.units)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizeAmountField(payableRow?.amount)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.truckNumber}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{convertDateToMDY(payableRow?.processedDate)}</TableCell>
                            <TableCell align="center">
                                <Stack direction="row" sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                  {allGlCodesToHideSplit.includes(receivablesRow.glCode)?( payableRow?.splitItems?.length > 0 ? (
                                        <IconButton disabled={(payableRow?.splitItems?.length === 0) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => deleteSplitItems()}>
                                            <CallMerge titleAccess='Merge Payables' color="black" />
                                        </IconButton>
                                    ) : (
                                         <IconButton disabled={(payableRow?.splitItems?.length > 0 || Object.keys(payableRow || {}).length === 0 || disablePayableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handleSplitItem()}>
                                            <CallSplit titleAccess='Split Payables' color="black" />
                                        </IconButton>
                                    )):null}
                                    {/**&& !disablePayableRowOnSplit */}
                                    <IconButton disabled={(disablePayableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handlePayableDelete()}>
                                        <DeleteIcon titleAccess='Delete Payable' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                </>
            )}
        </>
    );
}