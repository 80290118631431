import { createContext, useContext, useRef, useState } from "react";

const DispatchContext = createContext(null);

export const DispatchProvider = ({ children }) => {
    const dispatchUnsavedChangesRef = useRef({
        unsavedRows: {},
        rowsBeforeChange: {},
    });
    
    const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(0); // ✅ UI trigger to notify updates

    return (
        <DispatchContext.Provider 
            value={{ dispatchUnsavedChangesRef, hasUnsavedRows, setHasUnsavedRows, updateTrigger, setUpdateTrigger }}
        >
            {children}
        </DispatchContext.Provider>
    );
};

export const useDispatchContext = () => {
    const context = useContext(DispatchContext);
    if (!context) {
        throw new Error("useDispatchContext must be used within a DispatchProvider");
    }
    return context;
};

export default DispatchContext;
