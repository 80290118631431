import * as React from 'react';
import { H2, TenderWrapper, Button } from '../styledComponents/index';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

import PdfGenerator from "../PdfGenerator/PdfGenerator";

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const historyRow = row.history;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.Line}
                </TableCell>
                <TableCell>{row.Desciption}</TableCell>
                <TableCell>{row.Amount}</TableCell>
                <TableCell>{row.Truck}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TenderWrapper sx={{ margin: 1 }}>

                            {/* <H2 style={{ padding: '10px 5px' }}>History</H2> */}
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Acc. No</TableCell>
                                        <TableCell>Acc. Type Code</TableCell>
                                        <TableCell>Ass. Stop</TableCell>
                                        <TableCell>Charges Type Code</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Department</TableCell>
                                        <TableCell>Paper work Completed Code</TableCell>
                                        <TableCell>Paper work Required Code</TableCell>
                                        <TableCell>Processed</TableCell>
                                        <TableCell>Rate Amount</TableCell>
                                        <TableCell>Transmitted</TableCell>
                                        <TableCell>Units</TableCell>
                                        <TableCell>Vendor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow key={historyRow.AccountNumber}>
                                        <TableCell component="th" scope="row">
                                            {historyRow.AccountNumber}
                                        </TableCell>
                                        <TableCell>{historyRow.AccountTypeCode}</TableCell>
                                        <TableCell>{historyRow.AssociatedStop}</TableCell>
                                        <TableCell>{historyRow.ChargesTypeCode}</TableCell>
                                        <TableCell>{historyRow.Code}</TableCell>
                                        <TableCell>{historyRow.Department}</TableCell>
                                        <TableCell>{historyRow.PaperworkCompletedCode}</TableCell>
                                        <TableCell>{historyRow.PaperworkRequiredCode}</TableCell>
                                        <TableCell>{historyRow.Processed}</TableCell>
                                        <TableCell>{historyRow.RateAmount}</TableCell>
                                        <TableCell>{historyRow.Transmitted}</TableCell>
                                        <TableCell>{historyRow.Units}</TableCell>
                                        <TableCell>{historyRow.Vendor}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TenderWrapper>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function TableView({ items = [], container, reference, title = '' }) {
    return (
        <TenderWrapper>
            <div style={{ "justify-content": "space-between", "display": "flex" }}>
                <H2 style={{ padding: '10px 5px' }}>{title}</H2>
                {(items?.rows?.length > 0) ? (
                    <Button type='button' variant='contained' style={{ marginTop: 5 }} color='primary' onClick={() => PdfGenerator(items, container, reference, '', '', '')} >
                        Download Invoice
                    </Button>
                ) : (null)}
            </div>
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Line</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Truck</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.rows?.length > 0 && items.rows.map((row) => (
                            <Row key={row.line} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </TenderWrapper>
    );
}
