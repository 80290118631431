import React, { Component, useState, useEffect, useRef, createRef } from "react";
// Import dependent librariers
import { Box, Stack, Typography, Snackbar, Alert, Tabs, Tab } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@material-ui/core";

// Import layout dependencies
import { TenderListWrapper, H2 } from "../../components/styledComponents";
import AccessorialTab from "./AccessorialTab";
import LinehaulTab from "./LinehaulTab";
import { RatebookSearch } from "./components/RatebookSearch";
import { RatebookImport } from "./components/RatebookImport";
import { RatebookExport } from "./components/RatebookExport";
import FuelSurcharge from "./components/FuelSurcharge";
import ResponsiveGrid from '../../components/GridCustomComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanded view
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Import Redux Dependencies
import { useDispatch, useSelector } from "react-redux";
import {
    getRateBookData,
    addRateBookData,
    getGLCodes,
    getStates,
    getCities,
    setRateBookData,
    delRateBookData
} from "../../store/actions/rateBookActions";
import { railRoadLookup } from "../../store/actions/orderActions";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function RateBook({ navigation }) {
    const dispatch = useDispatch();
    const headerLocation = useSelector(({ rateBookReducer }) => rateBookReducer.headerLocationValues);
    const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
    const orderReducer = useSelector(({ orderReducer }) => orderReducer)

    const [activeTab, setActiveTab] = useState(0);
    const [searchStr, setSearchStr] = useState('');
    const [reloadData, setReloadData] = useState(false);
    const [snackbar, setSnackbar] = useState(null);
    const [isExpanded, setIsExpanded] = useState(true);

    useEffect(() => {
        if (rateBookReducer?.GLCodeData?.length === 0) {
            dispatch(getGLCodes())
        }
        if (orderReducer?.pickupPointLookupRes?.length === 0) {
            dispatch(railRoadLookup())
        }

    }, [headerLocation])

    // useEffect(() => {
    //     if (reloadData) {
    //         console.log('change status');
    //         setReloadData(false);
    //     }
    // }, [reloadData])

    return (
        <TenderListWrapper>
            <Box
                sx={{
                    padding: 3
                }}
            >
                 <ResponsiveGrid gridValues={[12]}> 
          <Box display="flex"  marginBottom={2}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                color: "#0911A7",
                flexGrow: 1,
              }}
            >
               Rate Book List
            </Typography>
            <Button
        variant="outlined"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          display: { xs: "flex", md: "flex" }, // Hide on medium and larger screens
          // marginBottom: 2,
        }}
      >
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      </Box>
      </ResponsiveGrid>
           <Box sx={{ display: { xs: isExpanded ? "block" : "none", md: isExpanded ? "block" : "none"} }}>
      <ResponsiveGrid
          gridValues={[  3, 3, 2, 2]} center
        >
            <FuelSurcharge
                            locationNumber={headerLocation}
                            setAlertMessage={(alertObj) => setSnackbar(alertObj)}
                        />
            <RatebookSearch
                            searchResult={(val) => setSearchStr(val)}
                        />
     
        <RatebookImport
                            locationNumber={headerLocation}
                            setAlertMessage={(alertObj) => setSnackbar(alertObj)}
                            refreshComponentData={(state) => setReloadData(state)}
                        />
                        <RatebookExport
                            locationNumber={headerLocation}
                        />
   </ResponsiveGrid>
</Box>
<Stack p={1}>
                        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
                            <Tab
                                label="Linehaul" id='simple-tab-0'
                                sx={(activeTab === 0) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                            <Tab
                                label="Accessorial" id='simple-tab-1'
                                sx={(activeTab === 1) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                        </Tabs>
                    </Stack>


                {/* <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 600, flexDirection: "columns", color: '#0911A7', mb: 2 }}
                >
                    Rate Book List
                </Typography> */}
                {/* <Stack direction="row" justifyContent={`space-between`} mb={1}>
                    <Stack>
                        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
                            <Tab
                                label="Linehaul" id='simple-tab-0'
                                sx={(activeTab === 0) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                            <Tab
                                label="Accessorial" id='simple-tab-1'
                                sx={(activeTab === 1) ? {
                                    backgroundColor: "#0911A7",
                                    color: "white !important",
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    borderRadius: '8%'
                                } : {}}
                            />
                        </Tabs>
                    </Stack>
                    <Stack direction="row" spacing={1} mr={16} >
                        <FuelSurcharge
                            locationNumber={headerLocation}
                            setAlertMessage={(alertObj) => setSnackbar(alertObj)}
                        />
                        <RatebookSearch
                            searchResult={(val) => setSearchStr(val)}
                        />
                        <RatebookImport
                            locationNumber={headerLocation}
                            setAlertMessage={(alertObj) => setSnackbar(alertObj)}
                            refreshComponentData={(state) => setReloadData(state)}
                        />
                        <RatebookExport
                            locationNumber={headerLocation}
                        />

                    </Stack>
                </Stack> */}
                <CustomTabPanel value={activeTab} index={0}>
                    <LinehaulTab
                        locationNumber={headerLocation}
                        setAlertMessage={(alertObj) => setSnackbar(alertObj)}
                        searchStr={(activeTab === 0) ? searchStr : ''}
                        reloadData={reloadData}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                    <AccessorialTab
                        locationNumber={headerLocation}
                        setAlertMessage={(alertObj) => setSnackbar(alertObj)}
                        searchStr={(activeTab === 1) ? searchStr : ''}
                        reloadData={reloadData}
                    />
                </CustomTabPanel>

            </Box>
            {
                !!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClose={() => setSnackbar(null)}
                        autoHideDuration={3000}
                    // bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
                    >
                        <Alert {...snackbar} onClose={() => setSnackbar(null)} />
                    </Snackbar>
                )
            }
        </TenderListWrapper>
    );
}