import { traceTypes } from "../types/traceTypes"
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';
import { convertMilitaryTimeToHHMM, convertMilitaryDateToYMD, sanitizeStringForDBField } from '../../utils/global-functions';

export const getTraceData = (postData: any, locationNumber: number) => {
    return async (dispatch: Dispatch) => {
        const res = await stopSearchApiReq(postData, locationNumber, 'trace');
        dispatch(setTrace(res));
        return res;
    };
}

const setTrace = (apiData: any) => {
    return {
        type: traceTypes.TRACE_DATA,
        payload: apiData,
    };
};

export const stopSearchApiReq = async (postData: any, locationNumber: number, requestType: string) => {
    try {
        let res = await POST(`/elastic/stops/_search?page=1&pageSize=10000&reqType=${requestType}`, postData);
        if (res && res.httpCode === 200 && res.status === 'success') {
            res = mapStopsDataToClient(res.data.hits);
            return res;
        }
        return [];
    } catch (err) {
        return [];
    }
}

export const bulkEditReq = async (postData: any, locationNumber: number) => {
    try {
        let res = await PUT(`/traces/bulk/${locationNumber}`, postData);
        if (res && res.httpCode === 200 && res.status === 'success') {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

const mapStopsDataToClient = (stopData: any) => {
    const now = moment().format('1YYMMDD');
    let finalData: any = [];

    stopData.map((item: any, indx: number) => {
        let obj = item._source;
        const regex = /^[A-Za-z]{4}[0-9]{5,7}$/;
        //if (!['V', 'C', 'B', 'M'].includes(obj.status) && obj['stops.departTruckStatus'] !== 'C' && obj['stops.lineNumber'] === 1 && obj['stops.spotDate'] === 0 && ['IMPORT', 'DOMINB'].includes(obj.orderType)) {
        if (!['V', 'C', 'B', 'M'].includes(obj.status) && obj['stops.departTruckStatus'] !== 'C') {
            
            finalData.push({
                id: indx,
                lineNumber:obj['stops.lineNumber'],
                orderType: obj.orderType,
                totalStops: obj?.totalStops || 0,
                control: obj.controlNumber,
                unit: obj.unitNumber || '',
                apptDate: convertMilitaryDateToYMD(obj['stops.apptDate']) || '',
                apptTime: obj['stops.apptTime'] || '',
                puloc: obj.poruPoint || '',
                dispLoc: obj.disposition || '',
                etaDate: convertMilitaryDateToYMD(obj.etaDate) || '',
                etaTime: convertMilitaryTimeToHHMM(obj.etaTime) || '',
                orderBy: obj.shipperAgentCode || '',
                shipperName: (['DOMOUT','EXPORT'].includes(obj.orderType))?obj['stops.name']:obj.shipper || "", //obj.shipper || "",
                consigneeName: (['DOMINB','IMPORT'].includes(obj.orderType))?obj['stops.name']:obj.consignee || "", //obj.consignee || '',
                city: obj['stops.city'] || '',
                state: obj['stops.state'] || '',
                release: (obj.orderType === 'DOMINB' || obj.orderType === 'IMPORT' || obj.orderType === 'DOMOUT') ? `${obj.releaseNumber}` : (obj.orderType === 'EXPORT') ? `${obj.billingBooking}` : '',
                booking: obj.billingBooking,
                notifyDate: convertMilitaryDateToYMD(obj.notifyDate) || '',
                notifyTime: convertMilitaryTimeToHHMM(obj.notifyTime) || '',
                lfd: convertMilitaryDateToYMD(obj.cuttOffDate),
                lfdTime: convertMilitaryTimeToHHMM(obj.cutOffTime) || '',
                lfdRedFlag: (obj['stops.apptDate'] > obj.cuttOffDate) ? true : false,
                lfdYellowFlag: false, //(obj.cuttOffDate == now) ? true : false,
                chassis: obj.chassisNumber,
                valid: regex.test(obj.unitNumber) ? 'Y' : 'N',
                vessel: obj.vessel,
                voyage: obj.voyageNumber,
                shipperObjServer: {
                    code: sanitizeStringForDBField(obj.shipperOrConsShipper, 7),
                    name: sanitizeStringForDBField(obj.shipper, 25),
                    address: sanitizeStringForDBField(obj.shipperAddress, 25),
                    city: sanitizeStringForDBField(obj.shipperCity, 25),
                    state: sanitizeStringForDBField(obj.shipperState, 2),
                    zip: sanitizeStringForDBField(obj.shipperZip, 10),
                    phone: sanitizeStringForDBField(obj.shipperPhone, 13),
                    fax: sanitizeStringForDBField(obj.shipperFax, 13)
                },
                spotDate: convertMilitaryDateToYMD(obj['stops.spotDate']) || 0
            });
        }
    });

    return finalData;
}
