import React, { useState, useEffect,useCallback } from "react";
import { Box, Stack, Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ComponentWrapper } from "../../../components/styledComponents";
import BillingFilterSection from '../Billing/BillingFilterSection';
import SummaryByBillTo from '../Billing/SummaryByBillTo';
import {
    getBillingReportData
} from "../../../store/actions/reportActions";
import { getGLCodes } from "../../../store/actions/rateBookActions";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export default function Billing({ navigation }) {
    const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const [activeTab, setActiveTab] = useState(2);
    const [snackbar, setSnackbar] = useState(null);
    const [totalRecords, setTotalRecords] = useState([0, 0]);
    const [rawData, setRawData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterParams, setFilterParams] = useState(false);
    const [selectedLocationList, setSelectedLocationList] = useState([]);
    const [exportButtons, setExportButtons] = useState(null);
    const handleSetExportButtons = useCallback((buttons) => {
        setExportButtons(buttons);
    }, []);
        
    const fetchDataFromServer = (locationNumber, filters) => {
        setFilterParams(filters)
        setIsLoading(true)
        const selectedLoc=selectedLocationList.length>0?selectedLocationList.map(num => Number(num)):[locationNumber]
        dispatch(getBillingReportData(selectedLoc, filters)).then((res) => {
        // dispatch(getBillingReportData(locationNumber, filters)).then((res) => {
            setIsLoading(false)
            const filterRes = res?.billingSummary?.filter(row => row.status !== "V") || [];
            setRawData(filterRes || []);
            //setTotalRecords([res?.data?.billingSummary.length, res?.data?.billingSummary.length])
            const length = res?.data?.billingSummary ? res.data.billingSummary.length : 0;
            setTotalRecords([length, length]);
        }).catch((err) => setIsLoading(false))

        

    }

    useEffect(() => {
        if(ordersLocation?.locationNumber > 0){
            //fetchDataFromServer(ordersLocation.locationNumber, filterData.queryParams);
            dispatch(getGLCodes(true))
        }
    }, [ordersLocation?.locationNumber])

    

    return (
        <ComponentWrapper>
            <Box
                sx={{
                    padding: 1
                }}
            >
                <Stack  sx={{mb:2}}>
                    <BillingFilterSection
                        onApplyFilters={(params) => fetchDataFromServer(ordersLocation?.locationNumber, params)}
                        onClearFilters={() => fetchDataFromServer(ordersLocation?.locationNumber, {})}
                        isCustRevScreen={true}
                        customerType={filterParams.customerType}
                        selectedLocationList={selectedLocationList}
                        setSelectedLocationList={setSelectedLocationList}
                        exportButtons={exportButtons}

                    />
                </Stack>
                <CustomTabPanel value={activeTab} index={2}>
                    <SummaryByBillTo
                        setTotalRecords={val => setTotalRecords(val)} activeTab={activeTab}
                        rawData={rawData}
                        locationNumber={ordersLocation?.locationNumber || 0}
                        isLoading={isLoading}
                        filterParams={filterParams}
                        customerType={filterParams.customerType}
                        renderExportButtons={handleSetExportButtons}
                    />
                </CustomTabPanel>
            </Box>
            {
                !!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClose={() => setSnackbar(null)}
                        autoHideDuration={3000}
                    // bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
                    >
                        <Alert {...snackbar} onClose={() => setSnackbar(null)} />
                    </Snackbar>
                )
            }
        </ComponentWrapper>
    );
}