import { colors } from '../../theme/colors';
import login_background from '../../assets/login_background.jpg';
import styled, { keyframes } from 'styled-components'

export const Button = styled.button`
    color: ${({ active }) => active ? colors.white.normal : colors.blue.normal};
    background-color: ${({ active }) => active ? colors.blue.normal : colors.white.normal};
    border: ${({ active }) => active ? `1px solid transparent` : `1px solid ${colors.blue.normal}`};
    min-width: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    &:hover {
        box-shadow: 0 5px 12px 0 rgba(9, 17, 167, 0.3);
        opacity: 0.8;
    }
    &:active {
        background-color: ${({ active }) => active ? colors.white.normal : colors.blue.normal};
        color: ${({ active }) => active ? colors.blue.normal : colors.white.normal};
        border: ${({ active }) => active ? `1px solid ${colors.blue.normal}` : '1px solid transparent'} ;
    }
    &:disabled {
        background-color: #c9c9c9;
        color: #6c6c6c;
        border-color: #c9c9c9;
        pointer-events: none;
    }
`;



export const SquareButton = styled(Button)`
    border-radius: 0;
    max-width: 110px;
    width: 110px;
    height: 60px;
`

export const CancelButton = styled(Button)`
    color: black;
    border: unset;
    &:active {
        background-color: gray;
    }
    &:hover {
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.3);
    }
`

// add more generic components

export const Wrapper = styled.div`
    padding: 10px;
`;

export const RouteWrapper = styled.div`
    max-width: ${props => props.isScreenClicked ? '1024px' : 'none'};
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    overflow: overlay;
`;

export const AppWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${login_background});
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
`;

export const LoginWrapper = styled(RouteWrapper)`
    flex: 0.8;
    background-color: ${colors.white.normal};
    width: 80%;
    justify-content: flex-start;
`;

export const TenderListWrapper = styled(RouteWrapper)`
    flex: 1;
    width: 100%;
    background: ${colors.white.normal};
    justify-content: unset;
    align-items: unset;
    overflow: overlay;
`;

export const OrdersWrapper = styled(RouteWrapper)`
    flex: 1;
    width: 100%;
    background: ${colors.white.normal};
    justify-content: unset;
    align-items: unset;
    overflow: auto;
`;
export const TraceWrapper = styled(RouteWrapper)`
    flex: 1;
    width: 100%;
    background: ${colors.white.normal};
    justify-content: unset;
    align-items: unset;
    overflow: overlay;
`;
export const DispatchWrapper = styled(RouteWrapper)`
    flex: 1;
    width: 100%;
    background: ${colors.white.normal};
    justify-content: unset;
    align-items: unset;
    overflow: auto;
`;

export const ComponentWrapper = styled(RouteWrapper)`
    flex: 1;
    width: 100%;
    background: ${colors.white.normal};
    justify-content: unset;
    align-items: unset;
    overflow: overlay;
`;

export const TenderDetailsWrapper = styled(TenderListWrapper)`
`

export const H2 = styled.h2`
    color: ${colors.blue.normal};
    font-weight: bold;
    font-size: 18px;
`;

export const H4 = styled.h4`
    color: ${colors.blue.normal};
    font-size: 11px;
    text-transform: uppercase;
`;

export const StyledHeader = styled.div`
    background-color: ${colors.blue.normal};
    min-height: 90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const StyledSceneHeader = styled.div`
    background-color: ${colors.gray.normal};
    min-height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    z-index:100;
    // margin-top:10px
  @media (max-width: 767px) { 
        min-height: 10px; /* For mobile */
        position: fixed;
        top: 5;
        left: 0;
        right: 0;
    }
    `
// export const StyledSceneHeader = styled.div`
//     background-color: ${colors.gray.normal};
//     min-height: 80px;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, 
//                 rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
//     z-index: 100;
//     position: relative; /* Default */

//     @media (max-width: 767px) { 
//         min-height: 350px; /* For mobile */
//         position: fixed;
//         top: 10;
//         left: 0;
//         right: 0;
//         margin-top: 0; /* Remove margin to avoid shifting */
//     }
// `
export const StyledSceneContent = styled.div`
    background-color: ${colors.white.normal};
    min-height: 50px;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    border: 1px solid red;
    overflow: overlay;
    `

export const StyledFormSection = styled.div`
    background-color: ${colors.white.normal};
    min-height: 50px;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    `

export const StyledLogo = styled.div`
    position: relative;
    width: 150px;
    overflow: hidden;
    &::before {
        position: absolute;
        content: ' ';
        height: 200%;
        left: -50px;
        top: -50%;
        border-radius: 400px;
        width: 200px;
        background-color: white;
    }
`

export const ListWrapper = styled.div`
    flex: 1;
    background-color: ${colors.gray.normal};
    padding: 20px;
    overflow-y: auto;
`
export const TruckDriverListWrapper = styled.div`
    flex: 1;
    background-color: ${colors.gray.normal};
    overflow-y: auto;
`

export const TenderWrapper = styled.div`
    background-color: ${colors.white.normal};
    width: 100%;
    min-height: 100px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.3);
    margin: 10px 0;
    overflow-y: scroll;
`

export const ModalOverlay = styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    user-select: none;
`
export const TenderModalWrapper = styled.div`
    max-width: 500px;
    min-width: 250px;
    width: 70%;
    position: relative;
    max-height: 300px;
    top: 50%;
    background-color: white;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    padding: 10px;
`
export const DetailsButton = styled.button`
    background-color: ${colors.white.normal};
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    &:hover {
        color : ${colors.blue.normal};
        background-color: ${colors.gray.normal};
        opacity: 0.8;
    }
`

const blinkingEffect = () => {
    return keyframes`
      50% {
        opacity: 0.5;
      }
    `;
}

export const AnimatedComponent = styled.div`
    animation: ${blinkingEffect} 1s linear infinite;
    background: rgb(255 29 10) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
`