import store from '../../store/index'

export const getDatafromReducer = (glType) => {
    const state = store.getState();
    const someData = state.rateBookReducer.GLCodeDataAll;
    let finalData = someData.filter(e => e.glType === glType).map(e => e.glcode);
    return finalData;
};

// Lazy initialization for dynamic values
let miscReceivables;
let miscPayables;

const updateConstants = () => {
    miscReceivables = getDatafromReducer('MR');
    miscPayables = getDatafromReducer('MP');
};

// Initial update
updateConstants();

// Update the constants dynamically when Redux state changes
let previousGLCodeDataAll = [];

store.subscribe(() => {
    const state = store.getState();
    const currentGLCodeDataAll = state.rateBookReducer.GLCodeDataAll;

    if (currentGLCodeDataAll !== previousGLCodeDataAll) {
        previousGLCodeDataAll = currentGLCodeDataAll;
        updateConstants();
    }
});

export { miscReceivables, miscPayables };

export const FSCCodes = [
    3318000,
    3324000,
    6988000
]
export const getRatesCalculations = (rates, excludeFSC = false) => {
    // Calculate totals
    let totalRevenue = 0;
    let totalExpense = 0;
    let totalMiscRevenue = 0;
    let totalMiscExpense = 0;
    let receivables = [];
    let payables = []

    rates.forEach(rate => {

        if (excludeFSC && FSCCodes.includes(rate.glCode)) {
            return;
        }
        //const amount = parseFloat(rate.amount);
        const amount = parseFloat(rate.amount) || 0; // Ensure valid number, default to 0 if NaN
        if (rate.payableReceivable === 'R') {
            totalRevenue += amount;
            totalMiscRevenue += miscReceivables.includes(rate.glCode) ? amount : 0;
            receivables.push({
                receivable: rate.glCode,
                description: rate.description,
                amount: amount.toFixed(2)
            });
        } else if (rate.payableReceivable === 'P') {
            totalExpense += amount;
            totalMiscExpense += miscPayables.includes(rate.glCode) ? amount : 0;
            payables.push({
                payable: rate.glCode,
                description: rate.description,
                truck: rate.truck,
                amount: amount.toFixed(2)
            });
        }
    });

    const totRevenueAftDeductions = totalRevenue - totalMiscRevenue;
    const totalNet = totalRevenue - totalExpense;
    const totalPercent = totalRevenue !== 0 ? (totalExpense / totalRevenue) * 100 : 0;
    const ptNet = totalNet - totalMiscRevenue;
    //const ptPercent = totalRevenue !== 0 ? (totalExpense / totRevenueAftDeductions) * 100 : 0;
    const ptPercent = totRevenueAftDeductions !== 0 ? (totalExpense / totRevenueAftDeductions) * 100 : 0; //To handle infinity value in percentage
    const revenue = totRevenueAftDeductions;
    const expense = totalExpense - totalMiscExpense;
    return { revenue, totalRevenue, totalExpense, totalMiscRevenue, expense, totalMiscExpense, totalNet, totalPercent, ptNet, ptPercent, receivables, payables };
}

export const billingReportProperties = (source, index) => {
    // Calculate totals
    const { revenue, totalRevenue, expense, totalExpense, totalNet, totalPercent, ptNet, ptPercent, receivables, payables, totalMiscRevenue, totalMiscExpense, } = getRatesCalculations(source.rates);
    return {
        id: index,
        locationNumber: source.location,
        controlNumber: source.controlNumber,
        totalLoads: source.totalStops,
        customerName: source.customerName,
        billToCode: source.billTo,
        shipperAgentCode: source.shipperAgentCode,
        billingDate: source.billingDate,
        reference: source.reference,
        unitNumber: source.unit,
        io: source.io,
        apptDate: source.apptDate,
        PULoc: source.puLocation,
        dispositionLoc: source.dispLocation,
        returnDate: source.returnDate,
        shipperName: source.shipper,
        shipperAddress: [source.shipperCity, source?.shipperState].filter(Boolean).join(', '), //`${source.shipperCity +','+ source.shipperState}`,
        consigneeName: source.consignee,
        consigneeAddress: [source.consigneeCity, source?.consigneeState].filter(Boolean).join(', '), //`${source.consigneeCity +','+ source.consigneeState}`,
        revenue: revenue.toFixed(2),
        miscRevenue: totalMiscRevenue.toFixed(2),
        totalRevenue: totalRevenue.toFixed(2),
        totalNet: totalNet.toFixed(2),
        totalPercent: totalPercent.toFixed(2),
        expense: expense.toFixed(2),
        miscExpense: totalMiscExpense.toFixed(2),
        totalExpense: totalExpense.toFixed(2),
        ptNet: ptNet.toFixed(2),
        ptPercent: ptPercent.toFixed(2),
        receivables: receivables,
        payables: payables,
        totalMiscRevenue: totalMiscRevenue.toFixed(2),
        totalMiscExpense: totalMiscExpense.toFixed(2)
    };
}