import { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';

export const RatebookSearch = (props) => {
    const { searchResult } = props;
    const [hide, setHide] = useState(false);
    const [searchStr, setSearchStr] = useState("");

    return (
        <Paper
            sx={{  display: 'flex',justifyContent: "space-between", height: "38px", }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                variant="outlined"
                value={searchStr}
                placeholder="Search Rate Name"
                onChange={(e) => { setSearchStr(e.target.value); }}
                onKeyPress={(event) => { event.key === 'Enter' && searchResult(searchStr); setHide(true) }}
            />
            {hide ? <IconButton onClick={() => { searchResult(''); setSearchStr(''); setHide(false) }}>

                <ClearIcon />

            </IconButton> :
                <IconButton type="submit"
                    onClick={() => { searchResult(searchStr); setHide(true) }}
                    // sx={{ p: '10px' }}
                    aria-label="search">
                    <SearchIcon />
                </IconButton>
            }
        </Paper>
    );
}