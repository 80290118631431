import React, { useState, useRef, useLayoutEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { GET } from "../../utils/axios";

const CustomAutoComplete = ({
    fetchDataEndpoint,         // Endpoint for data fetching (optional)
    queryParams = {},          // Additional query parameters for the API call
    searchKey = "search",      // Key to use for the search query (dynamic)
    valueChange,               // Callback for value changes
    existingValue = "",        // Initial value for the autocomplete
    isDisabled = false,        // Whether the component is disabled
    filterOptions,             // Function to filter API response data
    optionLabel,               // Function to define option label
    optionKey = "id",          // Unique key for options
    placeholder = "Search...", // Placeholder text
    debounceTime = 500,        // Debounce time for input changes
    staticOptions = [],        // Options provided directly as props
    enableSearch = true,       // Whether to enable remote search
    allowedPattern = null, ///^[a-zA-Z0-9]*$/, // Allowed input pattern (default: alphanumeric)
    maxSize = 500,              // Maximum input size
    existingObject = null,
    hasFocus = false
}) => {
    const [value, setValue] = useState(existingObject);
    const [options, setOptions] = useState(staticOptions);
    const [error, setError] = useState(""); // State for error messages
    const timeOutRef = useRef(null);

    const handleLookup = async (event, inputValue, reason) => {
        // Validate input if allowedPattern is defined
        if (allowedPattern && !allowedPattern.test(inputValue)) {
            setError("Input contains invalid characters.");
            return;
        }

        // Validate maxSize
        if (inputValue.length > maxSize) {
            setError(`Input exceeds maximum size of ${maxSize} characters.`);
            return;
        }

        setError(""); // Clear error if input is valid

        if (fetchDataEndpoint && enableSearch && inputValue && reason !== "reset") {
            if (timeOutRef.current) clearTimeout(timeOutRef.current);

            timeOutRef.current = setTimeout(async () => {
                
                 // Construct query parameters as a string
                const queryParamsString = new URLSearchParams({
                    ...queryParams,
                    [searchKey]: inputValue,
                }).toString();

                const url = `${fetchDataEndpoint}?${queryParamsString}`;
                
                try {
                    const response = await GET(url); // Make the GET request
                    if (response?.status !== "error") {
                        let apiData = response.data || [];
                        if (filterOptions) apiData = filterOptions(apiData);
                        setOptions(apiData);
                    } else {
                        setOptions([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setOptions([]);
                }
            }, debounceTime);
        } else if (!fetchDataEndpoint || !enableSearch) {
            // If no endpoint is provided or search is disabled, use staticOptions
            setOptions(staticOptions);
        }
    };

    const handleChange = (event, selectedValue, reason) => {
        if (reason === "clear" || !selectedValue) {
            setValue(null);
            if (valueChange) valueChange(null);
        } else {
            setValue(selectedValue);
            // if (valueChange && error ==='' ) valueChange(selectedValue);
            if (valueChange && (!allowedPattern || error === "")) valueChange(selectedValue);
        }
    };

    const getOptionLabel = (option) => {
        if (typeof option === "string") return option;
        if (optionLabel) return optionLabel(option);
        return option?.notes || "";
    };

    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <div>
            <Autocomplete
                disabled={isDisabled}
                freeSolo
                autoSelect
                options={options}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="autocomplete"
                        variant="standard"
                        placeholder={placeholder}
                        error={!!error}
                        helperText={error}
                        inputProps={{
                            ...params.inputProps,
                            maxLength: maxSize, // Enforce max size directly in the input field
                        }}
                        sx={{
                            input: {
                                //textTransform: "uppercase",
                            },
                        }}
                        style={{
                            width: 120,
                        }}
                        inputRef={ref}
                    />
                )}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        key={option[optionKey]}
                        style={{ borderBottom: "1px solid #E0E0E0" }}
                    >
                        {optionLabel ? optionLabel(option) : option?.notes || ""}
                    </li>
                )}
                componentsProps={{ popper: { style: { width: 300 } } }}
                onInputChange={handleLookup}
                onChange={handleChange}
                value={value}
            />
        </div>
    );
};

export default CustomAutoComplete;
