import { useRef, useLayoutEffect, useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

export default function CustomDatePicker(props) {
    const { label, size, className, value, onValueChange, disabled, variant, hasFocus, isArrowKeyDisabled, showOneMonthAlert = false } = props;
    
    const ref = useRef();
    const [currentValue, setCurrentValue] = useState(value ? moment.utc(value) : null);
    const [previousValue, setPreviousValue] = useState(() => moment.utc(value));

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current?.focus();
        }
    }, [hasFocus]);

    const handleKeyDown = (event) => {
        const { key } = event;
        if ((key === 'ArrowUp' || key === 'ArrowDown') && isArrowKeyDisabled) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    useEffect(() => {
        const inputElement = ref.current;
        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);

    const handleDateChange = (newValue) => {
        if (!newValue || !moment(newValue).isValid()) {
            setCurrentValue(null);
            onValueChange("");
            return;
        }

        //const formattedValue = moment.utc(newValue);

        // Parse as Local Date (prevents UTC shifting)
        const formattedValue = moment(newValue, ['MM/DD/YY', 'MM/DD/YYYY', 'YYYY-MM-DD'], true);
        
        // Only update if current value is different from the previous value
            setCurrentValue(formattedValue);
            onValueChange(formattedValue?.format('YYYY-MM-DD'));
    };

    const handleFocusOut = (selectedDate) => {
        if (!selectedDate) return;

        const currentDate = moment();
        const thirtyDaysBefore = currentDate.clone().subtract(30, "days");
        const thirtyDaysAfter = currentDate.clone().add(30, "days");

        // Check if the selected date is out of the 30-day range and if the current value is different from the previous value
        if (showOneMonthAlert && (selectedDate.isBefore(thirtyDaysBefore) || selectedDate.isAfter(thirtyDaysAfter)) && !selectedDate?.isSame(previousValue)) {
            const userConfirmed = window.confirm("Selected date is outside the 30-day range. Do you want to proceed?");
  
            if (!userConfirmed) {
                setCurrentValue(previousValue);
                onValueChange(previousValue?.format('YYYY-MM-DD'));
            }else {
                setPreviousValue(currentValue);
            }
        }
    };

    const handleDateAccept = (newValue) => {
        handleDateChange(newValue); // To set the value
        handleFocusOut(newValue); // Trigger validation after selection
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                value={currentValue}
                onChange={handleDateChange}
                onAccept={handleDateAccept} // Trigger validation when date is selected from the calendar
                slotProps={{
                    textField: { 
                        size: size, 
                        className: className, 
                        error: false, 
                        disabled: disabled, 
                        variant: variant,
                        onBlur: () => handleFocusOut(currentValue), // Validate on blur (manual entry)
                        inputRef: ref 
                    },
                    actionBar: { actions: ['clear', 'accept'] },
                    openPickerIcon: {
                        sx: { fontSize: '1.1rem' }
                    }
                }}
                clearable={true}
                format='MM/DD/YY'
                reduceAnimations={true}
                disabled={disabled}
                variant={variant}
            />
        </LocalizationProvider>
    );
}
