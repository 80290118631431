import { useState } from 'react';
import { Stack, Button, Typography, Tooltip, FormControlLabel, Checkbox,Box } from "@mui/material";
import ResponsiveGrid from "../../components/GridCustomComponent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanded view
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const TraceFilterSection = ({
    handleShowAll,
    handleInvalidRemove,
    handleRemoveSelected,
    handleCopyUnitToClipboard,
    isDomesticChecked,
    setIsDomesticChecked,
    rowSelectionModel,
    hasUnsavedRows,
    isSaving,
    saveChanges,
    discardChanges,
    sortModel,
    setSortModel
}) => {
       const [isExpanded, setIsExpanded] = useState(true);
    return (
        // <Stack spacing={2}>
       <Box sx={{ padding: 2, boxShadow: "0 4px 6px rgba(0,0,0,0.1)" }}>
        <ResponsiveGrid gridValues={[12]}> 
          <Box display="flex"  marginBottom={2}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                color: "#0911A7",
                flexGrow: 1,
              }}
            >
              Trace
            </Typography>
            <Button
        variant="outlined"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          display: { xs: "flex", md: "flex" }, // Hide on medium and larger screens
          // marginBottom: 2,
        }}
      >
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      </Box>
      </ResponsiveGrid>
{/* <Box sx={{ display: { xs: isExpanded ? "block" : "none", md: "flex" } }}>   */}
<Box sx={{ display: { xs: isExpanded ? "block" : "none", md: isExpanded ? "block" : "none"} }}>
        <ResponsiveGrid
          gridValues={[  1, 2, 2, 1, 1, 2, 2, 1, 1, 1]}
        >
                <Button variant="outlined"  sx={{ textTransform: "capitalize" }} fullWidth onClick={() => handleShowAll()}>Show All</Button>
                <Button variant="outlined"  sx={{ textTransform: "capitalize" }} fullWidth onClick={() => handleInvalidRemove()}>Remove Invalid</Button>
                <Button variant="outlined"  sx={{ textTransform: "capitalize" }} fullWidth onClick={() => handleRemoveSelected()}>Remove Selected</Button>
                <Button variant="outlined"  sx={{ textTransform: "capitalize" }} fullWidth onClick={() => handleCopyUnitToClipboard()} disabled={(rowSelectionModel.length > 0) ? false : true}>Copy Units</Button>
                <Box>
                <Tooltip title="Drop leading zeroes and check digit" arrow>
                    <FormControlLabel
                        control={<Checkbox checked={isDomesticChecked} onChange={(e, value) => setIsDomesticChecked(value)} />}
                        label="Domestic"
                        // size="small"
                        disabled={(rowSelectionModel.length > 0) ? false : true}
                        fullWidth
                    />
                </Tooltip>
                </Box>
                <Button variant="outlined"  sx={{ textTransform: "capitalize" }}
                    disabled={!hasUnsavedRows}
                    //loading={isSaving}
                    onClick={saveChanges}
                    fullWidth
                >Save Changes</Button>
                <Button variant="outlined"  sx={{ textTransform: "capitalize" }}
                    disabled={!hasUnsavedRows || isSaving}
                    onClick={discardChanges}
                    fullWidth
                >Discard Changes</Button>
                <Button variant="outlined" sx={{ textTransform: "capitalize" }} fullWidth onClick={() => setSortModel([])} disabled={(sortModel.length > 0) ? false : true}>Remove Sort</Button>
               
                </ResponsiveGrid>
                </Box> 
            {/* </Stack> */}
            </Box>            
        
    );
};

export default TraceFilterSection;
