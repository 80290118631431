import { Grid } from "@material-ui/core";
import { useEffect, useState } from 'react';
import CompletedOrderTable from "./COTable";
import { OrdersWrapper } from "../../components/styledComponents";
import CompletedOrderFormContainer from "./COFormContainer";
import { useSelector, useDispatch } from "react-redux";
import { searchOrdersElastic } from "../../store/actions/orderActions";
import { getSingleOrder } from "../../store/actions/orderActions"
import { maptoCompletedOrderTable } from "./components/commonFn"

export default function CompletedOrder() {

    const [rows, setRows] = useState([]);
    const [rowsCount, setRowsCount] = useState(1)
    const dispatch = useDispatch();
    const [orderType, setOrderType] = useState('INBOUND');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState("")
    const [selectedRow, setSelectedRow] = useState("")
    const [returnDateFrom, setReturnDateFrom] = useState("");
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    // const [pageSize, setPageSize] = useState(200);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10000
    });
    const [sortModel, setSortModel] = useState([]);
    const [onbaseLink, setOnbaseLink] = useState("");

    const handlePageChange = (params) => {
        console.log("Pagination model changed:", params);
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize
        });
        completedOrderTableApiCall({ page: params.page, pageSize: params.pageSize, sortModel });
    };

    const handleSortChange = (newSortModel) => {
        setSortModel(newSortModel);
        completedOrderTableApiCall({ page: paginationModel.page, pageSize: paginationModel.pageSize, sortModel: newSortModel });
    };

    useEffect(() => {
        setIsLoading(true);
        setSelectedRow('');
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            completedOrderTableApiCall({ page: 0, pageSize: paginationModel.pageSize, sortModel });
        }
    }, [ordersLocation, returnDateFrom])
    function processInput(isSearch) {
        // Check if the input can be converted to a valid number
        if (!isNaN(isSearch) && !isNaN(parseFloat(isSearch))) {
            return "number"; // Return as a number
        }
        return "string"; // Return as a string
    }

    const completedOrderTableApiCall = ({ page, pageSize, sortModel, isSearch = "" }) => {
        setSelectedRowData("");
        setSelectedRow('');
        setOnbaseLink("")
        setIsLoading(true);

        let filter = [
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation?.locationNumber,
                format: "Number",
            },
            {
                clause: "AND",
                field: "status",
                operand: "IS",
                value: "C",
                format: "Alphanumeric",
            },
        ];

        if (isSearch) {
            console.log("issearch",typeof isSearch)
            const searchType=processInput(isSearch)
            console.log("searchType",searchType)
            filter =
            searchType === "number"
            ? [
                {
                  clause: "AND",
                  field: "locationNumber",
                  operand: "IS",
                  value: ordersLocation?.locationNumber,
                  format: "Number",
                },
                {
                  clause: "OR",
                  field: "controlNumber",
                  operand: "IS",
                  value: `${isSearch}`,
                  format: "Number",
                },
              ]
            : [
                {
                  clause: "AND",
                  field: "locationNumber",
                  operand: "IS",
                  value: ordersLocation?.locationNumber,
                  format: "Number",
                },
                {
                  clause: "OR",
                  field: "shipperAgentCode",
                //   operand: "IS",
                  operand: "CONTAINS",
                  value: `${isSearch}`,
                  format: "Alphanumeric",
                },
                {
                  clause: "OR",
                  field: "unitNumber",
                //   operand: "IS",
                  operand: "CONTAINS",
                  value: `${isSearch}`,
                  format: "Alphanumeric",
                },
                // {
                //   clause: "OR",
                //   field: "billingBooking",
                //   operand: "IS",
                //   value: `${isSearch}`,
                //   format: "Alphanumeric",
                // },
                {
                  clause: "OR",
                  field: "billingReference",
                //   operand: "IS",
                  operand: "CONTAINS",
                  value: `${isSearch}`,
                  format: "Alphanumeric",
                },
                // {
                //   clause: "OR",
                //   field: "chassisNumber",
                //   operand: "IS",
                //   value: `${isSearch}`,
                //   format: "Alphanumeric",
                // },
                {
                    clause: "OR",
                    field: "shipperOrConsShipper",
                    // operand: "IS",
                    operand: "CONTAINS",
                    value: `${isSearch}`,
                    format: "Alphanumeric",
                  },
                  
                
              ];
            // filter = [...filter, ...queryArray]
        }
        if (returnDateFrom) {
            const returnFilter = {
                clause: "AND",
                field: "returnDate",
                operand: "BETWEEN",
                value: `${returnDateFrom.split(" ")[0]}`,
                format: "DateRange",
            };
            filter.push(returnFilter);
        }

        const queryData = {
            query: {
                page,
                pageSize,
                sortBy: sortModel[0]?.field || "controlNumber",
                sortOrder: sortModel[0]?.sort || "desc",
            },
        };

        dispatch(searchOrdersElastic(filter, queryData)).then((res) => {
            const mappedData = maptoCompletedOrderTable(res?.data?.hits);
            setRows(mappedData);
            const rowLen=mappedData.length ||0
            setRowsCount(rowLen)
            setIsLoading(false);
        });
    };

    const singleCompletedOrderData = (controlNo) => {
        setSelectedRow(controlNo)
        let reqParams = {
            locationNumber: ordersLocation.locationNumber,
            controlNumber: controlNo,
        }
        dispatch(getSingleOrder(reqParams)).then((res) => {
            if (res?.status === 'success') {
                setSelectedRowData(res.data)
            }
        })
    }
    return (
        <OrdersWrapper>
            <Grid container  >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div style={{ height: `calc(100vh - 90px)`, width: '100%', padding: '10px' }}>
                        <CompletedOrderTable
                            rows={rows}
                            setOrderType={setOrderType}
                            rowsCount={rowsCount}
                            singleCompletedOrderData={singleCompletedOrderData}
                            selectedRow={selectedRow}
                            ordersLocation={ordersLocation}
                            returnDateFrom={returnDateFrom}
                            setReturnDateFrom={setReturnDateFrom}
                            completedOrderTableApiCall={completedOrderTableApiCall}
                            sortModel={sortModel}
                            handlePageChange={handlePageChange}
                            handleSortChange={handleSortChange}
                            isLoading={isLoading}
                            paginationModel={paginationModel}
                            onbaseLink={onbaseLink}
                            setOnbaseLink={setOnbaseLink}
                        />

                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <div style={{ height: `calc(100vh - 90px)`, width: '100%', padding: !selectedRow === undefined || !selectedRow === '' ? '0px' : '10px' }}>
                        {
                            !selectedRow ?
                                <div style={{ width: '100%', height: `calc(100vh - 90px)`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <h3 style={{ fontSize: '22px', color: '#707070' }}> Open any Completed Order</h3>
                                </div>
                                :
                                (<div key={selectedRow}>
                                    <CompletedOrderFormContainer
                                        selectedRowData={selectedRowData}
                                        orderType={orderType}
                                        setOrderType={setOrderType}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        setOnbaseLink={setOnbaseLink}
                                    />
                                </div>)
                        }

                    </div>
                </Grid>
            </Grid>

        </OrdersWrapper>
    )

}