import { Button } from "@mui/material";
import { useDispatchContext } from "../dispatchContext";

export default function TableHeadingStrip(props) {
    const { title, handleSave, discardChanges, isSaving } = props
    const { hasUnsavedRows } = useDispatchContext();

    return (
        <div style={{
            width: '100%', height: '40px', backgroundColor: '#0B4387', alignItems: 'center', display: 'flex', paddingLeft: '15px', justifyContent: 'center',
            padding: '0 15px',
            position: 'relative'
        }}>
            {/* <label style={{ fontSize: '14px', color: 'white' }}>{title}</label> */}
            <label style={{ fontSize: '14px', color: 'white', position: 'absolute', left: '15px' }}>
                {title}
            </label>

            {title === "Spotted" && (
                <div style={{ display: "flex", gap: "15px" }}>
                    <Button
                        variant="text"
                        style={{
                            color: hasUnsavedRows ? "white" : "rgba(255, 255, 255, 0.6)", // Dull color when disabled
                            textTransform: "none",
                            textDecoration: "underline",
                            fontWeight: hasUnsavedRows ? "bold" : "normal", // Make text bold when enabled
                        }}
                        disabled={!hasUnsavedRows}
                        onClick={handleSave}
                    >
                        {isSaving ? "Saving..." : "Save"}
                    </Button>

                    <Button
                        variant="text"
                        style={{
                            color: hasUnsavedRows ? "white" : "rgba(255, 255, 255, 0.6)", // Dull color when disabled
                            textTransform: "none",
                            textDecoration: "underline",
                            fontWeight: hasUnsavedRows ? "bold" : "normal", // Make text bold when enabled
                        }}
                        disabled={!hasUnsavedRows}
                        onClick={discardChanges}
                    >
                        Discard
                    </Button>
                </div>
            )}
        </div>
    )
}