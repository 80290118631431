import moment from "moment";
import TenderButton from './components/TenderButton';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import CustomDatePicker from '../../components/CustomDatePicker';
import {
    convertYMDToMDY,
    convertMDYToYMD,
    convertMilitaryDateToYMD,
    convertMilitaryTimeToHHMM
} from '../../utils/global-functions';

export const todaysDate = moment().format('1YYMMDD');
export const apptDateGTE180d = moment().subtract(180, 'days').format('1YYMMDD');

export const loadsTableTabs = () => {
    let days = [];
    let daysRequired = 8

    for (let i = 0; i <= daysRequired; i++) {
        let dayName = moment().add(i, 'days').format('dddd');
        let obj = { id: i, dayDate: moment().add(i, 'days').format('1YYMMDD'), nextDayDate: moment().add(i + 1, 'days').format('1YYMMDD'), uniqKey: dayName.toLowerCase() }
        if (i === 0) {
            obj = { ...obj, label: 'Past/Today', uniqKey: (dayName === 'Saturday') ? 'satsun' : 'pasttoday' }
        } else if (i === 7) {
            obj = { ...obj, label: 'Future Loads', uniqKey: 'futureload' }
        } else if (i === 8) {
            obj = { ...obj, label: 'Unappointed', uniqKey: 'unappointed' }
        } else if (dayName === 'Saturday') {
            obj = { ...obj, label: 'Sat/Sun', uniqKey: 'satsun' }
        } else if (dayName === 'Sunday') {
            continue;
        } else {
            obj = { ...obj, label: dayName }
        }
        days.push(obj)
    }
    return days;
}

const commonTableColumn = [
    { field: 'io', headerName: 'I/O', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spec', headerName: 'Spec', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'unit', headerName: 'Unit', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'chassisNumber', headerName: 'Chassis', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'ssl', headerName: 'SSL/Eqp', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'size', headerName: 'Size', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'puloc', headerName: 'PU Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'rtnloc', headerName: 'RTN Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'shipConsName', headerName: 'Shipper/Cgnee', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'cityState', headerName: 'City,ST', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
]

const apptDatetimeComparator = (v1, v2) => {
    const parsed1 = parseApptDateTime(v1);
    const parsed2 = parseApptDateTime(v2);

    if (parsed1 < parsed2) return -1;
    if (parsed1 > parsed2) return 1;
    return 0;
};

const parseApptDateTime = (value) => {
    if (!value || value.trim() === "") return Number.MAX_VALUE; // Push empty values to the bottom

    const match = value.match(/^(\d{2}\/\d{2}\/\d{2})\s*(.*)?$/); // Extract date and suffix
    if (!match) return Number.MAX_VALUE; // Fallback for invalid formats

    const datePart = match[1]; // "02/28/25"
    const timeOrText = match[2]?.trim(); // "AAA", "12:00", "8", etc.

    // Convert date to a timestamp
    const date = new Date(datePart);
    if (isNaN(date.getTime())) return Number.MAX_VALUE;

    // Handle time values
    if (timeOrText) {
        if (/^\d+$/.test(timeOrText)) {
            // Convert numeric time (e.g., "8" -> 08:00, "50" is treated as higher)
            return date.getTime() + parseInt(timeOrText, 10) * 60000;
        }
        if (/^\d+:\d+$/.test(timeOrText)) {
            // Convert HH:MM format (e.g., "12:00")
            const [hours, minutes] = timeOrText.split(":").map(Number);
            return date.getTime() + (hours * 60 + minutes) * 60000;
        }
        // If it's a string (AAA, ABCD, etc.), sort it last but keep ordering
        return date.getTime() + timeOrText.charCodeAt(0) * 100000;
    }

    return date.getTime(); // Return only date if no suffix exists
};


export const loadsTableColumns = [
    { field: 'stop', headerName: 'Stop', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell' },
    { field: 'control', headerName: 'Control', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true, renderCell: (params) => (<TenderButton {...params} type='loads' btnLabel={`T`} />) },
    { field: 'io', headerName: 'I/O', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'puloc', headerName: 'PU Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'unit', headerName: 'Unit', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'chassisNumber', headerName: 'Chassis', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    {
        field: 'apptDateTime',
        headerName: 'Appt Date/Time',
        headerClassName: 'MuiDataGrid-columnHeaders',
        cellClassName: 'super-app-theme--cell',
        disableColumnMenu: true,
        renderCell: (params) => params.row.apptDateTime,
        valueGetter: (params) => params.row.apptDateTime,
        sortComparator: apptDatetimeComparator,
        minWidth: 110
    },
    { field: 'shipConsName', headerName: 'Shipper/Cgnee', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'cityState', headerName: 'City,ST', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'rtnloc', headerName: 'RTN Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'etaDateTime', headerName: 'ETA', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'notifyDateTime', headerName: 'Notify/ERD', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'lfdcut', headerName: 'LFD/CUT', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'release', headerName: 'Release/Bkg#', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spec', headerName: 'Spec', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'ssl', headerName: 'SSL/Eqp', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'size', headerName: 'Size', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true }
]

export const spottedTableColumns = [
    { field: 'stop', headerName: 'Stop', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell' },
    { field: 'control', headerName: 'Control', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true, renderCell: (params) => (<TenderButton {...params} type='spotted' btnLabel={`T`} />) },
    ...commonTableColumn,
    { field: 'outageDateTime', headerName: 'Outage Date/Time', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spotDateTime', headerName: 'Spotted Date/Time', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    {
        field: 'availDateTime',
        headerName: 'Avail Date/Time',
        headerClassName: 'MuiDataGrid-columnHeaders',
        cellClassName: 'super-app-theme--cell',
        disableColumnMenu: true,
        editable: true,
        renderCell: (params) => params.row.availDateTime,
        sortComparator: apptDatetimeComparator,
        minWidth: 110,
        editable: true,
        renderEditCell: (params) => {
            // Convert the MM/DD/YYYY date and time to ISO format for the picker
            const extractDate = convertMilitaryDateToYMD(params.row.availDate)
            const apiResTime = convertMilitaryTimeToHHMM(params.row.availTime)
            const isoFormattedDate = `${extractDate}T${apiResTime}`;
            return (
                <CustomDateTimePicker
                    variant="standard"
                    size="small"
                    className="width_100"
                    value={isoFormattedDate || ''}
                    onValueChange={(selectedVal) => {
                        const formattedDate = convertYMDToMDY(selectedVal.eDate) || ''; // Convert to MM/DD/YYYY
                        const formattedTime = selectedVal.eTime || '';
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: `${formattedDate} ${formattedTime}`
                        });
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                    showOneMonthAlert={true}
                />
            );
        },
        valueGetter: (params) => {
            if (params.row?.availDateTime === undefined || params.row?.availDateTime === null) {
                console.warn('Missing availDateTime:', params.row);
                return '';
            }
            return params.row.availDateTime;
        }
    },
    {
        field: 'lastFreeDay',
        headerName: 'Last Diem Day',
        headerClassName: 'MuiDataGrid-columnHeaders',
        cellClassName: 'super-app-theme--cell',
        disableColumnMenu: true,
        editable: true,
        minWidth: 160,
        flex: 1,
        renderCell: (params) => {
            return params.row.lastFreeDay ? convertYMDToMDY(params.row.lastFreeDay) : '';
        },
        renderEditCell: (params) => (
            <CustomDatePicker
                size="small"
                className="width_100"
                variant="standard"
                value={params.row.lastFreeDay ? convertMDYToYMD(params.row.lastFreeDay) : ''}
                onValueChange={(selectedVal) => {
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: convertYMDToMDY(selectedVal) // Ensure MM/DD/YYYY format
                    });
                }}
                hasFocus={params.hasFocus}
                isArrowKeyDisabled={true}
            />
        ),
        minWidth: 130,
        flex: 1,
        valueGetter: (params) => {
            const datePart = convertMDYToYMD(params.row.lastFreeDay);
            return datePart;
        },
        sortComparator: (v1, v2) => {
            // Ensure values are valid Date objects before comparing
            const date1 = v1 instanceof Date ? v1 : new Date(v1);
            const date2 = v2 instanceof Date ? v2 : new Date(v2);
            return date1 - date2;
        },
    }
]

export const subItemTableColumns = [
    { field: 'stop', headerName: 'Stop', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell' },
    {
        field: 'control', headerName: 'Control', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true, minWidth: 91,
        renderCell: (params) => (<TenderButton {...params} type='driver' btnLabel={`T`} />)
    },
    { field: 'io', headerName: 'I/O', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spec', headerName: 'Spec', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'unit', headerName: 'Unit', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'chassisNumber', headerName: 'Chassis', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    {
        field: 'apptDateTime',
        headerName: 'Appt Date/Time',
        headerClassName: 'MuiDataGrid-columnHeaders',
        cellClassName: 'super-app-theme--cell',
        disableColumnMenu: true,
        renderCell: (params) => params.row.apptDateTime,
        valueGetter: (params) => params.row.apptDateForSort,
        sortComparator: apptDatetimeComparator,
        minWidth: 110
    },
    { field: 'ssl', headerName: 'SSL/Eqp', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'size', headerName: 'Size', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'puloc', headerName: 'PU Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'rtnloc', headerName: 'RTN Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'shipConsName', headerName: 'Shipper/Cgnee', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'cityState', headerName: 'City,ST', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'lfdcut', headerName: 'LFD/CUT', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', editable: false, disableColumnMenu: true, minWidth: 110 },
    { field: 'tenderType', headerName: 'Type', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', editable: false, disableColumnMenu: true },
    { field: 'lastEvent', headerName: 'Last Event', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', editable: false, disableColumnMenu: true },
]

export function validateHhMm(inputField) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const setRowOrderByCookie = (truck, arr) => {
    let reOrder = arr.map((v, i) => ({ ...v, displayPosition: i }))
    localStorage.setItem(truck, JSON.stringify(reOrder));
}

export const updateRowOrderByCookie = (truck, arr) => {
    localStorage.setItem(truck, JSON.stringify(arr));
}

export const getRowOrderFromCookie = (truck) => {
    const arrFromCookie = JSON.parse(localStorage.getItem(truck));
    return arrFromCookie?.length > 0 ? arrFromCookie : [];
}

