import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { Paper } from "@mui/material";
import './DispatchStyle.css'
import TableHeadingStrip from './components/TableHeadingStrip';
import CommonTable from './components/CommonTable';

import TabsComponent from "./components/TabsComponent";
import { loadsTableTabs, loadsTableColumns, validateHhMm, isNumeric } from './commonFn';
import _ from 'loadsh';

const tabname = loadsTableTabs();
const tableColumns = loadsTableColumns;

export default function LoadsTable({ stopsDataParent, isLoading }) {
    //const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [groupRows, setGroupRows] = useState({});
    const [tabNames, setTabNames] = useState(tabname);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(async () => {
        await groupStopsData(stopsDataParent);
    }, [stopsDataParent])

    const groupStopsData = useCallback(async (stops) => {
        try {
            let finalData = {};
            let tabNameWithLoadCount = [];

            tabname.map((tabData) => {
                let filterData = stops.filter((val) => {
                    if (
                        val.arriveTruck === 0 &&
                        val.arriveDriver === 0
                        // val.spotDate === 0 &&
                        // (val.departDriver === 0 || val.departDriver > 0) &&
                        // (val.departTruck === 0 || val.departTruck > 0)
                    ) { // && val.departDriver === 0 && val.arriveDriver === 0 && val.arriveTruck === 0 && val.availDate === 0
                        if (tabData['uniqKey'] === 'unappointed' && !val.apptDate) {
                            return val;
                        } else if (tabData['uniqKey'] === 'pasttoday' && Number(val.apptDate) <= Number(tabData['dayDate']) && val.apptDate) {
                            return val;
                        } else if (tabData['uniqKey'] === 'satsun' && (Number(val.apptDate) === Number(tabData['dayDate']) || Number(val.apptDate) === Number(tabData['nextDayDate'])) && val.apptDate) {
                            return val;
                        } else if (tabData['uniqKey'] === 'futureload' && Number(val.apptDate) >= Number(tabData['dayDate']) && val.apptDate) {
                            return val;
                        } else if (Number(val.apptDate) === Number(tabData['dayDate'])) {
                            return val;
                        }
                    }
                })

                // let sortedData = _.sortBy(filterData, function (o) {
                //     // 1st sort by apptDate is its valid
                //     let sortByDate = (o.apptDateForSort) ? new Date(o.apptDateForSort).getTime() : '';
                //     // 2nd sort by apptTime if its blank
                //     let sortByBlank = (o.apptTime == '') ? "" : -1;
                //     // 3rd sort by apptTime if its numeric
                //     let sortByNumeric = (o.apptTimeForSort.match(/^\d/)) ? parseInt(o.apptTimeForSort, 10) * -2 : "";
                //     // 4th sort by apptTime if its string
                //     let sortByAlpha = (!o.apptTimeForSort.match(/^\d/)) ? o.apptTimeForSort.toString() : "";
                //     return [sortByDate, sortByBlank, sortByNumeric, sortByAlpha];
                // });
                let sortedData = _.sortBy(filterData, (o) => {
                    let sortByDate = o.apptDateForSort ? new Date(o.apptDateForSort).getTime() : Number.MAX_VALUE;
                    let sortByBlank = o.apptTime === '' ? 1 : -1;
                    let sortByNumeric = /^\d/.test(o.apptTimeForSort) ? parseInt(o.apptTimeForSort, 10) * -2 : Number.MAX_VALUE;
                    let sortByAlpha = !/^\d/.test(o.apptTimeForSort) ? o.apptTimeForSort.toLowerCase() : '';
                
                    return [sortByDate, sortByBlank, sortByNumeric, sortByAlpha];
                });
                
                Object.assign(finalData, { [tabData['uniqKey']]: sortedData });
                tabNameWithLoadCount.push({ ...tabData, loadCount: filterData?.length || 0 })
            })

            setTabNames(tabNameWithLoadCount);
            if (typeof finalData !== 'undefined' && Object.keys(finalData || {}).length > 0) {
                setGroupRows(finalData);
                setRows(finalData[tabname[selectedTab]['uniqKey']])
            }
            //return finalData;
        } catch (error) {
            console.log('Error:', error)
        }
    }, [stopsDataParent])

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        let tabData = tabname[newValue];
        let data = groupRows[tabData['uniqKey']];
        if (typeof data !== 'undefined' && data.length > 0) {
            return setRows(data);
        }
        return setRows([]);
    }


    return (
        <div style={{ height: 'auto', width: '100%', flexDirection: 'column', display: 'flex', padding: '10px' }}>
            <TableHeadingStrip title={'Load'} />
            <TabsComponent
                tabname={tabNames}
                tabletype={'Load'}
                rowData={rows}
                handleTabChange={(event, newValue) => handleTabChange(event, newValue)}
                tableColumns={tableColumns}
                isLoading={isLoading}
            />
            <CommonTable rowData={rows} tableColumns={tableColumns} isLoading={isLoading} tabletype={'load'} />
        </div>
    )

}