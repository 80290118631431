import * as React from "react";
import { useState, useEffect } from "react";
import {
  Stack,
  Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import {
  DataGridPro, useGridApiRef
} from "@mui/x-data-grid-pro";
import { FSCCodes, miscReceivables, miscPayables } from '../CommonFn';
import { formatCurrency, currencySortComparator, formatPercentageNum, percentageSortComparator } from '../../../utils/global-functions';
import ExportSummaryByDay from './ExportSummaryByDay';
export default function SummaryByDay(props) {
  const { activeTab, rawData, locationNumber, isLoading, filterParams, customerType,renderExportButtons } = props;
  const [rows, setRows] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (locationNumber !== 0 && filterParams && filterParams?.dateRange && filterParams?.dateRange.length === 2) {
      setRows(processData(rawData || [], filterParams, customerType));
    }
  }, [activeTab, rawData]);

  const processData = (inputData, filters, customerType) => {
    const { startingPercentage, endingPercentage, revenuePercentage, excludeFSC, dateRange } = filters;
    const customerMap = new Map();

    customerMap.clear();

    const clonedData = JSON.parse(JSON.stringify(inputData));

    clonedData.forEach(item => {
      const location = item.location;
      const key = customerType === "billTo" ? item.billTo : item.shipperAgentCode;
      const customerName = customerType === "billTo" ? `${item.billTo} ${item.billToName}` : `${item.shipperAgentCode} ${item.customerName}`;

      if (!customerMap.has(key)) {
        customerMap.set(key, {
          id: key,
          location: location,
          customer: customerName,
          totalLoads: 0,
          revenue: 0,
          miscRevenue: 0,
          totalRevenue: 0,
          expense: 0,
          miscExpense: 0,
          totalExpense: 0,
          totalNet: 0,
          ptNetExclMisc: 0,
          totalPercent: 0,
          ptPercent: 0
        });
      }

      const existingData = customerMap.get(key);

      if (excludeFSC) {
        item.rates = item.rates.filter(rate => !FSCCodes.includes(rate.glCode));
      }

      let rates = item.rates;

      // let revenue = rates
      //   .filter(rate => rate.payableReceivable === "R" && !miscReceivables.includes(rate.glCode))
      //   .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

      let revenue = rates
        .filter(rate => rate.payableReceivable === "R" && !miscReceivables.includes(rate.glCode))
        .reduce((sum, rate) => sum + (parseFloat(rate.amount) || 0), 0);

      let expense = rates
        .filter(rate => rate.payableReceivable === "P" && !miscPayables.includes(rate.glCode))
        .reduce((sum, rate) => sum + (parseFloat(rate.amount) || 0), 0);

      let miscRevenue = rates
        .filter(rate => rate.payableReceivable === "R" && miscReceivables.includes(rate.glCode))
        .reduce((sum, rate) => sum + (parseFloat(rate.amount) || 0), 0);

      let miscExpense = rates
        .filter(rate => rate.payableReceivable === "P" && miscPayables.includes(rate.glCode))
        .reduce((sum, rate) => sum + (parseFloat(rate.amount) || 0), 0);

      revenue += existingData.revenue;
      expense += existingData.expense;
      miscRevenue += existingData.miscRevenue;
      miscExpense += existingData.miscExpense;

      const totalRevenue = revenue + miscRevenue;
      const totalExpense = expense + miscExpense;
      const totalNet = totalRevenue - totalExpense;
      const totalPercent = totalRevenue ? (totalExpense / totalRevenue) * 100 : 0;
      const ptPercent = revenue ? (expense / revenue) * 100 : 0;
      const ptNetExclMisc = revenue - expense;
      const totalLoads = Number(existingData.totalLoads) + 1;

      customerMap.set(key, {
        id: key,
        location:location,
        customer: customerName,
        totalLoads: totalLoads,
        revenue: Number(revenue),
        miscRevenue: Number(miscRevenue),
        totalRevenue: Number(totalRevenue),
        expense: Number(expense),
        miscExpense: Number(miscExpense),
        totalExpense: Number(totalExpense),
        totalNet: Number(totalNet),
        ptNetExclMisc: Number(ptNetExclMisc),
        totalPercent: Number(totalPercent),
        ptPercent: Number(ptPercent)
      });
    });

    let filteredData = Array.from(customerMap.values());

    if (revenuePercentage && startingPercentage && endingPercentage) {
      filteredData = filteredData.filter(item => item[revenuePercentage] >= startingPercentage && item[revenuePercentage] <= endingPercentage);
    }

    filteredData = filteredData.map((item, index) => {
      return {
        id: index,
        location: item.location,
        customer: item.customer,
        totalStops: Number(item.totalLoads),
        revenue: item.revenue.toFixed(2),
        miscRevenue: item.miscRevenue.toFixed(2),
        totalRevenue: item.totalRevenue.toFixed(2),
        expense: item.expense.toFixed(2),
        miscExpense: item.miscExpense.toFixed(2),
        totalExpense: item.totalExpense.toFixed(2),
        totalNet: item.totalNet.toFixed(2),
        ptNetExclMisc: item.ptNetExclMisc.toFixed(2),
        totalPercent: item.totalPercent.toFixed(2),
        ptPercent: item.ptPercent.toFixed(2)
      };
    });

    return filteredData;
  };

  const calculateTotals = (rows) => {
    let revenue = 0;
    let miscRevenue = 0;
    let totalRevenue = 0;
    let expense = 0;
    let miscExpense = 0;
    let totalExpense = 0;
    let totalNet = 0;
    let ptNetExclMisc = 0;
    let totalStops = 0;

    rows.forEach((row) => {
      revenue += parseFloat(row.revenue) || 0;
      miscRevenue += parseFloat(row.miscRevenue) || 0;
      totalRevenue += parseFloat(row.totalRevenue) || 0;
      expense += parseFloat(row.expense) || 0;
      miscExpense += parseFloat(row.miscExpense);
      totalExpense += parseFloat(row.totalExpense) || 0;
      totalNet += parseFloat(row.totalNet) || 0;
      ptNetExclMisc += parseFloat(row.ptNetExclMisc) || 0;
      totalStops += Number(row.totalStops);
    });

    return {
      revenue,
      miscRevenue,
      totalRevenue,
      expense,
      miscExpense,
      totalExpense,
      totalNet,
      ptNetExclMisc,
      totalPercent: ((totalExpense / totalRevenue) * 100).toFixed(2),
      ptPercent: ((expense / revenue) * 100).toFixed(2),
      totalStops
    };
  };

  const totals = calculateTotals(rows);

  const pinnedRows = {
    bottom: [
      {
        id: 'total-rows',
        customer: 'Totals',
        totalStops: Number(totals.totalStops),
        revenue: totals.revenue.toFixed(2),
        miscRevenue: totals.miscRevenue.toFixed(2),
        totalRevenue: totals.totalRevenue.toFixed(2),
        expense: totals.expense.toFixed(2),
        miscExpense: totals.miscExpense.toFixed(2),
        totalExpense: totals.totalExpense.toFixed(2),
        totalNet: totals.totalNet.toFixed(2),
        ptNetExclMisc: totals.ptNetExclMisc.toFixed(2),
        totalPercent: parseFloat(totals.totalPercent) || 0,
        ptPercent: parseFloat(totals.ptPercent) || 0
      }
    ],
  };
  const tableColumns = [
    {
      field: "location",
      headerName: "Location",
      minWidth: 90,
      flex: 1,
      sortable: true
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 90,
      flex: 1,
      sortable: true
    },
    {
      field: "totalStops",
      headerName: "Total Loads",
      minWidth: 90,
      flex: 1,
      sortable: true
    },
    {
      field: "revenue",
      headerName: "Revenue",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator,
    },
    { field: "miscRevenue", headerName: "Misc Revenue", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value),sortComparator: currencySortComparator, },
    { field: "totalRevenue", headerName: "Total Revenue", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value),sortComparator: currencySortComparator, },
    {
      field: "expense",
      headerName: "Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator,
    },
    {
      field: "miscExpense",
      headerName: "Misc Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator
    },
    {
      field: "totalExpense",
      headerName: "Total Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator
    },
    { field: "totalNet", headerName: "Total Net", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value),sortComparator: currencySortComparator },
    { field: "ptNetExclMisc", headerName: "PT Net (Excl Misc)", minWidth: 90, flex: 1 , renderCell: (params) => formatCurrency(params.value),sortComparator: currencySortComparator },
    // { field: "totalPercent", headerName: "Total %", minWidth: 90, flex: 1, renderCell: (params) => `${params.value}%` },
    { field: "totalPercent", headerName: "Total %", minWidth: 90, flex: 1, renderCell: (params) => formatPercentageNum(params.value),sortComparator: percentageSortComparator },
    { field: "ptPercent", headerName: "PT %", minWidth: 90, flex: 1,  renderCell: (params) => formatPercentageNum(params.value),sortComparator: percentageSortComparator },
  ];
  useEffect(() => {
    renderExportButtons(
        <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
                zIndex: 9999,
                // marginTop: "-2%"
            }}
        >
            <Button
                variant="text"
                size="small"
                onClick={() => apiRef.current.exportDataAsCsv()}
                startIcon={<TextSnippetIcon  sx={{ color: (!rows || rows.length === 0) ? 'gray' : 'primary', opacity: (!rows || rows.length === 0) ? 0.5 : 1 }} />}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                disabled={!rows || rows.length === 0}
            >
                CSV
            </Button>
            <Button
                variant="text"
                size="small"
                onClick={() => {
                    console.log("Rows:", rows);
                    console.log("Table Columns:", tableColumns);
                    // ExportSummaryByControl(rows, tableColumns)(); 
                    ExportSummaryByDay(rows, tableColumns,"customer")()// Call the function
                }}
                startIcon={<PictureAsPdfIcon  sx={{ color: (!rows || rows.length === 0) ? 'gray' : 'primary', opacity: (!rows || rows.length === 0) ? 0.5 : 1 }} />}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                disabled={!rows || rows.length === 0}
            >
                PDF
            </Button>
        </Stack>
    );
}, [rows]);
  return (
    <ComponentWrapper>
      {/* <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'absolute',
          //bottom:0,
          right: "1%",
          zIndex: 9999,
          marginTop: "-2%"
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => apiRef.current.exportDataAsCsv()}
          startIcon={<TextSnippetIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          CSV
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={ExportSummaryByDay(rows, tableColumns,"customer")}
          startIcon={<PictureAsPdfIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          PDF
        </Button>
      </Stack> */}
      <Stack
        //marginTop={2}
        sx={{
          height: window.innerHeight - 270,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(11, 67, 135, 1)",
            color: "white",
          },
          "& .isSubtotal": {
            fontWeight: "bold",
            // color: "blue",
            backgroundColor: "#E8E8E8 !important",
            // backgroundColor: "#F0F0F0 !important",
          },

          "& .pinned-row": {
            backgroundColor: "#f0f0f0",  /* Light gray background */
            fontWeight: "bold",          /* Bold text */
            color: "#000000"            /* Black text */
          },
          "& .pinned-row .MuiDataGrid-cell": {
            borderTop: "2px solid #000000"
          }

        }}
      >
        <DataGridPro
            apiRef={apiRef}
            hideFooter={true}
            rows={rows}
            columns={tableColumns}
            loading={isLoading}
            pinnedRows={pinnedRows}
            getRowClassName={(params) => params.id === 'total-rows' ? 'pinned-row' : ''}
            disableRowSelectionOnClick
        />
      </Stack>
    </ComponentWrapper>
  );
}
