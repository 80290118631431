import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { Paper } from "@mui/material";
import './DispatchStyle.css'
import TabsComponent from "./components/TabsComponent";
import TableHeadingStrip from './components/TableHeadingStrip';
import CommonTable from './components/CommonTable';

import { spottedTableColumns } from './commonFn';
import { partialBulkEditReq } from "../../store/actions/orderActions";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
    convertMDYToYMD,
    validateTimeField
} from '../../utils/global-functions';
import { Prompt } from "react-router-dom";
import { useDispatchContext } from "./dispatchContext";

const tabname = [
    { id: 'dropavailable', label: 'Drop Available' },
    { id: 'dropunavailable', label: 'Drop Unavailable' },
]

const tableColumns = spottedTableColumns;

export default function SpottedTable({ stopsDataParent, isLoading }) {
    //const [isLoading, setIsLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [groupRows, setGroupRows] = useState({});
    const [tabNames, setTabNames] = useState(tabname);
    const [selectedTab, setSelectedTab] = useState(0);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const { dispatchUnsavedChangesRef, hasUnsavedRows, setHasUnsavedRows } = useDispatchContext();

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const unsavedRows = Object.values(dispatchUnsavedChangesRef.current.unsavedRows);
    
            if (unsavedRows.length === 0) {
                console.warn("No changes to save.");
                return;
            }
    
            let postData = unsavedRows.map((el) => {
                const [datePart, timePart] = el.availDateTime ? el.availDateTime.split(" ") : [null, null];
                 return {
                    updateRequestFrom: "dispatch",
                    controlNumber: el.control,
                    pickup: {
                        pickDate: convertMDYToYMD(el.lastFreeDay),
                    },
                    stops: [
                        {
                            lineNumber: 1,
                            availDate: datePart ? convertMDYToYMD(datePart) : 0,
                            availTime: timePart ? validateTimeField(timePart) : 0,
                        },
                    ],
                };
            });
    
            // Persist updates in the database
            let apiRes = await partialBulkEditReq(postData, ordersLocation.locationNumber);
    
            // Show success or error message
            setSnackOpen(true);
            setSnackContent({
                severity: apiRes ? "success" : "error",
                message: apiRes ? "Records updated successfully!" : "Failed to save records, please try again later!",
            });
    
            if (apiRes) {
                // Reset unsaved changes after successful save
                dispatchUnsavedChangesRef.current = { unsavedRows: {}, rowsBeforeChange: {} };
            }
        } catch (error) {
            console.error("Error saving changes:", error);
        } finally {
            setIsSaving(false);
            setHasUnsavedRows(false);
        }
    };
    
    const discardChanges = () => {
        setHasUnsavedRows(false);
        let unsavedRow = dispatchUnsavedChangesRef.current.unsavedRows;
        setRows(rows.map(row => {
            if (unsavedRow?.id === row.id) {
                return unsavedRow;
            }
            return row;
        }));
        dispatchUnsavedChangesRef.current = { unsavedRows: {}, rowsBeforeChange: {} };
    };

    useEffect(async () => {
        await groupStopsData(stopsDataParent);
    }, [stopsDataParent])

    const groupStopsData = useCallback(async (stops) => {
        try {
            let finalData = {};
            let tabNameWithLoadCount = [];
            tabname.map((tabData) => {
                let filterData = stops.filter((val) => {
                    if (tabData['id'] === 'dropunavailable') {
                        if (
                            val.arriveDriver !== 0 &&
                            val.arriveTruck !== 0 &&
                            val.spotDate !== 0 &&
                            val.availDate === 0 &&
                            val.departTruck === 0 &&
                            val.departDriver === 0
                        ) {
                            return val
                        }
                    } else if (tabData['id'] === 'dropavailable') {
                        if (
                            val.arriveDriver !== 0 &&
                            val.arriveTruck !== 0 &&
                            val.spotDate !== 0 &&
                            val.availDate !== 0 &&
                            val.departTruck === 0 &&
                            val.departDriver === 0
                        ) {
                            return val
                        }
                    }
                })
                // Sort data by apptDate
                let sortedData;
                if (tabData['id'] === 'dropunavailable') {
                    sortedData = filterData.sort((a, b) => {
                        let dateB = new Date(b.outageDateTime);
                        let dateA = new Date(a.outageDateTime);
                        return dateA - dateB;
                    });
                } else {
                    sortedData = filterData.sort((a, b) => {
                        let dateB = new Date(b.availDate);
                        let dateA = new Date(a.availDate);
                        return dateA - dateB;
                    });
                }

                Object.assign(finalData, { [tabData['id']]: sortedData });
                tabNameWithLoadCount.push({ ...tabData, loadCount: filterData?.length || 0 })
            })

            setTabNames(tabNameWithLoadCount);
            if (typeof finalData !== 'undefined' && Object.keys(finalData || {}).length > 0) {
                setGroupRows(finalData);
                setRows(finalData[tabname[selectedTab]['id']])
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }, [stopsDataParent])
    
    const handleTabChange = (event, newValue) => {
        // Reset unsaved changes reference (if proceeding)
        dispatchUnsavedChangesRef.current = { unsavedRows: {}, rowsBeforeChange: {} };
        setHasUnsavedRows(false);
        setSelectedTab(newValue);
        let tabData = tabname[newValue];
        let data = groupRows[tabData['id']];
        if (typeof data !== 'undefined' && data.length > 0) {
            return setRows(data);
        }
        return setRows([]);
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedRows) {
                event.preventDefault();
                event.returnValue = "Changes you made may not be saved.";
            }
        };    
        window.addEventListener("beforeunload", handleBeforeUnload);    
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [hasUnsavedRows]);

    return (
        <div style={{ height: 'auto', width: '100%', flexDirection: 'column', display: 'flex', padding: '10px' }}>
            <Prompt
                when={Object.keys(dispatchUnsavedChangesRef.current.unsavedRows).length > 0}
                message="You have unsaved data. Are you sure you want to leave?"
            />
            <TableHeadingStrip
                title={'Spotted'}
                hasUnsavedRows={hasUnsavedRows}                
                handleSave={handleSave}
                isSaving={isSaving}
                discardChanges={discardChanges}
            />
            <TabsComponent
                tabname={tabNames}
                tabletype={'Spotted'}
                rowData={rows}
                handleTabChange={(event, newValue) => handleTabChange(event, newValue)}
                tableColumns={tableColumns}
                isLoading={isLoading}
            />
            <CommonTable rowData={rows} 
                tableColumns={tableColumns} 
                isLoading={isLoading}
                tabletype={'spotted'}
                setHasUnsavedRows={setHasUnsavedRows}
            />
            < Snackbar open={snackOpen} style={{ marginTop: "3%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackOpen(false)} >
                <Alert onClose={() => setSnackOpen(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </div>
    );
}