import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

export default function DriversList(props) {
    const { valueChange, valueChangeObj, existingValue, isDisabled, applyFilter=true } = props;
    const [value, setValue] = useState({ id: '', name: "" });
    const options = useSelector(({ loadsReducer }) => loadsReducer.driverList);

    useEffect(() => {
    if (options.length > 0) {
        let selectedValue = (existingValue) ? options.filter(e => e.truck === existingValue)[0] : { id: '', truck: '', name: '' }
        setValue({
            id: selectedValue?.id,
            name: selectedValue?.name,
            truck: selectedValue?.truck
        });
    }
    }, [options])

    return (
        <Autocomplete
            disabled={isDisabled?true:false}
            disableClearable
            freeSolo
            size={`small`}
            options={applyFilter?options.filter(e => e.isAvailable === 'Y'):options} // && e.isTruckUsable === 'Y'
            getOptionLabel={(option) => `${(option.id) ? option.name : ''}`}
            value={value}
            onChange={(event, value, reason) => {
                if (typeof (value) === 'string') {
                    setValue({ id: value, name: "" });
                } else {
                    setValue(value);
                }

                if (typeof (value) === 'object') {
                    console.log(value)
                    if (typeof valueChange === "function") 
                        valueChange(value.id);

                    if (typeof valueChangeObj === "function") 
                        valueChangeObj(value)
                }
            }}
           // style={{ width: '50%' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="driver"
                    variant="standard"
                    sx={{
                        input: {
                            textTransform: 'uppercase'
                        }
                    }}
                />
            )}
            componentsProps={{ popper: { style: { width: 300 } } }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']}>
                        {(typeof option?.truck !== 'undefined' && option.truck !== '') ? `${option.truck} - ${option.name} [${option.id}]` : ''}
                    </li>
                )
            }}
        />
    )
}