import { useState, useEffect } from 'react';

import {
    Stack,
    TextField,
    IconButton,
    TableCell,
    TableRow
} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import CallSplit from '@mui/icons-material/CallSplit';
import CallMerge from '@mui/icons-material/CallMerge';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

// import custom date picker
import CustomDatePicker from '../../../../components/CustomDatePicker';
import {
    getPayableGlCode,
    getParentPayableRate,
    findMaxLineNumber,
    calAmount,
    calAmountByPer,
    getSubitemReceivableGL,
    getSubitemPayableGL,
    getSubItemUnit,
    sanitizeAmountField,
    getParentPayableUnit,
    sanitizePercentageField,
    getRecSubItemUnit,
    convertStrToUpperCase,
    convertDateToMDY
} from '../CommonFn';

// import components
import RatesAutoComplete from './RatesAutoComplete';
import GLCodesAutoComplete from './GLCodesAutoComplete';
import TrucksAutoComplete from './TrucksAutoComplete';
import '../RatesAndChargesStyles.css'

import { useDispatch } from 'react-redux';

import {
    postRatesCharges
} from "../../../../store/actions/orderActions";
import { useParams } from "react-router-dom";

export default function LinehaulTableRow(props) {
    const {
        rowIndex,
        row,
        glCodesData,
        linehaulEditRef,
        accessorialEditRef,
        orderType,
        FSCPercentage,
        tableType,
        handleLineItemDelete,
        handleExistingRow,
        snackBarHandler,
        unPayableGLCodes,
        edi210,
        isTemplateScreen,
        controlNumber,
        ordersLocation
    } = props;

    let params = useParams();
    params = params?.locationNumber ? params : { locationNumber: ordersLocation, controlNumber: controlNumber }

    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [payableRow, setPayableRow] = useState({ ...row.payable, processedDate: row.payable?.processedDate || '' } || '');
    const [receivablesRow, setReceivablesRow] = useState(row.receivable);

    // disable payable fields if split records count > 0
    const [disablePayableRowOnSplit, setDisablePayableRowOnSplit] = useState((row.payable?.splitItems?.length > 0) ? true : false)
    // disable payable actions -> parent row
    const [disablePayableRow, setDisablePayableRow] = useState(
        (
            !row.payable.hasOwnProperty("lineNumber") ||
            (typeof row.payable?.isFreeLockPayable !== 'undefined' && row.payable?.isFreeLockPayable === true) ||
            (typeof row.payable?.isLockPayable !== 'undefined' && row.payable?.isLockPayable === true) ||
            (row.payable?.splitItems?.length > 0 && row.payable?.splitItems.some(item => item.isLockPayable === true))
        ) ? true : false
    );
    // disable receivable actions -> parent row
    const [disableReceivableRow, setDisableReceivableRow] = useState(
        (
            !row.receivable.hasOwnProperty("lineNumber") ||
            (typeof row.receivable?.isFreeLockReceivable !== 'undefined' && row.receivable?.isFreeLockReceivable === true) ||
            (typeof row.receivable?.isLockReceivable !== 'undefined' && row.receivable?.isLockReceivable === true)
        ) ? true : false
    );

    const [isGasPumpClicked, setIsGasPumpClicked] = useState(false);
    const maxLineNumber = findMaxLineNumber([...linehaulEditRef.current, ...accessorialEditRef.current]) + 1;
    const FSC_PER_R = getRecSubItemUnit(FSCPercentage.fsc_per_r);

    // send records to parent component
    const handleSave = () => {
        setIsEditModeOn(false);
        handleExistingRow({
            receivable: receivablesRow,
            payable: payableRow
        }, rowIndex)
    }

    /**
     * Handle Sub Items of Linehaul
     */
    const handleSubItem = () => {

        let recSubItems = {
            ...receivablesRow,
            subItems: !disableReceivableRow ? [
                {
                    ...receivablesRow,
                    isServerRecord: false,
                    lineNumber: maxLineNumber,
                    parentLineNumber: receivablesRow?.lineNumber,
                    payRec: '',
                    notes: '',
                    description: 'FUEL SURCHARGE',
                    glCode: getSubitemReceivableGL(receivablesRow?.glCode),
                    units: FSC_PER_R,
                    amount: calAmountByPer(receivablesRow?.unitRate, FSC_PER_R)
                }
            ] : []
        }

        let paySubItems = {
            ...payableRow,
            subItems: !disablePayableRow ? [
                {
                    ...payableRow,
                    isServerRecord: false,
                    lineNumber: maxLineNumber + Number(1),
                    parentLineNumber: payableRow?.lineNumber,
                    payRec: '',
                    description: 'FUEL SURCHARGE',
                    payableReceivable: 'P',
                    glCode: getSubitemPayableGL(receivablesRow?.glCode || ''),
                    unitRate: calAmountByPer(receivablesRow?.unitRate || 0, FSC_PER_R),
                    units: getSubItemUnit(receivablesRow?.glCode, FSCPercentage),
                    amount: calAmountByPer(calAmountByPer(receivablesRow?.unitRate || 0, FSC_PER_R), getSubItemUnit(receivablesRow?.glCode || '', FSCPercentage)),
                    splitItems: payableRow?.splitItems?.length > 0 ? [
                        {
                            ...payableRow,
                            isServerRecord: false,
                            lineNumber: maxLineNumber + Number(2),
                            parentLineNumber: '',
                            splitLineNumber: maxLineNumber + Number(1),
                            recAgainstSplitLine: maxLineNumber,
                            payRec: '',
                            description: 'FUEL SURCHARGE',
                            payableReceivable: 'P',
                            glCode: getSubitemPayableGL(receivablesRow?.glCode),
                            unitRate: Number(calAmountByPer(receivablesRow?.unitRate, FSC_PER_R)) / 2,
                            units: Number(getSubItemUnit(receivablesRow?.glCode, FSCPercentage)),
                            amount: Number(calAmountByPer(calAmountByPer(receivablesRow?.unitRate, FSC_PER_R), getSubItemUnit(receivablesRow?.glCode, FSCPercentage))) / 2
                        },
                        {
                            ...payableRow,
                            isServerRecord: false,
                            lineNumber: maxLineNumber + Number(3),
                            parentLineNumber: '',
                            splitLineNumber: maxLineNumber + Number(1),
                            recAgainstSplitLine: maxLineNumber,
                            payRec: '',
                            description: 'FUEL SURCHARGE',
                            glCode: getSubitemPayableGL(receivablesRow?.glCode),
                            unitRate: Number(calAmountByPer(receivablesRow?.unitRate, FSC_PER_R)) / 2,
                            units: Number(getSubItemUnit(receivablesRow?.glCode, FSCPercentage)),
                            amount: Number(calAmountByPer(calAmountByPer(receivablesRow?.unitRate, FSC_PER_R), getSubItemUnit(receivablesRow?.glCode, FSCPercentage))) / 2,
                            truckNumber: '',
                            processedDate: ''
                        }
                    ] : []
                }
            ] : []
        }

        setReceivablesRow(recSubItems)
        if (paySubItems?.subItems?.[0]?.splitItems?.length > 0) {
            paySubItems = { ...payableRow, subItems: [{ splitItems: paySubItems?.subItems?.[0]?.splitItems || [] }] };
        }

        setPayableRow(paySubItems);
        handleExistingRow({ receivable: recSubItems, payable: paySubItems }, rowIndex);
    }

    /**
     * handle split items of linehaul for parent
     */
    const handleSplitItem = async () => {

        let paySplitItems;
        paySplitItems = {
            //...payableRow,
            subItems: linehaulEditRef.current[rowIndex]?.payable?.subItems?.length > 0 ? [{
                splitItems: [
                    {
                        ...payableRow?.subItems?.[0],
                        lineNumber: maxLineNumber + Number(2),
                        parentLineNumber: '',
                        splitLineNumber: linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.lineNumber,
                        recAgainstSplitLine: receivablesRow?.subItems?.[0]?.lineNumber,
                        isServerRecord: linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.isServerRecord ? true : false,
                        payRec: '',
                        description: convertStrToUpperCase(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.description),
                        glCode: linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.glCode,
                        unitRate: (Number(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.unitRate) / Number(2)).toFixed(2),
                        units: (Number(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.units)).toFixed(2),
                        amount: (Number(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.amount) / Number(2)).toFixed(2)
                    },
                    {
                        ...payableRow?.subItems?.[0],
                        lineNumber: maxLineNumber + Number(3),
                        parentLineNumber: '',
                        splitLineNumber: linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.lineNumber,
                        recAgainstSplitLine: receivablesRow?.subItems?.[0]?.lineNumber,
                        isServerRecord: linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.isServerRecord ? true : false,
                        payRec: '',
                        description: convertStrToUpperCase(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.description),
                        glCode: linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.glCode,
                        unitRate: Number(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.unitRate) / Number(2),
                        units: (Number(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.units)).toFixed(2),
                        amount: (Number(linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.amount) / Number(2)).toFixed(2),
                        truckNumber: '',
                        processedDate: '',
                    }
                ]
            }] : [],
            splitItems: [
                {
                    ...payableRow,
                    lineNumber: maxLineNumber,
                    parentLineNumber: '',
                    splitLineNumber: payableRow?.lineNumber,
                    recAgainstSplitLine: receivablesRow?.lineNumber,
                    isServerRecord: linehaulEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                    payRec: '',
                    description: convertStrToUpperCase(payableRow?.description),
                    glCode: payableRow?.glCode,
                    unitRate: Number(payableRow?.unitRate) / Number(2), // modified on 11-10-2023
                    units: (Number(payableRow?.units)).toFixed(2), // modified on 11-10-2023
                    amount: (Number(payableRow?.amount) / Number(2)).toFixed(2),
                },
                {
                    ...payableRow,
                    lineNumber: maxLineNumber + Number(1),
                    parentLineNumber: '',
                    splitLineNumber: payableRow?.lineNumber,
                    recAgainstSplitLine: receivablesRow?.lineNumber,
                    isServerRecord: linehaulEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                    payRec: '',
                    description: convertStrToUpperCase(payableRow?.description),
                    glCode: payableRow?.glCode,
                    unitRate: Number(payableRow?.unitRate) / Number(2), // modified on 11-10-2023
                    units: (Number(payableRow?.units)).toFixed(2), // modified on 11-10-2023
                    amount: (Number(payableRow?.amount) / Number(2)).toFixed(2),
                    truckNumber: '',
                    processedDate: '',
                }
            ]
        }
        /**
         * delete parent of split item from server
         */
        if (linehaulEditRef.current[rowIndex]?.payable?.isServerRecord !== 'undefined' && linehaulEditRef.current[rowIndex]?.payable?.isServerRecord === true) {
            let parentPayable = linehaulEditRef.current[rowIndex]?.payable || [];
            let subItem = linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0] || [];
            let splitItemParentArr = [parentPayable, subItem];
            let delRes = await Promise.all(splitItemParentArr.map(async (item) => {
                if (typeof item?.isServerRecord !== 'undefined' && item?.isServerRecord === true) {
                    return await handleLineItemDelete(item?.lineNumber);
                }
                return false;
            }));
            if (delRes.includes(true)) {
                snackBarHandler({
                    status: true,
                    type: 'info',
                    message: 'Splitting Payables....'
                });
                let res = await saveRatesCharges(paySplitItems);
            }
            console.log('delete parent of split item result', delRes);
        }

        setDisablePayableRow(true);
        setPayableRow(paySplitItems);
        handleExistingRow({ ...linehaulEditRef.current[rowIndex], payable: paySplitItems }, rowIndex);
    }
    /**
     * Delete split items
     */
    const deleteSplitItems = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {

            // handle local state
            let paySplitItems;
            paySplitItems = {
                ...payableRow?.splitItems[0],
                lineNumber: maxLineNumber,
                parentLineNumber: '',
                splitLineNumber: '',
                recAgainstSplitLine: '',
                isServerRecord: linehaulEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                payRec: receivablesRow.lineNumber,
                unitRate: Number(payableRow?.splitItems[0]?.unitRate || 0) * Number(2),
                amount: Number(payableRow?.splitItems[0]?.amount || 0) * Number(2),
                truckNumber: payableRow?.splitItems[0]?.truckNumber,
                processedDate: payableRow?.splitItems[0]?.processedDate,

                subItems: payableRow?.subItems?.length > 0 ? [{
                    ...payableRow?.subItems?.[0]?.splitItems[0],
                    lineNumber: maxLineNumber + Number(1),
                    parentLineNumber: maxLineNumber,
                    splitLineNumber: '',
                    recAgainstSplitLine: '',
                    isServerRecord: linehaulEditRef.current[rowIndex]?.payable?.isServerRecord ? true : false,
                    payRec: '',
                    unitRate: Number(payableRow?.subItems?.[0]?.splitItems[0]?.unitRate || 0) * Number(2),
                    amount: Number(payableRow?.subItems?.[0]?.splitItems[0]?.amount || 0) * Number(2),
                    truckNumber: payableRow?.subItems?.[0]?.splitItems[0]?.truckNumber,
                    processedDate: payableRow?.subItems?.[0]?.splitItems[0]?.processedDate,
                    splitItems: []
                }] : [],
                splitItems: []
            }
            // handle server data
            const delRes = await deleteSplitItemsFromServer()
            console.log('delete result', delRes);
            if (delRes.includes(true)) {
                snackBarHandler({
                    status: true,
                    type: 'info',
                    message: 'Merging Payables...'
                });
                let res = await saveRatesCharges(paySplitItems);
            }
            setDisablePayableRow(false);
            setPayableRow(paySplitItems);
            handleExistingRow({ ...linehaulEditRef.current[rowIndex], payable: paySplitItems }, rowIndex);
        }
    }

    const handlePayableDelete = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {
            if ((typeof payableRow?.isServerRecord !== 'undefined' && payableRow?.isServerRecord === true) || payableRow?.splitItems?.length > 0) {
                const delResponse = await deleteSplitItemsFromServer();
                console.log('rates charges payable delete response', delResponse);
                if (delResponse) {
                    handleExistingRow({
                        ...row,
                        payable: {}
                    }, rowIndex);
                    setPayableRow({})
                    setDisablePayableRow(true)
                }
            } else {
                handleExistingRow({
                    ...row,
                    payable: {}
                }, rowIndex);
                setPayableRow({})
                setDisablePayableRow(true)
            }
        }
    }
    /**
     * Delete split items from server
     */
    const deleteSplitItemsFromServer = async () => {
        let parentItem = linehaulEditRef.current[rowIndex]?.payable || [];
        let parentSplitItem = linehaulEditRef.current[rowIndex]?.payable?.splitItems || [];
        let subSplitItem = linehaulEditRef.current[rowIndex]?.payable?.subItems?.[0]?.splitItems || [];
        let splitItemArr = [parentItem, ...parentSplitItem, ...subSplitItem];
        if (splitItemArr.length > 0) {
            let res = await Promise.all(splitItemArr.map(async (item) => {
                if (typeof item?.isServerRecord !== 'undefined' && item?.isServerRecord === true) {
                    return await handleLineItemDelete(item?.lineNumber);
                }
                return false;
            }));
            return res;
        }
        return true;
    }

    /**
     * Monitor row changes in parent element 
     */
    useEffect(() => {
        // console.log('parent-item',row)
        // Keep GasPump icon enable if payable or receivable subItem exist
        if (row.payable?.subItems?.length > 0 || row.receivable?.subItems?.length > 0) {
            if(row.payable?.subItems?.length > 0){
                setPayableRow({...payableRow, subItems:row.payable.subItems});
            }

            if(row.receivable?.subItems?.length > 0){
                setReceivablesRow({...receivablesRow, subItems:row.receivable.subItems});
            }
            setIsGasPumpClicked(true)
        }
        // Disable GasPump icon if payable & receivable subItem removed
        if (row.payable?.subItems?.length === 0 && row.receivable?.subItems?.length === 0) {
            setIsGasPumpClicked(false)
        }
        // Refresh payableRow state on subItem changes
        if (row.payable?.subItems?.length === 0) {
            setPayableRow({ ...row.payable, subItems: [] })
        }
        // Refresh receivableRow state on subItem changes
        if (row.receivable?.subItems?.length === 0) {
            setReceivablesRow({ ...row.receivable, subItems: [] })
        }
        // Refresh disablePayableRow state on splitItem changes
        if (row.payable?.splitItems?.length > 0) {
            setDisablePayableRowOnSplit(true)
        } else {
            setDisablePayableRowOnSplit(false)
        }
        // Refresh disableReceivableRow state on isFreeLock variable changes
        if (row.receivable?.isFreeLockReceivable === false && row.receivable.isFreeLockReceivable === true) {
            setDisableReceivableRow(false)
        }

    }, [row])

    // handle delete of sub items based on lineNumber
    const handleReceivableDelete = async () => {
        let confirmAlert = window.confirm('Are you sure?');
        if (confirmAlert) {
            if (typeof receivablesRow?.isServerRecord !== 'undefined' && receivablesRow?.isServerRecord === true) {
                const delResponse = await handleLineItemDelete(receivablesRow?.lineNumber);
                console.log('rates charges receivable delete response', delResponse);
                if (delResponse) {
                    handleExistingRow({
                        ...row,
                        receivable: { subItems: [] },
                        payable: { ...payableRow, payRec: '' }
                    }, rowIndex);
                    setReceivablesRow({})
                    setPayableRow({ ...payableRow, payRec: '' })
                    setDisableReceivableRow(true);
                }
            } else {
                if(isTemplateScreen){
                    const payabledata = Object.keys(payableRow).length === 0
                    ? {}
                    : { ...payableRow, payRec: '' };
                    handleExistingRow({
                        ...row,
                        receivable: { subItems: [] },
                        payable:payabledata
                    }, rowIndex);
                    setPayableRow(payabledata)
                }else{
                handleExistingRow({
                    ...row,
                    receivable: { subItems: [] },
                    payable: { ...payableRow, payRec: '' }
                }, rowIndex);
                setPayableRow({ ...payableRow, payRec: '' })
            }
                setReceivablesRow({ subItems: [] })
                setDisableReceivableRow(true);
            }
        }
    }

    // Add New rates & charges
    const saveRatesCharges = async (paySplitItems) => {
        if (typeof params.locationNumber !== 'undefined' && typeof params.controlNumber !== 'undefined') {
            let res = await postRatesCharges(params.locationNumber, params.controlNumber, paySplitItems);
            if (res?.status === 'success') {
                snackBarHandler({
                    status: true,
                    type: 'success',
                    message: 'Payables updated successfully!'
                });
            }
        }
    }
    return (
        <>
            {isEditModeOn ? (
                <TableRow className={`${receivablesRow?.transmitted === 'Y' ? 'tx_r_y' : ''} ${payableRow?.transmitted === 'Y' ? 'tx_p_y' : ''}`}>
                    <TableCell>
                        <Stack direction="row">
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => handleSave()}>
                                <DoneIcon titleAccess='Save Changes' fontSize="small" color="success" />
                            </IconButton>
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(false)}>
                                <CloseIcon titleAccess='Cancel Changes' fontSize="small" sx={{ color: 'red' }} />
                            </IconButton>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <RatesAutoComplete
                            valueChange={(val) => {
                                //console.log(val)
                                if (val !== null) {
                                    setReceivablesRow({
                                        ...receivablesRow,
                                        description: val.invoiceDesc,
                                        destinationCity: val.destinationCity,
                                        destinationStatePrefix: val.destinationState,
                                        glCode: val.GLCode,
                                        glDescription: val.GLDesc,
                                        notes: val.notes,
                                        originCity: val.originCity,
                                        originName: val.originCity,
                                        originPrefix: val.originState,
                                        unitRate: val.receivableRates,
                                        amount: val.receivableRates
                                    })
                                    if (unPayableGLCodes.includes(val.GLCode)) {
                                        setPayableRow({})
                                    } else {
                                        setPayableRow({
                                            ...payableRow,
                                            description: val.invoiceDesc,
                                            destinationCity: val.destinationCity,
                                            destinationStatePrefix: val.destinationState,
                                            glCode: getPayableGlCode(orderType, val.GLCode),
                                            glDescription: val.GLDesc,
                                            notes: val.notes,
                                            originCity: val.originCity,
                                            originName: val.originCity,
                                            originPrefix: val.originState,
                                            unitRate: val.receivableRates,
                                            units: getParentPayableUnit(val.GLCode, val.payableRatioBroker, val.payableRatioContract),
                                            amount: getParentPayableRate(val.GLCode, val.payableRateBroker, val.payableRateContract)
                                        })
                                    }
                                }
                            }
                            }
                            existingValue={receivablesRow.notes}
                            lineAccount={tableType}
                            isDisabled={disableReceivableRow}
                            orderType={orderType}
                            edi210={edi210}
                        />
                    </TableCell>
                    <TableCell>
                        <GLCodesAutoComplete
                            valueChange={(val) => {
                                if (val !== '') {
                                    setReceivablesRow({
                                        ...receivablesRow,
                                        glCode: val
                                    });
                                    if (unPayableGLCodes.includes(val)) {
                                        setPayableRow({})
                                    } else {
                                        setPayableRow({
                                            ...payableRow,
                                            glCode: getPayableGlCode(orderType, val),
                                        })
                                    }
                                } else if (val === '') {
                                    setPayableRow({
                                        ...payableRow,
                                        glCode: '',
                                    })
                                }
                            }}
                            existingValue={receivablesRow.glCode}
                            glCodesData={glCodesData}
                            isDisabled={disableReceivableRow}
                            lineAccount={tableType}
                            orderType={orderType}
                            edi210={edi210}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={receivablesRow.description}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                description: e.target.value
                            })}
                            disabled={disableReceivableRow}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                unitRate: e.target.value,
                                amount: calAmount(e.target.value, receivablesRow.units)
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                units: e.target.value,
                                amount: calAmount(receivablesRow.unitRate, e.target.value)
                            })}
                            disabled={disableReceivableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={receivablesRow.amount}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setReceivablesRow({
                                ...receivablesRow,
                                amount: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow.description}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                description: e.target.value
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.glCode}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                glCode: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                unitRate: e.target.value,
                                amount: calAmount(e.target.value, payableRow.units)
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                units: e.target.value,
                                amount: calAmount(payableRow.unitRate, e.target.value)
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.amount}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                amount: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        {/* <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow.truckNumber}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                truckNumber: e.target.value
                            })}
                            disabled={disablePayableRow || disablePayableRowOnSplit}
                            inputProps={{ maxLength: 5 }}
                        /> */}
                        <TrucksAutoComplete
                            valueChange={(val) => {
                                if (val !== '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: val,
                                    })

                                } else if (val === '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: '',
                                    })
                                }
                            }
                            }
                            existingValue={payableRow.truckNumber}
                            isDisabled={disablePayableRow || disablePayableRowOnSplit}
                        />
                    </TableCell>

                    <TableCell>
                        <CustomDatePicker
                            variant="standard"
                            value={convertDateToMDY(payableRow.processedDate) || ''}
                            style={{ width: 'fit-content' }}
                            onValueChange={(val) => {
                                if (val === '') {
                                    setDisablePayableRow(false);
                                    setPayableRow({
                                        ...payableRow,
                                        processedDate: ''
                                    })
                                } else {
                                    setPayableRow({
                                        ...payableRow,
                                        processedDate: val
                                    })
                                }
                            }
                            }
                            disabled={(row.payable?.isLockReceivable === true || disablePayableRowOnSplit) ? true : false}
                            showOneMonthAlert={true}
                        />
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
            ) : (
                <>
                    {(!payableRow.hasOwnProperty("lineNumber") && !disablePayableRowOnSplit && !receivablesRow.hasOwnProperty("lineNumber")) ? null : (
                        <TableRow className={`${receivablesRow.transmitted === 'Y' ? 'tx_r_y' : 'tx_r_n'} ${payableRow.transmitted === 'Y' ? 'tx_p_y' : 'tx_p_n'}`}>
                            <TableCell>
                                <Stack direction="row">
                                    <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(true)}>
                                        <EditIcon titleAccess='Edit' color="black" />
                                    </IconButton>
                                    <IconButton disabled={(Object.keys(receivablesRow || {}).length === 0 || disableReceivableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handleReceivableDelete()}>
                                        <DeleteIcon titleAccess='Delete receivable' fontSize="small" color="black" />
                                    </IconButton>
                                    <IconButton disabled={isGasPumpClicked || disableReceivableRow} size={`small`} sx={{ width: '25px' }} onClick={() => handleSubItem()}>
                                        <LocalGasStation titleAccess='Add FSC' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                            <TableCell align="center">{convertStrToUpperCase(receivablesRow?.notes)}</TableCell>
                            <TableCell align="center">{receivablesRow?.glCode}</TableCell>
                            <TableCell align="center">{convertStrToUpperCase(receivablesRow?.description)}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.unitRate)}</TableCell>
                            <TableCell align="center">{receivablesRow?.units}</TableCell>
                            <TableCell align="center">{sanitizeAmountField(receivablesRow?.amount)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{convertStrToUpperCase(payableRow?.description)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.glCode}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizeAmountField(payableRow?.unitRate)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizePercentageField(payableRow?.units)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{sanitizeAmountField(payableRow?.amount)}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{payableRow?.truckNumber}</TableCell>
                            <TableCell align="center" className={(disablePayableRowOnSplit) ? 'disabledCellOnSplit' : ''}>{convertDateToMDY(payableRow?.processedDate)}</TableCell>
                            <TableCell align="center">
                                <Stack direction="row" sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    {payableRow?.splitItems?.length > 0 ? (
                                        <IconButton disabled={(payableRow?.splitItems?.length === 0) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => deleteSplitItems()}>
                                            <CallMerge titleAccess='Merge Payables' color="black" />
                                        </IconButton>
                                    ) : (
                                        <IconButton disabled={(payableRow?.splitItems?.length > 0 || Object.keys(payableRow || {}).length === 0 || disablePayableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handleSplitItem()}>
                                            <CallSplit titleAccess='Split Payables' color="black" />
                                        </IconButton>
                                    )}
                                    {/*&& !disablePayableRowOnSplit*/}
                                    <IconButton disabled={(disablePayableRow) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => handlePayableDelete()}>
                                        <DeleteIcon titleAccess='Delete Payable' fontSize="small" color="black" />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                </>
            )}
        </>
    );
}