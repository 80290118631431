import React, { useState } from 'react';
import {
    Stack,
    Typography,
    FormControlLabel,
    Checkbox,
    TextField,
    InputAdornment,
    IconButton,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Button,
    Box
} from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OrderByLookup from '../../../components/OrderByLookup';
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import {
    formatedDateRangeFunc,
    formatDateRange,
} from "../../../utils/global-functions";

import LocAutoComplete from '../../../components/AutoComplete/LocAutoComplete';
import { isDateDiffGreaterThan31 } from '../../../utils/global-functions';
import ResponsiveGrid from '../../../components/GridCustomComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanded view
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const BillingFilterSection = (props) => {
    const {onApplyFilters, onClearFilters, activeTab, printDocument,isCustRevScreen,selectedLocationList,setSelectedLocationList,exportButtons} = props;
    
    const [dateType, setDateType] = useState('');
    const [sortByControl, setSortByControl] = useState(false);
    const [revenuePercentage, setRevenuePercentage] = useState('');
    const [excludeFSC, setExcludeFSC] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [billTo, setBillTo] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startingPercentage, setStartingPercentage] = useState('');
    const [endingPercentage, setEndingPercentage] = useState('');

    // date picker states
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [showApplyButton, setShowApplyButton] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [customerType, setCustomerType] = useState('');
    const [isExpanded, setIsExpanded] = useState(true);

    const handleApply = () => {
        if(dateType !== '' && (dateRange[0] === null || dateRange[1] === null)){
            alert('Please select valid date range!')
            return false;
        }
        if(isCustRevScreen && !customerType){
            alert('Please select Customer type!')
            return false;
        }
        
        if(selectedLocationList.length>1 &&dateRange ){
            console.log("dateRange",dateRange)
            const isDateGreaterThan31=isDateDiffGreaterThan31(dateRange)
            if(isDateGreaterThan31){
                alert(`When multiple locations are selected, the date range should be less than or equal to 30 days.`);
            }
        }
        onApplyFilters({
            dateType,
            sortByControl,
            revenuePercentage,
            excludeFSC,
            orderBy,
            billTo,
            dateRange,
            startingPercentage,
            endingPercentage,
            customerType
        });
    };

    const handleClear = () => {
        setDateType('');
        setSortByControl(false);
        setRevenuePercentage('');
        setExcludeFSC(false);
        setOrderBy('');
        setBillTo('');
        setDateRange([null, null]);
        setStartingPercentage('');
        setEndingPercentage('');
        onClearFilters();
        setSelectedDate("");
    };

    const handleClosePicker = () => {
        setIsPickerOpen(false); // Close picker
    };
    const handleAccept = (index, dateValue) => {
        const { formattedStartDate, formattedEndDate } = formatDateRange(dateValue);
        const formatDate = formattedStartDate + "|" + formattedEndDate;        
        setDateRange([formattedStartDate, formattedEndDate])
        setSelectedDate(formatDate);
        handleClosePicker();
        setShowApplyButton(true);
    };

    return (
          <Box sx={{ padding: 2, boxShadow: "0 4px 6px rgba(0,0,0,0.1)" }}>
        <ResponsiveGrid gridValues={[12]}> 
          <Box display="flex"  marginBottom={2}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                color: "#0911A7",
                flexGrow: 1,
              }}
            >
              {isCustRevScreen?"Customer Revenue Report": "Billing Report"}
            </Typography>
            <Button
        variant="outlined"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          display: { xs: "flex", md: "flex" }, // Hide on medium and larger screens
          // marginBottom: 2,
        }}
      >
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      </Box>
      </ResponsiveGrid>
      <Box sx={{ display: { xs: isExpanded ? "block" : "none", md: isExpanded ? "block" : "none"} }}>
        <ResponsiveGrid
          gridValues={[  3, 3, 3, 3, 3, 3, 3, 3, 3, 2]}
        >
                        <LocAutoComplete
                seletedLocationList={selectedLocationList}
                setSelectedLocationList={setSelectedLocationList}
                maxDisplayTags={2}
              />
          {isCustRevScreen ? (
            <Stack direction="column">
              <FormControl sx={{ minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">
                  Customer Type
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={customerType}
                  label="Customer Type"
                  size="small"
                  onChange={(e) => setCustomerType(e.target.value)}
                  fullWidth
                >
                  {/* <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem> */}
                  <MenuItem value="orderBy">Order By</MenuItem>
                  <MenuItem value="billTo">Bill To</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          ) : null}
        
          <FormControl size="small" fullWidth>
            <InputLabel>Date Type</InputLabel>
            <InputLabel id="demo-select-small-label">Date Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={dateType}
              label="Date Type"
              size="small"
              onChange={(e) => setDateType(e.target.value)}
              fullWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="billingDate">Billing Date</MenuItem>
              <MenuItem value="returnDate">Return Date</MenuItem>
              <MenuItem value="apptDate">Appt Date</MenuItem>
            </Select>
          </FormControl>
           <Stack direction="column" style={{ position: 'relative'}}>
            <TextField
                                disabled={!dateType?true:false}
                                autoComplete="off"
                                label="Date Range"
                                disablePortal
                                size="small"
                                variant="outlined"
                                id="combo-box-demo"
                                value={formatedDateRangeFunc(selectedDate) || ""}
                                onClick={() => setIsPickerOpen(true)}
                                readOnly // Makes the field read-only, only clickable
                                style={{ cursor: "pointer"}} // Styling for better UI
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="clear date"
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevent calendar from opening
                                                    setSelectedDate(null); // Clear the date
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {isPickerOpen && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "95%",
                                        //   left: "10%",
                                        width: "max-content",
                                        zIndex: 1000,
                                        background: "#fff",
                                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                                    }}
                                >
                                    <CustomDateRangePicker
                                        indexValue={0}
                                        defaultDateRangeValue={selectedDate ? selectedDate : ""}
                                        onDateSelect={handleAccept}
                                        handleCloseDatePicker={handleClosePicker}
                                        isRestrict={1}
                                        maxDays={selectedLocationList.length>1? 31:null}
                                    />
                                </div>
                            )}
                        </Stack>
                        <FormControl  size="small" fullWidth>
                           <InputLabel id="demo-select-small-label">Revenue %</InputLabel>
                         <Select
                                    label="Revenue Percentage"
                                    value={revenuePercentage}
                                    onChange={(e) => setRevenuePercentage(e.target.value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="ptPercent">PT %</MenuItem>
                                    <MenuItem value="totalPercent">Total %</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label='Min %'
                                value={startingPercentage}
                                onChange={(e) => setStartingPercentage(e.target.value)}
                                size="small"
                                variant="outlined"
                                disabled={!revenuePercentage?true:false}
                                fullWidth
                            />
          <TextField
            label="Max %"
            value={endingPercentage}
            onChange={(e) => setEndingPercentage(e.target.value)}
            size="small"
            variant="outlined"
            disabled={!revenuePercentage?true:false}
            fullWidth
          />
          {activeTab !== 2 && (
            <OrderByLookup
              handleOnChangeValue={(val) => setOrderBy(val?.code || "")}
            />
          )}
          {activeTab !== 2 && (
            <TextField
              label="Bill To"
              value={billTo}
              onChange={(e) => setBillTo(e.target.value)}
              size="small"
              fullWidth
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeFSC}
                onChange={(e) => setExcludeFSC(e.target.checked)}
                fullWidth
              />
            }
            label="Exclude FSC"
          />
          </ResponsiveGrid>
          <ResponsiveGrid gridValues={[1, 1]} center>
          <Button variant="contained" onClick={handleApply} fullWidth>
            Apply
          </Button>
          <Button variant="outlined" onClick={handleClear} fullWidth>
            Clear
          </Button>
          <div>
                {exportButtons} {/* Render buttons here */}
            </div>
        </ResponsiveGrid>
        </Box>
      </Box>

    );
};

export default BillingFilterSection;
