import React, {useRef, useLayoutEffect} from 'react';
import { TextField } from '@mui/material';

const CustomTextField = ({ value, onChange, hasFocus }) => {

    const ref = useRef();

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
            <TextField
                value={value || ''}
                onChange={(e) => onChange(e.target.value)}
                fullWidth
                variant="standard"
                size="small"
                inputRef={ref}
            />
        )
}

export default CustomTextField;
