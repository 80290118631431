import moment from 'moment';
export const validateDateField = (dateVal) => {
    if (dateVal === 0 || !dateVal)
      return ""
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
      return dateVal;
    }
    if (moment(dateVal, 'MM/DD/YY', true).isValid()) {
      return moment(dateVal).format('YYYY-MM-DD');
    }
    if (dateVal?.toString().length === 7) {
      let date = dateVal.toString().substring(1)
      date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
      return date;
    }
    return "";
  
  }