import { useEffect, useState } from 'react';
import {
    Paper, Tabs, Tab, Box, Stack, Grid, Checkbox, FormControl, InputLabel, FormControlLabel, Divider, Chip,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Backdrop,
    CircularProgress,
    IconButton,
    Autocomplete
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import CallSplit from '@mui/icons-material/CallSplit';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

// import custom date picker
import CustomDatePicker from '../../../../components/CustomDatePicker';

import {
    deleteOrderRatesChargesData
} from "../../../../store/actions/orderActions";
import { useDispatch } from "react-redux";

import RatesAutoComplete from './RatesAutoComplete';
import GLCodesAutoComplete from './GLCodesAutoComplete';
import { getPayableGlCode, getParentPayableRate, calAmount, calAmountByPer, roundOfNumber, getSubitemReceivableGL, getSubitemPayableGL, getSubItemUnit, sanitizeAmountField, sanitizePercentageField, convertDateToMDY } from '../CommonFn';
import TrucksAutoComplete from './TrucksAutoComplete';

export default function SplitItemsTableRow(props) {
    const {
        rowIndex,
        row,
        linehaulEditRef,
        splitType,
        splitItemIndx,
        handleExistingRow
    } = props;
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [payableRow, setPayableRow] = useState({ ...row, processedDate: row?.processedDate || '' } || '');

    // state to disable editable fields
    const [disablePayableRow, setDisablePayableRow] = useState(
        ((Object.keys(row || {})).length === 0 ||
            (typeof row?.isFreeLockPayable !== 'undefined' && row?.isFreeLockPayable === true) ||
            (typeof row?.isLockPayable !== 'undefined' && row?.isLockPayable === true)) ? true : false
    );

    const handleSave = () => {
        setIsEditModeOn(false);

        let splitItemsVar;
        if (splitType === 'parent') {
            linehaulEditRef.current[rowIndex].payable.splitItems[splitItemIndx] = payableRow

            splitItemsVar = {
                ...linehaulEditRef.current[rowIndex],
                payable: {
                    ...linehaulEditRef.current[rowIndex].payable,
                    splitItems: [...linehaulEditRef.current[rowIndex].payable.splitItems]
                }
            }
        }

        if (splitType === 'child') {
            linehaulEditRef.current[rowIndex].payable.subItems[0].splitItems[splitItemIndx] = payableRow

            splitItemsVar = {
                ...linehaulEditRef.current[rowIndex],
                payable: {
                    ...linehaulEditRef.current[rowIndex].payable,
                    subItems: [{
                        ...linehaulEditRef.current[rowIndex].payable?.subItems?.[0],
                        splitItems: [...linehaulEditRef.current[rowIndex].payable.subItems[0].splitItems]
                    }]

                }
            }
        }
        handleExistingRow(splitItemsVar, rowIndex);
    }

    useEffect(() => {
        if (row.isFreeLockPayable === false && payableRow.isFreeLockPayable === true) {
            setDisablePayableRow(false)
        }
    }, [row])

    return (
        <>
            {isEditModeOn ? (
                <TableRow className={`${payableRow.transmitted === 'Y' ? 'tx_p_y' : ''}`}>
                    <TableCell>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow.description}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                description: e.target.value
                            })}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.glCode}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                glCode: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.unitRate}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                unitRate: e.target.value,
                                amount: calAmountByPer(e.target.value, payableRow.units)
                            })}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.units}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                units: e.target.value,
                                amount: calAmountByPer(payableRow.unitRate, e.target.value)
                            })}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            type={`number`}
                            variant="standard"
                            value={payableRow.amount}
                            disabled={true}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                amount: e.target.value
                            })}
                        />
                    </TableCell>
                    <TableCell>
                        {/* <TextField
                            type={`text`}
                            variant="standard"
                            value={payableRow?.truckNumber}
                            style={{ width: 'fit-content' }}
                            onChange={(e, val) => setPayableRow({
                                ...payableRow,
                                truckNumber: e.target.value
                            })}
                            disabled={disablePayableRow}
                            inputProps={{ maxLength: 5 }}
                        /> */}
                        <TrucksAutoComplete
                            valueChange={(val) => {
                                if (val !== '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: val,
                                    })

                                } else if (val === '') {
                                    setPayableRow({
                                        ...payableRow,
                                        truckNumber: '',
                                    })
                                }
                            }
                            }
                            existingValue={payableRow?.truckNumber || ''}
                            disabled={disablePayableRow}
                        />
                    </TableCell>
                    <TableCell>
                        <CustomDatePicker
                            variant="standard"
                            value={convertDateToMDY(payableRow?.processedDate) || ''}
                            style={{ width: 'fit-content' }}
                            onValueChange={(val) => {
                                if (val === '') {
                                    setDisablePayableRow(false);
                                    setPayableRow({
                                        ...payableRow,
                                        processedDate: ''
                                    })
                                } else {
                                    setPayableRow({
                                        ...payableRow,
                                        processedDate: val
                                    })
                                }
                            }
                            }
                            disabled={(row.isLockPayable === true) ? true : false}
                            showOneMonthAlert={true}
                        />
                    </TableCell>
                    <TableCell>
                        <Stack direction="row">
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => handleSave()}>
                                <DoneIcon titleAccess='Save Changes' fontSize="small" color="success" />
                            </IconButton>
                            <IconButton size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(false)}>
                                <CloseIcon titleAccess='Cancel Changes' fontSize="small" sx={{ color: 'red' }} />
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
            ) : (
                <TableRow className={`${payableRow.transmitted === 'Y' ? 'tx_p_y' : ''}`}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{payableRow?.description}</TableCell>
                    <TableCell align="center">{payableRow?.glCode}</TableCell>
                    <TableCell align="center">{sanitizeAmountField(payableRow?.unitRate)}</TableCell>
                    <TableCell align="center">{sanitizePercentageField(payableRow?.units)}</TableCell>
                    <TableCell align="center">{sanitizeAmountField(payableRow?.amount)}</TableCell>
                    <TableCell align="center">{payableRow?.truckNumber}</TableCell>
                    <TableCell align="center">{convertDateToMDY(payableRow?.processedDate)}</TableCell>
                    <TableCell>
                        <Stack direction="row" sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <IconButton disabled={(row.isLockPayable === true) ? true : false} size={`small`} sx={{ width: '25px' }} onClick={() => setIsEditModeOn(true)}>
                                <EditIcon titleAccess='Edit' color="black" />
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}