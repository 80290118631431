import { useRef, useLayoutEffect, useEffect, useState } from 'react';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

export default function CustomDateTimePicker(props) {
    const { label, size, className, value, onValueChange, disabled, sx, variant, hasFocus, isArrowKeyDisabled, showOneMonthAlert = false } = props;
    
    const ref = useRef();
    const [currentValue, setCurrentValue] = useState(value ? moment.utc(value) : null);
    const [previousValue, setPreviousValue] = useState(() => (value ? moment.utc(value) : null));
    const alertTriggeredRef = useRef(false);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current?.focus();
        }
    }, [hasFocus]);

    const handleKeyDown = (event) => {
        if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && isArrowKeyDisabled) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    useEffect(() => {
        const inputElement = ref.current;
        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);

    const handleDateChange = (newValue) => {
        if (!newValue) {
            setCurrentValue(null);
            setPreviousValue(null);
            onValueChange({ eDate: null, eTime: null });
            return;
        }

        if (!moment(newValue).isValid()) return;

        const localMoment = moment(newValue);

        setCurrentValue(localMoment);
        onValueChange({
            eDate: localMoment.format('YYYY-MM-DD'),
            eTime: localMoment.format('HH:mm')
        });
    };

    const handleFocusOut = () => {
        if (!currentValue || alertTriggeredRef.current) return;

        const selectedDate = moment(currentValue);
        if (!selectedDate.isValid()) return;

        const currentDate = moment();
        const thirtyDaysBefore = currentDate.clone().subtract(30, "days");
        const thirtyDaysAfter = currentDate.clone().add(30, "days");

        if (!currentValue.isSame(previousValue) && showOneMonthAlert && (selectedDate.isBefore(thirtyDaysBefore) || selectedDate.isAfter(thirtyDaysAfter))) {
            alertTriggeredRef.current = true;

            setTimeout(() => {
                const userConfirmed = window.confirm("Selected date is outside the 30-day range. Do you want to proceed?");
                if (!userConfirmed) {
                    setCurrentValue(previousValue);
                    onValueChange({
                        eDate: previousValue?.format('YYYY-MM-DD') || null,
                        eTime: previousValue?.format('HH:mm') || null
                    });
                } else {
                    setPreviousValue(moment(currentValue));
                }

                setTimeout(() => {
                    alertTriggeredRef.current = false;
                }, 300);
            }, 0);
        } else {
            setPreviousValue(moment(currentValue));
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                label={label}
                value={currentValue}
                onChange={handleDateChange}
                onClose={handleFocusOut}
                slotProps={{
                    textField: { 
                        size, 
                        className, 
                        error: false, 
                        disabled, 
                        sx, 
                        variant,
                        onBlur: handleFocusOut 
                    },
                    actionBar: { actions: ['clear', 'accept'] },
                    openPickerIcon: {
                        sx: { fontSize: '1.1rem' }
                    }
                }}
                format='MM/DD/YY HH:mm'
                ampm={false}
                reduceAnimations
                disabled={disabled}
                inputRef={ref}
            />
        </LocalizationProvider>
    );
}
