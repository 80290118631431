import Chip from "@mui/material/Chip";
import Switch from '@mui/material/Switch';
import Divider from "@mui/material/Divider";
import { Paper, Button, Box, Typography } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import { Checkbox, FormControlLabel } from '@mui/material';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { Select, MenuItem } from "@mui/material";

import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StopsList from "../Orders/Stops/StopsList";
import orderContext from "../Orders/orderContext";
import Loader from "../../components/Loader/Lodaer";
import StopsTable from "../Orders/Stops/StopsTable";
import RatesAndCharges from "../Orders/RatesAndCharges";
import { StyledSceneContent } from "../../components/styledComponents";
import BillingControl from "../Orders/BillingControl/BillingControlForm";
import { getDefaultBillingControl, orderTypeOptions } from "../Templates/OrdersLogics";

import { mapEdiOrderToClient, mapOrderToServer, mapAcceptRejectEdiOrderToServer, mapRampEdiOrderToServer } from "./components/commonFn";
import { getSingleEdiOrderData, acceptEdiOrder, rejectEdiOrder, restoreEdiOrder, unAssignLocation, assignLocation, getEdiOrderRampList, addBulkContacts } from "../../store/actions/ediOrderAction";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { getGLCodes } from "../../store/actions/rateBookActions";
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import OrderNotes from "../Orders/OrderNotes";
import Autocomplete from '@mui/material/Autocomplete';
import RestoreIcon from '@mui/icons-material/Restore';
import { getSingleOrder } from "../../store/actions/orderActions";
import { getUserFromCookie } from '../../utils/cookie';
import { getShipperData } from "../../store/actions/shipperActions";
import CustomAutoComplete from '../../components/CustomAutoComplete';
import { GET } from "../../utils/axios";
import {validateOrderFields,validateTruckDriverFieldsInStop} from "../../utils/vaildateOrderStopTruck"

const actionStyles = { display: 'flex', width: '100%', padding: '2em', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }

export default function EdiOrderFormContainer(props) {
  const { selectedRow, setSelectedRow, orderType, setOrderType, setSelectedRowNote, selectedRowNote, ediTableApiCall, activeButton, selectedRowData, oldEdiOrderData, setOldEdiOrderData, setNonMatchedJsonData, setActiveButton, currentEdiJson, setCurrentEdiJson } = props;

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState('');
  const [orderCreateDate, setorderCreateDate] = useState('');
  const [stopsGridView, setStopsGridView] = useState(false);
  let [currentOrder, setCurrentOrder] = useState([]);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [isLoading, setIsLoading] = useState((params.controlNumber === 'new') ? false : true);

  const [isEdi210, setIsEdi210] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [updateEDI, setUpdateEDI] = useState(false);
  const [note, setNote] = useState('');
  const [controlNo, setControlNo] = useState("")
  const [isAckRejectButton, setIsAckRejectButton] = useState("REJECT")
  const [openDialog, setOpenDialog] = useState(false)

  const locationList = useSelector(({ loadsReducer }) => loadsReducer.locationList);
  // const stringAllLocationList = locationList?.map(item => String(item.locationNumber)) || [];
  const stringAllLocationList = activeButton !== "Unassigned" ? ([
    ...(locationList ? locationList.map(item => String(item.locationNumber)) : []),
    "UnAssigned"
  ]) : (locationList?.map(item => String(item.locationNumber)) || []);

  const [selectedLocation, setSelectedLocation] = useState();
  const [nonMatchingFields, setNonMatchingFields] = useState([])
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showConfirmationDialogPrevRecord, setShowConfirmationDialogPrevRecord] = useState(false);
  const [finalPostObjOrderData, setFinalPostObjOrderData] = useState({})
  const [truckAssignStopDetail, setTruckAssignStopDetail] = useState({})
  const [newContacts, setNewContacts] = useState({})
  const [filteredNewContacts, setFilteredNewContacts] = useState({})

  const [rampListOptions, setRampListOptions] = useState([]);
  const [rampName, setRampName] = useState("")
  const user = getUserFromCookie();

  const [previousRecordId, setPreviousRecordId] = useState("")
  const [isDelStopsBeforeUpd, setIsDelStopsBeforeUpd] = useState(true)
  const [stopsUpdate, setStopsUpdate] = useState(0);
  const [stopsMasterLoad, setStopsMasterLoad] = useState([]);
  const [orderIdsArr, setOrderIdsArr] = useState([]);
  const [isAcceptDialogOpen, setAcceptDialogOpen] = useState(false); // Dialog state for "Accept" dialog

  const open = Boolean(anchorEl);

  let stopsRef = useRef([]);
  let linehaulRef = useRef([]);
  let accessorialRef = useRef([]);
  let orderNotesRef = useRef([])

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );


  let billingControlRef = useRef(getDefaultBillingControl());



  useEffect( async() => {

    stopsRef.current = []
    linehaulRef.current = []
    accessorialRef.current = []
    billingControlRef.current = getDefaultBillingControl()
    orderNotesRef.current = []
    setSelectedRowNote("")
    fetchSingleRecordData();
    getControlNumbers();

    fetchGlCodes()

  }, [selectedRow])
  const fetchGlCodes = async () => {
    dispatch(getGLCodes());
  }

  const fetchSingleRecordData = async () => {
    let mapOrderData
    let currentEdiJsonData

    try {
      let selectedRowId = selectedRow
      if (previousRecordId && previousRecordId.id) {

        let activeButtonStatus = (previousRecordId?.status === "U") ? "Unassigned" : (previousRecordId?.status === "P") ? "Pending" : (previousRecordId?.status === "H") ? "History" : "NULL"
        selectedRowId = previousRecordId.id

        setActiveButton(activeButtonStatus)
        setSelectedRowNote("")
        setSelectedRow("")
        ediTableApiCall({ page: 1, pageSize: 50 }, { field: "receivedDate", sort: "asc" }, "", activeButtonStatus)
        setSelectedRow(selectedRowId)

      }
      let res = await dispatch(getSingleEdiOrderData({
        selectedRowId: selectedRowId
      })).then((serverData) => {
          getControlNumbers(serverData[0]?.billingReference || '', serverData[0]?.locationNumber);
          return serverData;
      }).catch((e) => {
          console.error("Error:", e);
          return [];
      });

      if (res[0]?.ediJson.length > 1) {
        let newEdiJson = [];
        let oldEdiJson = [];
        //new
        const sortRes = res[0]?.ediJson.sort(
          (a, b) => new Date(b.receivedDate).getTime() - new Date(a.receivedDate).getTime()
        );
        newEdiJson.push(sortRes[0])
        currentEdiJsonData = sortRes[0]
        setCurrentEdiJson(sortRes[0]);
        oldEdiJson.push(sortRes[1]);

        // res[0]?.ediJson.forEach(item => {
        //         if (item.TypeDesc === "CHANGE" || item?.Type=== "01" || item?.Type=== "05") {
        //             newEdiJson.push(item);
        //             if(res[0]?.ediJson.length > 1){
        //                 setCurrentEdiJson(item);
        //             }
        //         } else if (item.TypeDesc === "ORIGINAL") {
        //             oldEdiJson.push(item);
        //         }
        //     });

        setSelectedRowNote(oldEdiJson[0])

        setRampName(newEdiJson[0]?.BillingRamp)
        mapOrderData = mapEdiOrderToClient(res[0])
        setCurrentOrder(mapOrderData);
        setSelectedLocation(mapOrderData.locationNumber);
        const mapOrderDataOld = mapEdiOrderToClient(res[0]?.oldEdiData)
        setOldEdiOrderData(mapOrderDataOld);
        const nonMatchingFieldsJsonData = getNonMatchingFields(oldEdiJson[0], newEdiJson[0]);
        setNonMatchedJsonData(nonMatchingFieldsJsonData)
        const nonMatchingFieldsData = getNonMatchingFields(mapOrderDataOld, mapOrderData);
        setNonMatchingFields(nonMatchingFieldsData)

      }
      else {
        currentEdiJsonData = res[0]?.ediJson[0]
        setCurrentEdiJson(res[0]?.ediJson[0]);
        mapOrderData = mapEdiOrderToClient(res[0])
        setSelectedRowNote(res[0]?.ediJson[0])
        setRampName(res[0]?.ediJson[0]?.BillingRamp)
        setNonMatchedJsonData([])
        setCurrentOrder(mapOrderData);
        setSelectedLocation(mapOrderData.locationNumber);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (currentEdiJsonData && mapOrderData) {
        const newContactsData = await newContactArray(currentEdiJsonData?.BillTo, mapOrderData?.billingControl?.orderBy?.orderBy);
        setNewContacts(newContactsData)
      }
      setCurrentOrder(mapOrderData)
      setIsLoading(false);
    }
  };

  const getControlNumbers = async(ref, location) => {
    if (ref && location) {
      try {
          const response = await GET(`orders/order-ids/${location}?reference=${ref}`);
          if (response?.status !== "error") {
              const apiData = response.data || [];
              setOrderIdsArr(apiData);
          }
      } catch (error) {
          console.error("Error fetching order IDs:", error);
      }
    } else {
        console.warn("Reference or location is missing.");
    }
  }

  function newContactArray(BillTo, shipperAgent) {
    const contacts = [];
    const uniqueEmails = new Set();

    // Extract the contact information from the BillTo object
    const contactEntries = Object.entries(BillTo.Contact);

    // Group contact fields by their suffix (e.g., '1', '2', etc.)
    const groupedContacts = contactEntries.reduce((acc, [key, value]) => {
      const suffix = key.match(/\d+$/)?.[0];
      if (suffix) {
        acc[suffix] = acc[suffix] || {};
        const fieldKey = key.slice(0, -suffix.length);
        acc[suffix][fieldKey] = value;
      }
      return acc;
    }, {});

    // Iterate over the grouped contacts and filter them
    Object.values(groupedContacts).forEach(contact => {
      const { Name, Phone, Email } = contact;

      // if (Email && !uniqueEmails.has(Email.toLowerCase())) {
      //   uniqueEmails.add(Email.toLowerCase());
      if (Email || Phone ) {

        contacts.push({
          fname: Name || "",
          lname: "",
          email: Email || "",
          primaryPhone: Phone || "",
          addr1: BillTo.Address1 || "",
          addr2: BillTo.Address2 || "",
          city: BillTo.City || "",
          state: BillTo.State || "",
          country: BillTo.Country || "",
          zip: BillTo.Zip || "",
          agentCode: shipperAgent,
          locationNumber: ordersLocation.locationNumber
        });
      }
    });
    return contacts;
  }


  const fetchData = (currentOrder, callback) => {
    setIsLoading(true);
    billingControlRef.current = currentOrder?.billingControl || getDefaultBillingControl();
    stopsRef.current = currentOrder?.stops || [];
    linehaulRef.current = currentOrder?.linehaul || [];
    accessorialRef.current = currentOrder?.accessorial || [];
    orderNotesRef.current = currentOrder?.orderNotes || [];


    if (callback) {
      setTimeout(() => {
        callback();
      }, 2000);
    };
  };
  const testFunction = () => {

    setOrderType(currentOrder?.billingControl?.orderType || "");
    setorderCreateDate(currentOrder?.billingControl?.orderType)
    setIsLoading(false);
  }
  useEffect(() => {

    fetchData(currentOrder, testFunction);
    // console.log(`current order is `,currentOrder )
    if (typeof currentOrder !== undefined && currentOrder?.stops?.length > 0) {
      setStopsMasterLoad(currentOrder?.stops)
    }
  }, [currentOrder]);

  useEffect(() => {
    setStopsMasterLoad(stopsRef.current)
  }, [stopsUpdate]);

  const handleRejectClick = (buttonType) => {
    setDialogOpen(true);
    setIsAckRejectButton(buttonType)
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setReason("")
    setUpdateEDI(false)
    setNote("")
    setControlNo("")
    setIsAckRejectButton("")
  };
  
  const handleAcceptDialogClose = () => {
    setAcceptDialogOpen(false);
  };

  const handleAcceptDialogConfirm = async (isUpdateExistingOrder) => {
    // Proceed with the order after the user clicks 'Yes'/"No"
    if(!isUpdateExistingOrder){
      setControlNo("")
    }
    handleConfirm(true, finalPostObjOrderData);
    setAcceptDialogOpen(false);
  };
  
  const handleDialogSubmit = async () => {
    const responseType = isAckRejectButton === "Acknowledge" ? "Accepted" : isAckRejectButton
    try {
      const postObj = mapAcceptRejectEdiOrderToServer(selectedRowNote, responseType, updateEDI, reason, note, controlNo, currentEdiJson?.References.find(item => item?.Qualifier == 'TH')?.Identifier);
      dispatch(rejectEdiOrder(selectedRow, postObj, isAckRejectButton)).then((res) => {
        
        setIsLoading(false);
        setDialogOpen(false);
        if (res.status === 'warn') {
          setPreviousRecordId(res?.data?.previousRecordData)
          setShowConfirmationDialogPrevRecord(true)
        } else {
          setSelectedRowNote("")
          setSelectedRow("")
          ediTableApiCall()
        }
        
      });
    } catch (error) {
      console.error("Error rejecting order:", error);
    } 
  };

  function updateStops(changeEdiData, existingOrderData) {
    // Helper function to compare stops
    function isSameStop(stop1, stop2) {
      return JSON.stringify(stop1) === JSON.stringify(stop2);
    }

    // Step 1: Check if stops data in both orders are the same
    const stopsAreSame = changeEdiData.stops.length === existingOrderData.stops.length &&
      changeEdiData.stops.every((stop, index) => isSameStop(stop, existingOrderData.stops[index]));

    if (stopsAreSame) {
      // setIsDelStopsBeforeUpd(false)
      return changeEdiData;
    }

    // Step 2: Check if all existing stops have yard === "Y"
    const anyYardTrue = existingOrderData.stops.some(stop => stop.yard === "Y");

    if (!anyYardTrue) {
      return changeEdiData;
    }

    // Step 3: Create newStopData with line numbers starting from 1
    let newStopData = [];
    let lineNumber = 1;

    // Add stops from existingOrderData
    existingOrderData.stops.forEach(stop => {
      if (stop.yard === "Y") {
        newStopData.push({ ...stop, lineNumber, newLineNumber: lineNumber });
        lineNumber++;
      }
    });

    // Add stops from changeEdiData that are not already in newStopData
    changeEdiData.stops.forEach(stop => {
      if (!newStopData.some(existingStop => existingStop.name === stop.name)) {
        newStopData.push({ ...stop, lineNumber, newLineNumber: lineNumber });
        lineNumber++;
      }
    });

    // Step 4: Assign the newStopData to changeEdiData.stops and return the modified changeEdiData
    changeEdiData.stops = newStopData;
    return changeEdiData;
  }





  const handleAcceptClick = async () => {
    try {
      let validationRes = validateOrderFields({ ...billingControlRef.current, stops: stopsRef.current });
      if (validationRes.status) {
        setSnackOpen(true);
        setSnackContent({ severity: "error", message: validationRes.error });
        return;
      }
      const postObjOrderData1 = mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] });
      let postObjOrderData2 = {}
      if (selectedRowNote.ControlNumber) {
        let reqParams = {
          locationNumber: ordersLocation.locationNumber,
          controlNumber: selectedRowNote.ControlNumber,
        }
        const respond = await dispatch(getSingleOrder(reqParams))
        if (respond?.status === 'success') {
          console.log("respond", respond)
          const mapOrderData = mapEdiOrderToClient(respond?.data, false)
          postObjOrderData2 = mapOrderToServer({ billingControl: { ...mapOrderData.billingControl, orderType: orderType }, stops: mapOrderData?.stops, ratecharges: [...mapOrderData?.linehaul, ...mapOrderData?.accessorial] });
          let shipperCodes = [];
          let stops = respond?.data?.stops
          if (stops.length > 0) {
            for (let i = 0; i < stops.length; i++) {
              const code = stops[i].code;
              if (code !== "MANUAL" && !shipperCodes.includes(code)) {
                shipperCodes.push(code);
              }
            }
          }
          const postParams = [
            {
              clause: "AND",
              field: "locationNumber",
              operand: "IS",
              value: ordersLocation?.locationNumber
            },
          ];
          
          for (let i = 0; i < shipperCodes.length; i++) {
            postParams.push({
              clause: "OR",
              field: "shipperCode",
              operand: "IS",
              value: `${shipperCodes[i]}*`
            });
          }
  
          const res = await dispatch(getShipperData({
            postParams,
            locationNumber: ordersLocation?.locationNumber,
            query: { page: 1, pageSize: 50 },
            sortData: { field: "name", sort: "asc" }
          })); 
          if (res?.data?.hits?.length > 0) {
            const shipperData = res?.data.hits;
  

            
            for (let i = 0; i < postObjOrderData2?.stops?.length; i++) {
              // Get the current stop's code
              const stopCode = postObjOrderData2?.stops[i].code;
              // Loop through each hit in shipperData
              for (let j = 0; j < shipperData?.length; j++) {
                const shipper = shipperData[j]._source;
                // Check if the stopCode matches the shipperCode
                if (stopCode === shipper.shipperCode) {
                  // Update the yard property with the matching shipperCode
                  postObjOrderData2.stops[i].yard = shipper.yard;
                  break; // Break out of the loop once a match is found
                } else {
                  postObjOrderData2.stops[i].yard = "N";
                  break;
                }
              }
            }
          }
  
        // Use updateStops to merge or modify stops
        const updatedData = updateStops(postObjOrderData1, postObjOrderData2);

        // Always show the dialog when updating an existing order
        setFinalPostObjOrderData(updatedData);
        setAcceptDialogOpen(true);
        return;
        }
      } else {
        setFinalPostObjOrderData(postObjOrderData1);
        handleConfirm(true, postObjOrderData1);
        // handleDialogueEdiSubmit()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };  

  const handleConfirm = async (isUpdateEdi, finalPostObjOrderData) => {
    let selectedDataSeq = billingControlRef?.current?.orderBy?.orderContactSeq || 0
    if (filteredNewContacts && filteredNewContacts?.length > 0) {
      const res = await dispatch(addBulkContacts(filteredNewContacts));
      if (res.status === "success" && res?.data) {
        if (!(billingControlRef?.current?.orderBy?.orderContactSeq)) {
          // const selectedContactEmail = billingControlRef?.current?.orderBy?.email
          const selectedContact = billingControlRef?.current?.orderBy
          const updatedContacts = res?.data
          // selectedDataSeq = updatedContacts.find(contact => contact.email.toLowerCase() === selectedContactEmail.toLowerCase())?.seq;
          selectedDataSeq = updatedContacts.find(contact => 
            contact.email?.toLowerCase() === selectedContact.email?.toLowerCase() || 
            contact.primaryPhone === selectedContact.primaryPhone)?.seq;
          finalPostObjOrderData.orderBy.dispositionCOrderByContactSeq = selectedDataSeq;
        }

      } else {
        setSnackOpen(true);
        setSnackContent({ severity: "error", message: res.message });

      }
    }

    try {
      const postObjEdiData = { edi990: mapAcceptRejectEdiOrderToServer(selectedRowNote, 'Accepted', isUpdateEdi, "", "", controlNo, currentEdiJson?.References.find(item => item?.Qualifier == 'TH')?.Identifier) };
      const userName = user?.email.split("@")[0]
      const rampEdiData = { rampDetails: mapRampEdiOrderToServer(selectedRowNote, rampName, userName, currentOrder) }
      const ediInfoData = { ediInfo: { shipmentId: currentEdiJson?.ShipmentId, trackingNumber: currentEdiJson?.InternalTrackingNum, receivedDate: currentOrder.receivedDateTime, ediType: currentEdiJson?.Type } }
      const postObj = { ...finalPostObjOrderData, ...postObjEdiData, ...rampEdiData, ...ediInfoData, orderNotes: orderNotesRef.current, controlNumber: controlNo, deleteStopsBeforeUpdate: isDelStopsBeforeUpd };
      if (!postObj) {
        setSnackOpen(true);
        setSnackContent({ severity: "error", message: "Data invalid, Request failed" });
        return;
      }
      setShowConfirmationDialog(false);
      setSnackOpen(true);
      setSnackContent({ severity: "info", message: "Saving Order ..." });
      const res = await dispatch(acceptEdiOrder(
        ordersLocation.locationNumber,
        selectedRow,
        postObj
      ));

      if (res.status === "success") {
        setSnackOpen(true);
        setSnackContent({ severity: "success", message: res.message });
        history.push(`/orders/${ordersLocation.locationNumber}/${res.data.orderNumber}`);
      } else if (res.status === "warn") {
        // setSnackOpen(true);
        // setSnackContent({ severity: "success", message: res.message });
        setPreviousRecordId(res?.data?.previousRecordData)
        setShowConfirmationDialogPrevRecord(true)



      } else if (res.status === "error") {
        setSnackOpen(true);
        setSnackContent({ severity: "error", message: res.message });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowConfirmationDialog(false);
    }
  };


  // const handleDialogueEdiSubmit=()=>{
  // //  Show confirmation dialog
  //     // setShowConfirmationDialog(true);
  // }
  const handleRestoreClick = async () => {
    try {
      const res = await dispatch(restoreEdiOrder(
        selectedRow
      ));
      if (res?.httpCode === 200 && res.status === "success") {
        setSnackOpen(true)
        setSnackContent({ severity: "success", message: res.message })
        setSelectedRowNote("")
        setSelectedRow("")
        ediTableApiCall()
      }
      else if (res.status === "error") {
        setSnackOpen(true)
        setSnackContent({ severity: "error", message: res.message })
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }



  }


  const handleClose = (event) => {
    setSnackOpen(false);
  };

  const handleConfirmationDialogSubmit = async () => {
    if (selectedLocation && selectedLocation !== "UnAssigned") {
      // Assigning Location
      try {
        const res = await dispatch(assignLocation(selectedLocation, selectedRow));

        if (res?.httpCode === 200 && res.status === "success") {
          setSnackOpen(true)
          setSnackContent({ severity: "success", message: res.message })
          setTimeout(() => {
            setSelectedRowNote("")
            setSelectedRow("")
            ediTableApiCall()
          }, 1000)

        }
        else if (res.status === "error") {
          setSnackOpen(true)
          setSnackContent({ severity: "error", message: res.message })
        }
      } catch (error) {
        console.error("Error Assigning location:", error);
      } finally {
        setIsLoading(false);
        setConfirmationDialogOpen(false);

      }

    } else {
      // UnAssigning Location
      try {
        const res = await dispatch(unAssignLocation(selectedRow));
        if (res?.httpCode === 200 && res.status === "success") {
          setSnackOpen(true)
          setSnackContent({ severity: "success", message: res.message })
          setTimeout(() => {
            setSelectedRowNote("")
            setSelectedRow("")
            ediTableApiCall()
          }, 1000)

        }
        else if (res.status === "error") {
          setSnackOpen(true)
          setSnackContent({ severity: "error", message: res.message })
        }
      } catch (error) {
        console.error("Error UnAssigning location:", error);
      } finally {
        setIsLoading(false);
        setConfirmationDialogOpen(false);

      }

    }
  }

  const handleConfirmationDialogClose = () => {
    setSelectedLocation(currentOrder.locationNumber);
    setConfirmationDialogOpen(false);
  }

  const handleChangeLocationData = (event, value) => {
    if (value || currentOrder.locationNumber !== 0) {
      setSelectedLocation(value);
      setConfirmationDialogOpen(true);
    }

  }
  function getNonMatchingFields(oldData, newData) {
    const nonMatchingFields = [];

    function compareObjects(oldObj, newObj, path = '') {
      for (const key in oldObj) {
        if (oldObj.hasOwnProperty(key)) {
          const oldVal = oldObj[key];
          const newVal = newObj ? newObj[key] : undefined;
          const currentPath = path ? `${path}.${key}` : key;

          if (typeof oldVal === 'object' && oldVal !== null && !Array.isArray(oldVal)) {
            compareObjects(oldVal, newVal, currentPath);
          } else if (Array.isArray(oldVal)) {
            oldVal.forEach((item, index) => {
              const newPath = `${currentPath}[${index}]`;
              const newItem = newVal ? newVal[index] : undefined;
              compareObjects(item, newItem, newPath);
            });
          } else {
            const normalizeValue = (val) => {
              if (val === null || val === undefined) {
                return "";
              }
              const strVal = String(val).trim();
              return strVal === '' || strVal === '0' ? '' : strVal.toLowerCase();
            };
            const normalizedOldVal = normalizeValue(oldVal);
            const normalizedNewVal = normalizeValue(newVal);
            // Convert to string for comparison to avoid issues with different types
            if (normalizedOldVal !== normalizedNewVal) {
              nonMatchingFields.push({
                field: currentPath,
                oldValue: oldVal,
                newValue: newVal
              });
            }
          }
        }
      }
    }

    compareObjects(oldData, newData);

    return nonMatchingFields;
  }

  // Example usage:
  const handleChangeOrderType = (event) => {
    billingControlRef.current = {
      ...billingControlRef.current, orderType: event.target.value
    }
    setOrderType(event.target.value)
  }
  const handleRampNameLookup = async (query) => {
    dispatch(
      getEdiOrderRampList(
        query
      )
    ).then((res) => {
      setRampListOptions(res);
    })

  };

  const handleRampNameChange = (event, value) => {
    setRampName(value)
  };

  const handleDialogClose2 = () => {
    setOpenDialog(false);
    // setFinalPostObjOrderData({})
    // setTruckAssignStopDetail({})
  };

  const handleOpenOrder = () => {
    // Logic to open the order
    console.log("Order opened.");
    window.open(`/orders/${ordersLocation.locationNumber}/${selectedRowNote.ControlNumber}`, '_blank');
    // handleDialogClose2();
  };

  const handleProceedAndAlertDriver = () => {
    // Logic to proceed and alert the driver
    console.log("Driver alerted.");
    handleDialogClose2();
    // handleDialogueEdiSubmit()
    handleConfirm(true, finalPostObjOrderData)

  };
  return (
    <Paper style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: '100%', height: '40px', backgroundColor: '#E4EEFA', alignItems: 'center', display: 'flex', padding: '20px' }}>

        {/* Left side with orderType Select */}
        <div style={{ display: 'flex', alignItems: 'center', width: "12%" }}>
          <Select
            id='orderType'
            name='orderType'
            // value={orderType || "N/A"}
            value={orderType || (selectedRowData?.orderType==="O"?"OUTBOUND":selectedRowData?.orderType==="I"?"INBOUND":selectedRowData?.orderType)}
            onChange={handleChangeOrderType}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            inputProps={{ id: 'orderType' }}
          >
            <MenuItem value="INBOUND" disabled={orderType && orderType !== "INBOUND" && orderType !== "IMPORT"}>INBOUND</MenuItem>
            <MenuItem value="IMPORT" disabled={orderType && orderType !== "INBOUND" && orderType !== "IMPORT"}>IMPORT</MenuItem>
            <MenuItem value="OUTBOUND" disabled={orderType && orderType !== "OUTBOUND" && orderType !== "EXPORT"}>OUTBOUND</MenuItem>
            <MenuItem value="EXPORT" disabled={orderType && orderType !== "OUTBOUND" && orderType !== "EXPORT"}>EXPORT</MenuItem>
            <MenuItem value="NA" disabled> N/A </MenuItem>
          </Select>
        </div>

        {/* Right side with other elements */}
        <div style={{ display: 'flex', alignItems: 'center', width: "80%", justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
            <Autocomplete
              disablePortal
              id="driver_number"
              size="small"
              value={rampName}
              className="width_100"
              style={{ width: 130 }}
              options={rampListOptions}
              getOptionLabel={(option) => option ? option : ""}
              renderOption={(props, option) => (
                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                  {option}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option === value || option === ""}
              renderInput={(params) => (
                <TextField
                  label="Ramp"
                  {...params}
                  // InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    input: {
                      fontSize: '14px',
                      textTransform: 'uppercase'
                    }
                  }}
                />
              )}
              onInputChange={(event, value) => {
                if (value.length >= 2) {
                  handleRampNameLookup(value);
                }
              }}
              onChange={handleRampNameChange}
              componentsProps={{
                paper: {
                  sx: {
                    width: 250
                  }
                }
              }}
            />
          </div>

          {/* {(selectedRowData.status === 'U' || selectedRowData.status === 'P') && ( */}
          {(activeButton === 'Unassigned' || activeButton === 'Pending') && (
            <div style={{ marginRight: '10px' }}>
              <Autocomplete
                options={stringAllLocationList}
                getOptionLabel={(option) => option}
                value={selectedLocation ? [selectedLocation] : ""}
                onChange={handleChangeLocationData}
                style={{ width: 130 }}
                disableClearable
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" label="Location" />
                )}
              />
            </div>
          )}

          {(selectedRowData?.status === 'A' || selectedRowData?.status === "R" || selectedRowData?.status === "K") && (
            <Button
              variant="outlined"
              style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }}
              startIcon={<RestoreIcon style={{ width: '14px', height: '14px' }} />}
              onClick={handleRestoreClick}
            >
              RESTORE
            </Button>
          )}

          {/* {selectedRowData.status === 'P' && ( */}
          {activeButton === 'Pending' && orderType && (
            <div>
              <Button
                variant="outlined"
                style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }}
                startIcon={<DoneIcon style={{ width: '14px', height: '14px' }} />}
                onClick={handleAcceptClick}
              >
                ACCEPT
              </Button>
              <Button
                variant="outlined"
                style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }}
                startIcon={<CancelIcon style={{ width: '14px', height: '14px' }} />}
                onClick={() => handleRejectClick("Rejected")}
              >
                REJECT
              </Button>
              <Button
                variant="outlined"
                style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }}
                startIcon={<ThumbUpIcon style={{ width: '14px', height: '14px' }} />}
                onClick={() => handleRejectClick("Acknowledge")}
              >
                ACK
              </Button>
            </div>
          )}
        </div>
      </div>
      {  (selectedRowData?.orderType!=="NA" || orderType)?isLoading ?
        <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Loader />
        </div>


       :(

          <div style={activeButton === "History" ? { pointerEvents: "none", opacity: "0.8" } : {}} >
            <StyledSceneContent>
              <orderContext.Provider value={{ isStateChanged, setIsStateChanged, isEdi210, setIsEdi210 }}>

                {/* <div style={{ pointerEvents: "none", opacity: "0.8" }}> */}
                <div>
                  <Divider
                    textAlign="left"
                    style={{ marginTop: "1%" }}>
                    <Chip label="BILLING CONTROL" size="large" variant="filled" />
                  </Divider>

                  <BillingControl billingControlRef={billingControlRef} stopsMasterLoad={stopsMasterLoad} orderType={orderType} orderTypeOptions={orderTypeOptions} disableFull={currentOrder?.billingControl?.orderBy?.billToStatus === 'V'} toTemplate={true} oldEdiOrderData nonMatchingFields={nonMatchingFields} isEdiScreen={true} newContactsEdi={newContacts} setFilteredNewContacts={setFilteredNewContacts} />
                </div>

                <div >
                  <Box display="flex" alignItems="center" width="100%">
                    <Divider textAlign="center" sx={{ flex: 1 }} >
                      <Typography sx={{ mx: 2 }}>
                        <Chip label="STOPS" size="large" variant="filled" />
                      </Typography>
                    </Divider>
                    <Divider sx={{ flex: 1 }} />
                    <Divider sx={{ flex: 1 }} />
                    <Divider sx={{ flex: 1 }} />
                    <Typography sx={{ mx: 2 }}>
                      <FormControlLabel
                        label="Grid View"
                        style={{ fontSize: 50, }}
                        control={
                          <Switch
                            checked={stopsGridView}
                            onChange={(event) => setStopsGridView(event.target.checked)}
                          />
                        }
                      />
                    </Typography>
                  </Box>
                  {/* <Divider
                                    textAlign="left"
                                    style={{ marginTop: "2%", marginBottom: "1%" }}
                                >
                                    <Chip label="STOPS" size="large" variant="filled" />
                                </Divider>

                                <div style={actionStyles}>
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Grid View"
                                            style={{ fontSize: 50 }}
                                            control={
                                                <Switch
                                                    checked={stopsGridView}
                                                    onChange={(event) => setStopsGridView(event.target.checked)}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div> */}

                  {!stopsGridView && <StopsList stopsRef={stopsRef} setStopsUpdate={() => setStopsUpdate(stopsUpdate + 1)} orderType={orderType} orderTypeOptions={orderTypeOptions} disableFull={currentOrder?.billingControl?.orderBy?.billToStatus === 'V'} toTemplate={true} locationNumber={ordersLocation?.locationNumber} controlNumber={selectedRow[0]?._source?.controlNumber} isEdiScreen={true} oldEdiOrderData nonMatchingFields={nonMatchingFields} />}
                  {stopsGridView && <StopsTable stopsRef={stopsRef} disableFull={currentOrder?.billingControl?.orderBy?.billToStatus === 'V'} oldEdiOrderData nonMatchingFields={nonMatchingFields} />}
                </div>

                <div >
                  <Divider
                    textAlign="left"
                    style={{ marginTop: "2%", marginBottom: "1%" }}
                  >
                    <Chip label="RATES & CHARGES" size="large" variant="filled" />
                  </Divider>
                  <RatesAndCharges
                    // receivablesRef={receivablesRef}
                    // payablesRef={payablesRef}
                    accessorialRef={accessorialRef}
                    linehaulRef={linehaulRef}
                    orderType={orderType}
                    snackBarHandler={(content) => { setSnackOpen(content.status); setSnackContent({ severity: content.type, message: content.message }) }}
                    disableFull={''}
                    // disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'}
                    billingControlRef={billingControlRef}
                    ordersLocation={ordersLocation?.locationNumber}
                    isTemplateScreen={true}
                    stopsRef={stopsRef}  
                  />
                </div>
                <div>
                  <Divider
                    textAlign="left"
                    style={{ marginTop: "2%", marginBottom: "1%" }}
                  >
                    <Chip label="Order Notes" size="large" variant="filled" />
                  </Divider>
                  <OrderNotes
                    controlNumber={"new"}
                    locationNumber={ordersLocation?.locationNumber}
                    orderNotesRef={orderNotesRef}
                  />
                </div>

              </orderContext.Provider>
            </StyledSceneContent>
          </div>
        ):<div  >Please select order type for this Edi Order </div>}
      < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
        <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
          {snackContent.message}
        </Alert>
      </Snackbar>
      <Dialog open={dialogOpen} onClose={handleDialogClose}
        PaperProps={{
          style: {
            width: '600px', // Set the desired width
            height: '400px', // Set the desired height
          },
        }}>

        <DialogTitle>{isAckRejectButton === "Acknowledge" ? "Acknowledge" : "Reject"} EDI Order</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Please provide a ${isAckRejectButton === "Acknowledge" ? "Control No" : "Reason"} and Note to ${isAckRejectButton} the order.`}
          </DialogContentText>
          {isAckRejectButton === "Acknowledge" ? (
            <CustomAutoComplete
              //fetchDataEndpoint="/orders/order-ids/800?reference=testref"
              //queryParams={{ region: "west" }}
              valueChange={(value) => (value!==null)? setControlNo(value): setControlNo('') }
              existingValue={controlNo}
              optionLabel={(option) => `${option.controlNumber}`}
              optionKey="controlNumber"
              placeholder="Search by Control Number.."
              debounceTime={300}
              staticOptions={orderIdsArr}
              allowedPattern={/^\d*$/}
              maxSize={7}
            />
            // <TextField
            //     autoFocus
            //     margin="dense"
            //     label="Control"
            //     type="text"
            //     fullWidth
            //     value={controlNo}
            //     onChange={(e) => setControlNo(e.target.value)}
            // />
          ) :
            (<TextField
              autoFocus
              margin="dense"
              label="Reason"
              type="text"
              fullWidth
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />)}
          <TextField
            margin="dense"
            label="Note"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={note}
            onChange={(e) => setNote(e.target.value)}

          />
          <FormControlLabel
            control={
              <Checkbox
                checked={updateEDI}
                onChange={(e) => setUpdateEDI(e.target.checked)}
                color="primary"
              />
            }
            label={isAckRejectButton === "Acknowledge" ? `Send Accept EDI990 to customer` : `Send Reject EDI990 to customer`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogSubmit} color="primary" disabled={(controlNo==='' && isAckRejectButton === "Acknowledge")?true:false}>
            Submit
          </Button>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationDialogOpen} onClose={handleDialogClose}
        PaperProps={{
          style: {
            width: '500px', // Set the desired width
            height: 'auto', // Set the desired height
          },
        }}>
        <DialogTitle> {selectedLocation && selectedLocation !== "UnAssigned" ? `Do You want to Add Location - ${selectedLocation} To This Edi?` : `UnAssign Location - ${currentOrder?.locationNumber} From This Edi?`}</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmationDialogSubmit} color="primary">
            Yes
          </Button>
          <Button onClick={handleConfirmationDialogClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
      open={showConfirmationDialog}
      onClose={() => setShowConfirmationDialog(false)}
      PaperProps={{
        style: {
            width: '500px', // Set the desired width
            height: 'auto', // Set the desired height
        },
    }}>
    
      <DialogTitle> Do you also want to update EDI990?</DialogTitle>
      <DialogActions>
        <Button onClick={()=>{handleConfirm(false)}}  color="primary">
          No
        </Button>
        <Button onClick={()=>{handleConfirm(true)}} color="primary" >
          Yes
        </Button>
      </DialogActions>
    </Dialog> */}
      <Dialog
        open={showConfirmationDialogPrevRecord}
        onClose={() => setShowConfirmationDialogPrevRecord(false)}
        PaperProps={{
          style: {
            width: '500px', // Set the desired width
            height: 'auto', // Set the desired height
          },
        }}>

        <DialogTitle> First, Perform Operation on the Previous EDI 204</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to open previous EDI 204
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setShowConfirmationDialogPrevRecord(false) }} color="primary">
            No
          </Button>
          <Button onClick={() => { fetchSingleRecordData() }} color="primary" >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleDialogClose2}>
        <DialogTitle>Driver is Assigned</DialogTitle>
        <DialogContent>
          A driver is assigned to Stop {truckAssignStopDetail?.lineNumber} for this order.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpenOrder} color="primary">
            Open Order
          </Button>
          <Button onClick={handleProceedAndAlertDriver} color="primary">
            Yes, Proceed
          </Button>
          <Button onClick={handleDialogClose2} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog open={isAcceptDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>The Order Exists</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want update the existing order?
          </Typography>
        </DialogContent>
        <CustomAutoComplete
              //fetchDataEndpoint="/orders/order-ids/800?reference=testref"
              //queryParams={{ region: "west" }}
              valueChange={(value) => (value!==null)? setControlNo(value): setControlNo('') }
              existingValue={controlNo}
              optionLabel={(option) => `${option.controlNumber}`}
              optionKey="controlNumber"
              placeholder="Search by Control Number.."
              debounceTime={300}
              staticOptions={orderIdsArr}
              allowedPattern={/^\d*$/}
              maxSize={7}
            />
        <DialogActions>
          <Button onClick={()=>handleAcceptDialogConfirm(true)} color="primary" disabled={!controlNo}>
            Yes
          </Button>
          <Button onClick={()=>handleAcceptDialogConfirm(false)} color="secondary">
            No
          </Button>
          <Button onClick={handleAcceptDialogClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
       <Dialog open={isAcceptDialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>
        The Order Exists
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
          Do you want to update the existing order?
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Search by Control Number:
          </Typography>
          <CustomAutoComplete
            valueChange={(value) => (value !== null ? setControlNo(value) : setControlNo(''))}
            existingValue={controlNo}
            optionLabel={(option) => `${option.controlNumber}`}
            optionKey="controlNumber"
            placeholder="Enter Control Number..."
            debounceTime={300}
            staticOptions={orderIdsArr}
            allowedPattern={/^\d*$/}
            maxSize={7}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'center', gap: 2, mt: 2, pb: 2 }}>
        <Button
          onClick={() => handleAcceptDialogConfirm(true)}
          color="primary"
          variant="contained"
          disabled={!controlNo}
          sx={{ minWidth: 100 }}
        >
          Yes
        </Button>
        <Button
          onClick={() => handleAcceptDialogConfirm(false)}
          color="secondary"
          variant="outlined"
          sx={{ minWidth: 100 }}
          disabled={controlNo}
        >
          No
        </Button>
        <Button
          onClick={handleAcceptDialogClose}
          color="inherit"
          variant="text"
          sx={{ minWidth: 100 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    </Paper>



  )
}