import React, { Component, useState, useEffect, useRef, createRef } from "react";
import { Box, Stack, Typography, Snackbar, Alert, Tabs, Tab ,InputAdornment,IconButton, TextField,Button} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ComponentWrapper } from "../../../components/styledComponents";
import DetailedSummary from '../Billing/DetailedSummary';
import SummaryByControl from '../Billing/SummaryByControl';
import {
    getBillingReportData
} from "../../../store/actions/reportActions";
import { getGLCodes } from "../../../store/actions/rateBookActions";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import {
    formatedDateRangeFunc,
    formatDateRange,
    getDateRangeLastYear
} from "../../../utils/global-functions";
import ClearIcon from "@mui/icons-material/Clear";
import LocAutoComplete from "../../../components/AutoComplete/LocAutoComplete";
import { isDateDiffGreaterThan31 } from "../../../utils/global-functions";
import { Portal } from '@mui/material';
import ResponsiveGrid from "../../../components/GridCustomComponent";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function PTPaidNoRevenue({ navigation }) {
    const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const [activeTab, setActiveTab] = useState(0);
    const [snackbar, setSnackbar] = useState(null);
    const [totalRecords, setTotalRecords] = useState([0, 0]);
    const [rawData, setRawData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterParams, setFilterParams] = useState(false);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [showApplyButton, setShowApplyButton] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedLocationList, setSelectedLocationList] = useState([]);
    const [exportButtons, setExportButtons] = useState(null);
    const filterData={
        // sortByControl: false,
        isBillingDateNull : true,
        transmitted : "Y",
        includeVoided :false,
        // sortOrder: "desc",
        // sortBy: "control",
        dateType:"",
        dateRange:dateRange ||""
    }
    useEffect(() => {
        if(ordersLocation?.locationNumber){
            const dateRangeValue=getDateRangeLastYear()
            handleAccept("",dateRangeValue)
            fetchDataFromServer(ordersLocation.locationNumber);
            // dispatch(getGLCodes(true))
        }
    }, [ordersLocation?.locationNumber])
      useEffect(() => {
        setShowApplyButton(true)
      }, [selectedLocationList]);

    function filterRowData(rowData) {
        return rowData
            .filter(row => row.status !== "V") 
            .map(row => ({
                ...row,
                rates: row.rates.filter(rate => (rate.transmitted === "Y" &&  rate.payableReceivable==="P")|| rate.payableReceivable==="R"),
            }));
    }
    
    const fetchDataFromServer = (locationNumber) => {
        if (selectedLocationList.length > 1 && dateRange) {
            const isDateGreaterThan31 = isDateDiffGreaterThan31(dateRange)
            if (isDateGreaterThan31) {
                alert(`When multiple locations are selected, the date range should be less than or equal to 30 days.`);
            }
        }
            setFilterParams(filterData)
            const filterDataToServer={...filterData,dateType:"ptDate"}
            setIsLoading(true)
            dispatch(getGLCodes(true)).then((res) => {
                const selectedLoc=selectedLocationList.length>0?selectedLocationList.map(num => Number(num)):[locationNumber]
                dispatch(getBillingReportData(selectedLoc, filterDataToServer)).then((res) => {
                // dispatch(getBillingReportData(locationNumber, filterDataToServer)).then((res) => {
                    setIsLoading(false)
                    const filteredRowData = filterRowData(res?.billingSummary || []);
                    setRawData(filteredRowData);
                    // setRawData(res?.billingSummary || [])
                    setTotalRecords([res?.data?.billingSummary.length, res?.data?.billingSummary.length])
                    setShowApplyButton(false);
                }).catch((err) => setIsLoading(false))
            })
        }
        const handleClosePicker = () => {
            setIsPickerOpen(false); // Close picker
        };
        const handleAccept = (index, dateValue) => {
                const { formattedStartDate, formattedEndDate } = formatDateRange(dateValue);
                const formatDate = formattedStartDate + "|" + formattedEndDate;        
                setDateRange([formattedStartDate, formattedEndDate])
                setSelectedDate(formatDate);
                filterData.dateRange=[formattedStartDate, formattedEndDate]
                handleClosePicker();
                setShowApplyButton(true);
            };
    return (
        <ComponentWrapper>
          <Box
            sx={{
              padding: 1,
              position: 'relative',
            }}
          >
            <Box mt={1} sx={{  display: 'flex', justifyContent: 'start', alignItems: 'center', overflowX: 'auto', whiteSpace: 'nowrap',marginBottom:2 }}>
              <ResponsiveGrid gridValues={[4, 4,2,2]}>
                <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                  <Tab
                    label="Summary By Control#" id='simple-tab-0'
                    sx={(activeTab === 0) ? {
                      backgroundColor: "#0911A7",
                      color: "white !important",
                      fontWeight: 500,
                      textTransform: 'capitalize',
                      borderRadius: '8%'
                    } : {}}
                  />
                  <Tab
                    label="Detail by Control #" id='simple-tab-1'
                    sx={(activeTab === 1) ? {
                      backgroundColor: "#0911A7",
                      color: "white !important",
                      fontWeight: 500,
                      textTransform: 'capitalize',
                      borderRadius: '8%'
                    } : {}}
                  />
                </Tabs>
                <Box sx={{ display: "flex" ,mt:1 }}>
                <LocAutoComplete
                seletedLocationList={selectedLocationList}
                setSelectedLocationList={setSelectedLocationList}
                maxDisplayTags={4}
              />
              </Box>
                <Box sx={{ display: "flex" ,mt:1 }}>
              
                  <Stack direction="column" style={{ position: 'relative' }} >
                    <TextField
                      autoComplete="off"
                      label="Date Range"
                      disablePortal
                      size="small"
                      variant="outlined"
                      id="combo-box-demo"
                      value={formatedDateRangeFunc(selectedDate) || ""}
                      onClick={() => setIsPickerOpen(true)}
                      readOnly // Makes the field read-only, only clickable
                      style={{ cursor: "pointer", width: "80%" }} // Styling for better UI
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear date"
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent calendar from opening
                                setSelectedDate(null); // Clear the date
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      onClick={() => { fetchDataFromServer(ordersLocation.locationNumber) }}
                      disabled={(selectedDate && showApplyButton) ? false : true}
                    >Apply</Button>
                  </Stack>
                 
                </Box>
                <div style={{paddingTop:10}}>
                {exportButtons} {/* Render buttons here */}
            </div>
              </ResponsiveGrid>
            </Box>
            <Box>
              <CustomTabPanel value={activeTab} index={0}>
                <SummaryByControl
                  setTotalRecords={val => setTotalRecords(val)}
                  rawData={rawData}
                  locationNumber={ordersLocation?.locationNumber || 0}
                  isLoading={isLoading}
                  filterParams={filterParams}
                  renderExportButtons={setExportButtons}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={1}>
                <DetailedSummary
                  setTotalRecords={val => setTotalRecords(val)} activeTab={activeTab}
                  rawData={rawData}
                  locationNumber={ordersLocation?.locationNumber || 0}
                  isLoading={isLoading}
                  filterParams={filterParams}
                  renderExportButtons={setExportButtons}
                />
              </CustomTabPanel>
            </Box>
          </Box>
          {isPickerOpen && (
            <Portal>
              <div
                style={{
                  position: "fixed",
                  top: "50px", // Adjust this value as needed
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 10000, // Higher zIndex for date picker
                  background: "#fff",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CustomDateRangePicker
                  indexValue={0}
                  defaultDateRangeValue={selectedDate ? selectedDate : ""}
                  onDateSelect={handleAccept}
                  handleCloseDatePicker={handleClosePicker}
                  isRestrict={1}
                  maxDays={selectedLocationList.length>1? 31:null}
                />
              </div>
            </Portal>
          )}
          {
            !!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setSnackbar(null)}
                autoHideDuration={3000}
              >
                <Alert {...snackbar} onClose={() => setSnackbar(null)} />
              </Snackbar>
            )
          }
        </ComponentWrapper>
    );
}