import React from "react";
import { Grid } from "@mui/material";

const ResponsiveGrid = ({ children, gridValues,center }) => {
  return (
    <Grid container spacing={2}
     sx={center ? { justifyContent: "center" } : {}}// Center items if 'center' is true
       >
      {React.Children.toArray(children).map((child, index) =>
        child ? ( // Ensure non-null children are rendered
          <Grid
            item
            xs={12} // Full width on mobile
            sm={gridValues[index] || 12} // Use provided value or default to full width
            lg={gridValues[index] || 12}
            // sx={index === 0 ? { ml: 1 } : {}} 
          >
            {child}
          </Grid>
        ) : null
      )}
    </Grid>
  );
};

export default ResponsiveGrid;

