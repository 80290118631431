/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import {
    Stack,
    Box,
    Button,
    Typography,
    FormLabel,
    MenuItem,
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,
    Tooltip
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TraceWrapper } from "../../components/styledComponents";
import {
    DataGridPro,
    GridToolbar,
    GridActionsCellItem,
    GridRowModes,
    GridCellModes,
    // useGridApiRef,
    GridToolbarQuickFilter,
    DEFAULT_GRID_AUTOSIZE_OPTIONS,
    GridToolbarContainer,
    GridToolbarFilterButton,
    gridFilteredSortedRowEntriesSelector
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { getTraceData, bulkEditReq } from "../../store/actions/traceActions";

// import custom date picker
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomTimePicker from '../../components/CustomTimePicker';
import PickupPointAutoComplete from './components/PickupPointAutoComplete';
import ShipperAutoComplete from './components/ShipperAutoComplete';
import moment from 'moment';
import {
    sanitizeStringForDBField,
    convertYMDToMDY,
    convertMDYToYMD,
    formatDateTime,
} from '../../utils/global-functions';

function CustomToolbar({ setFilterButtonEl }) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton ref={setFilterButtonEl} />
        </GridToolbarContainer>
    );
}


export default function Inbound({ navigation,isDomesticChecked,
    rowSelectionModel,
    setRowSelectionModel,
    hasUnsavedRows,
    setHasUnsavedRows,
    sortModel,
    setSortModel,
    setIsDomesticChecked,
    snackOpen,
    setSnackOpen,
    snackContent,
    setSnackContent,
    unsavedChangesRef,
    shipperArrObj ,
    setShipperArrObj,isLoading,apiRef,rows,setRows,isSaving}) {
    // const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const [filterButtonEl, setFilterButtonEl] = useState(null);

    const adjustColumnSize = () => {
        if (apiRef.current) {
            apiRef.current.autosizeColumns({
                includeHeaders: false,
                includeOutliers: true,
            })
        }
    }
    /**
     * Update apiRef for edited fields
     * @param {*} newRow 
     * @param {*} oldRow 
     * @returns 
     */
    const processRowUpdate = (
        newRow,
        oldRow,
    ) => {
        const rowId = newRow.id;

        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasUnsavedRows(true);
        adjustColumnSize()
        return newRow;
    };

    /**
     * Hanlde orting
     * @param {*} props 
     * @returns 
     */
    const handleSorting = (field) => {

        const existingSortingIndex = sortModel.findIndex((item) => item.field === field);
        let newSorting = [];

        if (existingSortingIndex !== -1) {
            // Toggle sorting direction if column is already being sorted
            newSorting = [...sortModel];
            newSorting[existingSortingIndex] = {
                ...sortModel[existingSortingIndex],
                sort: sortModel[existingSortingIndex].sort === 'asc' ? 'desc' : 'asc',
            };
        } else {
            // Add new column to sorting
            newSorting = [...sortModel, { field, sort: 'asc' }];
        }
        setSortModel(newSorting);
    };

    const CustomEditTextFieldComponent = (props) => {
        const { id, value, field, hasFocus, fieldLength } = props;
        const ref = useRef();

        useLayoutEffect(() => {
            if (hasFocus) {
                ref.current.focus();
            }
        }, [hasFocus]);

        return (
            <TextField
                variant="standard"
                style={{
                    width: 120,
                }}
                onChange={(e) => {
                    switch (field) {
                        case 'unit':
                            apiRef.current.setEditCellValue({
                                id, field, value: e.target.value.toUpperCase().replace(/\s/g, '')
                            })
                            break;
                        case 'chassis':
                            apiRef.current.setEditCellValue({
                                id, field, value: sanitizeStringForDBField(e.target.value.toUpperCase(), 15, true)
                            })
                            break;
                        default:
                            apiRef.current.setEditCellValue({
                                id, field, value: e.target.value.toUpperCase()
                            })
                            break;
                    }

                }

                }
                value={(value === 0) ? '' : value}
                inputProps={{ style: { textTransform: "uppercase" }, maxLength: fieldLength }}
                inputRef={ref}
            />
        )
    }

    /**
     * Table column fields
     */
    const tableColumns = [
        {
            field: 'control',
            headerName: 'Control',
            editable: false,
            filterable: false,
            renderCell: (params) => (<a href={`/orders/${ordersLocation.locationNumber}/${params.row.control}`} target='_blank' >{params.row.control}</a>),
            minWidth: 110,
            flex: 1
        },
        {
            field: 'unit',
            headerName: 'Unit',
            editable: true,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={13} />
            ),
            minWidth: 130,
            flex: 1
        },
        // {
        //     field: 'apptDate',
        //     headerName: 'Appt',
        //     editable: true,
        //     filterable: false,
        //     renderCell: (params) => `${params.row.apptDate}`,
        //     renderEditCell: (params) => (
        //         <CustomDatePicker
        //             //label="Appt Date"
        //             size="small"
        //             className="width_100"
        //             variant="standard"
        //             value={params.row.apptDate || ''}
        //             onValueChange={(selectedVal) => {
        //                 params.api.setEditCellValue({
        //                     id: params.id,
        //                     field: params.field,
        //                     value: convertYMDToMDY(selectedVal)
        //                 })
        //             }}
        //             hasFocus={params.hasFocus}
        //             isArrowKeyDisabled={true}
        //         />
        //     ),
        //     minWidth: 130,
        //     flex: 1,
        //     valueGetter: (params) => {
        //         // Combine apptDate and apptTime into a single ISO string or Date object
        //         const datePart = convertMDYToYMD(params.row.apptDate);
        //         return datePart;
        //         //const timePart = params.row.apptTime || '00:00:00'; // Default to midnight if no time
        //         //return datePart && timePart ? new Date(`${datePart}T${timePart}`) : null;
        //     },
        //     sortComparator: (v1, v2) => {
        //         // Ensure values are valid Date objects before comparing
        //         const date1 = v1 instanceof Date ? v1 : new Date(v1);
        //         const date2 = v2 instanceof Date ? v2 : new Date(v2);
        //         return date1 - date2;
        //     },
        // },
        {
            field: 'apptDate',
            headerName: 'Appt Date',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                // Always display in MM/DD/YYYY format
                return params.row.apptDate ? convertYMDToMDY(params.row.apptDate) : '';
            },
            renderEditCell: (params) => (
                <CustomDatePicker
                    size="small"
                    className="width_100"
                    variant="standard"
                    value={params.row.apptDate ? convertMDYToYMD(params.row.apptDate) : ''}
                    onValueChange={(selectedVal) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal) // Ensure MM/DD/YYYY format
                        });
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 130,
            flex: 1,
            valueGetter: (params) => {
                // Combine apptDate and apptTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.apptDate);
                return datePart;
                //const timePart = params.row.apptTime || '00:00:00'; // Default to midnight if no time
                //return datePart && timePart ? new Date(`${datePart}T${timePart}`) : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        
        {
            field: 'apptTime',
            headerName: 'Appt Time',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={8} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'puloc',
            headerName: 'P/U',
            editable: true,
            renderEditCell: (params) => (
                <PickupPointAutoComplete
                    existingValue={params.row.puloc}
                    valueChange={(selectedVal) => params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: selectedVal
                    })}
                    hasFocus={params.hasFocus}
                />
            ),
            minWidth: 120,
            flex: 1
        },
        {
            field: 'dispLoc',
            headerName: 'Disp',
            editable: false,
            hide: true,
            // renderEditCell: (params) => (
            //     <PickupPointAutoComplete
            //         existingValue={params.row.dispLoc}
            //         valueChange={(selectedVal) => params.api.setEditCellValue({
            //             id: params.id,
            //             field: params.field,
            //             value: selectedVal
            //         })}
            //         hasFocus={params.hasFocus}
            //     />
            // ),
            // minWidth: 120,
            // flex: 1
        },
        {
            field: 'etaDate',
            headerName: 'ETA Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                // Ensure etaDate and etaTime are displayed together, even if no change
                const formattedDate = params.row.etaDate ? formatDateTime(params.row.etaDate, params.row.etaTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                // Convert the MM/DD/YYYY date and time to ISO format for the picker
                const isoFormattedDate = `${convertMDYToYMD(params.row.etaDate)}T${params.row.etaTime}`;
                
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            // When the value is changed, format the date and time
                            const formattedEtaDate = convertYMDToMDY(selectedVal.eDate); // Convert to MM/DD/YYYY
                            const etaTime = selectedVal.eTime;
        
                            // Update both etaDate and etaTime fields
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedEtaDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'etaTime',
                                value: etaTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine apptDate and apptTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.etaDate);
                const timePart = params.row.etaTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },      
        
        {
            field: 'etaTime',
            headerName: 'ETA Time',
            editable: true,
            filterable: false,
            hide: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTimePicker
            //         //label="ETA Time"
            //         variant="standard"
            //         size="small"
            //         className="width_100"
            //         value={params.row.etaTime || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: selectedVal
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //     />
            // )
        },
        {
            field: 'orderBy',
            headerName: 'Order By',
            editable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'shipperName',
            headerName: 'Shipper',
            editable: true,
            renderEditCell: (params) => (
                <ShipperAutoComplete
                    existingValue={params.row.shipperName}
                    locationNumber={ordersLocation?.locationNumber || 0}
                    valueChange={(selectedVal) => {

                        if (selectedVal !== null) {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: selectedVal.name
                            });
                            let newShipperItem = {
                                ...shipperArrObj,
                                [params.id]: {
                                    code: sanitizeStringForDBField(selectedVal.code, 7),
                                    name: sanitizeStringForDBField(selectedVal.name, 25),
                                    address: sanitizeStringForDBField(selectedVal.address, 25),
                                    city: sanitizeStringForDBField(selectedVal.city, 25),
                                    state: sanitizeStringForDBField(selectedVal.state, 2),
                                    zip: sanitizeStringForDBField(selectedVal.zip, 10),
                                    phone: sanitizeStringForDBField(selectedVal.phone, 13),
                                    fax: sanitizeStringForDBField(selectedVal.fax, 13),
                                }
                            }
                            setShipperArrObj(newShipperItem)
                        } else {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: ''
                            });
                            let newShipperItem = {
                                ...shipperArrObj,
                                [params.id]: {
                                    code: '',
                                    name: '',
                                    address: '',
                                    city: '',
                                    state: '',
                                    zip: '',
                                    phone: '',
                                    fax: ''
                                }
                            }
                            setShipperArrObj(newShipperItem)
                        }
                    }
                    }
                    hasFocus={params.hasFocus}
                />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'consigneeName',
            headerName: 'Consignee',
            editable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'city',
            headerName: 'City',
            editable: false,
            filterable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'state',
            headerName: 'State',
            editable: false,
            filterable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'release',
            headerName: 'Release',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={10} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'booking',
            headerName: 'Booking #',
            editable: false,
            hide:true
            // filterable: false,
            // renderEditCell: (params) => (
            //     <CustomEditTextFieldComponent {...params} fieldLength={10} />
            // ),
            // minWidth: 130,
            // flex: 1
        },
        {
            field: 'notifyDate',
            headerName: 'Notify Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.notifyDate ? formatDateTime(params.row.notifyDate, params.row.notifyTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.notifyDate)}T${params.row.notifyTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedNotifyDate = convertYMDToMDY(selectedVal.eDate);
                            const notifyTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedNotifyDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'notifyTime',
                                value: notifyTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine notifyDate and notifyTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.notifyDate);
                const timePart = params.row.notifyTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        
        {
            field: 'notifyTime',
            headerName: 'Notify Time',
            editable: true,
            filterable: false,
            hide: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTimePicker
            //         //label="Notify Time"
            //         variant="standard"
            //         size="small"
            //         className="width_100"
            //         value={params.row.notifyTime || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: selectedVal
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //     />
            // )
        },
        {
            field: 'lfd',
            headerName: 'LFD Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                const formattedDate = params.row.lfd ? formatDateTime(params.row.lfd, params.row.lfdTime) : '';
                return formattedDate;
            },
            renderEditCell: (params) => {
                const isoFormattedDate = `${convertMDYToYMD(params.row.lfd)}T${params.row.lfdTime}`;
        
                return (
                    <CustomDateTimePicker
                        variant="standard"
                        size="small"
                        className="width_100"
                        value={isoFormattedDate || ''}
                        onValueChange={(selectedVal) => {
                            const formattedLfdDate = convertYMDToMDY(selectedVal.eDate);
                            const lfdTime = selectedVal.eTime;
        
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: formattedLfdDate, // MM/DD/YYYY format
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: 'lfdTime',
                                value: lfdTime, // HH:mm
                            });
                        }}
                        hasFocus={params.hasFocus}
                        isArrowKeyDisabled={true}
                    />
                );
            },
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
                // Combine apptDate and apptTime into a single ISO string or Date object
                const datePart = convertMDYToYMD(params.row.lfd);
                const timePart = params.row.lfdTime || '00:00:00'; // Default to midnight if no time
                return datePart && timePart ? `${datePart}T${timePart}` : null;
            },
            sortComparator: (v1, v2) => {
                // Ensure values are valid Date objects before comparing
                const date1 = v1 instanceof Date ? v1 : new Date(v1);
                const date2 = v2 instanceof Date ? v2 : new Date(v2);
                return date1 - date2;
            },
        },
        
        {
            field: 'lfdTime',
            headerName: 'LFD Time',
            editable: true,
            filterable: false,
            hide: true,
            hideable: false,
            // renderEditCell: (params) => (
            //     <CustomTimePicker
            //         //label="Notify Time"
            //         variant="standard"
            //         size="small"
            //         className="width_100"
            //         value={params.row.notifyTime || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: selectedVal
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //     />
            // )
            flex: 1
        },
        {
            field: 'chassis',
            headerName: 'Chassis',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={15} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'valid',
            headerName: 'Valid',
            editable: false,
            filterable: false,
            minWidth: 100,
            flex: 1
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            editable: true,
            filterable: false,
            minWidth: 130,
            flex: 1,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={25} />
            ),
        },
        {
            field: 'voyage',
            headerName: 'Voyage',
            editable: true,
            filterable: false,
            minWidth: 130,
            flex: 1,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={5} />
            ),
        }
    ]

    /**
     * As per the requirement overriding default tab key behaviour for mui datagrid
     */
    const handleKeyDown = (params, event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            moveFocusToNextColumn(params);
        }
    }

    const moveFocusToNextColumn = (params) => {
        let currentColumnIndex = tableColumns.findIndex((col) => col.field === params.field);
        let nextColumnIndex = currentColumnIndex + 1;

        // Find the next visible column
        while (nextColumnIndex < tableColumns.length && tableColumns[nextColumnIndex].hide) {
            nextColumnIndex++;
        }

        // Wrap around to the first visible column if needed
        if (nextColumnIndex >= tableColumns.length) {
            nextColumnIndex = 0;
            while (nextColumnIndex < tableColumns.length && tableColumns[nextColumnIndex].hide) {
                nextColumnIndex++;
            }
        }

        if (nextColumnIndex < tableColumns.length) {
            const nextColumnField = tableColumns[nextColumnIndex].field;
            apiRef.current.setCellFocus(params.id, nextColumnField);
        }
    };

    return (
        <TraceWrapper>
           <Stack spacing={2}>
                 <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Stack
                        //marginTop={1}
                        sx={{
                            height: window.innerHeight - Number(165),
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: "rgba(11, 67, 135, 1)",
                                color: "rgba(255, 255, 255, 255)",
                            },
                            '& .non-editable': {
                                backgroundColor: "#69696929 !important"
                            },
                            '& .MuiDataGrid-sortIcon': {
                                color: "rgba(255, 255, 255, 255)",
                            },
                            '& .MuiDataGrid-menuIconButton': {
                                color: "rgba(255, 255, 255, 255)",
                            },
                            // '& .MuiIconButton-root': {
                            //     color: "rgba(255, 255, 255, 255)"
                            // },
                            '& .highLightWithRed > .MuiDataGrid-cellContent': {
                                color: "red",
                                fontWeight: "bold"
                            },
                            '& .highLightWithYellow > .MuiDataGrid-cellContent': {
                                backgroundColor: "yellow",
                                color: "rgba(0, 0, 0, 0.87)",
                            },
                            // '& .MuiDataGrid-toolbarContainer': {
                            //     marginTop: "-82px",
                            //     marginBottom: "35px",
                            //     marginLeft: "100px"
                            // }
                            '& .css-a713xs-MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
                                backgroundColor: 'rgb(25 118 210 / 23%)'
                            }
                        }}
                    >
                        <DataGridPro
                            hideFooter={true}
                            hideFooterRowCount={false}
                            // columnHeaderHeight={30}
                            // rowHeight={30}
                            editMode="row"
                            rows={rows}
                            columns={tableColumns}
                            loading={isLoading || isSaving}
                            apiRef={apiRef}
                            //disableRowSelectionOnClick
                            //cellSelection
                            processRowUpdate={processRowUpdate}
                            // ignoreValueFormatterDuringExport
                            sx={{
                                '& .MuiDataGrid-row.row--removed': {
                                    backgroundColor: (theme) => {
                                        return 'rgba(255, 170, 170, 0.3)';
                                    },
                                },
                                '& .MuiDataGrid-row.row--edited': {
                                    backgroundColor: (theme) => {
                                        return 'rgba(255, 254, 176, 0.3)';
                                    },
                                },
                            }}
                            getRowClassName={({ id }) => {
                                const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                                if (unsavedRow) {
                                    if (unsavedRow._action === 'delete') {
                                        return 'row--removed';
                                    }
                                    return 'row--edited';
                                }
                                return '';
                            }}
                            getCellClassName={(params) => {
                                const regex = /^[A-Za-z]{4}[0-9]{5,7}$/;
                                // if (!regex.test(params.row?.unit) && params?.field === "unit") {
                                //     return 'highLightWithRed';
                                // }

                                if ((convertMDYToYMD(params.row.apptDate) > convertMDYToYMD(params.row.lfd)) && params?.field === "lfd") {
                                    return 'highLightWithRed';
                                }
                                if (params.row.lfdYellowFlag && params?.field === "lfd") {
                                    return 'highLightWithYellow';
                                }
                            }}
                            // checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            // filterMode={filterModel}
                            // onFilterModelChange={(model) => setFilterModel(model)}
                            onSortModelChange={adjustColumnSize}
                            slotProps={{
                                panel: {
                                    anchorEl: filterButtonEl,
                                },
                                toolbar: {
                                    setFilterButtonEl,
                                },
                            }}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        notifyTime: false,
                                        lfdTime: false,
                                        etaTime: false,
                                        booking:false,
                                        dispLoc:false
                                    },
                                }
                            }}
                            onColumnHeaderClick={(column) => handleSorting(column.field)}
                            sortModel={sortModel}
                            onCellKeyDown={handleKeyDown}
                        //onSortModelChange={handleSorting}
                        />
                    </Stack>
                </Box>
            </Stack > 
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackOpen(false)} >
                <Alert onClose={() => setSnackOpen(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </TraceWrapper >
    )
}