// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { formatCurrency, formatPercentageNum } from '../../../../utils/global-functions';

// const PTReportPDF = (rows,processDate,selectedTruck,selectedVendor) => {
//     console.log("rows", rows);
    
//     const generatePDF = () => {
//         if (!rows || rows.length === 0) {
//             console.error("No data available to generate PDF.");
//             return;
//         }

//         const doc = new jsPDF({
//             orientation: 'landscape',
//             unit: 'mm',
//             format: 'a4',
//             hotfixes: ['pxsplit'] // To prevent split issue in large tables
//         });

//         // Title Section
//         doc.setFontSize(12);
//         const title = "Purchase Transportation Report";
//         const titleWidth = doc.getTextWidth(title);
//         const pageWidth = doc.internal.pageSize.getWidth();
//         const titleX = (pageWidth - titleWidth) / 2;
//         let currentTruck = null;
//         rows.forEach((row, index) => {
//             if (row.truck !== currentTruck) {
//                 if (index !== 0) {
//                     doc.addPage();
//                 }
                
//                 currentTruck = row.truck;

//                 // Title Section for new page
//                 doc.setFontSize(12);
//                 doc.text(title, titleX, 20);
//                 doc.text(`Processed Date: ${processDate}`, 14, 30);
//                 doc.text(`Vendor #${selectedVendor ? selectedVendor : "ALL"} | Truck # ${currentTruck || "ALL"}`, 14, 40);
//             }

//         // doc.text(title, titleX, 20);
//         // doc.text(`Processed Date: ${processDate}`, 14, 30);
//         // doc.text(`Vendor #${selectedVendor?selectedVendor:"ALL"} | Truck # ${selectedTruck?selectedTruck:"ALL"}`, 14, 40);

//         // Prepare table body
//         // const tableData = rows.map((row) => [
//              // Prepare table data for current page (filtered by truck)
//              const tableData = rows
//              .filter(r => r.truck === currentTruck)
//              .map(row => [
//             row.vendor || "",
//             row.truck || "",
//             row.controlNumber || "",
//             row.unit || "",
//             row.status || "",
//             row.transmitted || "",
//             row.description || "",
//             row.processedDate || "",
//             (!isNaN(row.rate))?formatCurrency(row.rate):row.rate || "",
//             row.unitValue || "",
//             (!isNaN(row.amount))?formatCurrency(row.amount):row.amount || "",
//             row.pt || ""
//         ]);

//         // AutoTable configuration for the PDF
//         doc.autoTable({
//             startY: 50,
//             head: [["Vendor #", "Truck #", "Control #", "Unit #", "Status", "Transmitted", "Payable Description", "Processed Date", "Rate", "Unit", "Amount"]],
//             body: tableData,
//             styles: {
//                 fontSize: 8,
//                 cellPadding: 2,
//                 overflow: 'linebreak',
//             },
//             headStyles: {
//                 fillColor: [255, 255, 255],
//                 textColor: [0, 0, 0], // Header text color (black)
//             },
//             columnStyles: {
//                 0: { cellWidth: 20 },
//                 1: { cellWidth: 20 },
//                 2: { cellWidth: 25 },
//                 3: { cellWidth: 35 },
//                 4: { cellWidth: 20 },
//                 5: { cellWidth: 20 },
//                 6: { cellWidth: 40 },
//                 7: { cellWidth: 25 },
//                 8: { cellWidth: 15 },
//                 9: { cellWidth: 30 },
//                 10: { cellWidth: 20 },
//                 // 11: { cellWidth: 20 },
//             },
//             margin: { top: 50 },
//             theme: 'grid',
//             didParseCell: (data) => {
//                 // Check if the row corresponds to the data being processed
//                 const rowIndex = data.row.index;
//                 if (rows[rowIndex] && rows[rowIndex].isSubtotal) {
//                     data.cell.styles.fillColor = [232, 232, 232]; // Grey background
//                 }
//             },
//         });
//     });

//         // Save the PDF
//         doc.save("pt-purchase-transportation-report.pdf");
//     };

//     return generatePDF;
// };

// export default PTReportPDF;

import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatCurrency, formatPercentageNum } from '../../../../utils/global-functions';

const PTReportPDF = (rows, processDate, selectedTruck, selectedVendor) => {
    console.log("rows", rows);
    
    const generatePDF = () => {
        if (!rows || rows.length === 0) {
            console.error("No data available to generate PDF.");
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            hotfixes: ['pxsplit'], // To prevent split issue in large tables
        });

        // Title Section
        doc.setFontSize(12);
        const title = "Purchase Transportation Report";
        const titleWidth = doc.getTextWidth(title);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth - titleWidth) / 2;

        // Find unique trucks
        const uniqueTrucks = [...new Set(rows.map(row => row.truck))];

        uniqueTrucks.forEach((truck, truckIndex) => {
            // Filter rows for the current truck
            const truckRows = rows.filter(row => row.truck === truck);

            // Skip empty truck data
            if (!truckRows.length) return;

            // Add a new page for each truck after the first one
            if (truckIndex !== 0) {
                doc.addPage();
            }

            // Title Section for new page
            doc.setFontSize(12);
            doc.text(title, titleX, 20);
            doc.text(`Processed Date: ${processDate}`, 14, 30);
            doc.text(`Vendor #${selectedVendor ? selectedVendor : "ALL"} | Truck # ${truck || "ALL"}`, 14, 40);

            // Prepare table data for current page (filtered by truck)
            const tableData = truckRows.map(row => [
                row.location || "",
                row.vendor || "",
                row.truck || "",
                row.controlNumber || "",
                row.unit || "",
                row.status || "",
                row.transmitted || "",
                row.description || "",
                row.processedDate || "",
                (!isNaN(row.rate)) ? formatCurrency(row.rate) : row.rate || "",
                row.unitValue || "",
                (!isNaN(row.amount)) ? formatCurrency(row.amount) : row.amount || "",
                row.pt || ""
            ]);

            // AutoTable configuration for the PDF
            doc.autoTable({
                startY: 50,
                head: [["Loc #","Vendor #", "Truck #", "Control #", "Unit #", "Status", "Transmitted", "Payable Description", "Processed Date", "Rate", "Unit", "Amount"]],
                body: tableData,
                styles: {
                    fontSize: 8,
                    cellPadding: 2,
                    overflow: 'linebreak',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0], // Header text color (black)
                },
                columnStyles: {
                    0: { cellWidth: 20 },
                    1: { cellWidth: 20 },
                    2: { cellWidth: 20 },
                    3: { cellWidth: 20 },
                    4: { cellWidth: 25 },
                    5: { cellWidth: 20 },
                    6: { cellWidth: 20 },
                    7: { cellWidth: 35 },
                    8: { cellWidth: 25 },
                    9: { cellWidth: 15 },
                    10: { cellWidth: 30 },
                    11: { cellWidth: 20 },
                    
                },
                margin: { top: 50 },
                theme: 'grid',
                didParseCell: (data) => {
                    const rowData = truckRows[data.row.index];
                    if (rowData && rowData.isSubtotal) {
                        data.cell.styles.fillColor = [232, 232, 232]; // Grey background
                    }
                },
            });
        });

        // Save the PDF
        doc.save("pt-purchase-transportation-report.pdf");
    };

    return generatePDF;
};

export default PTReportPDF;
