import jsPDF from "jspdf";
import "jspdf-autotable";
import { convertMilitaryDateToDDMMYY, formatCurrency, formatPercentageNum } from '../../../utils/global-functions';

const ExportSummaryByControl = (rows, tableColumns) => {
    const generatePDF = () => {
        if (!rows || rows.length === 0) {
            alert("No data available to generate PDF.");
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a3', // Use larger page size
            hotfixes: ['pxsplit']
        });

        // Title Section
        doc.setFontSize(14);
        const title = "Summary By Control# Report";
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.text(title, pageWidth / 2, 10, { align: "center" });

        // Table Data Preparation
        const tableData = rows.map((row) => [
            row.locationNumber || "",
            row.controlNumber || "",
            row.totalLoads || "",
            row.customerName || "",
            row.billToCode || "",
            convertMilitaryDateToDDMMYY(row.billingDate) || "",
            row.reference || "",
            row.unitNumber || "",
            row.io || "",
            convertMilitaryDateToDDMMYY(row.apptDate) || "",
            row.PULoc || "",
            row.dispositionLoc || "",
            convertMilitaryDateToDDMMYY(row.returnDate) || "",
            row.shipperName || "",
            row.shipperAddress || "",
            row.consigneeName || "",
            row.consigneeAddress || "",
            formatCurrency(row.revenue) || "",
            formatCurrency(row.miscRevenue) || "",
            formatCurrency(row.totalRevenue) || "",
            formatCurrency(row.totalNet) || "",
            formatPercentageNum(row.totalPercent) || "",
            formatCurrency(row.expense) || "",
            formatCurrency(row.miscExpense) || "",
            formatCurrency(row.totalExpense) || "",
            formatCurrency(row.ptNet) || "",
            formatPercentageNum(row.ptPercent) || ""
        ]);

        const columnData = tableColumns.map(column => column.headerName);

        // Configure AutoTable
        doc.autoTable({
            startY: 20,
            head: [columnData],
            body: tableData,
            styles: {
                fontSize: 7, // Smaller font size to fit more columns
                cellPadding: 1, // Reduce padding for compactness
                overflow: 'linebreak',
                halign: "center" // Horizontal alignment
            },
            headStyles: {
                fontSize: 8,
                fillColor: [200, 200, 200],
                textColor: [0, 0, 0]
            },
            columnStyles: {
                // Adjust column widths proportionally
                0: { cellWidth: 20 }, // Control Number
                1: { cellWidth: 15 }, // Total Loads
                2: { cellWidth: 30 }, // Customer Name
                3: { cellWidth: 20 }, // Bill To Code
                4: { cellWidth: 20 }, // Billing Date
                // Add similar rules for all columns
            },
            margin: { top: 20, left: 10, right: 10 },
            theme: 'grid',
            willDrawCell: (data) => {
                if (data.section === 'head') {
                    doc.setFontSize(6);
                }
            },
            didDrawPage: (data) => {
                // Add page footer
                const pageCount = doc.internal.getNumberOfPages();
                doc.setFontSize(8);
                doc.text(
                    `Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`,
                    pageWidth - 20,
                    doc.internal.pageSize.getHeight() - 10
                );
            }
        });

        // Save the PDF
        doc.save(`Summary-by-control-report.pdf`);
    };

    return generatePDF;
};

export default ExportSummaryByControl;
