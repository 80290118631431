import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";


export default function BillToOrderByForm({ orderByFormRef }) {
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(orderByFormRef?.current);
    
  }, [orderByFormRef]);
  function correctSpaceFormat(value) {
    // Replace any non-standard whitespace characters with regular spaces
    return value.replace(/\s+/g, ' ');
}

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    //let newValue = correctSpaceFormat(value.toString().toUpperCase()); // Convert value to uppercase 
    let newValue = correctSpaceFormat((value ?? "").toString().toUpperCase());
    // Validate the length based on the field name
    const maxCharacterLength = {
      code: 5,
      customerHash:25,
      location:25,
      status:25,
      COD:25,
      name: 25,
      address: 25,
      city: 25,
      state: 2,
      zip: 10,
      phone: 13,
      fax: 13,
      internalNote: 75,
      notes1: 25,
      notes2: 25,
      notes3: 25,
    };

    if (newValue.length > maxCharacterLength[name]) {
      newValue = newValue.slice(0, maxCharacterLength[name]); // Truncate the value if it exceeds the limit
    }

    // Update the state with the validated value
    setFormData({
      ...formData,
      [name]: newValue,
    });
    // Update the ref with the validated value
    orderByFormRef.current = {
      ...orderByFormRef.current,
      [name]: newValue,
    };
  };
  
 
 
  return (
    <>
      <form style={{ margin: "10px" }}>
     
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
    <div style={{ flex: 3, marginRight: "10px" }}>
        <InputLabel
            htmlFor="code"
            style={{
                color: "black",
                // marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
           Order By Code
        </InputLabel>
        <TextField
            id="code"
            name="code"            
            value={(formData?.code ?? "").toString().toUpperCase()}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            InputLabelProps={{ style: { color: "black" } }}
            disabled={errors.code}
        />
    </div>
    <div style={{ flex: 3, marginRight: "10px" }}>
        <InputLabel
            htmlFor="customerHash"
            style={{
                color: "black",
                // marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
           Bill To #
        </InputLabel>
        <TextField
            id="customerHash"
            name="customerHash"
            value={formData.customerHash|| ""}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            InputLabelProps={{ style: { color: "black" } }}
            disabled={errors.customerHash}
        />
    </div>
    <div style={{ flex: 3, marginRight: "10px" }}>
        <InputLabel
            htmlFor="status"
            style={{
                color: "black",
                // marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
           Status
        </InputLabel>
        <TextField
            id="status"
            name="status"
            value={(formData?.status ?? "").toString().toUpperCase()}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            InputLabelProps={{ style: { color: "black" } }}
            disabled={errors.status}
        />
    </div>
    <div style={{ flex: 2 }}>
        <InputLabel
            htmlFor="COD"
            style={{
                color: "black",
                // marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
            COD
        </InputLabel>
    
       
        <TextField
          id="COD"
          name="COD"
          value={(formData?.COD ?? "").toString().toUpperCase()}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.COD}
         
          InputProps={{
            style: {
              backgroundColor:formData.COD?"red":"",
                color: formData.COD ? "white" : "",
            },
        }}
        />
    </div>
    
</div>   
        <InputLabel
          htmlFor="name"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Name
        </InputLabel>
        <TextField
          id="name"
          name="name"
          value={(formData?.name ?? "").toString().toUpperCase()}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.name}
        />
         <InputLabel
          htmlFor="location"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Location
        </InputLabel>
        <TextField
          id="location"
          name="location"
          value={(formData?.location ?? "").toString().toUpperCase()}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.location}
        />

        <InputLabel
          htmlFor="address"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Address
        </InputLabel>
        <TextField
          id="address"
          name="address"   
          value={(formData?.address ?? "").toString().toUpperCase()}     
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.address}
        />

        <InputLabel
          htmlFor="city"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          City
        </InputLabel>
        <TextField
          id="city"
          name="city"
          value={(formData?.city ?? "").toString().toUpperCase()}    
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.city}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="state"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
              State
            </InputLabel>
            <TextField
              id="state"
              //   label="State"
              name="state"
              value={(formData?.state ?? "").toString().toUpperCase()}   
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.state}
            />
          </div>
          <div style={{ flex: 1, marginLeft: "10px" }}>
            <InputLabel
              htmlFor="zip"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
              Zip Code
            </InputLabel>
            <TextField
              id="zip"
              name="zip"
              value={formData.zip|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.zip}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="phone"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
             Phone
            </InputLabel>
            <TextField
              id="phone"
              //   label="State"
              name="phone"
              value={(formData?.phone ?? "").toString().toUpperCase()}   
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.phone}
            />
          </div>
          <div style={{ flex: 1, marginLeft: "10px" }}>
            <InputLabel
              htmlFor="fax"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
             Fax
            </InputLabel>
            <TextField
              id="fax"
              name="fax"
              value={formData.fax|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.fax}
            />
          </div>
        </div>

        <InputLabel
          htmlFor="internalNote"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Internal Note
        </InputLabel>
        <TextField
          id="internalNote"
          name="internalNote"
          value={formData.internalNote|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.internalNote}
        />
      </form>
    </>
  );
}
