import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
    getExportedValues,
    setExportedValues
} from "../../../store/actions/rateBookActions";
import Loader from '../../../components/Loader/Lodaer';

import { useDispatch, useSelector } from "react-redux";

export const RatebookExport = (props) => {
    const { locationNumber } = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const handleExport = () => {
        setIsLoading(true);
        dispatch(getExportedValues(locationNumber));
    };
    const exportedValues = useSelector(
        ({ rateBookReducer }) => rateBookReducer.exportedValues.data
    );

    useEffect(() => {
        if (exportedValues?.data?.length > 0) {
            csvExport(exportedValues.data);
            dispatch(setExportedValues([]));
            setIsLoading(false);
        }
    }, [exportedValues?.data?.length > 0]);

    return (
        <div >
            <Button
                variant="outlined"
                // size="small"
                onClick={() => {
                    handleExport();
                }}
                startIcon={isLoading ? <Loader /> : <GetAppIcon color="primary" />}
                color={`primary`}
                disabled={isLoading}
                style={{ textTransform: 'capitalize' }}
                fullWidth
            >
                Export All
            </Button>
        </div>
    );
}

export function csvExport(exportedValues) {
    function convertToCSV(objArray) {
        var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";
        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line != "") line += ",";

                line += array[i][index];
            }

            str += line + "\r\n";
        }
        return str;
    }

    function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + ".csv" || "export.csv";

        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    var headers = {
        slNo: "Id",
        notes: "Rate Name",
        invoiceDesc: "Invoice Description",
        gLDescription: "GL Description",
        gLCode: "GL Code",
        originCity: "Ramp/Port/CY",
        originState: "State",
        destinationCity: "Destination City",
        destinationState: "Destination State",
        miles: "RT Miles",
        receivableRates: "Receivable Rates",
        billTo: "Bill To",
        LorA: "L/A",
        payableRateContract: "Payable Rate (Contractor)",
        payableRatioContract: "Payable Ratio (Contractor)",
        payableRateBroker: "Payable Rate (Broker)",
        payableRatioBroker: "Payable Rate (Broker)",
        marginContractor: "Margin Contractor",
        marginBroker: "Margin Broker",
        revenuePerMile: "Revenue Per Mile",
        contractorCPM: "Contractor CPM",
        brokerCPM: "Broker CPM",
        edi210: "Edi210"
    };

    let itemsNotFormatted = exportedValues;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
        itemsFormatted.push({
            slNo: item.id,
            notes: item.notes,
            invoiceDesc: item.invoiceDesc,
            gLDescription: item.GLDesc,
            gLCode: item.GLCode,
            originCity: item.originCity,
            originState: item.originState,
            destinationCity: item.destinationCity,
            destinationState: item.destinationState,
            miles: item.totMiles,
            receivableRates: item.receivableRates,
            billTo: item.billTo,
            LorA: item.LorA,
            payableRateContract: item.payableRateContract,
            payableRatioContract: item.payableRatioContract,
            payableRateBroker: item.payableRateBroker,
            payableRatioBroker: item.payableRatioBroker,
            marginContractor: item.marginContractor,
            marginBroker: item.marginBroker,
            revenuePerMile: item.revenuePerMile,
            contractorCPM: item.contractorCPM,
            brokerCPM: item.brokerCPM,
            edi210: item.edi210
        });
    });

    var fileTitle = 'Ratebook_' + getRandomFileName(); // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle);
}

function getRandomFileName() {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
}