

  const formatDate = (value) => {
    if (value) {
        value = value.toString()
        return `${value.substring(3, 5)}/${value.substring(5)}/${value.substring(1, 3)}`
    }
    return ''
}

export const mapToPTReportTable= (ptReportRes) => {
  try {
    let ptReportData= ptReportRes?.data?.map((item,index) => {
      return {id: index,
    location: item?.location || "",
    vendor: item?.vendor || "",
    truck: item?.truck || "",
    controlNumber: item?.controlNumber || "",
    unitNumber: item?.unitNumber || "",
    status: item?.status || "",
    transmitted: item?.transmitted || "",
    description: item?.paybleDesc || "",
    processedDate: formatDate(item?.processedDate) || "",
    unit:item?.unitNumber|| "",
    rate:item?.rate||"",
    unitValue: item?.unit || "",
    amount: item?.amount || "",
    // pt:item?.amount?`${(item?.amount/item?.rate)*100}%`:"0%",
    pt:item?.amount?`${((item?.amount/item?.rate)*100).toFixed(2)}%`:"0%",
    totalAmount:ptReportRes?.totalAmount ||0
  }
})
return ptReportData;

} catch (e) {
console.log('Error occurred while mapping', e)
return false;
}
}
