import React from 'react';
import { Select, MenuItem } from '@mui/material';

const CustomDropdown = ({ options, value, onChange }) => (
    <Select
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        variant="standard"
    >
        {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </Select>
);

export default CustomDropdown;
