import React, { Component, useState, useEffect, useRef, createRef } from "react";
import { Box, Stack, Typography, Snackbar, Alert, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ComponentWrapper } from "../../components/styledComponents";
import CustomTabPanel from "../../components/CustomTabPanel";
import Inbound from './Inbound';
import Outbound from './Outbound';
import TraceFilterSection from "./TraceFilterSection";
import { gridFilteredSortedRowEntriesSelector} from '@mui/x-data-grid-pro';
import {   DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import {
    sanitizeStringForDBField,
    convertMDYToYMD,
} from '../../utils/global-functions';
import { getTraceData, bulkEditReq } from "../../store/actions/traceActions";

export default function Trace({ navigation }) {
    const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    
    const [activeTab, setActiveTab] = useState(0);
      //fromchild
    const [isDomesticChecked, setIsDomesticChecked] = useState(false);
    const [sortModel, setSortModel] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [rows, setRows] = useState([]);
    const [filterField, setFilterField] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const apiRef = useGridApiRef();
    const gridRef = useRef();
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const unsavedChangesRef = useRef({
        unsavedRows: {},
        rowsBeforeChange: {},
    });
    const [shipperArrObj, setShipperArrObj] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            fetchDataFromServer()
        }
    }, [ordersLocation,activeTab])
    useEffect(() => {
        adjustColumnSize();
    }, [rows, rowSelectionModel, isSaving, isDomesticChecked, snackOpen])

    // // handle show all button click
    const handleShowAll = () => {
        setFilterField('');
        setFilterValue('');
        fetchDataFromServer()
    }

     /**
     * Handle remove invalid button click
     * Valid container numbers are 4 alpha and between 5 and 7 numeric:
     * [A-Z]{4}[0-9]{5,7}
     */
     const handleInvalidRemove = () => {
        const regex = /^[A-Za-z]{4}[0-9]{5,7}$/;
        let filterRows = rows.filter((e) => regex.test(e.unit));
        setRows(filterRows);
    }

      /**
     * Handle remove selected button click
     * manually select row & remove rows from the view
     */
      const handleRemoveSelected = () => {
        let filterRows = rows.filter((e) => !rowSelectionModel.includes(e.id));
        setRowSelectionModel([]);
        setRows(filterRows);
    }

    const handleCopyUnitToClipboard = () => {
           const filteredSortedRowsAndIds = gridFilteredSortedRowEntriesSelector(apiRef);
   
           if (navigator.clipboard) {
               //const unitNumbers = rows.filter((e, indx) => rowSelectionModel.includes(e.id)); //.map((item, indx) => isDomesticChecked ? removeLeadingZerosAfter4(item.unit) : item.unit).join('\n');
               const unitNumbers = filteredSortedRowsAndIds.filter((e, indx) => rowSelectionModel.includes(e.id)).map((item, indx) => isDomesticChecked ? removeLeadingZerosAfter4(item.model.unit) : item.model.unit).join('\n');
               // let unitNumbersArr = [];
               // rowSelectionModel.forEach((item, index) => {
               //     const row = filteredSortedRowsAndIds.filter(obj => obj.id === item);
               //     if (row.length > 0 && row[0] !== undefined) {
               //         const extractUnit = isDomesticChecked ? removeLeadingZerosAfter4(row[0].model.unit) : row[0].model.unit
               //         unitNumbersArr.push(extractUnit);
               //     }
               // });
   
               // const unitNumbers = unitNumbersArr.join('\n');
   
               setSnackOpen(true)
               navigator.clipboard.writeText(unitNumbers)
                   .then(() => {
                       setSnackContent({ severity: "info", message: 'Copied to clipboard!' })
                       setTimeout(() => {
                           setSnackOpen(false)
                       }, 2000)
                   })
                   .catch((err) => {
                       setSnackContent({ severity: "error", message: `${'Failed to copy:' + err}` })
                   });
           }
       }
       function removeLeadingZerosAfter4(str) {
        if (str.length > 10) {
            str = str.slice(0, -1)
        }
        const firstFour = str.slice(0, 4);
        const rest = str.slice(4).replace(/^0+/, '');
        const newStr = firstFour + rest;
        return newStr;
    }

    /**
        * Handle save change button click
        */
       const saveChanges = async () => {
          const type=activeTab===0?"inbound":"outbound"
           try {
               let postData = Object.values(unsavedChangesRef.current.unsavedRows).map(el => {
                   return {
                       controlNumber: el.control,
                       orderType: el.orderType,
                       totalStops: el.totalStops,
                       equipmentDetails: {
                           unitNumber: sanitizeStringForDBField(el.unit, 13),
                           chassisNumber: sanitizeStringForDBField(el.chassis, 15, true),
                           realeaseNumber: sanitizeStringForDBField(el.release, 10),
                           vessel: sanitizeStringForDBField(el.vessel, 25),
                           voyageNumber: sanitizeStringForDBField(el.voyage, 5),
                           billingBooking: sanitizeStringForDBField(el.booking, 25),
                       },
                       pickup: {
                           poruPoint: sanitizeStringForDBField(el.puloc, 5),
                           disposition:sanitizeStringForDBField(el.dispLoc, 5),
                           notifyDate: convertMDYToYMD(el.notifyDate),
                           notifyTime: el.notifyTime,
                           cuttOffDate: convertMDYToYMD(el.lfd),
                           cutOffTime: el.lfdTime,
                           etaDate: convertMDYToYMD(el.etaDate),
                           etaTime: el.etaTime,
                       },
                       shipper: shipperArrObj[el.id] ? shipperArrObj[el.id] : el.shipperObjServer,
                       stops: {
                           lineNumber: 1,
                           apptDate: convertMDYToYMD(el.apptDate),
                           apptTime: el.apptTime,
                           etaDate: convertMDYToYMD(el.etaDate),
                           etaTime: el.etaTime,
                           //outbound
                        //    totalStops: el.totalStops
                        ...(type==="outbound" && { totalStops: el.totalStops }) 
                       }
                   }
               });
   
               // Persist updates in the database
               setIsSaving(true);
   
               let apiRes = await bulkEditReq(postData, ordersLocation.locationNumber);
               if (apiRes === true) {
                   setSnackOpen(true);
                   setSnackContent({ severity: "success", message: 'Records has been updated successfully!' })
   
                   // Update local rows after successfull Update
                   setRows(rows.map(row => {
                       if (unsavedChangesRef.current.unsavedRows[row.id]?.id == row.id) {
                           row = { ...unsavedChangesRef.current.unsavedRows[row.id], shipperObjServer: { ...unsavedChangesRef.current.unsavedRows[row.id].shipperObjServer } }
                           return row;
                       }
                       return row
                   }))
   
   
                   setHasUnsavedRows(false);
                   unsavedChangesRef.current = {
                       unsavedRows: {},
                       rowsBeforeChange: {},
                   };
                   setIsSaving(false);
                   // setTimeout(() => {
                   //     fetchDataFromServer();
                   //     setIsSaving(false);
                   // }, 5000)
   
               } else if (apiRes === false) {
                   setSnackOpen(true);
                   setSnackContent({ severity: "error", message: 'Failed to save records, please try after sometime!' })
                   setIsSaving(false);
               }
   
           } catch (error) {
               setIsSaving(false);
           }
       };

    /**
     * Handle discard changes button click
     */
    const discardChanges = () => {
        setHasUnsavedRows(false);
        apiRef.current.updateRows(
            Object.values(unsavedChangesRef.current.rowsBeforeChange),
        );
        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
        };
    };
    const fetchDataFromServer = (searchCriteria = []) => {
        const type=activeTab===0?"inbound":"outbound"
        let loadsSearchCriteria = [
            ...searchCriteria,
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation.locationNumber
            }
        ];
    
        if (type === "outbound") {
            loadsSearchCriteria.push(
                {
                    clause: "OR",
                    field: "orderType",
                    operand: "IS",
                    value: "DOMOUT"
                },
                {
                    clause: "OR",
                    field: "orderType",
                    operand: "IS",
                    value: "EXPORT"
                }
            );
        } else if (type === "inbound") {
            loadsSearchCriteria.push(
                {
                    clause: "AND",
                    field: "stops.spotDate",
                    operand: "IS",
                    value: 0
                },
                {
                    clause: "OR",
                    field: "orderType",
                    operand: "IS",
                    value: "DOMINB"
                },
                {
                    clause: "OR",
                    field: "orderType",
                    operand: "IS",
                    value: "IMPORT"
                }
            );
        }
    
        setIsLoading(true);
        setSnackOpen(true);
        setSnackContent({ severity: "info", message: "Reloading data from server...!" });
    
        dispatch(getTraceData(loadsSearchCriteria, ordersLocation.locationNumber, type === "outbound" ? "outbound" : "trace"))
            .then((res) => {
                setIsLoading(false);
    
                if (type === "outbound") {
                    setRows(res.filter(obj => obj.lineNumber == obj.totalStops && ["EXPORT", "DOMOUT"].includes(obj.orderType)));
                } else if (type === "inbound") {
                    setRows(res.filter(obj => obj.lineNumber === 1 && obj.spotDate === 0 && ["IMPORT", "DOMINB"].includes(obj.orderType)));
                }
    
                adjustColumnSize();
                setSnackOpen(false);
            });
    };
    
    const adjustColumnSize = () => {
        if (apiRef.current) {
            apiRef.current.autosizeColumns({
                includeHeaders: false,
                includeOutliers: true,
            })
        }
    }
    return (
        <ComponentWrapper>
            <Box
                sx={{
                    padding: 1
                }}
            >
                <TraceFilterSection
                  handleShowAll={handleShowAll}
                  handleInvalidRemove={handleInvalidRemove}
                  handleRemoveSelected={handleRemoveSelected}
                  handleCopyUnitToClipboard={handleCopyUnitToClipboard}
                  isDomesticChecked={isDomesticChecked}
                  setIsDomesticChecked={setIsDomesticChecked}
                  rowSelectionModel={rowSelectionModel}
                  hasUnsavedRows={hasUnsavedRows}
                  isSaving={isSaving}
                  saveChanges={saveChanges}
                  discardChanges={discardChanges}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
              />
                <Box mt={1} sx={{ flexDirection:"row" }}>
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
                        <Tab
                            label="Inbound" id='simple-tab-0'
                            sx={(activeTab === 0) ? {
                                backgroundColor: "#0911A7",
                                color: "white !important",
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                borderRadius: '8%'
                            } : {}}
                        />
                        <Tab
                            label="Outbound" id='simple-tab-1'
                            sx={(activeTab === 1) ? {
                                backgroundColor: "#0911A7",
                                color: "white !important",
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                borderRadius: '8%'
                            } : {}}
                        />

                    </Tabs>
                </Box>
                {/* </Stack>          
                </Stack> */}
                <CustomTabPanel value={activeTab} index={0}>
                    <Inbound
                   isDomesticChecked={isDomesticChecked}
                   rowSelectionModel={rowSelectionModel}
                   hasUnsavedRows={hasUnsavedRows}
                   sortModel={sortModel}
                   setRowSelectionModel={setRowSelectionModel}
                   setHasUnsavedRows={setHasUnsavedRows}
                   setSortModel={setSortModel}
                   setIsDomesticChecked={setIsDomesticChecked}
                   snackOpen={snackOpen}
                   setSnackOpen={setSnackOpen}
                   snackContent={snackContent}
                   setSnackContent={setSnackContent}
                   unsavedChangesRef={unsavedChangesRef}
                   shipperArrObj={shipperArrObj}
                   setShipperArrObj={setShipperArrObj}
                   isLoading={isLoading}
                   apiRef={apiRef}
                   rows={rows}
                   setRows={setRows}
                   isSaving={isSaving}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                    <Outbound
                    isDomesticChecked={isDomesticChecked}
                    rowSelectionModel={rowSelectionModel}
                    hasUnsavedRows={hasUnsavedRows}
                    sortModel={sortModel}
                    //new
                    setRowSelectionModel={setRowSelectionModel}
                   setHasUnsavedRows={setHasUnsavedRows}
                   setSortModel={setSortModel}
                   setIsDomesticChecked={setIsDomesticChecked}
                   snackOpen={snackOpen}
                   setSnackOpen={setSnackOpen}
                   snackContent={snackContent}
                   setSnackContent={setSnackContent}
                   unsavedChangesRef={unsavedChangesRef}
                   shipperArrObj={shipperArrObj}
                   setShipperArrObj={setShipperArrObj}
                   isLoading={isLoading}
                   apiRef={apiRef}
                   rows={rows}
                   setRows={setRows}
                   isSaving={isSaving}
                   />
                </CustomTabPanel>
            </Box>
        </ComponentWrapper>
    );
}