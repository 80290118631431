const common = {
    projectName: 'Eagle Systems',
    serverDown:false,
    message:"Server is down. Please try again later."
};

const config = {
    production: {
        ...common,
        api: {
            // URL: 'https://dispatch.eaglegroup.com/api',
            URL: 'https://api.eaglegroup.com/api',
            URL2: 'https://api.eaglegroup.com/auth',
            WSURL: 'wss://api.eaglegroup.com'
        },
    },
    development: {
        ...common,
        api: {
            URL: 'http://localhost:3002/api',
            URL2: 'http://localhost:3002/auth',
            WSURL: 'ws://localhost:3002',
        },
    },
    testing: {
        ...common,
        api: {
            URL: 'http://test-alb-1724792386.us-west-2.elb.amazonaws.com/api',
            URL2: 'http://test-alb-1724792386.us-west-2.elb.amazonaws.com/auth',
            WSURL: 'ws://test-alb-1724792386.us-west-2.elb.amazonaws.com',
        },
    }
};

// export default config.testing;
export default config[process.env.NODE_ENV] || config.development;
