import { orderTypes } from "../types/orderTypes"

import { Dispatch } from 'redux';
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';
import { convertDateToCentury, convertMilitaryDateToYMD, convertMilitaryTimeToHHMM} from '../../utils/global-functions'

export const ordersLocation = (ordersLocationValue: any) => {
    return {
        type: orderTypes.ORDERS_LOCATION,
        payload: ordersLocationValue,
    };
};

// interface

export const setErrorData1 = (errorData: object) => {
    return {
        type: orderTypes.SET_ERROR_MESSAGE,
        payload: errorData
    }
}

const setLoader = (loadState: Boolean) => {
    return {
        type: orderTypes.SET_LOADING,
        payload: loadState,
    };
};


//OrderLookup Api on Create order entry screen

export const orderByLookUp = (orderLookupValue: any) => {
    return async (dispatch: Dispatch) => {
        let res = await orderByLookUpData(orderLookupValue);
        dispatch(orderByLookUpAction(res))
    }
}

export const orderByLookUpData = async (orderLookupValueParams: any) => {
    let res = await GET(`/orders/getOrderByLookupData?${orderLookupValueParams.code}=${orderLookupValueParams.value}`);
    console.log(res)
    if (res.status !== 'error')
        return res.data;
    return []
}

const orderByLookUpAction = (orderLookupRes: any) => {
    return {
        type: orderTypes.ORDER_LOOKUP_DATA,
        payload: orderLookupRes
    }
}

//OrderLookup Api on Shipper api

export const orderTypeShipper = (shipperValue: any) => {
    return async (dispatch: Dispatch) => {
        let res = await orderTypeShipperData(shipperValue);
        dispatch(orderTypeShipperAction(res))
    }
}
// Deprecated?
export const orderTypeShipperData = async (shipperValueParams: any) => {
    let res = await GET(`/shippers/?${shipperValueParams.code}=${shipperValueParams.value}`);
    if (res.status !== 'error')
        return res.data;
    return res
}

/**
 * Get Single order based on location & control number
 * @param params
 * Location Number
 * Control Number
 * @returns
 */
// Get Single Orders
export const shipperLookup = (params: any) => {
    return async (dispatch: Dispatch) => {
        let res = await GET(`/shippers/${params.locationNumber}?${params.searchKey}=${params.searchValue}`);
        if (res.status !== 'error') {
            dispatch(orderTypeShipperAction(res.data));
            return res.data;
        } else {
            dispatch(orderTypeShipperAction([]))
            return []
        }
    }
}

const orderTypeShipperAction = (shipperLookupRes: any) => {

    return {
        type: orderTypes.ORDER_SHIPPER_DATA,
        payload: shipperLookupRes
    }
}

// Pickup, Disposition, Chassis Pickup, Chassis Disposition Lookup values
export const railRoadLookup = (req: any) => {
    return async (dispatch: Dispatch) => {
        let res = await GET(`/rail-road`);
        if (res?.status !== 'error') {
            dispatch(pickupPointLookupAction(res?.data))
        }
    }
}

export const pickupPointLookup = (req: any) => {
    return async (dispatch: Dispatch) => {
        let res = await railRoadAPIRequest(req);
        dispatch(pickupPointLookupAction(res))
        return res;
    }
}

export const railRoadAPIRequest = async (req: any) => {
    let res = await GET(`/rail-road?${req.code}=${req.value}`);
    if (res.status !== 'error')
        return res.data;
    return []
}

const pickupPointLookupAction = (res: any) => {
    return {
        type: orderTypes.PICKUP_POINT_LOOKUP_DATA,
        payload: res
    }
}

export const dispositionLookup = (req: any) => {
    return async (dispatch: Dispatch) => {
        let res = await railRoadAPIRequest(req);
        dispatch(dispositionLookupAction(res))
    }
}

const dispositionLookupAction = (res: any) => {
    return {
        type: orderTypes.DISPOSITION_LOOKUP_DATA,
        payload: res
    }
}

export const chassisPickupLookup = (req: any) => {
    return async (dispatch: Dispatch) => {
        let res = await railRoadAPIRequest(req);
        dispatch(chassisPickupLookupAction(res))
    }
}

const chassisPickupLookupAction = (res: any) => {
    return {
        type: orderTypes.CHASSIS_PICKUP_LOOKUP_DATA,
        payload: res
    }
}

export const chassisDispositionLookup = (req: any) => {
    return async (dispatch: Dispatch) => {
        let res = await railRoadAPIRequest(req);
        dispatch(chassisDispositionLookupAction(res))
    }
}

const chassisDispositionLookupAction = (res: any) => {
    return {
        type: orderTypes.CHASSIS_DISPOSITION_LOOKUP_DATA,
        payload: res
    }
}

//Selected OrderBy Value

export const selectOrderBy = (orderBySelectedValue: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getselectOrderByAction(orderBySelectedValue));
    }
}

export const getselectOrderByAction = (selectedValueOrderby: any) => {
    return {
        type: orderTypes.ORDERBY_SELECTEDVALUE,
        payload: selectedValueOrderby
    }
}

//Selected Shipper Value

export const selectedShipperValue = (shipperSelectedValue: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(getSelectedShipperAction(shipperSelectedValue));
    }
}

export const getSelectedShipperAction = (selectedValueShipper: any) => {
    return {
        type: orderTypes.SHIPPER_SELECTEDVALUE,
        payload: selectedValueShipper
    }
}

//Selected Consignee Value

export const selectedConsigneeValue = (consigneeSelectedValue: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(getSelectedConsigneeAction(consigneeSelectedValue));
    }
}

export const getSelectedConsigneeAction = (selectedValueConsignee: any) => {
    return {
        type: orderTypes.CONSIGNEE_SELECTEDVALUE,
        payload: selectedValueConsignee
    }
}

//Billing Control Input fields




export const billingCntrlInputValue = (billingCntrlInputValue: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(billingCntrlInputValueAction(billingCntrlInputValue));
    }
}

export const billingCntrlInputValueAction = (billingCntrlInputValueParams: any) => {
    return {
        type: orderTypes.BILLINGCNTRL_INPUTVALUE,
        payload: billingCntrlInputValueParams
    }
}


//Final value dispatch Billing control

export const billingCntrlDispatch = (billingCntrlValues: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(billingCntrlDispatchAction(billingCntrlValues));
    }
}

export const billingCntrlDispatchAction = (billingCntrlFinalValue: any) => {
    return {
        type: orderTypes.BILLINGCNTRL_FINALVALUE,
        payload: billingCntrlFinalValue
    }
}


//Stop Order Input fields

export const stopCntrlInputValues = (stopCntrlInputValues: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(stopCntrlInputValuesAction(stopCntrlInputValues));
    }
}

export const stopCntrlInputValuesAction = (stopCntrlInputValue: any) => {
    return {
        type: orderTypes.STOPCNTRL_INPUTVALUE,
        payload: stopCntrlInputValue
    }
}


//Final value dispatch Stop Order

export const stopCntrlDispatch = (stopCntrlValues: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(stopCntrlDispatchAction(stopCntrlValues));
    }
}

export const stopCntrlDispatchAction = (stopCntrlFinalValue: any) => {
    return {
        type: orderTypes.STOPCNTRL_FINALVALUE,
        payload: stopCntrlFinalValue
    }
}

// Value for Select Stop value

export const selectedStopControl = (selectedStopValues: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(selectedStopControlAction(selectedStopValues));
    }
}

export const selectedStopControlAction = (selectedStopsFinalValue: any) => {
    return {
        type: orderTypes.SELECTEDSTOPCNTRL_FINALVALUE,
        payload: selectedStopsFinalValue
    }
}


// Rates and Charges changed Input value

export const ratesInputValues = (ratesValues: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(selectedRatesInputAction(ratesValues));
    }
}

export const selectedRatesInputAction = (ratesSelectedValues: any) => {
    return {
        type: orderTypes.SELECTEDRATES_FINALVALUE,
        payload: ratesSelectedValues
    }
}

// Rates and Charges selected value

export const ratesInputDispatch = (ratesValuesDispatch: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(ratesInputDispatchAction(ratesValuesDispatch));
    }
}

export const ratesInputDispatchAction = (ratesDispatchValues: any) => {
    return {
        type: orderTypes.RATESDISPATCH_VALUE,
        payload: ratesDispatchValues
    }
}

//Rates Unit Number

export const ratesUnitDispatch = (ratesUnitNumberDispatch: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(ratesUnitDispatchAction(ratesUnitNumberDispatch));
    }
}

export const ratesUnitDispatchAction = (ratesUnitNumberDispatchValues: any) => {
    return {
        type: orderTypes.RATESUNITDISPATCH_VALUE,
        payload: ratesUnitNumberDispatchValues
    }
}

//Create Order API

export const createOrderFinal = (orderEntryFinal: any) => {
    return async (dispatch: Dispatch) => {
        let res = await createOrderFinalData(orderEntryFinal);
        dispatch(createOrderFinalDataAction(res))
    }
}

export const createOrderFinalData = async (orderEntryFinalparams: any) => {
    let res = await POST('/orders/createNewOrder', orderEntryFinalparams);
    return res;
}

const createOrderFinalDataAction = (createOrderFinalResponse: any) => {
    return {
        type: orderTypes.CREATE_ORDER_FINAL,
        payload: createOrderFinalResponse
    }
}


//ADD Shipper/Consignee API


export const addShipperConsignee = (addShipperValue: any) => {
    return async (dispatch: Dispatch) => {
        let res = await addShipperConsigneeData(addShipperValue);
        // dispatch(addShipperConsigneeAction(res))
        return res;
    }
}

export const addShipperConsigneeData = async (addShipperConsigneeparams: any) => {
    let res = await POST('/orders/shipperByLookup', addShipperConsigneeparams);
    return res;
}

// const addShipperConsigneeAction = (addShipperConsigneeResponse: any) => {
//     return {
//         type: orderTypes.ADD_SHIPPER_CONSIGNEE,
//         payload: addShipperConsigneeResponse
//     }
// }


//Edit Shipper/Consignee API


export const editShipperConsignee = (editShipperValue: any) => {
    return async (dispatch: Dispatch) => {
        let res = await editShipperConsigneeData(editShipperValue);
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const editShipperConsigneeData = async (editShipperConsigneeparams: any) => {
    let res = await PUT('/orders/shipperByLookup', editShipperConsigneeparams);
    return res;
}

// const editShipperConsigneeAction = (editShipperConsigneeResponse: any) => {
//     return {
//         type: orderTypes.EDIT_SHIPPER_CONSIGNEE,
//         payload: editShipperConsigneeResponse
//     }
// }


//Lookup Api for Rates and Charges api

export const orderTypeRatesCharges = (ratesChargesValue: any) => {
    return async (dispatch: Dispatch) => {
        let res = await orderTypeRatesChargesData(ratesChargesValue);
        dispatch(orderTypeRatesChargesAction(res))
    }
}

export const orderTypeRatesChargesData = async (ratesChargesValueParams: any) => {
    let res = await GET(`/orders/getRatesAndChargesLookupData?${ratesChargesValueParams.code}=${ratesChargesValueParams.value}`);
    return res;
}

const orderTypeRatesChargesAction = (ratesChargesLookupRes: any) => {
    return {
        type: orderTypes.ORDER_RATESCHARGES_DATA,
        payload: ratesChargesLookupRes
    }
}

//Lookup Api for Pickup and Chasis api

export const orderTypePickup = () => {

    return async (dispatch: Dispatch) => {
        dispatch(setLoader(true));
        let res = await orderTypePickupData();

        dispatch(orderTypePickupAction(res));
        dispatch(setLoader(false));

    }
}

export const orderTypePickupData = async () => {

    let res = await GET('/rail-road');
    return res;
}

const orderTypePickupAction = (orderTypePickupRes: any) => {
    return {
        type: orderTypes.ORDER_PICKUPCHASIS_DATA,
        payload: orderTypePickupRes
    }
}

//Selected PickupChasis

export const pickupChassisDispatch = (pickupChassisDispatchParams: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(pickupChassisDispatchAction(pickupChassisDispatchParams));
    }
}

export const pickupChassisDispatchAction = (pickupChassisDispatchValues: any) => {

    return {
        type: orderTypes.PICKUPCHASSISDISPATCH_VALUE,
        payload: pickupChassisDispatchValues
    }
}

export const pickupDispositionDispatch = (pickupDispositionDispatchParams: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(pickupDispositionDispatchAction(pickupDispositionDispatchParams));
    }
}

export const pickupDispositionDispatchAction = (pickupDispositionDispatchValue: any) => {

    return {
        type: orderTypes.PICKUPDISPOSITIONDISPATCH_VALUE,
        payload: pickupDispositionDispatchValue
    }
}

export const chasisPickupDispatch = (chasisPickupDispatchParams: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(chasisPickupDispatchAction(chasisPickupDispatchParams));
    }
}

export const chasisPickupDispatchAction = (chasisPickupDispatchValue: any) => {

    return {
        type: orderTypes.CHASISPICKUPDISPATCH_VALUE,
        payload: chasisPickupDispatchValue
    }
}

export const chasisDispositionDispatch = (pickupDispositionDispatchParams: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(chasisDispositionDispatchAction(pickupDispositionDispatchParams));
    }
}

export const chasisDispositionDispatchAction = (pickupDispositionDispatchValue: any) => {

    return {
        type: orderTypes.PICKUPDISPOSITIONDISPATCH_VALUE,
        payload: pickupDispositionDispatchValue
    }
}

//edit PickupChassis

export const editPickupChassis = (editPickupChassisValue: any) => {

    return async (dispatch: Dispatch) => {
        let res = await editPickupChassisData(editPickupChassisValue);
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const editPickupChassisData = async (editPickupChassisparams: any) => {

    let res = await PUT('/rail-road', editPickupChassisparams);
    return res;
}


// Get All Orders

export const getAllOrders = (params: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getAllOrdersAction({
            isLoading: true,
            data: []
        }))
        //let url = params.search ? `/orders/order/${params.locationNumber}?search=${params.search}` : `/orders/order/${params.locationNumber}`
        let url = params.search ? `/orders/${params.locationNumber}?search=${params.search}` : `/orders/${params.locationNumber}`

        let res = await GET(url);
        console.log(res)
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(getAllOrdersAction({
                isLoading: false,
                data: res.data
            }))
        }
        else {
            dispatch(getAllOrdersAction({
                isLoading: false,
                data: []
            }))
        }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}
export const getAllOrdersAction = (allOrders: any) => {

    return {
        type: orderTypes.GET_ALL_ORDERS,
        payload: allOrders
    }
}

const defaultPage = 1;
const defaultPageSize = 500;

export const getAllOrdersElastic = (params: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getAllOrdersElasticAction({
            isLoading: true,
            data: [],
            totalRows: 0
        }))
        // let url = `/elastic/orders/${params.locationNumber}`
        let url = `/elastic/stops/${params.locationNumber}`
        
        if (typeof params?.query?.page !== 'undefined' && params?.query?.pageSize !== 0) {
            url += `?page=${Number(params.query.page) + Number(1)}&size=${params.query.pageSize}`;
        } else {
            url += `?page=${defaultPage}&size=${defaultPageSize}`;
        }
        if (typeof params?.query?.sortOrder !== 'undefined' && typeof params?.query?.sortBy !== 'undefined') {
            url += `&sortOrder=${params.query.sortOrder}&sortBy=${params.query.sortBy}`;
        }
        let res = await GET(url);
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(getAllOrdersElasticAction({
                isLoading: false,
                data: res.data.length === 0
                    ? []
                    : res.data.hits.map((item: any, index: number) => {
                        let formattedItem = {
                            ...item._source,
                            id: `${index}-${item._source.controlNumber}-${item._source['stops.lineNumber']}`,
                        };
                        // Format all date fields
                        return formatDateTimeFields(formattedItem);
                    }),
                totalRows: res.data.length === 0 ? 0 : res.data.total.value
            }));
        }
        else {
            dispatch(getAllOrdersElasticAction({
                isLoading: false,
                data: [],
                totalRows: 0
            }))
        }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const formatDateTimeFields = (data: any) => {
    const dateFields = [
        'apptDate',
        'notifyDate',
        'cuttOffDate',
        'etaDate',
        'spotDate',
        'lfd',
        'pickDate',
        'returnDate',
        'poruDate',     //pickupDateTime in Order Screen
        'billingDate',
        'stops.apptDate',
        'stops.arriveDate',
        'stops.spotDate',
        'stops.unloadDate',
        'stops.departDate',
        'stops.availDate',
        'stops.etaDate',
    ];

    const timeFields = [
        'lfdTime', 
        // 'apptTime',
        'etaTime',
        'notifyTime',
        'cutOffTime',
        'returnTime',
        'poruTime',     //pickupDateTime in Order Screen
        // 'stops.apptTime',
        'stops.arriveTime',
        'stops.spotTime',
        'stops.unloadTime',
        'stops.departTime',
        'stops.availTime',
        'stops.etaTime',        
    ];

    // Format date fields
    dateFields.forEach((field) => {
        if (data[field]) {
            data[field] = convertMilitaryDateToYMD(data[field]) || ''; // Apply the date function
        }
    });

    // Format time fields
    timeFields.forEach((field) => {
        if (data[field]) {
            data[field] = convertMilitaryTimeToHHMM(data[field]) || ''; // Apply the time function
        }
    });

    return data;
};

export const getAllOrdersElasticForExport = async (params: any, queryData: any) => {   
        let url = `/elastic/stops/_search`
        // let url = `/elastic/orders/_search`
        if (typeof queryData?.query?.page !== 'undefined' && queryData?.query?.pageSize !== 0) {
            url += `?page=${Number(queryData.query.page) + Number(1)}&pageSize=${queryData.query.pageSize}`;
        } else {
            url += `?pageNumber=${defaultPage}&pageSize=${defaultPageSize}`;
        }
        if (typeof queryData?.query?.sortOrder !== 'undefined' && typeof queryData?.query?.sortBy !== 'undefined') {
            url += `&sortOrder=${queryData.query.sortOrder}&sortBy=${queryData.query.sortBy}`;
        }
        if(params && params.length>1 ){
            url+=`&allStatus=true`
        }
        if (typeof queryData?.query?.includeRates !== 'undefined') {
            url += `&includeRates=${queryData.query.includeRates}`;
        }
        let res = await POST(url, params);
        let finalData = {
            data: [],
            totalRows: 0
        }
        // if (res && res.httpCode === 200 && res.status === 'success') {
        //     finalData = {
        //         data: res.data.length === 0 ? [] : res.data.hits.map((item: any, index:number) => ({ id: index, ...item._source})),
        //         totalRows: res.data.length === 0 ? 0 : res.data.total.value
        //     }
        // }

        if (res && res.httpCode === 200 && res.status === 'success') {
            finalData = {
                data: res.data.length === 0 
                    ? [] 
                    : res.data.hits.map((item: any, index: number) => {
                        let formattedItem = {
                            ...item._source,
                            id: index,
                        };
                        return formatDateTimeFields(formattedItem); // Format date/time fields
                    }),
                totalRows: res.data.length === 0 ? 0 : res.data.total.value
            };
        }
    
        return finalData;
}
const handleParamsDateConversion = (searchClause: any) => {
    let searchClauseToSendServer = [...searchClause];

    for (let i = 0; i < searchClauseToSendServer.length; i++) {
        const clausedata = searchClauseToSendServer[i];
        if (clausedata.format === "Date") {
            console.log("clauevalue", clausedata.value)
            // let rangeArray = clausedata?.value?.split('|')
            const centuryFormat = convertDateToCentury(clausedata.value)
            searchClauseToSendServer[i].value = centuryFormat;
        }
    }

    return searchClauseToSendServer
}

export const searchOrdersElastic = (paramsData: any, queryData: any) => {
    const params = handleParamsDateConversion(paramsData)
    return async (dispatch: Dispatch) => {
        dispatch(getAllOrdersElasticAction({
            isLoading: true,
            data: [],
            totalRows: 0
        }))
        let url = `/elastic/stops/_search`
        // let url = `/elastic/orders/_search`
        if (typeof queryData?.query?.page !== 'undefined' && queryData?.query?.pageSize !== 0) {
            url += `?pageNumber=${Number(queryData.query.page) + Number(1)}&pageSize=${queryData.query.pageSize}`;
        } else {
            url += `?pageNumber=${defaultPage}&pageSize=${defaultPageSize}`;
        }
        if (typeof queryData?.query?.sortOrder !== 'undefined' && typeof queryData?.query?.sortBy !== 'undefined') {
            url += `&sortOrder=${queryData.query.sortOrder}&sortBy=${queryData.query.sortBy}`;
        }
        if(params && params.length>1 ){
            url+=`&allStatus=true`
        }
        if (typeof queryData?.query?.includeRates !== 'undefined') {
            url += `&includeRates=${queryData.query.includeRates}`;
        }
        let res = await POST(url, params);
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(getAllOrdersElasticAction({
                isLoading: false,
                // data: res.data.length === 0 ? [] : res.data.hits.map((item: any) => item._source),
                data: res.data.length === 0 
                        ? [] 
                        : res.data.hits.map((item: any, index:number) => {
                            let formattedItem = {
                                ...item._source,
                                id: `${index}-${item._source.controlNumber}-${item._source['stops.lineNumber']}`,
                            };
                            // Format all date fields
                            return formatDateTimeFields(formattedItem);
                        }),
                totalRows: res.data.length === 0 ? 0 : res.data.total.value
            }))
        }
        else {
            dispatch(getAllOrdersElasticAction({
                isLoading: false,
                data: [],
                totalRows: 0
            }))
        }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const getAllOrdersElasticAction = (allOrders: any) => {

    return {
        type: orderTypes.GET_ALL_ORDERS_ELASTIC,
        payload: allOrders
    }
}


export const getAllSearchFieldsElastic = (params: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getAllSearchFieldsElasticAction({
            isLoading: true,
            data: []
        }))
        let url = `/elastic/search-fields`

        let res = await GET(url);
        console.log(res)
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(getAllSearchFieldsElasticAction({
                isLoading: false,
                data: res.data.length === 0 ? [] : res.data.searchFields
            }))
        }
        else {
            dispatch(getAllSearchFieldsElasticAction({
                isLoading: false,
                data: []
            }))
        }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const getAllSearchFieldsElasticAction = (allOrders: any) => {

    return {
        type: orderTypes.ORDER_SEARCH_FIELDS_DATA,
        payload: allOrders
    }
}


export const getAllUserViewsExtendedSearch = (params: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getAllUserViewsExtendedSearchAction({
            isLoading: true,
            data: []
        }))

        let url = `/elastic/user-preferences/views`

        let res = await GET(url);
        console.log('RESPONSE ON VIEWS', res)
        if (res && res.httpCode === 200 && res.status === 'success') {
            res.data = res.data.extendedSearchViews ? Object.keys(res.data.extendedSearchViews).map((item: any) => res.data.extendedSearchViews[item]) : []
            dispatch(getAllUserViewsExtendedSearchAction({
                isLoading: false,
                data: res.data
            }))
        }
        else {
            dispatch(getAllOrdersElasticAction({
                isLoading: false,
                data: []
            }))
        }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const getAllUserViewsExtendedSearchAction = (allViews: any) => {

    return {
        type: orderTypes.GET_ALL_VIEWS_EXTENDED_SEARCH,
        payload: allViews
    }
}


export const saveViewElastic = (params: any) => {

    return async (dispatch: Dispatch) => {
        // dispatch(getAllOrdersElasticAction({
        //     isLoading: true,
        //     data: []
        // }))

        let url = `/elastic/user-preferences/views`

        let res = await POST(url, params);
        console.log(res)
        // if (res && res.httpCode === 200 && res.status === 'success') {
        //     dispatch(getAllOrdersElasticAction({
        //         isLoading: false,
        //         data: res.data.length === 0 ? [] : res.data.hits.map((item: any) => item._source)
        //     }))
        // }
        // else {
        //     dispatch(getAllOrdersElasticAction({
        //         isLoading: false,
        //         data: []
        //     }))
        // }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}
export const saveViewElasticAction = (allOrders: any) => {

    // return {
    //     type: orderTypes.GET_ALL_ORDERS_ELASTIC,
    //     payload: allOrders
    // }
}

export const deleteViewElastic = (params: any) => {

    return async (dispatch: Dispatch) => {
        let url = `/elastic/user-preferences/views/${params.name}`

        let res = await DELETE(url, params);
        console.log(res)
        // if (res && res.httpCode === 200 && res.status === 'success') {
        //     dispatch(getAllOrdersElasticAction({
        //         isLoading: false,
        //         data: res.data.length === 0 ? [] : res.data.hits.map((item: any) => item._source)
        //     }))
        // }
        // else {
        //     dispatch(getAllOrdersElasticAction({
        //         isLoading: false,
        //         data: []
        //     }))
        // }
        // dispatch(editShipperConsigneeAction(res))
        return res;
    }
}

export const deleteViewElasticAction = (allOrders: any) => {

    return {
        type: orderTypes.GET_ALL_ORDERS_ELASTIC,
        payload: allOrders
    }
}


/**
 * Get Single order based on location & control number
 * @param params
 * Location Number
 * Control Number
 * @returns
 */
// Get Single Orders
export const getSingleOrder = (params: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getSingleOrderAction({
            isLoading: true,
            data: []
        }))
        let url = `/orders/order/${params.locationNumber}/${params.controlNumber}`

        let res = await GET(url);
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(getSingleOrderAction({
                isLoading: false,
                data: res.data
            }))
        }
        else {
            dispatch(getSingleOrderAction({
                isLoading: false,
                data: []
            }))
        }

        return res;
    }
}

export const getSingleOrderAction = (singleOrder: any) => {

    return {
        type: orderTypes.GET_SINGLE_ORDER,
        payload: singleOrder
    }
}

/**
 * Sending request to backend for deleting the stop entry from table.
 * @param stopData
 * @returns
 */

export const deleteOrderStopData = (stopData: any) => {
    return async (dispatch: Dispatch) => {
        let res = await deleteOrderStopDataReq(stopData);
        dispatch(setDeletedStopData(res));
        return res;
    }
}

export const deleteOrderStopDataReq = async (stopData: any) => {
    let res = await DELETE(`/orders/${stopData.location}/${stopData.control}/stops/${stopData.lineNo}`);
    return res
}

export const reorderOrderStopData = (stopData: any,updatedStopListOrder:any) => {
    return async (dispatch: Dispatch) => {
        let res = await reorderOrderStopDataReq(stopData,updatedStopListOrder);
        dispatch(setDeletedStopData(res));
        return res;
    }
}

export const reorderOrderStopDataReq = async (stopData: any,updatedStopListOrder:any) => {
    let res = await PUT(`/orders/stops/sort/${stopData.location}/${stopData.control}`,updatedStopListOrder);
    return res
}

const setDeletedStopData = (deleteStopEntry: any) => {
    return {
        type: orderTypes.DELETE_STOP_RECORD,
        payload: deleteStopEntry
    }
}

/**
 * Sending request to backend for deleting the rates & charges entry from table.
 * @param stopData
 * @returns
 */

export const deleteOrderRatesChargesData = (ratesChargesData: any) => {
    return async (dispatch: Dispatch) => {
        let res = await deleteOrderRatesChargesDataReq(ratesChargesData);
        dispatch(setDeletedRatesChargesData(res));
        return res;
    }
}

export const deleteOrderRatesChargesDataReq = async (ratesChargesData: any) => {
    let res = await DELETE(`/orders/${ratesChargesData.location}/${ratesChargesData.control}/rates-charges/${ratesChargesData.lineNo}`);
    return res
}

const setDeletedRatesChargesData = (deleteRCEntry: any) => {
    return {
        type: orderTypes.DELETE_RATES_CHARGES_RECORD,
        payload: deleteRCEntry
    }
}

/**
 * Handle COPY ORDER request
 */
export const copyOrderReq = async (locationNumber: number, postData: any, count: number) => {
    try {
        let res = await POST(`/orders/order/${locationNumber}/bulk/${count}`, postData);
        return res;
    } catch (error) {
        return {
            status: 'error',
            message: 'Something went wrong, Please try again after sometime!'
        };
    }
}

/**
 * Handle splitted Rates & Charges
 */
// export const postRatesCharges = async (locationNumber: number, orderNumber: number, postData: any) => {
//     return async (dispatch: Dispatch) => {
//         let res = await postRatesChargesReq(locationNumber, orderNumber, postData);
//         dispatch(setRatesChargesData(res));
//         return res;
//     }
// }
export const postRatesCharges = async (locationNumber: number, orderNumber: number, item: any) => {
    try {
        let ratesChargesarray: any = [];
        let rowIndex = 0;
        // handle parent & subitem parent
        if (typeof item?.lineNumber !== 'undefined' && item?.splitItems?.length === 0) {
            rowIndex = item.lineNumber;
            ratesChargesarray.push(ratesChargesObjsForServer({ ...item, splitType: 'P', commonSplitLine: rowIndex }));
        }
        if (typeof item?.subItems?.[0]?.lineNumber !== 'undefined' && item?.subItems?.[0]?.splitItems?.length === 0) {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...item?.subItems?.[0], splitType: 'C', commonSplitLine: rowIndex }));
        }

        item?.splitItems?.length > 0 && item?.splitItems?.map((splitItem: any) => {
            rowIndex = splitItem.lineNumber;
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItem, splitType: 'P', commonSplitLine: rowIndex }));
        });
        item?.subItems?.[0]?.splitItems?.length > 0 && item?.subItems?.[0]?.splitItems?.map((splitItem: any) => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItem, splitType: 'P', commonSplitLine: rowIndex }));
        })

        const rates = ratesChargesarray.filter((item: any) => item.lineNumber !== '');
        let finalData = { ratesCharges: rates }
        let res = await POST(`/orders/ratescharges/${locationNumber}/${orderNumber}`, finalData);
        return res;
    } catch (error) {
        return {
            status: 'error',
            message: 'Something went wrong, Please try again after sometime!'
        };
    }
}

const ratesChargesObjsForServer = (obj: any) => {
    const res = {
        lineNumber: obj.lineNumber,
        description: obj.description || "",
        notes: obj.notes || "",
        glDescription: obj.glDescription || "",
        glCode: obj.glCode || "",
        originName: obj.originName || "",
        originCity: obj.originCity || "",
        originPrefix: obj.originPrefix || "",
        destinationCity: obj.destinationCity || "",
        destinationStatePrefix: obj.destinationStatePrefix || "",
        accountLine: obj.accountLine || "",
        unitRate: obj.unitRate || "",
        units: obj.units || "",
        amount: obj.amount || "",
        transmitted: obj.transmitted || "",
        truckNumber: obj.truckNumber || "",
        parentLineNumber: obj.parentLineNumber || "",
        splitLineNumber: obj.splitLineNumber || "",
        loadType: obj.loadType || "",
        subPer: obj.subPer || "",
        payRec: obj.payRec || "",
        payType: obj.payType || "",
        payableRecievable: obj.payableReceivable || "",
        processedDate: obj.processedDate,
        department: getRCDepartment(obj.glCode),
        recAgainstSplitLine: obj.recAgainstSplitLine || 0,
        splitType: obj.splitType || '',
        commonSplitLine: obj.commonSplitLine || 0,
    }
    return res
}

const getRCDepartment = (glCode: number) => {
    const glCodeArr = [2026000, 2035000, 2175000, 2177000];
    if (glCodeArr.includes(glCode)) {
        return 0;
    }
    return 310;
}

const getDateDMY = (dateVal: any) => {
    if (dateVal === 0 || !dateVal)
        return ""
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
        return dateVal;
    } else {
        let date = dateVal.toString().substring(1)
        date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
        return date;
    }
}

export const partialBulkEditReq = async (postData: any, locationNumber: number) => {
    try {
        let res = await PUT(`/orders/partial-bulk-update/${locationNumber}`, postData);
        if (res && res.httpCode === 200 && res.status === 'success') {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}