// import DriversList from '../../../../components/DriverInfo/DriversList';

// export default function TrucksAutoComplete(props) {
//     const { valueChange, existingValue, isDisabled } = props
//     return (
//         <DriversList
//                 valueChangeObj={(value) => { 
//                     if (typeof (value) === 'object' && typeof value?.truck !== 'undefined' && value?.truck !== '' && value?.truck !== null) {
//                         valueChange(value.truck)
//                     } else if (value === null) {
//                         valueChange('')
//                     }
//                 }}
//                 existingValue={existingValue} 
//                 isDisabled={isDisabled}
//         />
//     );
// }
import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { GET } from '../../../../utils/axios'

export default function TrucksAutoComplete(props) {
    const { valueChange, existingValue, isDisabled } = props
    const params = useParams();
    const [value, setValue] = useState({});
    const [options, setOptions] = useState([])
    const [truckList, setTruckList] = useState([]);
    
    // const driverList = useSelector(({ loadsReducer }) => {
    //     return loadsReducer.driverList        
    // });
   
    const timeOutRef = useRef(null)

    // const handleLookup = (event, value, reason) => {
    //     if (value && reason !== 'reset') {
    //         if (timeOutRef.current)
    //             clearTimeout(timeOutRef.current)
    //         const timer = setTimeout(async () => {
    //             //const response = await GET(`/trucks?searchVal=${value}&location=${params.locationNumber}`)
    //             const response = await GET(`/trucks/active/${params.locationNumber}`);
    //             if (response.length > 0)
    //                 setOptions(response)
    //             else
    //                 setOptions([])
    //         }, 1000)

    //         timeOutRef.current = timer
    //     }
    //     else {
    //         console.log('Reason is reset not making an api call')
    //     }
    // }

    // useEffect(() => {
    //     if (driverList.length > 0) {
    //         setOptions(driverList.map(e1 => {
    //             return {
    //                 driverId: e1.id,
    //                 name: e1.name,
    //                 truck: e1.truck
    //             }
    //         }));
    //         let selectedValue = (typeof existingValue !== 'undefined') ? driverList.filter(e => e.truck === existingValue)[0] : { id: '', truck: '', name: '' }
    //         setValue({
    //             driverId: selectedValue?.id,
    //             name: selectedValue?.name,
    //             truck: selectedValue?.truck
    //         });
    //     }
    // }, [driverList])

    const handleLookup = (event, value, reason) => {
        if (value && reason !== 'reset') {
            if (timeOutRef.current)
                clearTimeout(timeOutRef.current)
            const timer = setTimeout(async () => {
                //const response = await GET(`/trucks?searchVal=${value}&location=${params.locationNumber}`)
                const response = await GET(`/trucks/active/${params.locationNumber}`);
                if (response.length > 0)
                    setOptions(response)
                else
                    setOptions([])
            }, 1000)

            timeOutRef.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    useEffect(() => {
        if (params.locationNumber) {
          fetchTrucks();
        }
      }, [params.locationNumber]); // Fetch data when locationNumber changes      
    
    const fetchTrucks = async () => {
        try {
          const response = await GET(`/trucks/active/${params.locationNumber}`);      
          if (response.length > 0) {
            // Transform response to match expected options format
            const formattedOptions = response.map(e1 => ({
              driverId: e1.driverId, // Handle null driverId
              driverName: e1.driverName,
              truck: e1.truck
            }));
      
            setTruckList(formattedOptions)
            setOptions(formattedOptions);
      
            // Select existing value if available
            const selectedValue = formattedOptions.find(e => e.truck === existingValue) || { driverId: '', driverName: '', truck: '' };
      
            setValue({
              driverId: selectedValue.driverId,
              driverName: selectedValue.driverName,
              truck: selectedValue.truck
            });
          } else {
            setOptions([]);
            setValue({ driverId: '', driverName: '', truck: '' });
          }
        } catch (error) {
          console.error("Error fetching trucks:", error);
          setOptions([]);
          setValue({ driverId: '', driverName: '', truck: '' });
        }
    };

    useEffect(() => {
        if (truckList.length > 0) {
            setOptions(truckList.map(e1 => {
                return {
                    driverId: e1.driverId,
                    driverName: e1.driverName,
                    truck: e1.truck
                }
            }));
            let selectedValue = (typeof existingValue !== 'undefined') ? truckList.filter(e => e.truck === existingValue)[0] : { id: '', truck: '', name: '' }
            setValue({
                driverId: selectedValue?.driverId,
                driverName: selectedValue?.driverName,
                truck: selectedValue?.truck
            });
        }
    }, [truckList])

    return (
        <Autocomplete
            disabled={isDisabled}
            freeSolo
            autoSelect
            options={options}
            //getOptionLabel={(option) => (typeof option?.truck !== 'undefined' && option.truck !== '') ? `${option.truck} - ${option.name} [${option.driverId}]` : ''}
            getOptionLabel={(option) => (typeof option?.truck !== 'undefined' && option.truck !== '') ? `${option.truck} - ${option.driverName} [${option.driverId}]` : ''}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']}>
                        {(typeof option?.truck !== 'undefined' && option.truck !== '') ? `${option.truck} - ${option.driverName} [${option.driverId}]` : ''}
                    </li>
                )
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    value={params.truck}
                    //label="Truck"
                    variant="standard"
                    sx={{
                        input: {
                            textTransform: 'uppercase'
                        }
                    }}
                />)}
            componentsProps={{ popper: { style: { width: 300 } } }}
            onInputChange={handleLookup}
            onChange={(event, value) => {
                if (typeof (value) === 'string') {
                    //console.log('on Change Some New Value', value, reason);
                    setValue({ truck: value, driverName: '' });
                }
                else {
                    setValue(value);
                    //console.log('on Change', value, reason);
                }
                if (typeof (value) === 'object' && typeof value?.truck !== 'undefined' && value?.truck !== '' && value?.truck !== null) {
                    //console.log('Changing parent', value)
                    valueChange(value.truck)
                } else if (value === null) {
                    valueChange('')
                }
            }}
            value={value}
        />
    );
}