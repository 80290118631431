import jsPDF from "jspdf";
import "jspdf-autotable";

const ExportSummaryByGlCode = (rows) => {
    const generatePDF = () => {
        if (!rows || rows.length === 0) {
            console.error("No data available to generate PDF.");
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            hotfixes: ['pxsplit']
        });

        // Title Section
        doc.setFontSize(12);
        const title = "Summary By GL Code Report";
        const titleWidth = doc.getTextWidth(title);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth - titleWidth) / 2;
        doc.text(title, titleX, 10);

        const tableData = rows.map((row) => [
            row.location || "",
            row.receivables || '',
            row.receivablesDesc || '',
            row.receivablesAmt || '',
            `${parseFloat(row.receivablesPercent || 0).toFixed(2)}%`,
            row.payables || '',
            row.payablesDesc || '',
            row.payablesAmt || '',
            `${parseFloat(row.payablesPercent || 0).toFixed(2)}%`,
            row.miscReceivables || '',
            row.miscReceivablesDesc || '',
            row.miscReceivablesAmt || '',
            `${parseFloat(row.miscReceivablesPercent || 0).toFixed(2)}%`,
            row.miscExpenses || '',
            row.miscExpensesDesc || '',
            row.miscExpensesAmt || '',
            `${parseFloat(row.miscExpensesPercent || 0).toFixed(2)}%`,
        ]);

        const columnData = [
            "Location",
            'Receivables', 'Description', '$', '%',
            'Payables', 'Description', '$', '%',
            'Misc Receivables', 'Description', '$', '%',
            'Misc Expenses', 'Description', 'Total $', 'Total %'
        ];

        doc.autoTable({
            startY: 20,
            head: [columnData],
            body: tableData,
            styles: {
                fontSize: 8,
                cellPadding: 2,
                overflow: 'linebreak',
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                lineColor: [0, 0, 0],
            },
            columnStyles: {
                // Apply general column styles if needed
            },
            theme: 'grid',
            margin: { top: 10 },
            didDrawCell: function (data) {
                const colIndex = data.column.index;
                const rowIndex = data.row.index;
                const totalRowIndex = tableData.length - 2; // Second last row for "Total"
                const grandTotalRowIndex = tableData.length - 1; // Last row for "Grand Total"

                // Add dark vertical border after every 4th column
                if ([3, 7, 11, 15].includes(colIndex)) {
                    doc.setDrawColor(0, 0, 0); // Black border
                    doc.setLineWidth(0.5); // Thicker border
                    doc.line(
                        data.cell.x + data.cell.width, data.cell.y, // Top of cell
                        data.cell.x + data.cell.width, data.cell.y + data.cell.height // Bottom of cell
                    );
                }

                // Apply grey background to Total and Grand Total rows
                if (rowIndex === totalRowIndex || rowIndex === grandTotalRowIndex) {
                    // For each cell in the row, apply the grey background
                    data.cell.styles.fillColor = [240, 240, 240]; // Light grey background
                    data.cell.styles.textColor = [0, 0, 0]; // Black text color
                    data.cell.styles.fontStyle = 'bold'; // Bold text
                }
            },
        });

        // Save the PDF
        doc.save("Summary-by-gl-report.pdf");
    };

    return generatePDF;
};

export default ExportSummaryByGlCode;
