import { useState } from "react";
import * as xlsx from "xlsx";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { Button } from '@mui/material';
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import { useDispatch } from "react-redux";
import Loader from '../../../components/Loader/Lodaer';

import {
    getRateBookData,
    importRateBookData
} from "../../../store/actions/rateBookActions";

export const RatebookImport = (props) => {
    const { locationNumber } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [importedData, setImportedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const sendDataToServer = () => {
        setIsLoading(true);
        dispatch(importRateBookData(importedData, locationNumber))
            .then((res) => {
                setIsDialogOpen(false);
                setIsLoading(false);
                props.refreshComponentData(true);
                props.setAlertMessage({
                    children: `Import Output: ${JSON.stringify(res)}`, severity: res.status
                })
            });
    };
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `Ratebook_Sample_CSV.csv`;
        link.href = "./sampleCSV/Ratebook_Sample_CSV.csv";
        link.click();
    };
    return (
        <div >
            <Button
                variant="outlined"
                // size="small"
                onClick={() => setIsDialogOpen(true)}
                startIcon={<PublishIcon color="inherit" />}
                style={{ textTransform: 'capitalize' }}
                fullWidth
            >
                Import
            </Button>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Choose a CSV file to import"}
                </DialogTitle>
                <DialogContent dividers>
                    {
                        isLoading ? <Loader />
                            : <ExcelFileImport fileUpload={(val) => setImportedData(val)} />
                    }
                </DialogContent>
                <DialogActions dividers style={{ justifyContent: "space-between" }}>
                    <div>
                        <Button onClick={onDownload} variant="text">Download Sample File</Button>
                    </div>
                    <div>
                        <Button onClick={() => setIsDialogOpen(false)} sx={{ mr: 1 }} variant="outlined" color="error" size="small">Cancel</Button>
                        <Button onClick={sendDataToServer} disabled={(importedData.length > 0 && !isLoading) ? false : true} variant="outlined" size="small" color="success">
                            Upload
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function ExcelFileImport({ fileUpload }) {
    let csvToJsonValue;
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {

                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet, {});
                csvToJsonValue = json;
                fileUpload(csvToJsonValue)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    return (
        <form>
            <label htmlFor="upload">Upload File</label>
            <input
                type="file"
                name="upload"
                id="upload"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={readUploadFile}
            />
        </form>
    );
}