import jsPDF from "jspdf";
import "jspdf-autotable";
import {convertYYYYMMDDToMMDDYY,convertYYYYMMDDToMMDDYYWithTime} from "../../utils/global-functions"

// define a generatePDF function that accepts a tickets argument
const PdfGenerator = (items, container, reference, chassisNum, stops, controlNumber, pickupDetails) => {
  // Initialize jsPDF
  const doc = new jsPDF();

  // Define the columns we want and their titles
  const tableColumn = ["Description", "Rate", "Units", "Amount"];

  // Define an empty array of rows
  const tableRows = [];

  // Calculate total and prepare table rows
  var total = 0;
  items.rows.forEach(row => {
    if (row.history.AccountTypeCode !== "P") {
      const itemData = [
        row.Desciption,
        "$" + Number(row.history.RateAmount).toFixed(2),
        row.history.Units,
        "$" + Number(row.Amount).toFixed(2)
      ];
      total = parseFloat(total) + parseFloat(row.Amount);
      tableRows.push(itemData);
    }
  });

  var body = [...tableRows, ["Total", "", "", "$" + Number(total).toFixed(2)]];

  // Add header text
  doc.setFont("courier", "normal");
  doc.text("Greetings from Eagle Systems,", 20, 20);

  // Add introductory text
  const splitLine1 = doc.splitTextToSize("To improve the accuracy of your invoice, please review and confirm the below charges within the next 24 hours.", 170);
  doc.text(splitLine1, 20, 30);
  const splitLine2 = doc.splitTextToSize("If we do not hear from you, we will assume all is correct and submit it to our accounting team to generate your invoice without corrections.", 170);
  doc.text(splitLine2, 20, 60);

  // Add reference and unit number
  const refNumberText = "REFERENCE NUMBER " + reference;
  doc.text(refNumberText, 20, 90);
  const unitNumberText = "UNIT NUMBER " + container;
  doc.text(unitNumberText, 20, 100);

  // Add chassis number
  const chassisNumberText = "CHASSIS NUMBER: " + chassisNum;
  doc.text(chassisNumberText, 20, 110);

  // Add pickup and return details
    // Add the formatted pickup and return details to the PDF
    const pickupText = `OUTGATE DATE: ${convertYYYYMMDDToMMDDYY(pickupDetails?.pickupDate) || "N/A"}`
    const returnText = `RETURN DATE:  ${convertYYYYMMDDToMMDDYY(pickupDetails?.returnDate) || "N/A"}`;

    doc.text(pickupText, 20, 120);
    doc.text(returnText, 20, 130);

  // Add stop details dynamically
  let yPosition = 160; // Adjusted initial Y position for stops
  if (stops && stops.length > 0) {
    
    stops.forEach((stop) => {
      const stopText = `STOP ${stop.stopNumber}: ${stop.location}`;
      const arrivalText = `ARRIVAL: ${convertYYYYMMDDToMMDDYYWithTime(stop.arrival) || "N/A" }`;
      const departureText = `DEPARTURE: ${convertYYYYMMDDToMMDDYYWithTime(stop.departure) || "N/A"}`;

      doc.text(stopText, 20, yPosition);
      doc.text(arrivalText, 20, yPosition + 10);
      doc.text(departureText, 20, yPosition + 20);

      yPosition += 30; // Adjust spacing for the next stop
    });
  }

  // Use dynamic yPosition to adjust the table's startY
  yPosition += 10;

  // Add the table
  doc.autoTable(tableColumn, body, {
    theme: 'grid',
    startY: yPosition,
    headStyles: { fillColor: "#0911A7" },
    drawRow: function (row, data) {
      doc.setFontStyle('bold');
      doc.setFontSize(10);
    }
  });

  // Save the PDF with a dynamic filename
  doc.save(`Final Charges - Order-${controlNumber}.pdf`);
};

export default PdfGenerator;