import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { Paper, Button, Box, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StopsList from "../Orders/Stops/StopsList";
import orderContext from "../Orders/orderContext";
import Loader from "../../components/Loader/Lodaer";
import StopsTable from "../Orders/Stops/StopsTable";
import RatesAndCharges from "../Orders/RatesAndCharges";
import { StyledSceneContent } from "../../components/styledComponents";
import BillingControl from "../Orders/BillingControl/BillingControlForm";
import { getDefaultBillingControl, orderTypeOptions } from "../EdiOrder/OrdersLogics";
import { mapOrderToClient } from "./components/commonFn";
import { getGLCodes } from "../../store/actions/rateBookActions";
import OrderNotes from "../Orders/OrderNotes";
import { mapOrderToServer } from "../Orders/Order";
import { validateOrderAndStops } from "../../utils/validateOrderAndStops";
import { POST, PUT } from "../../utils/axios";
import {validateOrderFields,validateTruckDriverFieldsInStop} from "../../utils/vaildateOrderStopTruck"

export default function CompletedOrderFormContainer(props) {
  const {
    selectedRow,
    orderType,
    setOrderType,
    selectedRowData,
    setSelectedRow,
    setOnbaseLink
  } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState("");
  const [orderCreateDate, setorderCreateDate] = useState("");
  const [stopsGridView, setStopsGridView] = useState(false);
  let [currentOrder, setCurrentOrder] = useState([]);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(
    params.controlNumber === "new" ? false : true
  );
  const [isEdi210, setIsEdi210] = useState(false);

  let stopsRef = useRef([]);
  let linehaulRef = useRef([]);
  let accessorialRef = useRef([]);
  let orderNotesRef = useRef([]);

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );

  let billingControlRef = useRef(getDefaultBillingControl());
  const [stopsUpdate, setStopsUpdate] = useState(0);
  const [stopsMasterLoad, setStopsMasterLoad] = useState([]);

  useEffect(() => {
    stopsRef.current = [];
    linehaulRef.current = [];
    accessorialRef.current = [];
    billingControlRef.current = getDefaultBillingControl();
    orderNotesRef.current = [];
    let mapOrderData;
    if (selectedRowData) {
      mapOrderData = mapOrderToClient(selectedRowData);
    }

    setCurrentOrder(mapOrderData);
    // }
    fetchGlCodes();
  }, [selectedRowData]);
  const fetchGlCodes = async () => {
    dispatch(getGLCodes());
  };

  const fetchData = (currentOrder, callback) => {
    setIsLoading(true);
    billingControlRef.current =
      currentOrder?.billingControl || getDefaultBillingControl();
    stopsRef.current = currentOrder?.stops || [];
    linehaulRef.current = currentOrder?.linehaul || [];
    accessorialRef.current = currentOrder?.accessorial || [];
    orderNotesRef.current = currentOrder?.orderNotes || [];

    if (callback) {
      setTimeout(() => {
        callback();
      }, 2000);
    }
  };
  const testFunction = () => {
    setOrderType(currentOrder?.billingControl?.orderType || orderType);
    setorderCreateDate(currentOrder?.billingControl?.orderType);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData(currentOrder, testFunction);
    if (typeof currentOrder !== undefined && currentOrder?.stops?.length > 0) {
      setStopsMasterLoad(currentOrder?.stops);
    }
  }, [currentOrder]);

  const handleCancelClick = () => {
    setSelectedRow("");
    setOnbaseLink("")
  };

  const handleSave = async () => {
    try {
    let validationRes = validateOrderFields({ ...billingControlRef.current, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] });
    if (validationRes.status) {
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: validationRes.error })
      return
    }
    let validationTruckDriverRes=validateTruckDriverFieldsInStop(stopsRef.current)
    if (validationTruckDriverRes.length > 0) {
      // Create a string of all errors
      const allErrors = validationTruckDriverRes.map(error => `Stop ${error.stopNo}: ${error.stopError}`).join('<br>');

    
      setSnackOpen(true);
      setSnackContent({
        severity: "error",
        message: <span dangerouslySetInnerHTML={{ __html: allErrors }} /> // Display all errors
        // message: allErrors // Display all errors
      });
    
      return;
    }
      const postObj = mapOrderToServer({
        billingControl: { ...billingControlRef.current, orderType: orderType },
        stops: stopsRef.current,
        ratecharges: [...linehaulRef.current, ...accessorialRef.current],
      });
      if (!postObj) {
        setSnackOpen(true);
        setSnackContent({
          severity: "error",
          message: "Data invalid, Request failed",
        });
        return;
      }

      setSnackOpen(true);
      setSnackContent({ severity: "info", message: "Saving Order ..." });
      if (selectedRow) {
        // We want to update the order
        PUT(
          `/orders/order/${ordersLocation.locationNumber}/${selectedRow}`,
          postObj
        )
          .then((res) => {
            if (res.status === "success") {
              setSnackOpen(true);
              setSnackContent({ severity: "success", message: res.message });
            } else if (res.status === "error") {
              setSnackOpen(true);
              setSnackContent({ severity: "error", message: res.message });
            }
          })
          .catch((e) => {
            setSnackOpen(true);
            setSnackContent({ severity: "error", message: e });
            console.log(e);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = (event) => {
    setSnackOpen(false);
  };

  useEffect(() => {
    setStopsMasterLoad(stopsRef?.current);
  }, [stopsUpdate]);

  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "20%" }}>
          <label style={{ fontSize: "14px", color: "#091DA3" }}>
            {orderType}
          </label>
          <label style={{ fontSize: "14px", marginLeft: 20 }}>
            {selectedRow}
          </label>
        </div>

        <div>
          <Button
            variant="outlined"
            style={{
              width: "80px",
              height: "30px",
              fontSize: "10px",
              marginRight: "10px",
              color: "#000000",
            }}
            startIcon={<SaveIcon style={{ width: "14px", height: "14px" }} />}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{
              width: "80px",
              height: "30px",
              fontSize: "10px",
              color: "#000000",
            }}
            startIcon={<ClearIcon style={{ width: "14px", height: "14px" }} />}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Loader />
        </div>
      ) : (
        <StyledSceneContent>
          <orderContext.Provider
            value={{ isStateChanged, setIsStateChanged, isEdi210, setIsEdi210 }}
          >
            <div>
              <Divider textAlign="left" style={{ marginTop: "1%" }}>
                <Chip label="BILLING CONTROL" size="large" variant="filled" />
              </Divider>

              <BillingControl
                billingControlRef={billingControlRef}
                stopsMasterLoad={stopsMasterLoad}
                orderType={orderType}
                orderTypeOptions={orderTypeOptions}
                disableFull={
                  currentOrder?.billingControl?.orderBy.billToStatus === "V"
                }
                // toTemplate={true}
              />
            </div>

            <div>
              <Box display="flex" alignItems="center" width="100%">
                <Divider sx={{ flex: 1 }}>
                  <Typography variant="body1" sx={{ mx: 2 }}>
                    <Chip label="STOPS" size="large" variant="filled" />
                  </Typography>
                </Divider>
                <Divider sx={{ flex: 1 }} />
                <Divider sx={{ flex: 1 }} />
                <Divider sx={{ flex: 1 }} />
                <Typography variant="body1" sx={{ mx: 2 }}>
                  <FormControlLabel
                    label="Grid View"
                    style={{ fontSize: 50 }}
                    control={
                      <Switch
                        checked={stopsGridView}
                        onChange={(event) =>
                          setStopsGridView(event.target.checked)
                        }
                      />
                    }
                  />
                </Typography>
              </Box>

              {!stopsGridView && (
                <StopsList
                  stopsRef={stopsRef}
                  setStopsUpdate={() => setStopsUpdate(stopsUpdate + 1)}
                  orderType={orderType}
                  orderTypeOptions={orderTypeOptions}
                  disableFull={
                    currentOrder?.billingControl?.orderBy?.billToStatus === "V"
                  }
                  // toTemplate={true}
                  locationNumber={ordersLocation?.locationNumber}
                  controlNumber={selectedRow[0]?._source?.controlNumber}
                />
              )}
              {stopsGridView && (
                <StopsTable
                  stopsRef={stopsRef}
                  disableFull={
                    currentOrder.billingControl.orderBy.billToStatus === "V"
                  }
                />
              )}
            </div>

            <div>
              <Divider
                textAlign="left"
                style={{ marginTop: "2%", marginBottom: "1%" }}
              >
                <Chip label="RATES & CHARGES" size="large" variant="filled" />
              </Divider>
              <RatesAndCharges
                accessorialRef={accessorialRef}
                linehaulRef={linehaulRef}
                orderType={orderType}
                snackBarHandler={(content) => {
                  setSnackOpen(content.status);
                  setSnackContent({
                    severity: content.type,
                    message: content.message,
                  });
                }}
                // disableFull={""}
                disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'}
                billingControlRef={billingControlRef}
                ordersLocation={ordersLocation?.locationNumber}
                controlNumber={selectedRow}
                // isTemplateScreen={true}
                // isCOScreen={true}
                stopsRef={stopsRef}  
              />
              
            </div>
            <div>
              <Divider
                textAlign="left"
                style={{ marginTop: "2%", marginBottom: "1%" }}
              >
                <Chip label="Order Notes" size="large" variant="filled" />
              </Divider>
              <OrderNotes
                controlNumber={"new"}
                locationNumber={ordersLocation?.locationNumber}
                orderNotesRef={orderNotesRef}
              />
            </div>
          </orderContext.Provider>
        </StyledSceneContent>
      )}
      <Snackbar
        open={snackOpen}
        style={{ marginTop: "5%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackContent.severity}
          sx={{ width: "100%" }}
        >
          {snackContent.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
