import React, { useState, useEffect } from 'react'
import { H4, StyledHeader, StyledLogo } from '../styledComponents'
import logo from '../../assets/logo/eagle-systems-logo.png'
import { Button } from './../styledComponents/index';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actions/userActions';
import { getUserFromCookie, logoutInternal } from '../../utils/cookie';
import { useGoogleLogout } from 'react-google-login';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { WindowSharp } from '@mui/icons-material';
import LocationList from '../../scenes/TenderList/LoadsSearch/LocationList'
import { screenChange } from '../../store/actions/loadsActions';
import NavBar from '../../components/NavBar/NavBar'
import Mobileviewnavbar from '../../components/NavBar/Mobileview_navbar'
import logouticon from '../../assets/icons/logout_icon.png'
import { useTheme, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import config from '../../config/config';

function Header() {

    const dispatch = useDispatch();
    const user = getUserFromCookie();
    const theme = useTheme();
    const show_navbar = useMediaQuery(theme.breakpoints.up('md'));
    const show_menu = useMediaQuery(theme.breakpoints.down('md'));

    const { signOut } = useGoogleLogout({
        clientId: '231767053305-h0ps5uct5r4rdi7b6ft62im3thvv1e1s.apps.googleusercontent.com',
        onLogoutSuccess: () => {

            logoutInternal();
            dispatch(logout())
        }
    });

    // To find ScreenWidth and click event
    const [isFullScreenClicked, setIsFullScreenClicked] = useState(false);
    const [getScreenWidth, setGetScreenWidth] = useState();
    const [newVariable, setNewVariable] = useState(null);

    let initialWidth = useSelector(({ userReducer }) => {
        return userReducer.screenWidthSize
    })

    useEffect(() => {
        setGetScreenWidth(initialWidth)
        dispatch(screenChange(false))
        setIsFullScreenClicked(true)
    }, [initialWidth])

    useEffect(() => {

        setNewVariable(getScreenWidth)
    }, [getScreenWidth])

    const screenChangeHandle = () => {
        if (isFullScreenClicked === true) {

            dispatch(screenChange(true))
            setIsFullScreenClicked(false);
        }
        else {

            dispatch(screenChange(false))
            setIsFullScreenClicked(true)
        }
    }

    window.addEventListener('resize', (event) => {
        let screenWidthActual = window.screen.width;
        if (1024 < screenWidthActual) {
            setGetScreenWidth(true)
        } else {
            setGetScreenWidth(false)
        }

    });
     // Check server status from config
const isServerDown = config;

    return (
        <StyledHeader>
            <StyledLogo style={{ width: "150px" }}>
                <div style={{ position: 'relative', zIndex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <img src={logo} width='70px' style={{ marginBottom: '10px' }} alt={'eagle logo'} />
                    <H4 style={{ fontStyle: "italic" }}>Load Dispatching</H4>
                </div>
            </StyledLogo>

            <div style={{ width: `calc(100% - 462px)`, justifyContent: 'flex-start', alignItems: 'center' }}>
                {show_navbar && <NavBar />}


            </div>

            <div style={{ display: 'flex', alignItems: 'center', width: '312px' }}>

                {
                    newVariable ? <div>
                        {
                            isFullScreenClicked ? <Button style={{ minWidth: "25px", marginRight: "7px", borderRadius: "6px", paddingTop: '4px', paddingBottom: '5px' }} onClick={screenChangeHandle}><FullscreenExitIcon style={{ fontSize: "20px" }} /></Button> : <Button style={{ minWidth: "25px", marginRight: "7px", borderRadius: "6px", paddingTop: '4px', paddingBottom: '5px' }} onClick={screenChangeHandle}><FullscreenIcon style={{ fontSize: "20px" }} /></Button>
                        }
                    </div> : <></>
                }
                <div style={{ display: 'flex', minWidth: "141px", marginRight: "12px", backgroundColor: "white" }}>
                    <LocationList />

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '80px' }}>
                    {!show_navbar && <Mobileviewnavbar profilepic={user.picture} />}

                    {
                        show_navbar &&
                        <>
                            <img src={user.picture} width={40} height={40} style={{ borderRadius: '50px', marginLeft: '10px', marginBottom: '8px' }} alt="profile" />
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
                                <a onClick={() => signOut()} style={{ color: 'white', textDecoration: 'underline' }}>
                                    Logout
                                </a>
                                <img src={logouticon} style={{ width: '20px', height: '20px', fill: 'white' }} />
                            </div>
                        </>
                    }

                </div>

            </div>
            {isServerDown.serverDown && (
        <div
          style={{
            position: 'fixed',
            top: "15%",
            left: 0,
            right: 0,
            backgroundColor: '#ffcccb',
            color: '#900',
            textAlign: 'center',
            padding: '10px',
            fontWeight: 'bold',
            zIndex: 9999,
          }}
        >
          {isServerDown.message||"Server is down. Please try again later"}
        </div>
      )}
        </StyledHeader>
    )
}

export default Header
