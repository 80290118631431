import * as React from "react";
import { useState, useEffect } from "react";
import {
  Stack,
  Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import {
  DataGridPro, useGridApiRef
} from "@mui/x-data-grid-pro";
import { FSCCodes, miscReceivables, miscPayables } from '../CommonFn';
import { convertMilitaryToYMD, convertYMDToMonthName, formatCurrency, currencySortComparator, formatPercentageNum, percentageSortComparator, safeParseFloat, safeParseNumber} from '../../../utils/global-functions';
import ExportSummaryByDay from './ExportSummaryByDay';

export default function SummaryByDay(props) {
  const { activeTab, rawData, locationNumber, isLoading, filterParams,renderExportButtons } = props;
  const [rows, setRows] = useState([]);
  const apiRef = useGridApiRef()
  useEffect(() => {

    // Filter by dateRange using dateType (e.g., billingDate)
    if (locationNumber !== 0 && filterParams && filterParams?.dateType && filterParams?.dateRange && filterParams?.dateRange.length === 2) {
      setRows(processDataForDateRange(rawData || [], filterParams));
    }

    // if (locationNumber !== 0 && rawData.length > 0 ) {
    //   setRows(processDataForDateRange(rawData || [], filterParams));
    // }
  }, [activeTab, rawData])

  // Function to convert date range and process data
  // const processDataForDateRange = (inputData, filters) => {
  //   const { dateType, startingPercentage, endingPercentage, revenuePercentage, excludeFSC, dateRange } = filters;
  //   const dateMap = new Map();

  //   // Clear the map
  //   dateMap.clear();

  //   // Initialize the date range from the provided start and end dates
  //   const [startDate, endDate] = dateRange;
  //   let currentDate = Number(startDate);
  //   const endDateObj = Number(endDate);

  //   // validate if date is set or not
  //   // if (dateType === '' || startDate === null || endDate === null) {
  //   //   alert('Date type & date values are mandatory!')
  //   //   return rows;
  //   // }

  //   // Loop to initialize the map for each day in the date range
  //   while (currentDate <= endDateObj) {
  //     for (const location of new Set(inputData.map(item => item.location))) { 

  //       const dayKey = `${currentDate}-${location}`; //currentDate;

  //       dateMap.set(dayKey, {
  //         id: dayKey,
  //         location: location,
  //         day: convertYMDToMonthName(convertMilitaryToYMD(currentDate)),
  //         totalLoads: 0,
  //         revenue: 0,
  //         miscRevenue: 0,
  //         totalRevenue: 0,
  //         expense: 0,
  //         miscExpense: 0,
  //         totalExpense: 0,
  //         totalNet: 0,
  //         ptNetExclMisc: 0,
  //         totalPercent: 0,
  //         ptPercent: 0
  //       });
  //       currentDate += 1
  //     }
  //   }

  //   // Make a deep copy of the data to avoid mutating the original
  //   const clonedData = JSON.parse(JSON.stringify(inputData));

  //   // Process each input object
  //   clonedData.forEach(item => {
  //     const source = item;
  //     const dateField = source[dateType];
  //     const dateKey = dateField;

  //     if (dateMap.has(dateKey)) {

  //       // Filter by excludeFSC (exclude entries with FUEL SURCHARGE in glDescription)
  //       if (excludeFSC) {
  //         source.rates = source.rates.filter(rate => !FSCCodes.includes(rate.glCode));
  //       }

  //       let rates = source.rates;

  //       let revenue = rates
  //         .filter(rate => rate.payableReceivable === "R" && !miscReceivables.includes(rate.glCode))
  //         .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

  //       let expense = rates
  //         .filter(rate => rate.payableReceivable === "P" && !miscPayables.includes(rate.glCode))
  //         .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

  //       let miscRevenue = rates
  //         .filter(rate => rate.payableReceivable === "R" && miscReceivables.includes(rate.glCode))
  //         .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

  //       let miscExpense = rates
  //         .filter(rate => rate.payableReceivable === "P" && miscPayables.includes(rate.glCode))
  //         .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

  //       // Update the map for the current date
  //       const existingData = dateMap.get(dateKey);

  //       // Calculations 
  //       revenue = existingData.revenue + revenue;
  //       expense = existingData.expense + expense;
  //       miscRevenue = existingData.miscRevenue + miscRevenue;
  //       miscExpense = existingData.miscExpense + miscExpense;

  //       const totalRevenue = revenue + miscRevenue;
  //       const totalExpense = expense + miscExpense;
  //       const totalNet = totalRevenue - totalExpense;
  //       const totalPercent = (totalExpense / totalRevenue) * 100;
  //       const ptPercent = (expense / revenue) * 100;
  //       const ptNetExclMisc = revenue - expense;
  //       const totalLoads = Number(existingData.totalLoads) + 1; //Number(source.totalStops);

  //       if (dateKey != 0)
  //         dateMap.set(dateKey, {
  //           id: dateKey,
  //           day: convertYMDToMonthName(convertMilitaryToYMD(dateKey)),
  //           totalLoads: totalLoads,
  //           revenue: Number(revenue), // correct
  //           miscRevenue: Number(miscRevenue),
  //           totalRevenue: Number(totalRevenue), // correct
  //           expense: Number(expense), // correct
  //           miscExpense: Number(miscExpense),
  //           totalExpense: Number(totalExpense),
  //           totalNet: Number(totalNet), // correct
  //           ptNetExclMisc: Number(ptNetExclMisc), // correct
  //           totalPercent: Number(totalPercent), // correct
  //           ptPercent: Number(ptPercent) // correct
  //         });
  //     }
  //   });

  //   // Filter by percentage if necessary
  //   let filteredData = Array.from(dateMap.values());

  //   if (revenuePercentage && startingPercentage && endingPercentage) {
  //     filteredData = filteredData.filter(item => item[revenuePercentage] >= startingPercentage && item[revenuePercentage] <= endingPercentage)
  //   }

  //   // First filter out the items where `day` is 0, then map over the filtered data
  //   filteredData = filteredData
  //     .filter(item => item.day !== 0)  // Remove entries where `day` is 0
  //     .map((item, index) => {
  //       return {
  //         id: index,
  //         // location: rawData[index]?.location || "",
  //         day: item.day,
  //         totalStops: Number(item.totalLoads),
  //         revenue: item.revenue.toFixed(2),
  //         miscRevenue: item.miscRevenue.toFixed(2),
  //         totalRevenue: item.totalRevenue.toFixed(2),
  //         expense: item.expense.toFixed(2),
  //         miscExpense: item.miscExpense.toFixed(2),
  //         totalExpense: item.totalExpense.toFixed(2),
  //         totalNet: item.totalNet.toFixed(2),
  //         ptNetExclMisc: item.ptNetExclMisc.toFixed(2),
  //         totalPercent: item.totalPercent.toFixed(2),
  //         ptPercent: item.ptPercent.toFixed(2)
  //       };
  //     });
  //   return filteredData;
  // }

  const processDataForDateRange = (inputData, filters) => {
    const { dateType, startingPercentage, endingPercentage, revenuePercentage, excludeFSC, dateRange } = filters;
    const dateMap = new Map();
    const orderCountMap = new Map(); // Track unique order numbers per (date, location)

    const [startDate, endDate] = dateRange;
    let currentDate = Number(startDate);
    const endDateObj = Number(endDate);

    // Initialize map for all date-location combinations
    while (currentDate <= endDateObj) {
        for (const location of new Set(inputData.map(item => item.location))) {
            const key = `${currentDate}-${location}`;
            dateMap.set(key, {
                id: key,
                location: location,
                day: currentDate, //convertYMDToMonthName(convertMilitaryToYMD(currentDate)),
                totalLoads: 0,
                revenue: 0,
                miscRevenue: 0,
                totalRevenue: 0,
                expense: 0,
                miscExpense: 0,
                totalExpense: 0,
                totalNet: 0,
                ptNetExclMisc: 0,
                totalPercent: 0,
                ptPercent: 0
            });

            orderCountMap.set(key, new Set()); // Initialize unique order tracker
        }
        currentDate += 1;
    }
    // Process each input object
    inputData.forEach(item => {
        const source = item;
        const dateKey = source[dateType]; // Date field (e.g., deliveryDate)
        const locationNumber = source.location; // Location
        const controlNumber = source.controlNumber; // Unique order number
        const key = `${dateKey}-${locationNumber}`;
        
        if (dateMap.has(key)) {
            if (excludeFSC) {
                source.rates = source.rates.filter(rate => !FSCCodes.includes(rate.glCode));
            }

            let rates = source.rates;
            let revenue = rates.filter(rate => rate.payableReceivable === "R" && !miscReceivables.includes(rate.glCode)).reduce((sum, rate) => sum + parseFloat(rate.amount), 0);
            let expense = rates.filter(rate => rate.payableReceivable === "P" && !miscPayables.includes(rate.glCode)).reduce((sum, rate) => sum + parseFloat(rate.amount), 0);
            let miscRevenue = rates.filter(rate => rate.payableReceivable === "R" && miscReceivables.includes(rate.glCode)).reduce((sum, rate) => sum + parseFloat(rate.amount), 0);
            let miscExpense = rates.filter(rate => rate.payableReceivable === "P" && miscPayables.includes(rate.glCode)).reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

            const existingData = dateMap.get(key);
            revenue += existingData.revenue;
            expense += existingData.expense;
            miscRevenue += existingData.miscRevenue;
            miscExpense += existingData.miscExpense;

            const totalRevenue = revenue + miscRevenue;
            const totalExpense = expense + miscExpense;
            const totalNet = totalRevenue - totalExpense;
            const totalPercent = (totalExpense / totalRevenue) * 100;
            const ptPercent = (expense / revenue) * 100;
            const ptNetExclMisc = revenue - expense;

            // Add unique `controlNumber` to track count for this location & date
            orderCountMap.get(key).add(controlNumber);
            const totalLoads = orderCountMap.get(key).size; // Correct count of unique orders per day & location

            dateMap.set(key, {
                id: key,
                location: locationNumber,
                day: dateKey, //convertYMDToMonthName(convertMilitaryToYMD(dateKey)),
                totalLoads: totalLoads, // Now correctly counting unique controlNumbers
                revenue: Number(revenue),
                miscRevenue: Number(miscRevenue),
                totalRevenue: Number(totalRevenue),
                expense: Number(expense),
                miscExpense: Number(miscExpense),
                totalExpense: Number(totalExpense),
                totalNet: Number(totalNet),
                ptNetExclMisc: Number(ptNetExclMisc),
                totalPercent: Number(totalPercent),
                ptPercent: Number(ptPercent)
            });
        }
    });

    // Convert map values to array & filter invalid days
    let filteredData = Array.from(dateMap.values()).filter(item => item.day !== 0);

    if (revenuePercentage && startingPercentage && endingPercentage) {
        filteredData = filteredData.filter(item => item[revenuePercentage] >= startingPercentage && item[revenuePercentage] <= endingPercentage);
    }

    return filteredData.map((item, index) => ({
        id: index,
        location: item.location,
        day: item.day,
        totalStops: Number(item.totalLoads), // Corrected totalLoads calculation
        revenue: item.revenue.toFixed(2),
        miscRevenue: item.miscRevenue.toFixed(2),
        totalRevenue: item.totalRevenue.toFixed(2),
        expense: item.expense.toFixed(2),
        miscExpense: item.miscExpense.toFixed(2),
        totalExpense: item.totalExpense.toFixed(2),
        totalNet: item.totalNet.toFixed(2),
        ptNetExclMisc: item.ptNetExclMisc.toFixed(2),
        totalPercent: item.totalPercent.toFixed(2),
        ptPercent: item.ptPercent.toFixed(2)
    }));
};



  // Function to calculate totals for price and quantity columns
  const calculateTotals = (rows) => {
    let revenue = 0;
    let miscRevenue = 0;
    let totalRevenue = 0;
    let expense = 0;
    let miscExpense = 0;
    let totalExpense = 0;
    let totalNet = 0;
    let ptNetExclMisc = 0;
    let totalStops = 0;

    rows.forEach((row) => {
      revenue += safeParseFloat(row.revenue);
      miscRevenue += safeParseFloat(row.miscRevenue);
      totalRevenue += safeParseFloat(row.totalRevenue);
      expense += safeParseFloat(row.expense);
      miscExpense += safeParseFloat(row.miscExpense);
      totalExpense += safeParseFloat(row.totalExpense);
      totalNet += safeParseFloat(row.totalNet);
      ptNetExclMisc += safeParseFloat(row.ptNetExclMisc);
      totalStops += safeParseNumber(row.totalStops);
    });

    return {
      revenue,
      miscRevenue,
      totalRevenue,
      expense,
      miscExpense,
      totalExpense,
      totalNet,
      ptNetExclMisc,
      // totalPercent: ((totalExpense / totalRevenue) * 100).toFixed(2),
      // ptPercent: ((expense / revenue) * 100).toFixed(2),
      totalPercent: totalRevenue ? Number(((totalExpense / totalRevenue) * 100).toFixed(2)) : 0,
      ptPercent: revenue ? Number(((expense / revenue) * 100).toFixed(2)) : 0,      
      totalStops
    };
  };

  const totals = calculateTotals(rows);

  const pinnedRows = {
    bottom: [
      {
        id: 'total-rows',
        day: 'Totals',
        totalStops: Number(totals.totalStops),
        revenue: `${totals.revenue.toFixed(2)}`,
        miscRevenue: `${totals.miscRevenue.toFixed(2)}`,
        totalRevenue: `${totals.totalRevenue.toFixed(2)}`,
        expense: `${totals.expense.toFixed(2)}`,
        miscExpense: `${totals.miscExpense.toFixed(2)}`,
        totalExpense: `${totals.totalExpense.toFixed(2)}`,
        totalNet: `${totals.totalNet.toFixed(2)}`,
        ptNetExclMisc: `${totals.ptNetExclMisc.toFixed(2)}`,
        totalPercent: `${parseFloat(totals.totalPercent) || 0}`,
        ptPercent: `${parseFloat(totals.ptPercent) || 0}`
      }
    ],

  };

  const tableColumns = [
    {
      field: "location",
      headerName: "Loc#",
      minWidth: 90,
      flex: 1,
      sortable: true
    },
    {
      field: "day",
      headerName: "Day",
      minWidth: 90,
      flex: 1,
      sortable: true,
      renderCell: (params) => convertYMDToMonthName(convertMilitaryToYMD(params.row.day))
    },
    {
      field: "totalStops",
      headerName: "Total Loads",
      minWidth: 90,
      flex: 1,
      sortable: true
    },
    {
      field: "revenue",
      headerName: "Revenue",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator
    },
    { field: "miscRevenue", headerName: "Misc Revenue", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value) ,sortComparator: currencySortComparator},
    { field: "totalRevenue", headerName: "Total Revenue", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value),sortComparator: currencySortComparator },
    {
      field: "expense",
      headerName: "Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator
    },
    {
      field: "miscExpense",
      headerName: "Misc Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator
    },
    {
      field: "totalExpense",
      headerName: "Total Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      sortComparator: currencySortComparator
    },
    { field: "totalNet", headerName: "Total Net", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value) ,sortComparator: currencySortComparator},
    { field: "ptNetExclMisc", headerName: "PT Net (Excl Misc)", minWidth: 90, flex: 1 , renderCell: (params) => formatCurrency(params.value) ,sortComparator: currencySortComparator},
    { field: "totalPercent", headerName: "Total %", minWidth: 90, flex: 1, renderCell: (params) => formatPercentageNum(params.value), sortComparator: percentageSortComparator },
    { field: "ptPercent", headerName: "PT %", minWidth: 90, flex: 1, renderCell: (params) => formatPercentageNum(params.value), sortComparator: percentageSortComparator },
  ];
  useEffect(() => {
    renderExportButtons(
        <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
                zIndex: 9999,
                // marginTop: "-2%"
            }}
        >
            <Button
                variant="text"
                size="small"
                onClick={() => apiRef.current.exportDataAsCsv()}
                startIcon={<TextSnippetIcon  sx={{ color: (!rows || rows.length === 0) ? 'gray' : 'primary', opacity: (!rows || rows.length === 0) ? 0.5 : 1 }} />}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                disabled={!rows || rows.length === 0}
            >
                CSV
            </Button>
            <Button
                variant="text"
                size="small"
                // onClick={ExportSummaryByControl(rows, tableColumns)}
                // onClick={() => ExportSummaryByControl(rows, tableColumns)} 
                // onClick={() => ExportSummaryByControl(rows, tableColumns)()}
                onClick={() => {
                    console.log("Rows:", rows);
                    console.log("Table Columns:", tableColumns);
                    ExportSummaryByDay(rows, tableColumns)(); // Call the function
                }}
                startIcon={<PictureAsPdfIcon  sx={{ color: (!rows || rows.length === 0) ? 'gray' : 'primary', opacity: (!rows || rows.length === 0) ? 0.5 : 1 }}/>}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                disabled={!rows || rows.length === 0}
            >
                PDF
            </Button>
        </Stack>
    );
}, [rows]);
  return (
    <ComponentWrapper>
      {/* <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'absolute',
          //bottom:0,
          right: "1%",
          zIndex: 9999,
          marginTop: "-2%"
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => apiRef.current.exportDataAsCsv()}
          startIcon={<TextSnippetIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          CSV
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={ExportSummaryByDay(rows, tableColumns)}
          startIcon={<PictureAsPdfIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          PDF
        </Button>
      </Stack> */}
      <Stack
        //marginTop={2}
        sx={{
          height: window.innerHeight - 270,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(11, 67, 135, 1)",
            color: "white",
          },
          "& .isSubtotal": {
            fontWeight: "bold",
            // color: "blue",
            backgroundColor: "#E8E8E8 !important",
            // backgroundColor: "#F0F0F0 !important",
          },

          "& .pinned-row": {
            backgroundColor: "#f0f0f0",  /* Light gray background */
            fontWeight: "bold",          /* Bold text */
            color: "#000000"            /* Black text */
          },
          "& .pinned-row .MuiDataGrid-cell": {
            borderTop: "2px solid #000000"
          }

        }}
      >
        <DataGridPro
            apiRef={apiRef}
            hideFooter={true}
            rows={rows}
            columns={tableColumns}
            loading={isLoading}
            pinnedRows={pinnedRows}
            getRowClassName={(params) => params.id === 'total-rows' ? 'pinned-row' : ''}
        />
      </Stack>
    </ComponentWrapper>
  );
}
