import { useEffect, useState } from 'react';
// import material components
import {
    Tabs, Tab, Box, FormControlLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    RadioGroup,
    Radio,
    FormControl,
    FormGroup,
    FormLabel
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DriversList from '../../DriverInfo/DriversList';
import { useDispatch } from 'react-redux';
import { tenderDispatchStops } from '../../../store/actions/loadsActions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const options = [
    { label: 'Stay With', value: 'staywith' },
    { label: 'Drop', value: 'drop' },
    { label: 'Pull', value: 'pull' }
];
const ioMap = { 'I': 'inbound', 'O': 'outbound' }
export default function TenderModalNew(props) {
    const dispatch = useDispatch();
    const {
        modalState,
        handleModalState,
        handleTenderSubmitState,
        type,
        row,
        canEnterTruckDetailsDepart,
        canEnterTruckDetailsArrive,
        stopState,
        stopsRef
    } = props;
    const [value, setValue] = useState(0);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [snackState, setSnackState] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [tenderPayload, setTenderPayload] = useState({
        stops: row?.lineNumber || 0,
        driverId: 0,
        drop: '',
        controlNumber: row?.control || 0,
        io: (row.io !== '') ? ioMap[row.io] : null,
        locationNumber: row.locationNumber,
        tenderStatus: (value === 1) ? 'A' : 'T',
    });

    const handleTenderSubmit = () => {
        setSnackState(true);
        setDisableSubmitButton(true);
        setSnackContent({ severity: "info", message: "Saving Tender changes..." });
    
        if (tenderPayload.driverId === 0 || tenderPayload.controlNumber === 0 || tenderPayload.drop === '') {
            setSnackContent({ severity: "error", message: "Invalid Driver or Tender Type!" });
            setDisableSubmitButton(false);
            return;
        }
    
        const handleTenderDispatch = () => {
            dispatch(tenderDispatchStops({
                ...tenderPayload, tenderStatus: value === 1 ? 'A' : 'T',
            })).then((res) => {
                if (res?.httpCode === 200) {
                    setSnackContent({ severity: 'success', message: res.message })
                    setTimeout(() => {
                       setDisableSubmitButton(false);
                       handleModalState(false);
                       handleTenderSubmitState({ ...tenderPayload, tenderStatus: (value == 1) ? 'A' : 'T' });
                     }, 1000)
                } else if (res?.httpCode === 422 || res?.httpCode === 400) {
                    setSnackContent({ severity: 'error', message: res.message });
                    setTimeout(() => {
                        setDisableSubmitButton(false);
                        handleModalState(false);
                    }, 2000);
                } else {
                    setSnackContent({ severity: "error", message: "Something went wrong, please try again!" });
                    setDisableSubmitButton(false);
                }
            });
        };
    
        handleTenderDispatch();
    };

    const handleRadioChange = (val) => {
        if (val === 'drop' || val === 'staywith') {
            // Call canEnterTruckDetailsArrive function
            const isValid = value===0?canEnterTruckDetailsArrive(stopState?.stop,stopsRef.current):true; // assuming currentStopId is available
            if (!isValid) {
                alert("Can't select without entering previous stop departure data");
                return;
            }
        } else if (val === 'pull') {
            // Call canEnterTruckDetailsDepart function
            const isValid = value===0?canEnterTruckDetailsDepart(stopState):true; // assuming currentStopData is available
            if (!isValid) {
                alert("Can't select without entering arrival truck data for this stop");
                return;
            }
        }
        // If validation passes, update the state
        setTenderPayload({ ...tenderPayload, drop: val });
    };
    return (
        <div className="mainContainer" >
            <Dialog
                fullWidth={true}
                maxWidth={`sm`}
                open={modalState} onClose={() => handleModalState(false)}>
                <DialogTitle sx={{ color: 'white', fontSize: '14px', backgroundColor: '#0B4387', height: '40px', alignContent: 'center', padding: '10px' }}>{type}</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={(e, newVal) => {setValue(newVal);setTenderPayload({ ...tenderPayload, drop: "" })}} aria-label="basic tabs example">
                                <Tab label="Tender" {...a11yProps(0)} />
                                <Tab label="Assign" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Box>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormLabel>Driver</FormLabel>
                                    <DriversList
                                        valueChangeObj={(val) => { setTenderPayload({ ...tenderPayload, driverId: val.id, truckNumber: val.truck }); setDisableSubmitButton(false) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormGroup>
                                        <FormLabel >Tender Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="ringtone"
                                            name="ringtone"
                                            onChange={(event, val) => handleRadioChange(val)}
                                        >
                                            {options.map((option) => (
                                                <FormControlLabel
                                                    value={option.value}
                                                    key={option.value}
                                                    control={<Radio checked={tenderPayload.drop===option.value?true:false}/>}
                                                    label={option.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Box>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormLabel>Driver</FormLabel>
                                    <DriversList
                                        valueChangeObj={(val) => { setTenderPayload({ ...tenderPayload, driverId: val.id, truckNumber: val.truck }); setDisableSubmitButton(false) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormGroup>
                                        <FormLabel >Tender Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="ringtone"
                                            name="ringtone"
                                            onChange={(event, val) => handleRadioChange(val)}
                                        >
                                            {options.map((option) => (
                                                <FormControlLabel
                                                    value={option.value}
                                                    key={option.value}
                                                    control={<Radio checked={tenderPayload.drop===option.value?true:false} />}
                                                    label={option.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </TabPanel>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModalState(false)} variant="outlined" size="small" color="primary">Cancel</Button>
                    <Button disabled={disableSubmitButton} onClick={() => handleTenderSubmit()} variant="contained" size="small" color="primary">Save</Button>
                </DialogActions>
                <Snackbar open={snackState} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackState(false)} >
                    <Alert onClose={() => setSnackState(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                        {snackContent.message}
                    </Alert>
                </Snackbar>
            </Dialog>
        </div>
    )
}