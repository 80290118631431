import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from "@mui/material";

import cancelicon from '../../assets/icons/cancel_icon.png'
import addicon from '../../assets/icons/add_icon.png'
import { Button } from "../styledComponents";
import moment from 'moment';
import dayjs from 'dayjs';
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

function Filter(props) {

    const { showFilter, setShowFilter, handleFilter } = props;
    // const [inputFields, setInputFields] = useState([
    //     { clause: '', field: '', operand: '',value:'' }
    // ])

    const { searchClause, setSearchClause } = props

    const viewname = [
        { label: 'OR' },
        { label: 'AND' },
    ]
    console.log(props)
    const viewfield = props.searchFields
    // const viewfield = [
    //     { label: 'Control #', fieldName:"controlNumber" },
    //     { label: 'Order By', fieldName:"shipperAgentCode" },
    //     { label: 'Shipper' },
    // ]

    const viewnumber = [
        { label: 'IS' },
        { label: 'IS NOT' },
        { label: 'BETWEEN' },
        { label: 'IS EMPTY' },
        { label: 'IS NOT EMPTY' },
    ]
    const valueOperand=[
        { label: 'IS' },
        { label: 'IS NOT' },
        // { label: 'BETWEEN' },
        { label: 'ABOVE' },
        { label: 'BELOW' },
      
    ]
    const standardOperand=[
        { label: 'IS' },
        { label: 'IS NOT' },
        { label: 'IS EMPTY' },
        { label: 'IS NOT EMPTY' },
        { label: 'STARTS WITH' },
        { label: 'DOES NOT START WITH' },
        { label: 'CONTAINS' },
        { label: 'DOES NOT CONTAIN' },
        
    ]
    const arrayOperand=[
      { label: 'IN' },
      { label: 'NOT IN' },
    ]

    const relativeDate = [
        'Today',
        'Yesterday',
    ]

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

    const handleFilterClose = () => {
        setShowFilter(false);
    }

    const handleApplyFilter = () => {
        console.log('filter Object', searchClause)

        const updatedSearch = searchClause.map(item =>
            item.operand.label === 'BETWEEN' && item.field.format === 'Date' ? { ...item, field: { ...item.field, format: 'DateRange'} } : item
        );

        // const filter = searchClause.map(item => ({
        //     clause: item.clause.label,
        //     field: item.field.fieldName,
        //     operand:item.operand.label,
        //     value: item.value,
        // }))

        handleFilter(updatedSearch);
        setShowFilter(false);
    }

    const handleAddnew = () => {
        let newfield = { clause: '', field: '', operand: '', value: '' }
        setSearchClause([...searchClause, newfield])
    }

    const handleRemoveField = (index) => {
        let data = [...searchClause];
        data.splice(index, 1)
        setSearchClause(data)
    }

    const onFieldChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].field = value
        setSearchClause([...values])
    }

    const onClauseChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].clause = value
        setSearchClause([...values])
    }

    const onOperandChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].operand = value
        if(value.label==="IS EMPTY" || value.label==="IS NOT EMPTY"){
            values[index].value = ""
        }
        setSearchClause([...values])
    }

    const onValueChange = (index, value) => {
        console.log('Changing Value', value)
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].value = value
        setSearchClause([...values])
    }

    const convertRelativeDate = (term) => {
        const normalizedTerm = term.toLowerCase().trim();

        if (normalizedTerm === 'today') {
            return moment().format('MM/DD/YY');
        } else if (normalizedTerm === 'yesterday') {
            return moment().subtract(1, 'days').format('MM/DD/YY');
        }

        // Default to today's date if term doesn't match
        return moment().format('MM/DD/YY');
    };
    

    const handleAccept = (index, dateValue) => {
        console.log("dateValue", dateValue);
        const formattedStartDate = dateValue[0] ? "1" + moment(dateValue[0].toDate()).format('YYMMDD') : '';
        const formattedEndDate = dateValue[1] ? "1" + moment(dateValue[1].toDate()).format('YYMMDD') : '';
        let values = searchClause;
        values[index].value = formattedStartDate + '|' + formattedEndDate;
        setSearchClause([...values]);
        handleClosePicker()
      };

    const formatedDateRangeFunc = (dateValueStr) => {
        // Check if dateValueStr exists and is in the expected format
        if (!dateValueStr || !dateValueStr.includes('|')) {
          return '';
        }
      
        // Split the dateValueStr to get the start and end dates
        const [startDateStr, endDateStr] = dateValueStr.split('|');
      
        // Extract the actual date by removing the leading '1'
        const startDate = startDateStr ? dayjs(startDateStr.substring(1), 'YYMMDD') : null;
        const endDate = endDateStr ? dayjs(endDateStr.substring(1), 'YYMMDD') : null;
      
        // Format the dates in MM/DD/YY format
        const formattedStartDate = startDate ? startDate.format('MM/DD/YY') : '';
        const formattedEndDate = endDate ? endDate.format('MM/DD/YY') : '';
      
        // Return the formatted date range in MM/DD/YY - MM/DD/YY format
        return `${formattedStartDate} - ${formattedEndDate}`;
      };

      const handleOpenPicker = (index) => {
        setIsPickerOpen(index); // Set picker open for the clicked index
      };
    
      // Function to handle closing the date picker
      const handleClosePicker = () => {
        setIsPickerOpen(null); // Close picker
      };
      //shipperOrAgentLoc- IS COD
      const ArrayFieldsName=["status","edi","stopStatus","hazMat"]
      const onlyInOperandFieldName=["shipperOrAgentLoc"]
      const getFieldOptions = (fieldName) => {
        switch (fieldName) {
          case "controlNumber":
            return { options: [], freeText: true, multiple: false };
          case "status":
            return { options: ["N", "P", "A", "C", "B", "V"], freeText: false, multiple: true };
          case "shipperOrAgentLoc":
            return { options: ["Y", "N"], freeText: false, multiple: false };
          case "edi":
            return { options: [204, 210, 214], freeText: false, multiple: false };
            case "hazMat":
              return { options: ["HAZMAT", "TANKER", "OVERWEIGHT", "REEFER","RESIDENIAL","HIGH VALUE","N"], freeText: false, multiple: true };
          case "stopYard":
            return { options: ["Y", "N"], freeText: false, multiple: false };
        case "stopStatus":
            return { options: ["T", "C","A"], freeText: false, multiple: true };
          case "Date":
            return { options: ["Today", "Yesterday"], freeText: false, multiple: false };
          default:
            return { options: [], freeText: true, multiple: false }; // Default to free text for other fields
        }
      };
      const getOptions = (field) => {
        switch (true) {
            case field.format === 'Date':
                return viewnumber;
            case field.format === 'Number':
                return valueOperand;
            case ArrayFieldsName.includes(field.fieldName):
                return arrayOperand;
            case onlyInOperandFieldName.includes(field.fieldName):
                return [arrayOperand[0]];
            default:
                return standardOperand;
        }
    };
    
    return (
        <div style={{ width: '50%', position: 'absolute', zIndex: '10', backgroundColor: '#FFFFFF', marginLeft: '250px', marginTop: '265px', borderRadius: '5px', borderColor: '#DCDCDC', borderStyle: 'solid', borderWidth: '2px', visibility: (showFilter == true ? 'visible' : 'hidden') }} >

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
                    <Button onClick={handleApplyFilter}>Apply</Button>
                    <Button onClick={() => props.loadDefaultView(null, null)}>Clear</Button>
                </div>
                <img src={cancelicon} style={{ width: '12px', height: '12px' }} onClick={handleFilterClose} />
                {/* <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', paddingRight: '1rem' }} onClick={() => props.loadDefaultView(null, null)}> {`Clear Filter`} </Typography>

                </div>

                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button onClick={handleApplyFilter}>Apply</Button>
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={cancelicon} style={{ width: '12px', height: '12px' }} onClick={handleFilterClose} />
                    </div>
                </div> */}
            </div>
            <div style={{ width: '94%', backgroundColor: '#707070', height: '0.5px', opacity: '40%', marginLeft: '1rem' }}></div>

            <div style={{ flexDirection: 'row', padding: '1rem', alignItems: 'center', display: 'flex', width: '100%', }}>
                <div style={{ width: "10%" }}>
                    <label>Where :</label>
                </div>
                <div style={{ width: "90%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        className="autocomplestyl"
                        id="combo-box-demo"
                        options={viewfield}
                        value={searchClause[0].field}
                        onChange={(event, value) => onFieldChange(0, value)}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled
                    />

                    <Autocomplete
                        disablePortal
                        disableClearable
                        className="autocomplestyl"
                        id="combo-box-demo"
                        options={viewnumber}
                        value={searchClause[0].operand}
                        onChange={(event, value) => onOperandChange(0, value)}
                        // defaultValue={'is'}
                        // style={{ width: '40%' }}
                        style={{ width: '30%', marginLeft: '6px', marginRight: '6px' }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled
                    />

                    <TextField
                        disablePortal
                        size="small"
                        className="autocomplestyl"
                        id="combo-box-demo"
                        defaultValue={'value'}
                        value={searchClause[0].value}
                        style={{ width: '40%' }}
                        onChange={(event) => onValueChange(0, event.target.value)}
                        disabled
                    />
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={cancelicon} style={{ width: '12px', height: '12px' }} />
                    </div>
                </div>
            </div>

            {searchClause.map((input, index) => {
                if (index === 0)
                    return (<></>)
                return (
                    <div key={index} style={{ flexDirection: 'row', paddingLeft: '1rem', paddingRight: '1rem', alignItems: 'center', alignItems: 'flex-start', display: 'flex', marginBottom: '5px' }}>
                        <div style={{ width: "10%", marginright: '2px', display: 'flex', alignItems: 'flex-start' }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={viewname}
                                // defaultValue={'OR'}
                                value={searchClause[index].clause}
                                onChange={(event, value) => onClauseChange(index, value)}
                                sx={{ width: '90%', paddingBottom: '2px' }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <div style={{ width: "90%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                // Exclude the "locationNumber" option
                                options={viewfield.filter(option => option.fieldName !== "locationNumber")}
                                // defaultValue={'Name'}
                                style={{ width: '30%' }}
                                renderInput={(params) => <TextField {...params} />}
                                value={searchClause[index].field}
                                onChange={(event, value) => onFieldChange(index, value)}
                            />

                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={getOptions(searchClause[index].field)}
                                // options={searchClause[index].field.format === 'Date' ? viewnumber:searchClause[index].field.format === 'Number'?valueOperand:ArrayFieldsName.includes(searchClause[index].field.fieldName)?arrayOperand:onlyInOperandFieldName.includes(searchClause[index].field.fieldName)?[arrayOperand[0]]: standardOperand}
                                // defaultValue={'is'}
                                // sx={{ width: "40%" }}
                                style={{ width: '30%', marginLeft: '6px', marginRight: '6px' }}
                                renderInput={(params) => <TextField {...params} />}
                                value={searchClause[index].operand}
                                onChange={(event, value) => onOperandChange(index, value)}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 220
                                        }
                                    }
                                }}
                            />
                            {searchClause[index].field.format!=="Date" ? (
                            
                            <Autocomplete
                              disablePortal
                              disableClearable={!getFieldOptions(searchClause[index].field.fieldName).freeText}
                              multiple={getFieldOptions(searchClause[index].field.fieldName).multiple}
                              freeSolo={getFieldOptions(searchClause[index].field.fieldName).freeText}
                              className="autocomplestyl"
                              id={`autocomplete-${index}`}
                              options={getFieldOptions(searchClause[index].field.fieldName).options}
                              style={{ width: "40%" }}
                              // renderInput={(params) => <TextField {...params} />}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(event) => {
                                    // Prevent invalid characters
                                    if (searchClause[index].field.format === "Number") {
                                      const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"];
                                      if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    maxLength: 10, // Optional: Limit max input length for numbers
                                  }}
                                />
                              )}
                              renderTags={(value, getTagProps) => {
                                const displayedTags = value.slice(0, 2); // Show only the first 2 tags
                                const extraCount = value.length - 2; // Count remaining tags

                                return (
                                  <>
                                    {displayedTags.map((option, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          display: "inline-block",
                                          marginRight: "8px",
                                          backgroundColor: "#f0f0f0",
                                          padding: "2px 6px",
                                          borderRadius: "4px",
                                          fontSize: "0.85rem",
                                        }}
                                        {...getTagProps({ index })}
                                      >
                                        {option}
                                      </span>
                                    ))}
                                    {extraCount > 0 && (
                                      <span
                                        style={{
                                          display: "inline-block",
                                          backgroundColor: "#e0e0e0",
                                          padding: "2px 6px",
                                          borderRadius: "4px",
                                          fontSize: "0.85rem",
                                        }}
                                      >
                                        +{extraCount}
                                      </span>
                                    )}
                                  </>
                                );
                              }}
                              renderOption={(props, option) => {
                                const isSelected = getFieldOptions(searchClause[index].field.fieldName).multiple
                                  ? searchClause[index].value?.includes(option)
                                  : searchClause[index].value === option;

                                return (
                                  <li
                                    {...props}
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent dropdown closure on click
                                      const fieldOptions = getFieldOptions(searchClause[index].field.fieldName);

                                      if (fieldOptions.multiple) {
                                        const currentValues = searchClause[index].value || [];
                                        const updatedValues = isSelected
                                          ? currentValues.filter((item) => item !== option) // Remove if already selected
                                          : [...currentValues, option]; // Add if not selected
                                        onValueChange(index, updatedValues);
                                      } else {
                                        onValueChange(index, isSelected ? "" : option);
                                      }
                                    }}
                                    style={{
                                      backgroundColor: isSelected ? "#f0f0f0" : "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {isSelected && <span style={{ marginRight: 8 }}>✓</span>}
                                    {option}
                                  </li>
                                );
                              }}
                              value={
                                getFieldOptions(searchClause[index].field.fieldName).multiple
                                  ? searchClause[index].value || []
                                  : searchClause[index].value || ""
                              }
                              onChange={() => {}} // Disable default onChange
                              onInputChange={(event, newInputValue) => {
                                if (!getFieldOptions(searchClause[index].field.fieldName).multiple) {
                                  onValueChange(index, newInputValue);
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                getFieldOptions(searchClause[index].field.fieldName).multiple
                                  ? Array.isArray(value) && value.includes(option)
                                  : option === value
                              }
                              getOptionLabel={(option) => (typeof option === "string" ? option : "")}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: 200,
                                  },
                                },
                              }}
                              disabled={
                                searchClause[index].operand.label === "IS EMPTY" ||
                                searchClause[index].operand.label === "IS NOT EMPTY"
                              }
                            />


                          ) :searchClause[index].operand.label !== 'BETWEEN' ?
                            (<Autocomplete
                                disablePortal
                                disableClearable
                                freeSolo // Allows entering custom values
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={searchClause[index].field.format === 'Date' ? relativeDate : []}
                                style={{ width: '40%' }}
                                renderInput={(params) => <TextField {...params} />}

                                // Ensure that value is a string, fallback to empty string if not
                                value={typeof searchClause[index].value === 'string'
                                    ? searchClause[index].value
                                    : searchClause[index].value?.label || ''}

                                onChange={(event, newValue) => {
                                    // If user selects a predefined option
                                    if (typeof newValue === 'string' && relativeDate.includes(newValue)) {
                                        const formattedValue = newValue.match(/^(Today|Yesterday)$/i)
                                                            ? convertRelativeDate(newValue)
                                                            : newValue;
                                        onValueChange(index, formattedValue);
                                    } else {
                                        // Handle custom input
                                        onValueChange(index, newValue);
                                    }
                                }}

                                // Capture free text input when typing
                                onInputChange={(event, newInputValue) => {
                                    onValueChange(index, newInputValue); // Store custom input value in state
                                }}

                                // Ensure string comparison for both custom input and predefined options
                                isOptionEqualToValue={(option, value) => option === value}

                                // Ensure that the correct label is shown for string options
                                getOptionLabel={(option) => typeof option === 'string' ? option : ''}

                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 120
                                        }
                                    }
                                }}
                            />): (
                            <div style={{ width: "40%", position: "relative" }}>
                              <TextField
                                size="small"
                                className="autocomplestyl"
                                id={`date-picker-${index}`}
                                value={formatedDateRangeFunc(searchClause[index].value) || ""}
                                onClick={() => handleOpenPicker(index)}
                                readOnly // Makes the field read-only, only clickable
                                variant="outlined"
                                style={{ cursor: "pointer", width: "100%" }}
                              />
                              {isPickerOpen === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10%",
                                    left: "10%",
                                    width: "max-content",
                                    zIndex: 1000,
                                    background: "#fff",
                                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                                  }}
                                >
                                  <CustomDateRangePicker
                                    indexValue={index}
                                    defaultDateRangeValue={searchClause[index].value || ""}
                                    onDateSelect={handleAccept}
                                    handleCloseDatePicker={handleClosePicker}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        }
                            {/* <TextField
                                className="autocomplestyl"
                                size="small"
                                id="combo-box-demo"
                                options={relativeDate}
                                //defaultValue={'value'}
                                style={{ width: '30%' }}
                                value={searchClause[index].value}
                                onChange={(event) => onValueChange(index, event.target.value)}
                            /> */}
                            <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRemoveField(index)}>
                                <img src={cancelicon} style={{ width: '12px', height: '12px' }} />
                            </div>
                        </div>
                    </div>
                )
            })}

            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', paddingTop: '1rem', paddingBottom: '1rem' }} onClick={handleAddnew}>
                <img src={addicon} style={{ width: '15px', height: '15px' }} />
                <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', marginLeft: '0.5rem' }} > {`Add Filter`} </Typography>
            </div>

        </div>
    )

}
export default Filter;