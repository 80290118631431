import moment from 'moment';
import dayjs from 'dayjs';
import {getOrderType} from './getOrderType';

/**
 * Converts a `YY-MM-DD` or `YYYY-MM-DD` date to a 7-character century-based format.
 * @param {string | number} dateVal - The date value to convert.
 * @returns {string} - The converted century-based date or an empty string.
 */
export const convertYYYYMMDDToCentury = (dateVal) => {
  if (!dateVal) return ""; // Handle null, undefined, or 0

  const dateStr = dateVal.toString();

  // Validate input date (accepts YYYY-MM-DD, YY-MM-DD, or MM/DD/YY)
  const isValidDate =
    moment(dateStr, "YYYY-MM-DD", true).isValid() ||
    moment(dateStr, "YY-MM-DD", true).isValid() ||
    moment(dateStr, "MM/DD/YY", true).isValid();

  if (!isValidDate) return "";

  // Parse the date using the supported formats
  const parsedDate = moment(dateStr, ["YYYY-MM-DD", "YY-MM-DD", "MM/DD/YY"]);

  // Extract components
  const year = parsedDate.year(); // Full year (e.g., 2024 or 1999)
  const century = year >= 2000 ? "1" : "0"; // Determine century (1 for 21st, 0 for 20th)
  const yy = year % 100; // Extract last two digits of the year
  const mm = parsedDate.format("MM"); // Extract month
  const dd = parsedDate.format("DD"); // Extract day

  // Combine into century format
  let finalDate = `${century}${String(yy).padStart(2, "0")}${mm}${dd}`;
  return Number(finalDate);
};


/**
* Converts a century-based date format to MM/DD/YY.
* @param {string | number} centuryDate - The 7-character century-based date.
* @returns {string} - The converted date in MM/DD/YY format or an empty string.
*/
export const convertCenturyToMDY = (centuryDate) => {
  if (centuryDate && moment(centuryDate, "MM/DD/YY", true).isValid()) return centuryDate;
  if (!centuryDate || centuryDate.toString().length !== 7) return ""; // Validate input length

  const dateStr = centuryDate.toString();

  // Extract components
  const century = dateStr[0]; // First digit represents the century
  const year = parseInt(dateStr.substring(1, 3), 10); // Next two digits are the year
  const month = dateStr.substring(3, 5); // MM
  const day = dateStr.substring(5, 7); // DD

  // Determine full year
  const fullYear = century === "1" ? 2000 + year : 1900 + year;

  // Create a moment date object
  const parsedDate = moment(`${fullYear}-${month}-${day}`, "YYYY-MM-DD");

  // Validate date and return formatted value
  return parsedDate.isValid() ? parsedDate.format("MM/DD/YY") : "";
};

/**
* Converts a date from YYYY-MM-DD format to MM/DD/YY format.
* @param {string} dateVal - The input date in YYYY-MM-DD format.
* @returns {string} - The converted date in MM/DD/YY format or an empty string for invalid dates.
*/
export const convertYYYYMMDDToMMDDYY = (dateVal) => {
  if (!dateVal || !moment(dateVal, "YYYY-MM-DD", true).isValid()) return 0; // Validate input
  return moment(dateVal, "YYYY-MM-DD").format("MM/DD/YY");
};
export const convertYYYYMMDDToMMDDYYWithTime = (dateVal) => {
  if (!dateVal || !moment(dateVal, "YYYY-MM-DD HH:mm", true).isValid()) return 0; // Validate input
  return moment(dateVal, "YYYY-MM-DD HH:mm").format("MM/DD/YY HH:mm");
};
export const convertMMDDYYToYYYYMMDD = (dateVal) => {
  if (!dateVal) return ""; // Handle null, undefined, or empty input

  const dateStr = dateVal.toString();

  // Validate the input format (MM/DD/YY)
  const isValidDate = moment(dateStr, "MM/DD/YY", true).isValid();
  if (!isValidDate) return ""; // Return empty string for invalid input

  // Parse the date
  const parsedDate = moment(dateStr, "MM/DD/YY");

  // Format it to YYYY-MM-DD
  const formattedDate = parsedDate.format("YYYY-MM-DD");
  return formattedDate;
};


/**
 * Convert military time to hh:mm
 * @param {*} timeVal
 * @returns
 */
export const convertMilitaryTimeToHHMM = (timeVal) => {
  if (!timeVal || timeVal === 0)
    return "";

  // Convert timeVal to string for manipulation
  let timeString = timeVal.toString();

  // Check if already in HH:mm format (e.g., "12:30")
  if (/^\d{2}:\d{2}$/.test(timeString)) {
    return timeString;
  }
  
  // Pad with zeros if the length is less than 4
  while (timeString.length < 4) {
    timeString = "0" + timeString;
  }

  // Extract hours and minutes parts
  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(2);
  // Return formatted time
  return `${hours}:${minutes}`;
}

/**
 * Convert Military date to YMD
 */
export const convertMilitaryDateToYMD = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ''
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(date).format('MM/DD/YY');
  }
  return '';
}

export const convertMilitaryDateToDDMMYY = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return 'NA'
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(date).format('MM/DD/YY');
  }
  return 'N/A';
}

export const convertMilitaryToYMD = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ''
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
}

/**
 * Convert YMD date to MDY
 * @param {*} dateVal
 * @returns
 */
export const convertYMDToMDY = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return 0
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return moment(dateVal).format('MM/DD/YY');
  }
  return dateVal;
}

/**
 * Convert MDY date to YMD
 * @param {*} dateVal
 * @returns
 */
export const convertMDYToYMD = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return 0
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid() === false) {
    return moment(dateVal).format('YYYY-MM-DD');
  }
  return dateVal;
}



/**
 * Sanitize string & trim characters to match database field size
 */
export const sanitizeStringForDBField = (str, length, removeSpecialChars = false) => {
  if (str === null || typeof str === 'undefined') {
    return '';
  }
  let newStr = str.substring(0, length);
  if (removeSpecialChars) {
    return newStr.replace(/[^a-zA-Z0-9]/g, '');
  }
  return newStr;
}

export const isNullOrZeroOrEmptyOrUndefined = (value) => {
  // Check if the value is undefined, null, or an empty string
  if (value === undefined || value === null || value === '') {
    return true;
  }

  // Check if the value is zero (you can include zero in the conditions if needed)
  if (typeof value === 'number' && value === 0) {
    return true;
  }

  // Check if the value is an empty array
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  // Check if the value is an empty object
  if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
    return true;
  }

  // If none of the conditions match, return false
  return false;
}

export const convertDateToCentury = (dateVal) => {
  // if (!dateVal || dateVal.trim() === "") {
  //     return "";
  // }
  const momentDate = moment(dateVal, ['MM-DD-YY', 'MM/DD/YY', 'MM/DD/YYYY', '1YYMMDD', 'YYYY-MM-DD'], true);

  if (momentDate.isValid()) {
    return "1" + momentDate.format('YYMMDD');
  } else {
    return "";
  }

}


export const formatedDateRangeFunc = (dateValueStr) => {
  // Check if dateValueStr exists and is in the expected format
  if (!dateValueStr || !dateValueStr.includes('|')) {
    return '';
  }

  // Split the dateValueStr to get the start and end dates
  const [startDateStr, endDateStr] = dateValueStr.split('|');

  // Extract the actual date by removing the leading '1'
  const startDate = startDateStr ? dayjs(startDateStr.substring(1), 'YYMMDD') : null;
  const endDate = endDateStr ? dayjs(endDateStr.substring(1), 'YYMMDD') : null;

  // Format the dates in MM/DD/YY format
  const formattedStartDate = startDate ? startDate.format('MM/DD/YY') : '';
  const formattedEndDate = endDate ? endDate.format('MM/DD/YY') : '';

  // Return the formatted date range in MM/DD/YY - MM/DD/YY format
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatDateRange = (dateValue) => {
  const formatSingleDate = (date) => {
    return date ? "1" + moment(date.toDate()).format('YYMMDD') : '';
  };

  const formattedStartDate = formatSingleDate(dateValue[0]);
  const formattedEndDate = formatSingleDate(dateValue[1]);

  return { formattedStartDate, formattedEndDate };
};

export const convertYMDToMonthName = (dateVal, hideYear = true) => {
  if (dateVal === 0 || !dateVal)
    return 0
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return hideYear ? moment(dateVal).format("MMMM D") : moment(dateVal).format("MMMM D, YY");
  }
  return dateVal;
}

export const validateDate = (dateVal, dateFormat = 'military') => {
  switch (dateFormat) {
    case 'military':
      if (dateVal === 0 || !dateVal)
        return false
      let date = dateVal.toString().substring(1)
      date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
      if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return true;
      }
      return false;
      break;

    default:
      break;
  }
}

export const sanitizeString = (str) => {
  return (typeof str === 'string' ? str.trim() : '') === '' ? 'N/A' : str;
}

export const formatCurrency = (amount) => {
  // Convert the amount to a fixed decimal format with 2 places
  // const formattedAmount = Math.abs(amount).toFixed(2);
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return formatUSD(0); // Default to $0.00 for invalid input
  }

  // Check if the amount is negative and add a minus sign before the dollar sign if so
  // return formattedAmount < 0 ? `-${formatUSD(formattedAmount)}` : `${formatUSD(formattedAmount)}`;
  return numericAmount < 0
    ? `-${formatUSD(Math.abs(numericAmount))}`
    : formatUSD(numericAmount);

}

export const formatPercentageNum = (value) => {
  // const formattedNumber = Math.abs(value).toFixed(2);
  const formattedNumber = parseFloat(value);
  if (isNaN(formattedNumber)) {
    return formatUSD(0); // Default to $0.00 for invalid input
  }
  // Check if the amount is negative and add a minus sign before the dollar sign if so
  return formattedNumber < 0 ? `-${formatPercentage(formattedNumber)}` : `${formatPercentage(formattedNumber)}`;
}

export const formatUSD = (amount) => {
  //return new Intl.NumberFormat("en-US", {
  return Number(amount).toLocaleString('en-US', {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }); //.format(amount);
}

export const formatPercentage = (value) => {
  return (value / 100).toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // return new Intl.NumberFormat("en-US", {
  //   style: "percent",
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2
  // }).format(value / 100); // Divide by 100 to convert from whole number to percentage
}

export function formatToK(number) {
  if (typeof number !== 'number' || isNaN(number)) {
    console.log('Input must be a valid number');
    return false;
  }

  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // Converts to "k" format
  }

  return number.toString(); // Return as is if less than 1000
}

export const splitOrderNotes = (notes) => {
  if (notes.length === 0) {
    return notes; // Return empty array if notes is empty
  }

  const result = [];

  notes.forEach(noteObj => {
    const { userId, note, time } = noteObj;

    // Split the note into chunks if its length is greater than 75
    if (note.length > 75) {
      let currentNote = note;
      while (currentNote.length > 75) {
        // Find the last space within the first 75 characters
        const splitIndex = currentNote.lastIndexOf(' ', 75);

        // If there's no space (in case the note is a single long word), break at 75 characters
        if (splitIndex === -1) {
          result.push({
            userId,
            note: currentNote.slice(0, 75),
            time
          });
          currentNote = currentNote.slice(75); // Continue with the remaining part
        } else {
          result.push({
            userId,
            note: currentNote.slice(0, splitIndex),
            time
          });
          currentNote = currentNote.slice(splitIndex + 1); // Skip the space and continue
        }
      }

      // Push the remaining part of the note (if any)
      if (currentNote.length > 0) {
        result.push({
          userId,
          note: currentNote,
          time
        });
      }
    } else {
      // If the note is not longer than 75 characters, just push it as is
      result.push(noteObj);
    }
  });

  return result;
};

export const getOrderTypeAbbr = (type) => {
    return getOrderType(type)
}

// Utility function to extract numeric value from a string
function parseCurrency(value) {
  return Number(value.replace('$', '').replace(',', '')) || 0;
}

// Utility function for sorting currency values
export const  currencySortComparator=(v1, v2) =>{
  return parseCurrency(v1) - parseCurrency(v2);
}


export const getDateRangeLastYear=()=> {
  const currentDate = moment(); // Current date as a moment object
    const lastYearDate = moment().subtract(1, 'years'); // Date one year ago as a moment object

    return [lastYearDate, currentDate];

  // const { formattedStartDate, formattedEndDate } = formatDateRange([
  //       lastYearDate.toISOString(),
  //       currentDate.toISOString()
  //   ]);
  // const formatDate = formattedStartDate + "|" + formattedEndDate; 
  // return formatDate
}

//new
// Convert MM/DD/YYYY to MM/DD/YY format
export const convertMDYToShortFormat = (mdy) => {
  // Check if mdy is a valid string with the format MM/DD/YYYY
  if (!mdy || typeof mdy !== 'string' || mdy.split('/').length !== 3) {
      return ''; // Return empty string or any default value for invalid input
  }
  const [month, day, year] = mdy.split('/');
  return `${month}/${day}/${year.slice(-2)}`; // Take the last two digits of the year
};

// Combine MM/DD/YYYY date and time into MM/DD/YY HH:mm format
// export const formatDateTime = (mdy, time) => {
//   const shortDate = convertMDYToShortFormat(mdy); // Shortens the date format
//   const formattedTime = time ? time : '00:00'; // Use '00:00' if no time is provided
//   return `${shortDate} ${formattedTime}`;
// };
export const formatDateTime = (mdy, time) => {
  const shortDate = convertToShortFormat(mdy); // Converts date to MM/DD/YY format
  const formattedTime = time ? extractTime(time) : ''; // Extracts HH:mm from input
  return `${shortDate} ${formattedTime}`;
};

// const convertToShortFormat = (date) => {
//   if (/^\d{2}\/\d{2}\/\d{2}$/.test(date)) {
//     // Already in MM/DD/YY format
//     return date;
//   } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(date)) {
//     // Convert YYYY-MM-DDTHH:mm to MM/DD/YY
//     const parsedDate = new Date(date);
//     return `${String(parsedDate.getMonth() + 1).padStart(2, '0')}/${String(parsedDate.getDate()).padStart(2, '0')}/${String(parsedDate.getFullYear()).slice(-2)}`;
//   }
//   return 'Invalid Date';
// };

const convertToShortFormat = (date) => {
  if (/^\d{2}\/\d{2}\/\d{2}$/.test(date)) {
    // Already in MM/DD/YY format
    return date;
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(date)) {
    // Convert YYYY-MM-DDTHH:mm to MM/DD/YY
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
      return '';
    }
    return `${String(parsedDate.getMonth() + 1).padStart(2, '0')}/${String(parsedDate.getDate()).padStart(2, '0')}/${String(parsedDate.getFullYear()).slice(-2)}`;
  }
  return '';  // Return an empty string if no valid format is matched
};

const extractTime = (time) => {
  if (typeof time !== 'string') {
    return '';
  }

  // Check if time is already in HH:mm format
  if (/^\d{2}:\d{2}$/.test(time)) {
    return time; // Return as is if already in correct format
  }

  // Extract HH:mm if present
  const match = time.match(/\d{2}:\d{2}/);
  return match ? match[0] : '';
};

// Format ISO date (YYYY-MM-DDTHH:mm) to MM/DD/YY HH:mm
export const formatISOToMDYTime = (isoDate) => {
  if (!isoDate) return '';
  const [datePart, timePart] = isoDate.split('T');
  const [year, month, day] = datePart.split('-');
  const shortDate = `${month}/${day}/${year.slice(-2)}`; // MM/DD/YY
  return `${shortDate} ${timePart || ''}`; // MM/DD/YY HH:mm
};

export const validateTimeField = (timeVal) => {
  if (!timeVal) return '';
 
  // Ensure timeVal is a string
  timeVal = String(timeVal);
 
  // Check if input is exactly 4 digits (HHMM)
  if (/^\d{4}$/.test(timeVal)) {
      let hours = parseInt(timeVal.substring(0, 2), 10);
      let minutes = parseInt(timeVal.substring(2, 4), 10);
 
      // Validate hours (00-23) and minutes (00-59)
      if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
          return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`; // Return in HH:MM format
      }
  }
 
  // If input is invalid, return as is (or handle differently)
  return timeVal;
};
export const isDateDiffGreaterThan31=(dateRange)=>{
  const formattedStart = convertMilitaryToYMD(dateRange[0]);
  const formattedEnd = convertMilitaryToYMD(dateRange[1]);
  
  const startDate = new Date(formattedStart);
  const endDate = new Date(formattedEnd);
  
  const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  return diffInDays > 31;
}

// Utility function for sorting percentage values
export const percentageSortComparator = (v1, v2) => {
  return parsePercentage(v1) - parsePercentage(v2);
};

// Function to parse formatted percentage strings back into numbers
export const parsePercentage = (value) => {
  const numericValue = parseFloat(value);
  return isNaN(numericValue) ? 0 : numericValue;
};

export const safeParseFloat = (value) => isNaN(parseFloat(value)) ? 0 : parseFloat(value);
export const safeParseNumber = (value) => isNaN(Number(value)) ? 0 : Number(value);