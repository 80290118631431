import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CommonTable from './CommonTable';
import { useSelector } from 'react-redux';
import { useDispatchContext } from "../dispatchContext";

export default function TabsComponent(props) {
  const {
    tabname,
    rowData,
    handleTabChange,
    tableColumns,
    isLoading
  } = props
  const [value, setValue] = useState(0);
  const { dispatchUnsavedChangesRef, hasUnsavedRows, setHasUnsavedRows } = useDispatchContext();

  const handleChange = (event, newValue) => {
    if (hasUnsavedRows) {
      alert("You have unsaved data. Please save or discard before you proceed.");
      return; // If user cancels, do not reset tab
    }
    // If no unsaved changes proceed with tab switch
    setValue(newValue);
    handleTabChange(event, newValue);
  };

  const reloadTablesOnTender = useSelector(
    ({ dispatchReducer }) => dispatchReducer.reloadTablesOnTender
  );

  useEffect(() => {
    if (reloadTablesOnTender) {
      setValue(value);
    }
  }, [reloadTablesOnTender])

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ minHeight: "35px", height: "35px" }}
          aria-label="wrapped label tabs example"
        >
          {
            tabname.map((tabitem, index) => (
              <Tab className='MuiButtonBase-root-tabstyle' sx={{zIndex:99}} value={index} label={`${tabitem?.label} (${tabitem?.loadCount || 0})`} key={index} />
            ))
          }
        </Tabs>

      </Box>
      {
        // <div style={{ overflow: 'auto', height: '77%' }}>
        //   <CommonTable rowData={rowData} tableColumns={tableColumns} isLoading={isLoading} />
        // </div>

      }
    </>
  );
}
